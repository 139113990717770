import { Button, CircularProgress, Divider, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Paper, Select, Theme, Typography, createStyles, makeStyles, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import { Data } from "../../../framework/data";
import { RegisterToTournament, TournamentHeader } from "../../../store/tournaments/models";
import React from "react";
import { groupBy } from "ramda";
import { connect } from "react-redux";
import { AppState } from "../../../store/reducer";
import { Dispatch } from "redux";
import { loadTournaments } from "../../../store/tournaments/actions";
import { selectTournamentsSubscribedTo } from "../../../store/tournaments/selectors";
import { setTournamentToRegister as setTournamentToRegisterAction } from "../../../store/registration/actions"
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@material-ui/lab";

interface TournamentSelectorProps {
    tournaments: Data<TournamentHeader[]>
    loadTournaments: (token: string, year: string) => void;
    onProceed: () => void;
    tournamentToRegister: RegisterToTournament;
    setTournamentToRegister: (x: RegisterToTournament) => void;
    subscribedTo: Data<string[]>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 280,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        selectPanel: {
            borderLeft: 0,
            borderRight: 0,
            margin: '0 -32px',
            padding: '16px 32px',
            backgroundColor: theme.palette.grey[100]
        },
        errorMessage: {
            color: theme.palette.error.main,
            marginLeft: 4,
            marginTop: theme.spacing(2)
        }
    }),
);

const TournamentSelector: React.FC<TournamentSelectorProps> = ({ tournaments, loadTournaments, onProceed, tournamentToRegister, setTournamentToRegister, subscribedTo }) => {

    const classes = useStyles();

    const [touched, setTouched] = React.useState<boolean>(false);

    const { getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {
        getAccessTokenSilently().then((token) => {
            const yearToRegister = new Date().getFullYear() >= 2024 ? new Date().getFullYear().toString() : "2024";
            loadTournaments(token, yearToRegister);
        })
    }, []);

    const onLeagueChange = (e: React.ChangeEvent<any>) => {

        const season = (tournaments.type === "LOADED" ? tournaments.value.find(x => x.id === e.target.value) : undefined)?.year;

        setTournamentToRegister({
            tournament: {
                id: e.target.value,
                season: season ?? ""
            },
            secondPlayerLastName: tournamentToRegister.secondPlayerLastName,
            secondPlayerName: tournamentToRegister.secondPlayerName,
            secondPlayerNeeded: tournamentToRegister.secondPlayerNeeded
        });

        setTouched(true);
    }

    const onSecondPlayerNameChange = (x: string) => {
        setTournamentToRegister({
            tournament: {
                id: tournamentToRegister.tournament.id,
                season: tournamentToRegister.tournament.season,

            },
            secondPlayerName: x,
            secondPlayerLastName: tournamentToRegister.secondPlayerLastName,
            secondPlayerNeeded: false
        });
    }

    const onSecondPlayerSurnameChange = (x: string) => {
        setTournamentToRegister({
            tournament: {
                id: tournamentToRegister.tournament.id,
                season: tournamentToRegister.tournament.season,

            },
            secondPlayerName: tournamentToRegister.secondPlayerName,
            secondPlayerLastName: x,
            secondPlayerNeeded: false
        });
    }

    const onSecondPlayerNeededChanged = (x: boolean) => {
        setTournamentToRegister({
            tournament: {
                id: tournamentToRegister.tournament.id,
                season: tournamentToRegister.tournament.season,

            },
            secondPlayerName: "",
            secondPlayerLastName: "",
            secondPlayerNeeded: x
        });
    }



    const onSubmit = () => {
        if (tournamentToRegister.tournament?.id) {
            onProceed();
        }

        setTouched(true);
    }

    const isDisabled = () => {
        if (tournaments.type !== "LOADED") return true;
        if (!tournamentToRegister.tournament.id) return true;

        return false;
    }

    return <>
        <Paper variant="outlined" className={classes.selectPanel} square>
            <Typography variant="subtitle1" style={{ marginLeft: 8 }}>Wybierz turniej</Typography>
            {
                tournaments.type === "LOADED" && subscribedTo.type === "LOADED" ? (
                    <Grid container alignItems="center">
                        <Grid item>
                            <FormControl className={classes.formControl} margin="normal" variant="outlined">
                                <InputLabel htmlFor="leagues-select">Turniej</InputLabel>
                                <Select defaultValue="" id="leagues-select" labelWidth={32} value={tournamentToRegister.tournament?.id} onChange={onLeagueChange}>
                                    {
                                        Object.entries(groupBy(x => x.city, tournaments.value.filter(l => !subscribedTo.value.includes(l.id) && l.participantsCount < l.maxPlayers))).map(([city, cityLeagues]) => {
                                            return [
                                                <ListSubheader key={city}>{city}</ListSubheader>,
                                                ...cityLeagues.map(cl => (
                                                    <MenuItem value={cl.id} key={cl.id}>{cl.name}</MenuItem>
                                                ))
                                            ]
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                ) : (
                        <div
                            style={{
                                width: "64px",
                                height: "64px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )
            }

        </Paper>
        {
            tournaments.type === "LOADED" && isDoubles(tournaments.value, tournamentToRegister.tournament.id) ? (<>

                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Alert severity="info" style={{ marginLeft: 8 }}>
                        Wybrany turniej jest turniejem deblowym. Proszę podać imię i nazwisko partnera deblowego, lub wskazać jego brak (wtedy z podobnych uczestników dobrany zostanie partner deblowy)
                    </Alert>

                    <Grid container spacing={3} style={{ marginLeft: -4, marginTop: 8 }}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Imię partnera deblowego"
                                variant="outlined"
                                value={tournamentToRegister.secondPlayerName}
                                style={{ width: "100%" }}
                                onChange={(e) => onSecondPlayerNameChange(e.target.value)}
                                error={(isDoubles(tournaments.value, tournamentToRegister.tournament.id) && !tournamentToRegister.secondPlayerName && !tournamentToRegister.secondPlayerNeeded)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Nazwisko partnera deblowego"
                                variant="outlined"
                                value={tournamentToRegister.secondPlayerLastName}
                                style={{ width: "100%" }}
                                onChange={(e) => onSecondPlayerSurnameChange(e.target.value)}
                                error={(isDoubles(tournaments.value, tournamentToRegister.tournament.id) && !tournamentToRegister.secondPlayerLastName && !tournamentToRegister.secondPlayerNeeded)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={tournamentToRegister.secondPlayerNeeded}
                                        onChange={(_, checked) => onSecondPlayerNeededChanged(checked)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Nie mam partnera deblowego"
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </>) : (<></>)
        }

        <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
        <Button color="primary" variant="contained" onClick={onSubmit} disabled={isDisabled()}>
            Dalej
        </Button>
    </>;
}

const mapStateToProps = (state: AppState) => ({
    tournaments: state.tournaments.items,
    tournamentToRegister: state.registration.tournamentSelection,
    subscribedTo: selectTournamentsSubscribedTo(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTournaments: (token: string, season: string) => dispatch(loadTournaments({ year: season, token })),
    setTournamentToRegister: (x: RegisterToTournament) => dispatch(setTournamentToRegisterAction(x))
});

const isDoubles = (tournaments: TournamentHeader[], id: string) => tournaments.find(l => l.id === id)?.isDoubles;

export default connect(mapStateToProps, mapDispatchToProps)(TournamentSelector);