import { ActionCreator } from "redux";
import { LOAD_USER_PLAYER, LOAD_USER_PLAYER_FAILED, LOAD_USER_PLAYER_SUCCESS, CREATE_PLAYER, CREATE_PLAYER_FAILED, CREATE_PLAYER_SUCCESS, UPDATE_PLAYER, UPDATE_PLAYER_FAILED, UPDATE_PLAYER_SUCCESS, SAVE_PLAYER, LOAD_ALL_PLAYERS, LOAD_ALL_PLAYERS_SUCCESS } from "./actionTypes";
import { ActionWithError, ActionWithPayload, ActionWithErrorCreator, ActionWithPayloadCreator, WithNotification, Severity } from "../../framework/action";
import { Player } from "./models";
import { PlayerType } from "../registration/model";

export const loadUserPlayer: ActionCreator<LoadUserPlayer> = (token) => ({ type: LOAD_USER_PLAYER, payload: token });
export const loadUserPlayerFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_USER_PLAYER_FAILED, error, description: "Nie udało nam się załadować Twoich danych. Spróbuj wylogować się i zalogować ponownie." });
export const loadUserPlayerSuccess: ActionWithPayloadCreator<Player> = (player) => ({ type: LOAD_USER_PLAYER_SUCCESS, payload: player });

export const createPlayer: ActionWithPayloadCreator<PlayerApiPayload> = ({player, playerType, token}) => ({ type: CREATE_PLAYER, payload: { player, token, playerType } });
export const createPlayerFailed: ActionWithErrorCreator = (error) => ({ type: CREATE_PLAYER_FAILED, error, description: "Nie udało nam się zapisać Twoich danych. Spróbuj wylogować się i zalogować ponownie." });
export const createPlayerSuccess: ActionWithPayloadCreator<Player> = (player) => ({ type: CREATE_PLAYER_SUCCESS, payload: player, notification: { severity: Severity.Success, message: 'Twój profil zawodnika został utworzony.' } });

export const updatePlayer: ActionWithPayloadCreator<PlayerApiPayload> = ({player, playerType, token}) => ({ type: UPDATE_PLAYER, payload: { player, token, playerType } });
export const updatePlayerFailed: ActionWithErrorCreator = (error) => ({ type: UPDATE_PLAYER_FAILED, error, description: "Nie udało nam się zapisać Twoich danych. Spróbuj wylogować się i zalogować ponownie." });
export const updatePlayerSuccess: ActionWithPayloadCreator<Player> = (player) => ({ type: UPDATE_PLAYER_SUCCESS, payload: player, notification: { severity: Severity.Success, message: 'Twoje dane zostały zapisane.' } });

export const savePlayer: ActionWithPayloadCreator<PlayerApiPayload> = ({ player, token, playerType }) => ({ type: SAVE_PLAYER, payload: { token, player, playerType } });

export const loadAllPlayers: ActionWithPayloadCreator<{token: string}> = ({ token }) => ({ type: LOAD_ALL_PLAYERS, payload: { token } });
export const loadAllPlayersSuccess: ActionWithPayloadCreator<Player[]> = (players) => ({ type: LOAD_ALL_PLAYERS_SUCCESS, payload: players });

export interface LoadUserPlayer extends ActionWithPayload<string> {
    type: typeof LOAD_USER_PLAYER;
}

export interface LoadUserPlayerFailed extends ActionWithError {
    type: typeof LOAD_USER_PLAYER_FAILED;
}

export interface LoadUserPlayerSuccess extends ActionWithPayload<Player> {
    type: typeof LOAD_USER_PLAYER_SUCCESS;
}

export interface PlayerApiPayload {
    player: Player,
    playerType?: PlayerType,
    token: string,
}

export interface CreatePlayer extends ActionWithPayload<PlayerApiPayload> {
    type: typeof CREATE_PLAYER;
}

export interface CreatePlayerFailed extends ActionWithError {
    type: typeof CREATE_PLAYER_FAILED;
}

export interface CreatePlayerSucceess extends ActionWithPayload<Player>, WithNotification {
    type: typeof CREATE_PLAYER_SUCCESS;
}

export interface UpdatePlayer extends ActionWithPayload<PlayerApiPayload> {
    type: typeof UPDATE_PLAYER;
}

export interface UpdatePlayerFailed extends ActionWithError {
    type: typeof UPDATE_PLAYER_FAILED;
}

export interface UpdatePlayerSuccess extends ActionWithPayload<Player>, WithNotification {
    type: typeof UPDATE_PLAYER_SUCCESS;
}

export interface SavePlayer extends ActionWithPayload<PlayerApiPayload> {
    type: typeof SAVE_PLAYER;
}

export interface LoadAllPlayers extends ActionWithPayload<{token: string}> {
    type: typeof LOAD_ALL_PLAYERS;
}

export interface LoadAllPlayersSuccess extends ActionWithPayload<Player[]> {
    type: typeof LOAD_ALL_PLAYERS_SUCCESS;
}

export type Actions =
 | LoadUserPlayer
 | LoadUserPlayerFailed
 | LoadUserPlayerSuccess
 | CreatePlayer
 | CreatePlayerFailed
 | CreatePlayerSucceess
 | UpdatePlayer
 | UpdatePlayerFailed
 | UpdatePlayerSuccess
 | SavePlayer
 | LoadAllPlayers
 | LoadAllPlayersSuccess;