import { ActionWithPayload2, ActionWithPayloadCreator2, ActionCreator2, Action2, ActionWithError2, ActionWithErrorCreator2 } from "../../framework/action";
import { SET_LEAGUE_TO_REGISTER, SET_REGISTRATION_PLAYER_TYPE, SET_UPDATED_PLAYER, SEND_SMS_VERIFICATION_CODE, VERIFY_SMS_CODE, VERIFY_SMS_CODE_SUCCESS, VERIFY_SMS_CODE_FAILED, GET_PRICE_INFO_FOR_LEAGUE, GET_PRICE_INFO_FOR_LEAGUE_SUCCESS, GET_PRICE_INFO_FOR_LEAGUE_FAILED, MAKE_REGISTRATION, MAKE_REGISTRATION_SUCCESS, MAKE_REGISTRATION_FAILED, SEND_REGISTRATION_MESSAGE, SEND_SMS_VERIFICATION_CODE_SUCCESS, SEND_SMS_VERIFICATION_CODE_FAILED, SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE, SET_TOURNAMENT_TO_REGISTER, SET_TRAINING_TO_REGISTER } from "./actionTypes";
import { RegisterToLeague, PlayerType, PriceInfo, RegistrationData } from "./model";
import { Player } from "../players/models";
import { RegisterToTournament } from "../tournaments/models";
import { RegisterToTraining } from "../trainings/models";

export interface SetLeagueToRegister extends ActionWithPayload2<typeof SET_LEAGUE_TO_REGISTER, RegisterToLeague> { }
export interface SetRegistrationPlayerType extends ActionWithPayload2<typeof SET_REGISTRATION_PLAYER_TYPE, PlayerType> { }
export interface SetUpdatedPlayer extends ActionWithPayload2<typeof SET_UPDATED_PLAYER, Player|undefined> { }

export interface SendSmsVerificationCode extends ActionWithPayload2<typeof SEND_SMS_VERIFICATION_CODE, { token: string, phoneNumber: string }> { }
export interface SendSmsVerificationCodeSuccess extends ActionWithPayload2<typeof SEND_SMS_VERIFICATION_CODE_SUCCESS, string> { }
export interface SendSmsVerificationCodeFailed extends ActionWithError2<typeof SEND_SMS_VERIFICATION_CODE_FAILED> { }

export interface VerifySmsCode extends ActionWithPayload2<typeof VERIFY_SMS_CODE, { token: string, code: string }> { }
export interface VerifySmsCodeSuccess extends Action2<typeof VERIFY_SMS_CODE_SUCCESS> { }
export interface VerifySmsCodeFailed extends ActionWithError2<typeof VERIFY_SMS_CODE_FAILED> { }

export interface GetPriceInfoForLeague extends ActionWithPayload2<typeof GET_PRICE_INFO_FOR_LEAGUE, { leagueId: string, leagueYear: string, promoCode: string, referral: string, playerId?: string }> {}
export interface GetPriceInfoForLeagueSuccess extends ActionWithPayload2<typeof GET_PRICE_INFO_FOR_LEAGUE_SUCCESS, PriceInfo> {}
export interface GetPriceInfoForLeagueFailed extends ActionWithError2<typeof GET_PRICE_INFO_FOR_LEAGUE_FAILED> {}

export interface MakeRegistration extends ActionWithPayload2<typeof MAKE_REGISTRATION, RegistrationData> { }
export interface MakeRegistrationSuccess extends ActionWithPayload2<typeof MAKE_REGISTRATION_SUCCESS, any> { }
export interface MakeRegistrationFailed extends ActionWithError2<typeof MAKE_REGISTRATION_FAILED> { }

export interface SendRegistrationMessage extends ActionWithPayload2<typeof SEND_REGISTRATION_MESSAGE, string> { }

export interface SetRegistrationForPlayerTypeUpgrade extends ActionWithPayload2<typeof SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE, { leagueId: string, leagueYear: string, player: Player }> { }

export interface SetTournamentToRegister extends ActionWithPayload2<typeof SET_TOURNAMENT_TO_REGISTER, RegisterToTournament> { }
export interface SetTrainingToRegister extends ActionWithPayload2<typeof SET_TRAINING_TO_REGISTER, RegisterToTraining> { }

export const setLeagueToRegister: ActionWithPayloadCreator2<SetLeagueToRegister> = (payload) => ({
    type: "SET_LEAGUE_TO_REGISTER",
    payload
});

export const setTournamentToRegister: ActionWithPayloadCreator2<SetTournamentToRegister> = (payload) => ({
    type: "SET_TOURNAMENT_TO_REGISTER",
    payload
});

export const setTrainingToRegister: ActionWithPayloadCreator2<SetTrainingToRegister> = (payload) => ({
    type: "SET_TRAINING_TO_REGISTER",
    payload
});

export const setRegistrationPlayerType: ActionWithPayloadCreator2<SetRegistrationPlayerType> = (payload) => ({
    type: "SET_REGISTRATION_PLAYER_TYPE",
    payload
});

export const setUpdatedPlayer: ActionWithPayloadCreator2<SetUpdatedPlayer> = (payload) => ({
    type: "SET_UPDATED_PLAYER",
    payload
});

export const sendSmsVerificationCode : ActionWithPayloadCreator2<SendSmsVerificationCode> = (payload) => ({
    type: "SEND_SMS_VERIFICATION_CODE",
    payload
});

export const sendSmsVerificationCodeSuccess : ActionWithPayloadCreator2<SendSmsVerificationCodeSuccess> = (payload) => ({
    type: "SEND_SMS_VERIFICATION_CODE_SUCCESS",
    payload
});

export const sendSmsVerificationCodeFailed : ActionWithErrorCreator2<SendSmsVerificationCodeFailed> = (error) => ({
    type: "SEND_SMS_VERIFICATION_CODE_FAILED",
    description: "Nie udało się wysłać kodu potwierdzającego przez SMS. Sprawdź swój numer telefonu - być może ten numer został już wcześniej podany w rejestracji innego zawodnika?",
    error
})

export const verifySmsCode: ActionWithPayloadCreator2<VerifySmsCode> = (payload) => ({
    type: "VERIFY_SMS_CODE",
    payload
});

export const verifySmsCodeSuccess: ActionCreator2<VerifySmsCodeSuccess> = () => ({
    type: "VERIFY_SMS_CODE_SUCCESS"
});

export const verifySmsCodeFailed: ActionWithErrorCreator2<VerifySmsCodeFailed> = () => ({
    type: "VERIFY_SMS_CODE_FAILED",
    description: "Błędny kod",
    error: ''
});

export const getPriceInfoForLeague: ActionWithPayloadCreator2<GetPriceInfoForLeague> = (payload) => ({
    type: "GET_PRICE_INFO_FOR_LEAGUE",
    payload
});

export const getPriceInfoForLeagueSuccess: ActionWithPayloadCreator2<GetPriceInfoForLeagueSuccess> = (payload) => ({
    type: "GET_PRICE_INFO_FOR_LEAGUE_SUCCESS",
    payload
});

export const getPriceInfoForLeagueFailed: ActionWithErrorCreator2<GetPriceInfoForLeagueFailed> = (error) => ({
    type: "GET_PRICE_INFO_FOR_LEAGUE_FAILED",
    error,
    description: "Nie udało nam się obliczyć obniżonej ceny. Spróbuj ponownie."
});

export const makeRegistration: ActionWithPayloadCreator2<MakeRegistration> = (payload) => ({
    type: "MAKE_REGISTRATION",
    payload
});

export const makeRegistrationSuccess: ActionWithPayloadCreator2<MakeRegistrationSuccess> = (payload) => ({
    type: "MAKE_REGISTRATION_SUCCESS",
    payload
});

export const makeRegistrationFailed: ActionWithErrorCreator2<MakeRegistrationFailed> = (error, { description = "Nie udało się zakończyć rejestracji. Spróbuj ponownie."}) => ({
    type: "MAKE_REGISTRATION_FAILED",
    error,
    description
});

export const sendRegistrationMessage: ActionWithPayloadCreator2<SendRegistrationMessage> = (payload) => ({
    type: "SEND_REGISTRATION_MESSAGE",
    payload
});

export const setRegistrationForPlayerTypeUpgrade: ActionWithPayloadCreator2<SetRegistrationForPlayerTypeUpgrade> = (payload) => ({
    type: "SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE",
    payload
});

export type Actions = 
    | SetTournamentToRegister
    | SetTrainingToRegister
    | SetLeagueToRegister
    | SetRegistrationPlayerType
    | SetUpdatedPlayer
    | SendSmsVerificationCode
    | SendSmsVerificationCodeSuccess
    | SendSmsVerificationCodeFailed
    | VerifySmsCode
    | VerifySmsCodeSuccess
    | VerifySmsCodeFailed
    | GetPriceInfoForLeague
    | GetPriceInfoForLeagueSuccess
    | GetPriceInfoForLeagueFailed
    | MakeRegistration
    | MakeRegistrationSuccess
    | MakeRegistrationFailed
    | SendRegistrationMessage
    | SetRegistrationForPlayerTypeUpgrade;