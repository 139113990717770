import React from "react";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import {
  Card,
  Avatar,
  CardContent,
  Typography,
  makeStyles,
  Divider,
  CircularProgress,
  Button,
  Link,
} from "@material-ui/core";
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { format } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import { useAuth0 } from "@auth0/auth0-react";

interface ShoppingInfoProps {
  player: Data<Player>;
}

const useStyles = makeStyles(({ palette }) => ({
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: "0.875em",
  },
  card: {
    textAlign: "center",
  },
  avatar: {
    margin: "auto",
  },
  divider: {
    margin: "8px 0",
  },
}));

const ShoppingInfo: React.FC<ShoppingInfoProps> = ({ player }) => {

  const styles = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <Card className={styles.card}>
      <CardContent>
        {player.type === "LOADED" ? (
          <>
            <Avatar className={styles.avatar}>
              <PersonPinIcon></PersonPinIcon>
            </Avatar>
            <Typography variant="h6">
              {player.value.name} {player.value.surname}
            </Typography>
            <Typography variant="caption" className={styles.subheader}>
              Z nami od: {format(new Date(player.value.joined!), "yyyy-MM-dd")}
            </Typography>
            <Divider className={styles.divider}></Divider>
            <Typography variant="caption" className={styles.subheader}>
              SmartPunkty
            </Typography>
            <Typography variant="h4">
              {player.value.rewardPoints ?? 0}
            </Typography>
            <Button color="secondary" href="/#/shop/orders" >
              Moje zamówienia
            </Button>
          </>
        ) : player.type === "LOADING" ? (
          <div
            style={{
              width: "64px",
              height: "64px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
              <Alert severity="warning">
                <Link color="textPrimary" href="" onClick={() => loginWithRedirect({
                  redirectUri: window.location.href, appState: {
                    targetUrl: window.location.hash
                  }
                })}><b>Zaloguj się,</b></Link> aby uzyskać informacje o graczu oraz kontynuować zakupy.
          </Alert>
            )}
      </CardContent>
    </Card>
  );
};

export default ShoppingInfo;
