import { DayOfWeek, SportCardType, CourtType, SurfaceType, WorkingHours, PriceList } from "../../store/clubs/models";

export const describeDayOfWeek = (d: DayOfWeek) => {
    switch(d) {
        case DayOfWeek.Friday: return "Piątek";
        case DayOfWeek.Monday: return "Poniedziałek";
        case DayOfWeek.Saturday: return "Sobota";
        case DayOfWeek.Sunday: return "Niedziela";
        case DayOfWeek.Thursday: return "Czwartek";
        case DayOfWeek.Tuesday: return "Wtorek";
        case DayOfWeek.Wednesday: return "Środa";
        case DayOfWeek.Weekday: return "Pon. - Pt."
        default: return "";
    }
}

export const describeSportCardType = (s: SportCardType) => {
    switch(s) {
        case SportCardType.Multisport: return "MultiSport";
        case SportCardType.OkSystem: return "OkSystem";
        default: return "";
    }
}

export const describeCourtType = (c: CourtType) => {
    switch(c) {
        case CourtType.Baloon: return "Balon";
        case CourtType.Hall: return "Hala";
        case CourtType.Outdoor: return "Na wolnym powietrzu";
        default: return "";
    }
}

export const describeSurfaceType = (s: SurfaceType) => {
    switch(s) {
        case SurfaceType.ArtificialClay: return "Ceglana mączka";
        case SurfaceType.Clay: return "Ceglana mączka";
        case SurfaceType.ArtifilicialGrass: return "Sztuczna trawa";
        case SurfaceType.Carpet: return "Dywanowa";
        case SurfaceType.Hard: return "Twarda";
        default: return "";
    }
};

export const describeBoolean = (x: boolean) => x ? "Tak" : "Nie";

export const canWorkingHoursBeShortened = (w: WorkingHours[] | undefined) => {
    if(!w || w.length < 7) {
        return false;
    }

    let theSame = true;
    const wSorted = w.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
    for(let i = 2; i < 6; i++) {
        if(wSorted[i].closeTime !== wSorted[i-1].closeTime || wSorted[i].openTime !== wSorted[i-1].openTime) {
            theSame = false;
        }
    }

    return theSame;
}

export const shortenedWorkingHours = (w: WorkingHours[]|undefined) => {
    if(!w) {
        return [];
    }

    const wSorted = w.sort((a, b) => a.dayOfWeek - b.dayOfWeek);

    return <WorkingHours[]>[
        { dayOfWeek: DayOfWeek.Weekday, closeTime: wSorted[1].closeTime, openTime: wSorted[1].openTime },
        { dayOfWeek: DayOfWeek.Saturday, closeTime: wSorted[6].closeTime, openTime: wSorted[6].openTime },
        { dayOfWeek: DayOfWeek.Sunday, closeTime: wSorted[0].closeTime, openTime: wSorted[0].openTime }
    ]
}

export const canPriceListBeShortened = (w: PriceList[] | undefined) => {
    if(!w) {
        return false;
    }

    return w
        .filter(x => x.dayOfWeek === DayOfWeek.Monday)
        .map(p => w.filter(x => x.closeTime === p.closeTime && x.openTime === p.openTime && x.price === p.price).length === 5)
        .reduce((x, y) => x && y, true);    
}

export const shortenedPriceList = (w: PriceList[] | undefined) => {
    if(!w) {
        return [];
    }

    return [
        ...w.filter(x => x.dayOfWeek === DayOfWeek.Monday).map<PriceList>(({ closeTime, openTime, price }) => ({ dayOfWeek: DayOfWeek.Weekday, closeTime, openTime, price  })),
        ...w.filter(x => x.dayOfWeek === DayOfWeek.Saturday),
        ...w.filter(x => x.dayOfWeek === DayOfWeek.Sunday)
    ];
}