import React from "react";
import { Clubs } from "../../store/leagues/models";
import { Grid, Typography } from "@material-ui/core";

interface OpenLeagueClubsProps {
    clubs: Clubs[];
}

const OpenLeagueClubs: React.FC<OpenLeagueClubsProps> = ( {clubs} ) => {
    return (
        <>
        <Typography variant="body1">
            Kluby wspierające to:
        </Typography>
        <Grid container spacing={3} style={{ margin: 0 }}>
            { clubs.filter(x => !!x.logoUrl).map(c => (<Grid item key={c.id}><img src={c.logoUrl} style={{height: '64px'}} alt={c.name} title={c.name} /></Grid>)) }
        </Grid>
        </>
    )
}


export default OpenLeagueClubs;