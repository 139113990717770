import React from "react";
import { TournamentHeader } from "../../store/tournaments/models";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import ExploreIcon from '@material-ui/icons/Explore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TournamentsList from "./TournamentsList";

interface TournamentCityGroupProps {
    city: string;
    tournaments: TournamentHeader[];
}

const TournamentCityGroup: React.FC<TournamentCityGroupProps> = ({ city, tournaments }) => {



    const [isOpen, open] = React.useState<boolean>();

    const season = tournaments[0].year;

    React.useEffect(() => {

        try {
            const state = window.localStorage.getItem(`tournaments--${season}---${city}`);
            if (state === 'open') {
                open(true);
                return;
            }

            if (window.location.hash.includes(`hint=${encodeURI(city)}`)) {
                open(true);
            }


        } catch (e) {
            console.error(e);
        }


    }, [city, season]);

    const setOpen = React.useCallback((o: boolean) => {

        open(o);

        try {

            if (!!o) {
                window.localStorage.setItem(`tournaments--${season}---${city}`, 'open');
            } else {
                window.localStorage.setItem(`tournaments--${season}---${city}`, 'closed');
            }

        } catch (e) {
            console.error(e);
        }

    }, [city, season]);

    return <div key={city} style={{ marginBottom: '16px' }}><Typography color={"primary"} variant="h5" style={{ marginBottom: '8px' }} key={city}><ExploreIcon fontSize={"small"} />  {city}  <IconButton onClick={() => setOpen(!isOpen)}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton> </Typography><Collapse in={isOpen}><TournamentsList tournaments={tournaments}></TournamentsList></Collapse></div>
};

export default TournamentCityGroup;