import React, { FC } from "react";
import { Set } from "../../store/matches/models";
import {
  Paper,
  Typography,
  IconButton,
  makeStyles,
  Grid,
  TextField,
  Button,
  Divider
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { formatInt } from "../../utils/numbers";

interface SetProps extends React.HTMLProps<HTMLElement> {
  set: Set;
  onSetChange: (set: Set) => void;
}

const useStyles = makeStyles(theme => ({
  res: {
    display: "inline-block",
    margin: "0 2px"
  },
  restb: {
    verticalAlign: "super",
    fontSize: "smaller"
  },
  gp: {
    padding: "0 8px"
  }
}));

const AddResult: FC<{ click: () => void }> = ({ click }) => {
  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        click();
      }}
    >
      <AddCircleIcon></AddCircleIcon>
    </IconButton>
  );
};
const ShowResult: FC<{
  set: Set;
  onRemoved: () => void;
  onClick: () => void;
}> = ({ set, onRemoved }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      style={{ width: "100%" }}
    >
      <Grid item className={classes.gp}>
        <Typography variant="h6">
          <span className={classes.res}>{set.firstPlayer}</span>
          {!!set.tieBreak && set.tieBreak.firstPlayer > 0 ? (
            <span className={classes.restb}>{set.tieBreak.firstPlayer}</span>
          ) : (
            <></>
          )}
          <span className={classes.res}>:</span>
          <span className={classes.res}>{set.secondPlayer}</span>
          {!!set.tieBreak && set.tieBreak.secondPlayer > 0 ? (
            <span className={classes.restb}>{set.tieBreak.secondPlayer}</span>
          ) : (
            <></>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            onRemoved();
          }}
        >
          <HighlightOffIcon></HighlightOffIcon>
        </IconButton>
      </Grid>
    </Grid>
  );
};

const InputResult: React.FC<{
  set: Set;
  onChange: (set: Set) => void;
  onDone: () => void;
}> = ({ set, onChange, onDone }) => {
  return (
    <Grid container spacing={1} justify="space-around">
      <Grid item>
        <TextField
          type="number"
          inputProps={{ min: "0" }}
          style={{ width: "64px" }}
          variant="outlined"
          value={formatInt(set.firstPlayer)}
          onChange={e =>
            onChange({ ...set, firstPlayer: parseInt(e.target.value) })
          }
        ></TextField>
      </Grid>
      <Grid item>
        <Typography variant="h6" style={{ paddingTop: "8px" }}>
          :
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          type="number"
          inputProps={{ min: "0" }}
          style={{ width: "64px" }}
          variant="outlined"
          value={formatInt(set.secondPlayer)}
          onChange={e =>
            onChange({ ...set, secondPlayer: parseInt(e.target.value) })
          }
        ></TextField>
      </Grid>
      {!set.tieBreak ? (
        <Grid item xs={12}>
          <Button
            onClick={() =>
              onChange({
                ...set,
                tieBreak: { firstPlayer: 0, secondPlayer: 0 }
              })
            }
          >
            Tie-break
          </Button>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">Tie-break</Typography>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              inputProps={{ min: "0" }}
              style={{ width: "64px" }}
              variant="outlined"
              value={formatInt(set.tieBreak.firstPlayer)}
              onChange={e =>
                onChange({
                  ...set,
                  tieBreak: {
                    ...set.tieBreak!,
                    firstPlayer: parseInt(e.target.value)
                  }
                })
              }
            ></TextField>
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ paddingTop: "8px" }}>
              :
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              inputProps={{ min: "0" }}
              style={{ width: "64px" }}
              variant="outlined"
              value={formatInt(set.tieBreak.secondPlayer)}
              onChange={e =>
                onChange({
                  ...set,
                  tieBreak: {
                    ...set.tieBreak!,
                    secondPlayer: parseInt(e.target.value)
                  }
                })
              }
            ></TextField>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider></Divider>
        <Button onClick={() => onDone()}>Zatwierdź</Button>
      </Grid>
    </Grid>
  );
};

const SetResult: React.FC<SetProps> = ({ set, onSetChange, style }) => {
  const [editMode, setEditMode] = React.useState(false);

  return (
    <Paper style={{ width: "100%", textAlign: "center", ...style }}>
      {editMode ? (
        <InputResult
          set={set}
          onChange={onSetChange}
          onDone={() => setEditMode(false)}
        />
      ) : set.firstPlayer > 0 || set.secondPlayer > 0 ? (
        <ShowResult
          set={set}
          onRemoved={() => onSetChange({ firstPlayer: 0, secondPlayer: 0 })}
          onClick={() => setEditMode(!editMode)}
        ></ShowResult>
      ) : (
        <AddResult click={() => setEditMode(true)}></AddResult>
      )}
    </Paper>
  );
};

export default SetResult;
