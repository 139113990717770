import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Hidden,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { PlayerDescriptor, LeaguePlayer, Clubs } from "../../../store/leagues/models";
import { TournamentDetails } from "../../../store/tournaments/models";
import { Match } from "../../../store/matches/models";
import { Player } from "../../../store/players/models";

interface CreateTournamentMatchDialogProps {
  open: boolean;
  player: Player | PlayerDescriptor;
  oponent: LeaguePlayer;
  tournament: TournamentDetails;
  onClosed: (success: boolean, match: Match | null) => void;
}

const CreateTournamentMatchDialog: React.FC<CreateTournamentMatchDialogProps> = ({
  open,
  player,
  oponent,
  tournament,
  onClosed,
}) => {
  const handleClose = () => {
    onClosed(false, null);
  };

  const handleSubmit = () => {
    if (!dateOfMatch || !club) {
      return;
    }

    const match: Match = {
      club,
      date: dateOfMatch,
      player: {
        id: player.id!,
        level: player.level || "",
        name: player.name!,
        surname: player.surname!,
      },
      oponent,
      isOponentRetired: false
    };

    onClosed(true, match);
  };

  const handleClubChange = (evt: any) => {
    const clubId = evt.target.value;
    const club = tournament.clubs.find(x => x.id === clubId);
    setClub(club);
  };

  const [club, setClub] = React.useState<Clubs | undefined>();
  const [dateOfMatch, setDateOfMatch] = React.useState<
    Date | undefined | null
  >();

  React.useEffect(() => {
    const currentClub: Clubs = tournament.clubs[0];
    setClub(currentClub);
    setDateOfMatch(new Date());
  }, []);

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Zaplanuj mecz</DialogTitle>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                value={`${player.name} ${player.surname}`}
                label="Zawodnik"
                variant="outlined"
                InputProps={{ readOnly: true }}
                fullWidth
              ></TextField>
            </Grid>
            <Hidden only={["xs", "sm"]}>
              <Grid item md={2}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  vs.
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={5}>
              <TextField
                value={`${oponent.name} ${oponent.surname}`}
                fullWidth
                label="Przeciwnik"
                variant="outlined"
                InputProps={{ readOnly: true }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                autoOk
                inputVariant="outlined"
                format="yyyy-MM-dd HH:mm"
                margin="normal"
                style={{ width: "100%" }}
                label="Data i czas meczu"
                invalidDateMessage="Błędny format daty"
                minDate={new Date()}
                minDateMessage="Błędna data meczu"
                maxDate={tournament.endDate}
                maxDateMessage="Błędna data meczu"
                value={dateOfMatch}
                onChange={(e) => {
                  setDateOfMatch(e);
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
              >
                <InputLabel>Klub</InputLabel>
                <Select
                  onChange={(evt) => handleClubChange(evt)}
                  value={club?.id}
                  labelWidth={32}
                >
                  {
                    tournament.clubs.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CreateTournamentMatchDialog;
