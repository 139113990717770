export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_FAILED = "LOAD_PRODUCTS_FAILED";

export const ADD_PRODUCT_TO_BASKET = "ADD_PRODUCT_TO_BASKET";
export const REMOVE_PRODUCT_FROM_BASKET = "REMOVE_PRODUCT_FROM_BASKET";

export const LOAD_SPOTS = "LOAD_SPOTS";
export const LOAD_SPOTS_SUCCESS = "LOAD_SPOTS_SUCCESS";
export const LOAD_SPOTS_FAILED = "LOAD_SPOTS_FAILED";

export const SET_SPOT = "SET_SPOT";

export const CREATE_SHOPPING_ORDER = "CREATE_SHOPPING_ORDER";
export const CREATE_SHOPPING_ORDER_SUCCESS = "CREATE_SHOPPING_ORDER_SUCCESS";
export const CREATE_SHOPPING_ORDER_FAILED = "CREATE_SHOPPING_ORDER_FAILED";

export const LOAD_MY_ORDERS = "LOAD_MY_ORDERS";
export const LOAD_MY_ORDERS_SUCCESS = "LOAD_MY_ORDERS_SUCCESS";
export const LOAD_MY_ORDERS_FAILED = "LOAD_MY_ORDERS_FAILED";