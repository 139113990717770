import React from "react";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { makeRegistration } from "../../store/registration/actions";
import { RegisterToLeague, PlayerType } from "../../store/registration/model";
import { Player } from "../../store/players/models";
import { State as RegistrationState } from '../../store/registration/reducer';
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@material-ui/core";

interface RegistrationProgressProps {
    startRegistration: (target: RegisterToLeague, playerType: PlayerType, playerData: Player, promoCode: string, referral: string, isUpgrading: boolean, token: string) => void;
    registration: RegistrationState;
}

const RegistrationProgress: React.FC<RegistrationProgressProps> = ({registration, startRegistration}) => {

    const { getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {

        getAccessTokenSilently().then(token => {
            startRegistration(
                registration.leagueSelection, 
                registration.playerType, 
                registration.updatedPlayer!, 
                registration.priceInfo.type === "LOADED" 
                    ? registration.priceInfo.value.promoCode 
                    : "", 
                registration.priceInfo.type === "LOADED" 
                    ? registration.priceInfo.value.referral
                    : "", 
                registration.isUpgrading,
                token);
        })

    }, []);

    return <>
        <Grid container style={{minHeight: '200px'}} alignItems="center" justify="center">
            <Grid item>
                <Typography variant="h5" color="primary">
                    {registration.registrationMessage}
                </Typography>
            </Grid>
        </Grid>
    </>

};

const mapStateToProps = (state: AppState) => ({
    registration : state.registration
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    startRegistration: (target: RegisterToLeague, playerType: PlayerType, playerData: Player, promoCode: string, referral: string, isUpgrading: boolean, token: string) => dispatch(makeRegistration({
        target,
        playerType,
        playerData,
        promoCode,
        referral,
        isUpgrading,
        token
    }))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationProgress);