import { LeagueHeader, League, LeagueWithPaymentStatus } from "./models";
import { getConfig } from "../../config/configuration";

export async function loadOpenLeagues(year?: string): Promise<LeagueHeader[]> {
    var config = await getConfig();
    if(!year) {
        year = config.defaultSeason;
    }

    const response = await fetch(`${config.apiUrl}/leagues/open/${year}`);

    const items = await response.json();

    return items.map((i:any) => ({ ...i, startDate: new Date(i.startDate), endDate: new Date(i.endDate) }));
}

export async function loadLeagueDetailsApi(year: string, id: string): Promise<League> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/leagues/open/${year}/${id}`);
    const item: League = await response.json();



    return {...item, startDate: new Date(item.startDate), endDate: new Date(item.endDate), admins: ['auth0|5e05236d284d470eb90d9240']};
}

export async function loadMyLeagues(token: string, year?: string): Promise<string[]> {
    var config = await getConfig();
    if(!year) {
        year = config.defaultSeason;;
    }

    const response = await fetch(`${config.apiUrl}/players/me/leagues/${year}/ids`, { 
        headers: {
            'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
    });

    const items = await response.json();

    return items;
}

export async function loadMyLeaguesWithPaymentStatusApi(token: string, year?: string): Promise<LeagueWithPaymentStatus> {
    var config = await getConfig();
    if(!year) {
        year = config.defaultSeason;
    }

    const response = await fetch(`${config.apiUrl}/players/me/leagues/${year}/payments`, { 
        headers: {
            'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
    });

    if(response.ok) {
        return await response.json();
    }

    throw new Error(response.statusText);
}

export async function assignPlayerToLeagueApi(token: string, league: LeagueHeader|League, referral: string,  promoCode: string, secondaryPlayerName?: string, secondaryPlayerSurname?: string) {
    var config = await getConfig();

    await fetch(`${config.apiUrl}/leagues/open/${league.year}/${league.id}/players`, { 
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            referral,
            promoCode,
            secondPlayerName: secondaryPlayerName,
            secondPlayerSurname: secondaryPlayerSurname
        }),
        credentials: 'include',
        method: 'POST'
    });
}

export async function loadMyPrivateLeagues(token: string, year?: string) {
    const config = await getConfig();
    
    if(!year) {
        year = config.defaultSeason;;
    }

    const response = await fetch(`${config.apiUrl}/leagues/private/${year}/mine`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include'
    });

    const items = await response.json();

    return items.map((i:any) => ({ ...i, startDate: new Date(i.startDate), finals: new Date(i.finalsDate) }));
}

export async function loadOpponentsPlayedWithApi(token: string, year: string, id: string) {
    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/leagues/open/${year}/${id}/matches/players-played`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    if(response.ok) {
        const data = await response.json();

        return data;
    } else {
        throw new Error(response.statusText);
    }
}