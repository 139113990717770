import { CircularProgress } from "@material-ui/core";
import { Data } from "../../framework/data";
import { TournamentHeader } from "../../store/tournaments/models";
import TournamentCityGroup from "./TournamentCityGroup";
import React from "react";

interface ITournamentGroupsProps {
    groups: Data<{[city:string]:TournamentHeader[]}>
}

const TournamentGroups: React.FC<ITournamentGroupsProps> = ({ groups }) => {

    switch(groups.type) {
        case "LOADED": {

            const toRender = Object.entries(groups.value).map(([city, tournaments]) => (<TournamentCityGroup key={city} city={city} tournaments={tournaments} ></TournamentCityGroup>));

            return (<div>{toRender}</div>)
        }
        case "LOADING": {
            return (<div style={{ width: '64px', height: '64px', marginLeft: 'auto', marginRight: 'auto'}}><CircularProgress /></div>)
        }
        default: {
            return(<div></div>);
        } 
    }
}

export default TournamentGroups;