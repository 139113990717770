import { combineReducers } from "redux";
import { Data, LOADING, NOT_STARTED, dataOf, fail } from "../../framework/data";
import { Actions } from "./actions";
import { TrainingHeader, TrainingDetails } from "./models";

export interface State {
    items: Data<TrainingHeader[]>;
    details: Data<TrainingDetails>
}

function reduceTrainings(state: Data<TrainingHeader[]> = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TRAININGS": return LOADING;
        case "LOAD_TRAININGS_FAILED": return fail(action.error);
        case "LOAD_TRAININGS_SUCCESS": return dataOf(action.payload.trainings);
        default:
            return state;
    }
}

function reduceDetails(state: Data<TrainingDetails> = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TRAINING_DETAILS": return LOADING;
        case "LOAD_TRAINING_DETAILS_SUCCESS": return dataOf(action.payload.training);
        case "LOAD_TRAINING_DETAILS_FAILED": return fail(action.error);
        default:
            return state;
    }
}

export default combineReducers<State>({
    items: reduceTrainings,
    details: reduceDetails
});
