import React, { useEffect } from "react";
import { CardHeader, CardContent } from "@material-ui/core";
import { useLocation, match, withRouter, RouteComponentProps } from "react-router-dom";
import logger from "../../framework/logger";
import ReactGA from 'react-ga';

interface PageProps {
    avatar?: React.ReactNode
    title?: React.ReactNode
    pageName?: string;
    match: match;
}



const Page: React.FC< RouteComponentProps<any> & PageProps> = ({ avatar, title, children, pageName, match }) => {

    let location = useLocation();
    useEffect(() => {
        logger.logPageView(pageName!, {...match});
        ReactGA.pageview(window.location.hash, undefined, pageName);
    }, [location, pageName]);

    return (
        <>
            <CardHeader
                  avatar={avatar}
                  title={title}
                />
                <CardContent>
                  {children}
                </CardContent>
        </>
    )
};

export default withRouter(Page);