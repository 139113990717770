import React from "react";
import StatsContainer from "./StatsContainer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";

const StatsPage: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
    return <>
        <Alert severity="info">
            <AlertTitle>Wersja beta</AlertTitle>
            W wersji beta statystyki z meczów w ligach deblowych nie są brane pod uwagę. Pracujemy nad tym, aby dostarczyć Wam pełne statystyki
        </Alert>
        <div style={{ marginTop: '32px' }}>
        <StatsContainer playerId={match.params.id}></StatsContainer>
        </div>
    </>
};

export default withRouter(StatsPage);