export interface Club {
    id: string;
    name: string;
    province: Province;
    logoUrl: string;
    phoneNumber: string;
    websiteUrl: string;
    address: Address;
    geoCoordinate: GeoCoordinates;
    priceList: string;
    workingHours: WorkingHours[];
    courts: Court[];
    sportCards: SportCard[];
}

export enum SportCardType {
    Multisport,
    OkSystem
}

export interface SportCard {
    type: SportCardType;
    priceDiscount: number;
}

export enum CourtType {
    Outdoor,
    Hall,
    Baloon,
}

export enum SurfaceType {
    Clay,
    ArtificialClay,
    ArtifilicialGrass,
    Carpet,
    Hard
}

export interface Court {
    courtType: CourtType;
    surfaceType: SurfaceType;
    lighting: boolean;
    name: string;
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Weekday
}

export interface WorkingHours {
    dayOfWeek: DayOfWeek;
    openTime: string;
    closeTime: string;
}

export interface PriceList extends WorkingHours {
    price: number;
}

export interface Address {
    address1: string;
    city: string;
    postalCode: string;
    country: string;
}

export interface GeoCoordinates {
    latitude: number;
    longitude: number;
}

export enum Province {
    Dolnoslaskie,
    Kujawskopomorskie,
    Lubelskie,
    Lubuskie,
    Lodzkie,
    Malopolskie,
    Mazowieckie,
    Opolskie,
    Podkarpackie,
    Podlaskie,
    Pomorskie,
    Slaskie,
    Swietokrzyskie,
    Warminskomazurskie,
    Wielkopolskie,
    Zachodniopomorskie,
}

