import { MatchHeader, MatchStatus } from "./models";
import { Data, NOT_STARTED, LOADING, dataOf } from "../../framework/data";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    currentLeagueNewestMatches: Data<MatchHeader[]>;
    currentLeaguePlayersMatchesPage: Data<MatchHeader[]>;
    currentLeagueAllMatchesPage: Data<MatchHeader[]>;
    currentTournamentPlayersMatchesPage: Data<MatchHeader[]>;
    currentTournamentAllMatchesPage: Data<MatchHeader[]>;
}

function reduceCurrentLeagueNewestMatches(state = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "GET_NEWEST_MATCHES": return LOADING;
        case "GET_NEWEST_MATCHES_FAILED": return fail(action.error);
        case "GET_NEWEST_MATCHES_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceCurrentLeaguePlayersMatchesPage(state: Data<MatchHeader[]> = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "GET_PLAYERS_MATCHES": return LOADING;
        case "GET_PLAYERS_MATCHES_FAILED": return fail(action.error);
        case "GET_PLAYERS_MATCHES_SUCCESS": return dataOf(action.payload);
        case "SUBMIT_MATCH_RESULT":
        case "CANCEL_MATCH":
        case "CONFIRM_MATCH": {
            var result = action.payload;

            if (state.type === "LOADED") {
                var matches = state.value;

                var idx = matches.findIndex(x => x.id === result.matchId);

                const ret = matches.slice(0);
                const toUpdate = ret[idx];
                ret[idx] = { ...toUpdate, status: MatchStatus.InProcess }

                return dataOf(ret);
            }

            return state;
        }
        default: return state;
    }
}

function reduceCurrentTournamentPlayersMatchesPage(state: Data<MatchHeader[]> = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "GET_PLAYERS_TOURNAMENT_MATCHES": return LOADING;
        case "GET_PLAYERS_TOURNAMENT_MATCHES_FAILED": return fail(action.error);
        case "GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS": return dataOf(action.payload);
        case "SUBMIT_TOURNAMENT_MATCH_RESULT":
        case "CANCEL_TOURNAMENT_MATCH":
        case "CONFIRM_TOURNAMENT_MATCH": {
            var result = action.payload;

            if (state.type === "LOADED") {
                var matches = state.value;

                var idx = matches.findIndex(x => x.id === result.matchId);

                const ret = matches.slice(0);
                const toUpdate = ret[idx];
                ret[idx] = { ...toUpdate, status: MatchStatus.InProcess }

                return dataOf(ret);
            }

            return state;
        }
        default: return state;
    }
}

function reduceCurrentLeagueAllMatchesPage(state = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "GET_LEAGUES_MATCHES": return LOADING;
        case "GET_LEAGUES_MATCHES_SUCCESS": return dataOf(action.payload);
        case "GET_LEAGUES_MATCHES_FAILED": return fail(action.error);
        default: return state;
    }
}

function reduceCurrentTournamentAllMatchesPage(state = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "GET_TOURNAMENT_MATCHES": return LOADING;
        case "GET_TOURNAMENT_MATCHES_SUCCESS": return dataOf(action.payload);
        case "GET_TOURNAMENT_MATCHES_FAILED": return fail(action.error);
        default: return state;
    }
}


export default combineReducers({
    currentLeagueNewestMatches: reduceCurrentLeagueNewestMatches,
    currentLeaguePlayersMatchesPage: reduceCurrentLeaguePlayersMatchesPage,
    currentLeagueAllMatchesPage: reduceCurrentLeagueAllMatchesPage,
    currentTournamentPlayersMatchesPage: reduceCurrentTournamentPlayersMatchesPage,
    currentTournamentAllMatchesPage: reduceCurrentTournamentAllMatchesPage
});