import { createSelector } from 'reselect';
import { AppState } from '../reducer';
import { dataOf, LOADING, fail, NOT_STARTED } from '../../framework/data';
import { groupBy } from 'ramda';
import { LeaguePlayerCanIPlay } from './models';

export const openLeagues = (state : AppState) => state.leagues.items;
export const currentUsersAssignedLeagues = (state : AppState) => state.leagues.subscribedTo;
export const currentLeagueDetails = (state: AppState) => state.leagues.currentDetails;
export const myPrivateLeagues = (state: AppState) => state.leagues.privateLeagues;
export const opponentsPlayedWith = (state: AppState) => state.leagues.currentOpponents;

export const usersAssignedLeagues = createSelector(openLeagues, currentUsersAssignedLeagues, (leaguesData, currentUsersAssignedLeaguesData) => {

    if(leaguesData.type === "LOADED" && currentUsersAssignedLeaguesData.type === "LOADED") { 
        return dataOf(leaguesData.value.map(l => ({...l, isCurrentUserAssigned: (currentUsersAssignedLeaguesData.value.indexOf(l.id) !== -1)})));
    } else if(leaguesData.type === "LOADED") {
        return dataOf(leaguesData.value.map(l => ({...l, isCurrentUserAssigned: false})));
    } else if(leaguesData.type === "LOADING" || currentUsersAssignedLeaguesData.type === "LOADING") {
        return LOADING;
    } else if(leaguesData.type === "FAILED") {
        return fail(leaguesData.error)
    } else if(currentUsersAssignedLeaguesData.type === "FAILED") {
        return fail(currentUsersAssignedLeaguesData.error)
    } else {
        return NOT_STARTED;
    }

});

export const usersAssignedLeague = createSelector(currentLeagueDetails, currentUsersAssignedLeagues, (leagueData, currentUsersAssignedLeaguesData) => {

    if(leagueData.type === "LOADED" && currentUsersAssignedLeaguesData.type === "LOADED") {
        return dataOf({ ...leagueData.value, players:(leagueData.value.players.sort((x,y) => y.rank - x.rank) ),  isCurrentUserAssigned: (currentUsersAssignedLeaguesData.value.indexOf(leagueData.value.id) !== -1) });
    } else if(leagueData.type === "LOADED") {
        return dataOf({ ...leagueData.value, isCurrentUserAssigned: false });
    } else if(leagueData.type === "LOADING" || currentUsersAssignedLeaguesData.type === "LOADING") {
        return LOADING;
    } else if(leagueData.type === "FAILED") {
        return fail(leagueData.error)
    } else if(currentUsersAssignedLeaguesData.type === "FAILED") {
        return fail(currentUsersAssignedLeaguesData.error)
    } else {
        return NOT_STARTED;
    }

});

export const userAssignedLeagueWithPossibleMatches = createSelector(usersAssignedLeague, opponentsPlayedWith, (leagueData, opponentIds) => {

    if(leagueData.type === "LOADED" && opponentIds.type === "LOADED") {
        return dataOf({ ...leagueData.value, players: [...leagueData.value.players.map<LeaguePlayerCanIPlay>(p => ({ ...p, canIPlay: !opponentIds.value.includes(p.id) }))] });
    } else if(leagueData.type === "LOADED" && opponentIds.type === "NOT_STARTED") {
        return dataOf({ ...leagueData.value, players: [...leagueData.value.players.map<LeaguePlayerCanIPlay>(p => ({ ...p, canIPlay: false }))] });
    } else if(leagueData.type === "LOADING" || opponentIds.type === "LOADING") {
        return LOADING; 
    } else if(leagueData.type === "FAILED") {
        return fail(leagueData.error);
    } else if(opponentIds.type === "FAILED") {
        return fail(opponentIds.error);
    } else {
        return NOT_STARTED;
    }

});

export const myPrivateLeaguesWithUserAssigned = createSelector(myPrivateLeagues, (leagues) => {
    if(leagues.type === "LOADED") {
        return dataOf( leagues.value.map(l => ({ ...l, isCurrentUserAssigned: true })));
    } else if(leagues.type === "LOADING") {
        return LOADING;
    } else if(leagues.type === "FAILED") {
        return fail(leagues.error);
    } else {
        return NOT_STARTED;
    }
})

export const openLeaguesByCity = createSelector(usersAssignedLeagues, (leaguesData) => {
    switch(leaguesData.type) {
        case "LOADED": {
            return dataOf(groupBy(l => l.city, leaguesData.value));
        }
        case "FAILED": {
            return fail(leaguesData.error)
        }
        case "LOADING": {
            return LOADING;
        }
        case "NOT_STARTED" : {
            return NOT_STARTED;
        }
    }
    
});