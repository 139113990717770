import { takeEvery, put, call, all } from "redux-saga/effects";
import { LOAD_PLAYER_STATS, LOAD_PLAYER_MATCHES } from "./actionTypes";
import { LoadPlayerStats, loadPlayerStatsSuccess, loadPlayerStatsFailed, LoadPlayerMatches, loadPlayerMatchesFailed, loadPlayerMatchesSuccess } from "./actions";
import { getConfig } from "../../config/configuration";
import { loadPlayersStatsApi, loadPlayersMatchesApi } from "./api";
import { Stat } from "./model";
import { PlayerMatch } from "../matches/models";

function* loadPlayerMatchesStep({ payload } : LoadPlayerMatches) {
    try {

        const playerId = payload;

        const config = yield call(getConfig);

        const callables = (config.seasons as string[]).map(s => loadPlayersMatchesApi(playerId, s));

        const data: PlayerMatch[][] = yield all(callables);

        const matches = data.reduce((prev, curr) => [...prev, ...curr], []);

        yield put(loadPlayerMatchesSuccess(matches));

    } catch(e) {
        yield put(loadPlayerMatchesFailed(e.message));
    }
}

function* loadPlayerStatsStep({ payload } : LoadPlayerStats) {
    try {
        const playerId = payload;

        const config = yield call(getConfig);

        const callables = (config.seasons as string[]).map(s => loadPlayersStatsApi(playerId, s));

        const data: Stat[][] = yield all(callables);

        const stats = data.reduce((prev, curr) => [...prev, ...curr], []);
        
        yield put(loadPlayerStatsSuccess(stats));

    } catch(e) {
        yield put(loadPlayerStatsFailed(e.message));
    }
}

export function* loadPlayerMatchesSaga() {
    yield takeEvery(LOAD_PLAYER_MATCHES, loadPlayerMatchesStep);
}

export function* loadPlayerStatsSaga() {
    yield takeEvery(LOAD_PLAYER_STATS, loadPlayerStatsStep)
}