import React, { FC } from "react";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Data } from "../../framework/data";
import { Club } from "../../store/clubs/models";
import { Typography, Button, Drawer } from "@material-ui/core";
import { AppState } from "../../store/reducer";
import { clubsWithGeoCoordinates } from "../../store/clubs/selectors";
import { Dispatch } from "redux";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { connect } from "react-redux";
import ClubDetails from "./ClubDetails";
import { getConfig } from "../../config/configuration";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface ClubPageProps {
  clubs: Data<Club[]>;
  loadClubs: () => void;
}

const ClubsPage: FC<ClubPageProps> = ({ clubs, loadClubs }) => {
  React.useEffect(() => {
    loadClubs();
  }, []);

  const [pos, setPos] = React.useState<number[]>([]);

  React.useEffect(() => {
    getConfig()
      .then(
        (c) =>
          new Promise<{ provinces: any; current: number[] }>(
            (resolve, reject) => {
              window.navigator.geolocation.getCurrentPosition(
                (pos) => {
                  resolve({
                    provinces: c.provincesConfig,
                    current: [pos.coords.latitude, pos.coords.longitude],
                  });
                },
                (e) => {
                  reject(e.message);
                }
              );
            }
          )
      )
      .then(({ provinces, current }) =>
        Object.values(provinces).map((v: any) => ({
          position: v.center,
          distance: Math.sqrt(
            Math.pow(v.center.lat - current[0], 2) +
              Math.pow(v.center.lng - current[1], 2)
          ),
        }))
      )
      .then((x) => x.sort((a, b) => a.distance - b.distance)[0].position)
      .then((p) => setPos([p.lat, p.lng, p.z]))
      .catch((e) => setPos([50.0468548, 19.9348337, 11]));
  }, []);

  const [currentClub, setCurrentClub] = React.useState<Club>();

  const selectClub = (c: Club) => {
    setCurrentClub(c);
  };

  return (
    <>
      <LeafletMap center={[pos[0], pos[1]]} zoom={pos[2]}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />

        {clubs.type === "LOADED" ? (
          clubs.value
            .filter((x) => !!x.geoCoordinate)
            .map((c) => (
              <Marker
                key={c.id}
                position={[c.geoCoordinate.latitude, c.geoCoordinate.longitude]}
              >
                <Popup>
                  <Typography variant="h6" color="primary">
                    {c.name}
                  </Typography>
                  <Typography>
                    {c.address.address1}, {c.address.postalCode}{" "}
                    {c.address.city}
                  </Typography>
                  <Button variant="outlined" onClick={() => selectClub(c)}>
                    Szczegóły
                  </Button>
                </Popup>
              </Marker>
            ))
        ) : (
          <></>
        )}
      </LeafletMap>
      <Drawer
        anchor="right"
        style={{ maxWidth: "90%" }}
        open={!!currentClub}
        onClose={() => setCurrentClub(undefined)}
      >
        <ClubDetails club={currentClub}></ClubDetails>
        <Button onClick={() => setCurrentClub(undefined)}>Zamknij</Button>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  clubs: clubsWithGeoCoordinates(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadClubs: () => dispatch(loadClubsInProvince()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubsPage);
