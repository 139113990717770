import { MatchHeader, Set } from "../../store/matches/models";

const GAME_SCORE_LIST = [
    [6, 0],
    [6, 1],
    [6, 2],
    [6, 3],
    [6, 4],
    [7, 5],
    [7, 6],
    [6, 7],
    [5, 7],
    [0, 6],
    [1, 6],
    [2, 6],
    [3, 6],
    [4, 6]
];

const SET_SCORE_LIST = [
    [2, 0],
    [2, 1],
    [1, 2],
    [0, 2]
];

const isFirstWinner = (set: Set) => set.firstPlayer > set.secondPlayer;

export const firstPlayerWon = (m: MatchHeader):boolean => {

    if(!m.score) return false;
  
    const firstPlayerWinSets = m.score.filter(x => x.firstPlayer > x.secondPlayer).length;
    const secondPlayerWinSets = m.score.filter(x => x.secondPlayer > x.firstPlayer).length;
  
    return firstPlayerWinSets > secondPlayerWinSets;
  }
  
export const secondPlayerWon = (m: MatchHeader): boolean => 
    !!m.score && !firstPlayerWon(m)

const validateRegularSet = (set: Set): string | undefined => {
    if (!GAME_SCORE_LIST.find(
        test => test[0] === set.firstPlayer && test[1] === set.secondPlayer
    )) {
        return "Niepoprawny wynik seta"
    }

    if (((set.firstPlayer === 7 && set.secondPlayer === 6) ||
        (set.firstPlayer === 6 && set.secondPlayer === 7)) && !set.tieBreak) {
        return "Brak wyniku tie break-a";
    }

    return undefined;
}

const validateTieBreak = (set: Set): string | undefined => {
    if (set.firstPlayer < 6 || set.secondPlayer < 6) {
        return "Tie break odbywa się tylko przy stanie 6:6 w gemach";
    }

    const absolute = Math.abs(
        set.tieBreak!.firstPlayer - set.tieBreak!.secondPlayer
    );
    if (absolute < 2) {
        return `Niepoprawny wynik tie break-a - musi się zakończyć różnicą dwóch punktów przewagi ${
            set.tieBreak!.firstPlayer
            } : ${set.tieBreak!.secondPlayer}`;
    }

    if (
        absolute > 2 &&
        (set.tieBreak!.firstPlayer > 7 || set.tieBreak!.secondPlayer > 7)
    ) {
        return `Niepoprawny wynik tie break-a ${set.tieBreak!.firstPlayer} : ${
            set.tieBreak!.secondPlayer
            }`;
    }

    if (set.tieBreak!.firstPlayer < 7 && set.tieBreak!.secondPlayer < 7) {
        return `Niepoprawny wynik tie break-a - gra się do 7 wygranych puntków ${
            set.tieBreak!.firstPlayer
            } : ${set.tieBreak!.secondPlayer}`;
    }

    if (set.firstPlayer < set.secondPlayer && set.tieBreak!.firstPlayer > set.tieBreak!.secondPlayer) {
        return "Zwycięzca gema, nie jest zwyciezcą seta";
    }
};

export const sanitizeSetsResult = (sets: Set[]): Set[] => {
    const saneSets: Set[] = [];
    for (let i = 0; i < sets.length; i++) {
        if (sets[i].tieBreak && sets[i].tieBreak?.firstPlayer === 0 && sets[i].tieBreak?.secondPlayer === 0) {
            sets[i].tieBreak = undefined;
        }

        if (sets[i].firstPlayer > 0 || sets[i].secondPlayer > 0) {
            saneSets.push(sets[i]);
        }
    }

    return saneSets;
}

export type SetValidation = { [key: number]: string[] } | undefined;

export const validateSetsResult = (sets: Set[]): SetValidation => {

    let messages: SetValidation = {};

    if (sets.length < 2 || sets.length > 3) {
        messages = {
            ...messages,
            [0]: [
                `Błędna liczba setów: ${sets.length}`
            ]
        }
    }

    let setNumber = 1;
    let firstPlayerSetsWon = 0;
    let secondPlayerSetsWon = 0;

    for (let i = 0; i < sets.length; i++) {

        let setMessages: string[] = [];

        if (setNumber === 3 && (firstPlayerSetsWon === 2 || secondPlayerSetsWon === 2)) {
            messages = {
                ...messages,
                [0]: [
                    ...(messages[0] ?? []),
                    'Niepoprawny wynik meczu - mecz powinien zakończyć się w dwóch setach'
                ]
            }
        }

        if (setNumber < 3) {
            const result = validateRegularSet(sets[i]);
            if (result) {
                setMessages = [...setMessages, result];
            }

            if (sets[i].tieBreak) {
                const tieBreakResult = validateTieBreak(sets[i]);
                if (tieBreakResult) {
                    setMessages = [...setMessages, `Tie break: ${tieBreakResult}`];
                }
            }
        }

        if ((sets[i].firstPlayer > 0 || sets[i].secondPlayer > 0)) {
            if (isFirstWinner(sets[i])) firstPlayerSetsWon++;
            else secondPlayerSetsWon++;
        }

        if (setMessages && setMessages.length > 0 && (sets[i].firstPlayer > 0 || sets[i].secondPlayer > 0)) {
            messages = { ...messages, [setNumber]: setMessages };
        }

        setNumber++;
    }

    if (sets.length === 3) {
        const lastSet = sets[2];
        let setMessages: string[] = messages[3] ?? [];
        // super tie break
        if (sets[2].firstPlayer > 7 || sets[2].secondPlayer > 7) {

            const absolute = Math.abs(lastSet.firstPlayer - lastSet.secondPlayer);
            if (absolute < 2) {
                setMessages = [...setMessages, `Niepoprawny wynik super tie break-a - musi się zakończyć różnicą dwóch punktów przewagi ${lastSet.firstPlayer} : ${lastSet.secondPlayer}`]
            }

            if (absolute > 2 && (lastSet.firstPlayer > 10 || lastSet.secondPlayer > 10)) {
                setMessages = [...setMessages, `Niepoprawny wynik super tie break-a ${lastSet.firstPlayer} : ${lastSet.secondPlayer}`]
            }

            if (lastSet.firstPlayer < 10 && lastSet.secondPlayer < 10) {
                setMessages = [...setMessages, `Niepoprawny wynik super tie break-a - gra się do 10 wygranych puntków ${lastSet.firstPlayer} : ${lastSet.secondPlayer}`];
            }

            if(lastSet.tieBreak) {
                setMessages = [...setMessages, `Przy rozgrywaniu super tie-break-a, wynik podajemy w trzecim secie jako gemy`];
            }

        } else {
            let result = validateRegularSet(lastSet);
            if (result) {
                setMessages = [...setMessages, result];
            }

            if (lastSet.tieBreak) {
                let tieBreakResult = validateTieBreak(lastSet);
                if (tieBreakResult) {
                    setMessages = [...setMessages, `Tie break: ${tieBreakResult}`];
                }
            }


        }

        if (setMessages && setMessages.length > 0 && (lastSet.firstPlayer > 0 || lastSet.secondPlayer > 0)) {
            messages = { ...messages, [3]: setMessages };
        }
    }

    if (
        !SET_SCORE_LIST.find(
            ([f, s]) => f === firstPlayerSetsWon && s === secondPlayerSetsWon
        )
    ) {
        messages = {
            ...messages,
            [0]: [
                `Mecz zakończył się niepoprawnym wynikiem ${firstPlayerSetsWon} : ${secondPlayerSetsWon}`
            ]
        };
    }


    return !!messages && Object.keys(messages).length > 0 ? messages : undefined;
};

export const validityStyle = (set: number, validateSetsResult: SetValidation) => {
    if (!validateSetsResult) return {};

    return Object.keys(validateSetsResult).indexOf(set.toString()) > -1
        ? { borderColor: 'red', borderWidth: '2px', borderStyle: 'solid' }
        : {};

}