import React from "react";
import { ActionWithError } from "../../framework/action";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Typography,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { currentErrorAction } from "../../store/shell/selectors";
import { clearCurrentError } from "../../store/shell/actions";
import ErrorIcon from '@material-ui/icons/Error';

interface ErrorNotifierProps {
  failure: ActionWithError;
  clearFailure: () => void;
}

const ErrorNotifier: React.FC<ErrorNotifierProps> = ({
  failure,
  clearFailure,
}) => {
  const handleClose = () => {
    clearFailure();
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Dialog
      open={!!failure}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Wystąpił błąd</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <ErrorIcon color="error" />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <span style={{ display: "inline-block" }}>
                {!!failure ? failure.description : ""}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Zamknij</Button>
        {failure && failure.context && failure.context.warning ? (
          <></>
        ) : (
          <Button onClick={handleReload} color="primary">
            Przeładuj stronę
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  failure: currentErrorAction(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearFailure: () => dispatch(clearCurrentError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotifier);
