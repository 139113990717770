import React, { Fragment } from "react";
import { SetValidation } from "./utils";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

interface ValidationSummaryProps {
  value: SetValidation;
}

const ValidationSummary: React.FC<ValidationSummaryProps> = ({ value }) => {
  return !!value ? (
    <>
      <Divider style={{ marginTop: "24px", backgroundColor: "red" }} />
      <List subheader={<li />} style={{ color: "rgba(255,0,0,1)" }}>
        {Object.entries(value)
          .map(([set, messages]: [string, string[]], _, __) => ({
            set: parseInt(set),
            messages
          }))
          .sort((x, y) => x.set - y.set)
          .map(({ set, messages }) => (
            <li key={set}>
              <ul>
                <ListSubheader style={{ lineHeight: "24px" }}>
                  {set === 0 ? "Mecz" : `Set ${set}`}
                </ListSubheader>
                {messages.map((m, i) => (
                  <Fragment key={`m-${set}-${i}`}>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <ListItemIcon>
                        <WarningIcon style={{ color: "red" }}></WarningIcon>
                      </ListItemIcon>
                      <ListItemText primary={m} />
                    </ListItem>
                    <Divider key={`d-${set}-${i}`}></Divider>
                  </Fragment>
                ))}
              </ul>
            </li>
          ))}
      </List>
    </>
  ) : (
    <></>
  );
};

export default ValidationSummary;
