import { PlayerRank } from "./model";
import { getConfig } from "../../config/configuration";

class EloApi {
    public static async loadRanking() : Promise<PlayerRank[]> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/elo`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'GET'
        });

        if(response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);

        
    }
}

export default EloApi;