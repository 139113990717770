import React from "react";
import { MatchHeader, Set, MatchStatus, Match } from "../../store/matches/models";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Link,
  IconButton
} from "@material-ui/core";
import { Player } from "../../store/players/models";
import DateTimeView from "../../framework/components/DateTimeView";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import LoopIcon from '@material-ui/icons/Loop';
import { firstPlayerWon, secondPlayerWon } from "./utils";
import CommentIcon from '@material-ui/icons/Comment';

interface MatchStatusProps {
  value: MatchStatus;
}

const MatchStatusDisplay: React.FC<MatchStatusProps> = ({ value }) => {
  switch (value) {
    case MatchStatus.New: {
      return (
        <Tooltip title="Nowy / niepotwierdzony">
          <WbSunnyIcon fontSize="small"></WbSunnyIcon>
        </Tooltip>
      );
    }
    case MatchStatus.Cancelled: {
      return (
        <Tooltip title="Anulowany">
          <ClearIcon color="error" fontSize="small"></ClearIcon>
        </Tooltip>
      );
    }
    case MatchStatus.Confirmed: {
      return (
        <Tooltip title="Potwierdzony">
          <CheckIcon color="secondary" fontSize="small"></CheckIcon>
        </Tooltip>
      );
    }
    case MatchStatus.Finished: {
      return (
        <Tooltip title="Zakończony">
          <DoneAllIcon color="primary" fontSize="small"></DoneAllIcon>
        </Tooltip>
      );
    }
    case MatchStatus.InProcess: {
      return (
        <LoopIcon color="secondary" className={"loading"} fontSize="small"></LoopIcon>
      )
    }
  }
};

interface ScoreDisplayProps {
  sets: Set[];
  isOponentRetired: boolean;
}

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ sets, isOponentRetired }) => {
  return (
    <>
      {isOponentRetired ? <b>wo</b> : <></>}
      {" "}
      {sets.map((s, i) => (
        <span key={i} style={{ display: "inline-block", marginRight: "16px" }}>
          {s.firstPlayer}
          {s.tieBreak ? <sup>({s.tieBreak.firstPlayer})</sup> : <></>} :{" "}
          {s.secondPlayer}
          {s.tieBreak ? <sup>({s.tieBreak.secondPlayer})</sup> : <></>}
        </span>
      ))}
    </>
  );
};

interface MatchesTableProps {
  matches: MatchHeader[];
  currentPlayer?: Player;
  forSinglePlayerOnly: boolean;
  page: number;
  pageSize: number;
  onLoadData: (page: number, pageSize: number) => void;
  actions?: (match: MatchHeader) => JSX.Element;
}

const MatchesTable: React.FC<MatchesTableProps> = ({
  matches,
  onLoadData,
  page,
  pageSize,
  actions,
  forSinglePlayerOnly
}) => {
  return (
    <>
      <TableContainer key="p1">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Klub</TableCell>
              <TableCell>Zawodnik</TableCell>
              <TableCell>Przeciwnik</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Wynik</TableCell>
              {!!forSinglePlayerOnly ? <TableCell>Info</TableCell> : <></>}
              {!!actions ? <TableCell align="right">Akcje</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map(match => (
              <TableRow key={match.id}>
                <TableCell>
                  <DateTimeView value={match.date}></DateTimeView>
                </TableCell>
                <TableCell>
                  <span>{match.club.name}</span>
                </TableCell>
                <TableCell>
                  <Link href={`/#/stats/${btoa(match.firstPlayer.id)}`}>
                    <span
                      style={{
                        fontWeight:
                          firstPlayerWon(match)
                            ? "bolder"
                            : "normal"
                      }}
                    >
                      {match.firstPlayer.name} {match.firstPlayer.surname}
                    </span>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={`/#/stats/${btoa(match.secondPlayer.id)}`}>
                    <span
                      style={{
                        fontWeight:
                          secondPlayerWon(match)
                            ? "bolder"
                            : "normal"
                      }}
                    >
                      {match.secondPlayer.name} {match.secondPlayer.surname}
                    </span>
                  </Link>
                </TableCell>
                <TableCell>
                  <MatchStatusDisplay
                    value={match.status!}
                  ></MatchStatusDisplay>
                </TableCell>
                <TableCell>
                  <span>
                    {match.score ? (
                      <ScoreDisplay sets={match.score} isOponentRetired={match.isOponentRetired}></ScoreDisplay>
                    ) : (
                        <span>Nierozegrany</span>
                      )}
                  </span>
                </TableCell>
                {!!forSinglePlayerOnly ? (<TableCell>
                  {
                    !!match.description ? (<>
                      <Tooltip title={match.description} enterTouchDelay={1}>
                        <IconButton size="small">
                          <CommentIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>) : (<></>)
                  }
                </TableCell>) : (<></>)}
                {!!actions ? (
                  <TableCell align="right">{actions(match)}</TableCell>
                ) : (
                    <></>
                  )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        key="p2"
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={
          matches.length % pageSize === 0
            ? (page + 1) * pageSize + 1
            : page * pageSize + matches.length
        }
        rowsPerPage={pageSize}
        page={page}
        labelDisplayedRows={() => " "}
        onChangePage={(e, p) => {
          onLoadData(p, pageSize);
        }}
        onChangeRowsPerPage={e => {
          const psize = parseInt(e.target.value);
          onLoadData(0, psize);
        }}
      />
    </>
  );
};

export default MatchesTable;
