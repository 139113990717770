import { takeEvery, call, put, all } from "redux-saga/effects";
import { LOAD_CLUBS } from "./actionType";
import { LoadClubs, loadClubsInProvinceSuccess, loadClubsInProvinceFailed } from "./actions";
import { loadClubsInProvinceApi } from "./api";
import { getConfig } from "../../config/configuration";
import { Club } from "./models";

function* loadClubsInProvinceStep(x: LoadClubs) {
    try {

        const config = yield call(getConfig);

        const callables = (config.availableProvinces as number[]).map(x => call(loadClubsInProvinceApi, x));

        const data: Club[][] = yield all(callables);

        const clubsData = data.reduce((prev, curr) => [...prev, ...curr], []);

        yield put(loadClubsInProvinceSuccess(clubsData));
    } catch(e) {
        yield put(loadClubsInProvinceFailed(e.message));
    }
}

export function* loadClubsForProvinceSaga() {
    yield takeEvery(LOAD_CLUBS, loadClubsInProvinceStep)
}