import { getConfig } from "../../config/configuration";
import { PaymentNeeded, PaymentInfo } from "./models";

export async function createPaymentApi(leagueId: string, leagueYear: string, promoCode: string, token: string):Promise<PaymentNeeded> {
    var config = await getConfig();

    const url = `/payment/${leagueYear}/${leagueId}`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            promoCode
        })
    });

    return await response.json();
}

export async function getUsersPaymentsApi(token: string): Promise<PaymentInfo[]> {
    var config = await getConfig();

    const url = `/players/me/payments`;
        
    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    const data: PaymentInfo[] = await response.json();

    return data.map(d => ({ ...d, paymentDate: new Date(d.paymentDate) }));
}

export async function redoUserPaymentApi(token: string, orderId: string): Promise<PaymentNeeded> {

    var config = await getConfig();

    const url = `/payment/${orderId}/copy`;

    const response = await fetch(`${config.apiUrl}${url}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST'
    });

    if(response.ok === false) {
        throw new Error(response.statusText);
    }

    return response.json();

}