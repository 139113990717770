import React from "react";
import { OrderSubmissionState, OrderSubmissionStatus } from "../../store/shop/models";
import { Grid, CircularProgress } from "@material-ui/core";

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';

interface ShoppingSubmissionStateProps {
  state: OrderSubmissionState;
}

const ShoppingSubmissionState: React.FC<ShoppingSubmissionStateProps> = ({
  state,
}) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "250px" }}>
        <Grid item>
            <div style={{ textAlign: 'center', marginBottom: '16px'}}>
                {
                    state.status === OrderSubmissionStatus.PENDING ? <CircularProgress color="primary" size="48px"/> :
                    state.status === OrderSubmissionStatus.FINISHED ? <ThumbUpIcon color="primary" fontSize="large" /> :
                    state.status === OrderSubmissionStatus.FAILED ? <ErrorIcon color="error" fontSize="large" /> :
                    <></>
                }
            </div>
            <div>
                { state.message }
            </div>
        </Grid>
    </Grid>
  );
};

export default ShoppingSubmissionState;
