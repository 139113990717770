import React from "react";
import { LeagueHeaderWithCurrentUserAssignment } from "../../store/leagues/models";
import LeagueHeaderComponent  from "./LeagueHeader";

interface ILeaguestListProps {
    leagues: LeagueHeaderWithCurrentUserAssignment[];
}

const LeaguesList: React.FC<ILeaguestListProps> = ({ leagues }) => {
    return (<> {leagues.sort((l1, l2) => l1.sequenceNo - l2.sequenceNo).map(l => (<LeagueHeaderComponent league={l} key={l.id}></LeagueHeaderComponent>))} </>)
};

export default LeaguesList;