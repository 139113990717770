import { Action, ActionCreator } from "redux";
import { CLEAR_CURRENT_ERROR, CLEAR_NOTIFICATION } from "./actionTypes";

export interface ClearCurrentError extends Action {
    type: typeof CLEAR_CURRENT_ERROR;
}

export interface ClearNotification extends Action {
    type: typeof CLEAR_NOTIFICATION;
}

export const clearCurrentError: ActionCreator<ClearCurrentError> = () => ({ type: "CLEAR_CURRENT_ERROR" });
export const clearNotification: ActionCreator<ClearNotification> = () => ({ type: CLEAR_NOTIFICATION });

export type Actions =
    | ClearCurrentError
    | ClearNotification;