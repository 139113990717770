import React from "react";
import { Set } from "../../store/matches/models";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button,
  Link,
  Checkbox
} from "@material-ui/core";
import { SetValidation, validityStyle, validateSetsResult, sanitizeSetsResult } from "./utils";
import { update } from "ramda";
import SetResult from "./SetResult";
import ValidationSummary from "./ValidationSummary";
import ReactGA from 'react-ga';
import { PlayerDescriptor } from "../../store/leagues/models";
import { Player } from "../../store/players/models";

interface MatchResultDialogProps {
  open: boolean;
  onClosed: (success: boolean, sets: Set[], walkover: boolean) => void;
  onHelpRequested: () => void;
  firstPlayer?: PlayerDescriptor;
  secondPlayer?: PlayerDescriptor;
  currentPlayer: Player;
}

const MatchResultDialog: React.FC<MatchResultDialogProps> = ({
  open,
  onClosed,
  onHelpRequested,
  firstPlayer,
  secondPlayer,
  currentPlayer
}) => {
  const [validationState, setValidationState] = React.useState<SetValidation>();
  const [sets, setSets] = React.useState<Set[]>([
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 }
  ]);
  const [walkover, setWalkover] = React.useState<boolean>(false);

  const handleClose = () => {
    onClosed(false, [], false);
  };

  const handleWalkover = (isWalkover: boolean) => {
    if (firstPlayer?.id === currentPlayer.id && isWalkover) {
      setSets([
        { firstPlayer: 6, secondPlayer: 0 },
        { firstPlayer: 6, secondPlayer: 0 },
        { firstPlayer: 0, secondPlayer: 0 },
      ])
    } else if (secondPlayer?.id === currentPlayer.id && isWalkover) {
      setSets([
        { firstPlayer: 0, secondPlayer: 6 },
        { firstPlayer: 0, secondPlayer: 6 },
        { firstPlayer: 0, secondPlayer: 0 },
      ])
    }

    setWalkover(isWalkover)
  }

  React.useEffect(() => {

    if (open) {
      ReactGA.modalview('Match result dialog');
    }

  }, [open]);

  const handleSubmit = () => {
    const saneSets = sanitizeSetsResult(sets);
    const validationResult = validateSetsResult(saneSets);
    if (validationResult) {
      setValidationState(validationResult);
      return;
    } else {
      setValidationState(undefined);
    }

    onClosed(true, sets, walkover);
    setSets([
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 }
    ]);
  };

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Podaj wynik meczu</DialogTitle>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">
                {firstPlayer?.name} {firstPlayer?.surname} : {secondPlayer?.name} {secondPlayer?.surname}
              </Typography>
            </Grid>
          </Grid>
          {
            walkover ? (<>Walkowera przyznajemy tylko w określonych przypadkach. Jeżeli chcesz wpisać walkowera to pierwsze skontaktuj się z nami poprzez <Link href="mailto:biuro@smarttennis.pl">biuro@smarttennis.pl</Link> i opisz całą sytuację.</>) : (<>
              <Grid container spacing={2}>
                {sets &&
                  sets.map((set: Set, i: number) => (
                    <Grid item key={i} sm={4} xs={12}>
                      <Typography variant="body2" style={{ textAlign: "center" }}>
                        {i + 1}. SET
                  </Typography>
                      <SetResult
                        style={{
                          width: '150px',
                          padding: "4px 0",
                          ...validityStyle(i + 1, validationState)
                        }}
                        set={set}
                        onSetChange={s => {
                          setSets([...update(i, { ...s }, sets)]);
                        }}
                      ></SetResult>
                    </Grid>
                  ))}
              </Grid>
            </>)
          }

          <Grid container spacing={2}>
            <Grid item>
              <Checkbox value={walkover} onChange={(e) => handleWalkover(e.target.checked)}></Checkbox>{" "}Walkover
              </Grid>
          </Grid>
          <ValidationSummary value={validationState}></ValidationSummary>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          { walkover ? (<></>) : (<Link href="javascript:void(0)" onClick={() => onHelpRequested()}>Wyślij wynik mailem</Link>) }
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default MatchResultDialog;
