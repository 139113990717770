import React, { Props } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, useLocation } from "react-router-dom";
import { Chip, Badge, BackdropProps, useTheme, useMediaQuery } from "@material-ui/core";

export interface NavBarLink {
    path: string;
    label: string;
    isExternal?: boolean;
    promote?: boolean;
}

export interface NavBarProps extends Props<HTMLDivElement> {
    links: NavBarLink[];
}

const NavBar: React.FC<NavBarProps> = ({ links }) => {

    const [selected, setSelected] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelected(newValue);
  };

const linkComponents = links.map(p => p.isExternal ? (<Tab key={p.path} label={p.label} component={(props) => (<a {...props}></a>)} target="_blank" href={p.path} />) : (<Tab key={p.path} label={<div>{p.label}{p.promote ? (<Badge color="primary" badgeContent={"!"}><>&nbsp;</></Badge>): <></>}</div>} component={Link} to={p.path} />));

    const location = useLocation();
    React.useEffect(() => {
        
        const selectedLink = links.find(l => l.path === location.pathname);
        const selectedLinkIdx = links.indexOf(selectedLink!);

        if(selectedLinkIdx !== selected && selectedLinkIdx !== -1) {
            setSelected(selectedLinkIdx);
        }
    }, [links, selected, location.pathname]);
    
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (<Tabs
        variant={matches ? "standard": "scrollable"}
        scrollButtons="auto"
        value={selected}
        centered={matches}
        onChange={handleChange}
        indicatorColor="primary"
        style={{ backgroundColor: "white" }}
      >
          {linkComponents}
      </Tabs>);
};

export default NavBar;