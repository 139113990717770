import React from "react";
import { Typography } from "@material-ui/core";

const CookiesPolicyPage: React.FC = () => (
    <Typography>
  <ol>
    <li>
      Pliki cookies (tzw. ciasteczka) to niewielkie pliki tekstowe wysyłane
      przez odwiedzany serwis internetowy do urządzenia internauty (komputer,
      smartfon itp.). Nie zawierają one żadnych danych osobowych.
    </li>
    <li>Serwis smarttennis.pl nie używa własnych plików cookies.</li>
    <li>
      Serwis smarttennis.pl używa zewnętrznych API na potrzeby obsługi:
      <ul>
        <li>płatności online</li>
        <li>map</li>
        <li>logowania się do serwisu</li>
      </ul>
      Serwimy te mogą zakładać swoje właśne pliki cookies.
    </li>
    <li>
      Standardowo oprogramowanie służące do przeglądania stron internetowych
      domyślnie dopuszcza umieszczanie plików cookies na urządzeniu końcowym
      Użytkownika. Ustawienia te mogą zostać zmienione przez Użytkownika w taki
      sposób, aby blokować automatyczną obsługę "cookies" w ustawieniach
      przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na
      urządzenia użytkownika.
    </li>
    <li>
      Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
      dotyczących plików cookies. Szczegółowe informacje o możliwości i
      sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
      (przeglądarki internetowej).
    </li>
  </ol>
  </Typography>
);

export default CookiesPolicyPage;