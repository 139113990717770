import React from "react";
import { Stat } from "../../store/stats/model";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, Link, makeStyles, Collapse, Box } from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TableChartIcon from '@material-ui/icons/TableChart';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as R from "ramda";

interface PerSeasonStatsProps {
    stats: Stat[];
    leagueSelected: (leagueId: string) => void;
}

//https://v4.mui.com/components/tables/#collapsible-table

interface SeasonStatsProps {
    season: string;
    stats: Stat[];
    leagueSelected: (leagueId: string) => void;
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

const SeasonStats: React.FC<SeasonStatsProps> = ({season, stats, leagueSelected}) => {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {season}
                </TableCell>
                <TableCell>{R.sum(stats.map(x => x.score.points))}</TableCell>
                <TableCell>{R.sum(stats.map(x => x.score.matchesPlayed))}</TableCell>
                <TableCell>{R.sum(stats.map(x => x.score.matchesWon))}</TableCell>
                <TableCell>{R.sum(stats.map(x => x.score.setsWon))} : {R.sum(stats.map(x => x.score.setsPlayed)) - R.sum(stats.map(x => x.score.setsWon))}</TableCell>
                <TableCell>{R.sum(stats.map(x => x.score.gemsWon))} : {R.sum(stats.map(x => x.score.gemsLost))}</TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#efefef' }}>
                <TableCell style={{ padding: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0} marginBottom={1} marginTop={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>Liga</TableCell>
                                        <TableCell>Poz. w rankingu</TableCell>
                                        <TableCell>Punkty</TableCell>
                                        <TableCell>Mecze</TableCell>
                                        <TableCell>M. wygrane</TableCell>
                                        <TableCell>Sety</TableCell>
                                        <TableCell>Gemy</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        stats.map(s => (
                                        <TableRow key={s.leagueId}>
                                            <TableCell>
                                                <IconButton size="small" color="primary" onClick={() => leagueSelected(s.leagueId)}>
                                                    <FormatListBulletedIcon></FormatListBulletedIcon>
                                                </IconButton>
                                                <IconButton size="small" color="primary" href={`/#/leagues/${s.year}/${s.leagueId}`}>
                                                    <TableChartIcon></TableChartIcon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <Link href="" color="primary" onClick={(e: React.MouseEvent) => { e.preventDefault(); leagueSelected(s.leagueId);}} style={{fontWeight: "bold"}}>
                                                    {s.leagueName}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{s.rank}.</TableCell>
                                            <TableCell>{s.score.points}</TableCell>
                                            <TableCell>{s.score.matchesPlayed}</TableCell>
                                            <TableCell>{s.score.matchesWon}</TableCell>
                                            <TableCell>{s.score.setsWon} : {(s.score.setsPlayed - s.score.setsWon)}</TableCell>
                                            <TableCell>{s.score.gemsWon} : {(s.score.gemsLost)}</TableCell>
                                        </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}


const PerSeasonStats: React.FC<PerSeasonStatsProps> = ({ stats, leagueSelected }) => {
    
    const statsGrouped = R.reverse(R.sortBy(([year, _]) => year, Object.entries(R.groupBy((a) => a.year.replace("-finals", ""), stats))));

    return (<>
        <Typography variant="h6">Sezon po sezonie...</Typography>
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{""}</TableCell>
                        <TableCell>Sezon</TableCell>
                        <TableCell>Punkty</TableCell>
                        <TableCell>Mecze</TableCell>
                        <TableCell>M. wygrane</TableCell>
                        <TableCell>Sety</TableCell>
                        <TableCell>Gemy</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        statsGrouped.map(([season, stats]) => (<SeasonStats leagueSelected={leagueSelected} season={season} stats={stats} />))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>)
};

export default PerSeasonStats;