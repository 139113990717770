import React from "react";
import TopBar from "./components/shell/TopBar";
import HeroImage from "./components/shell/HeroImage";
import NavBar, { NavBarLink } from "./components/shell/NavBar";
import PageWrapper from "./components/shell/PageWrapper";

import {
  ThemeProvider,
  Avatar,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Link,
  Badge
} from "@material-ui/core";
import theme from "./utils/theme";

import { HashRouter as Router, Route, Redirect } from "react-router-dom";

import LeaguesPage from "./components/leagues/LeaguesPage";

import { Provider } from "react-redux";
import store from "./store/store";

import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Page from "./components/shell/Page";
import "date-fns";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorNotifier from "./components/shell/ErrorNotifier";
import LeagueDetailsPage from "./components/leagues/LeagueDetailsPage";
import ClubsPage from "./components/clubs/ClubsPage";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import InfoIcon from "@material-ui/icons/Info";
import StoreIcon from "@material-ui/icons/Store";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventNoteIcon from '@material-ui/icons/EventNote';
import StarIcon from '@material-ui/icons/Star';
import BathtubIcon from '@material-ui/icons/Bathtub';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EventsIcon from '@material-ui/icons/EmojiEvents';
import SchoolIcon from '@material-ui/icons/School';

import "leaflet/dist/leaflet.css";
import MyMatchesPage from "./components/matches/MyMatchesPage";
import AllMatchesPage from "./components/matches/AllMatchesPage";
import MessageNotifier from "./components/shell/MessageNotifier";
import PaymentsPage from "./components/payments/PaymentsPage";
import AboutPage from "./components/static-pages/AboutPage";
import CookiesPolicyPage from "./components/static-pages/CookiesPolicyPage";
import PrivacyPolicyPage from "./components/static-pages/PrivacyPolicyPage";
import OnboardingDialog from "./components/shell/onboarding/OnboardingDialog";
import NewsPage from "./components/static-pages/NewsPage";
import ShopPage from "./components/shop/ShopPage";
import ShoppingOrdersPage from "./components/shop/ShoppingOrdersPage";
import DesktopPage from "./components/desktop/DesktopPage";
import StatsPage from "./components/stats/StatsPage";
import MatchOffersPage from "./components/match-offers/MatchOffersPage";
import DescriptionIcon from '@material-ui/icons/Description';
import RegistrationPage from "./components/registration/RegistrationPage";
import RankingPage from "./components/elo/RankingPage";
import AdminAllPlayersPage from "./components/players/AdminAllPlayersPage";
import { getConfig } from "./config/configuration";
import SponsorsPage from "./components/sponsors/SponsorsPage";
import TournamentsPage from "./components/tournaments/TournamentsPage";
import TournamentRegistrationPage from "./components/tournaments/registration/TournamentRegistrationPage";
import TournamentDetailsPage from "./components/tournaments/TournamentDetailsPage";
import MyTournamentMatchesPage from "./components/tournaments/matches/MyTournamentMatchesPage";
import AllTournamentMatchesPage from "./components/tournaments/matches/AllTournamentMatchesPage";
import TrainingPage from "./components/trainings/TrainingPage";
import TrainingDetailsPage from "./components/trainings/TrainingDetailsPage";
import TrainingRegistrationPage from "./components/trainings/registration/TrainingRegistrationPage";

const links: (season: string, year: string) => NavBarLink[] = (season: string, year: string) => [
  {
    label: "Pulpit",
    path: "/desktop"
  },
  {
    label: "Ligi",
    path: `/leagues/${season}`,
  },
  {
    label: "Turnieje",
    path: `/tournaments/${year}`,
    promote: true
  },
  {
    label: "Ranking",
    path: "/ranking"
  },
  {
    label: "Kluby",
    path: "/clubs",
  },
  {
    label: "Sklep",
    path: "/shop"
  },
  {
    label: "Sponsorzy",
    path: "/sponsors"
  },
  {
    label: "O nas",
    path: "https://smarttennis.pl/",
    isExternal: true
  },
];

const App: React.FC = () => {

  const [defaultSeason, setDefaultSeason] = React.useState<string>();

  React.useEffect(() => {
    getConfig().then(c => {
      setDefaultSeason(c.defaultSeason);
    });
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <>
            <OnboardingDialog></OnboardingDialog>
            <ErrorNotifier></ErrorNotifier>
            <MessageNotifier></MessageNotifier>
            <Router>
              <TopBar />
              <NavBar links={links(defaultSeason || "", new Date().getFullYear() >= 2024 ? new Date().getFullYear().toString() : "2024")}></NavBar>
              <HeroImage />
              <PageWrapper>
                <Route path="/ranking">
                  <Page
                    pageName="Ranking ELO"
                    title={<Typography variant="h5">Ranking ELO</Typography>}
                    avatar={
                      <Avatar>
                        <StarIcon></StarIcon>
                      </Avatar>
                    }>
                    <RankingPage />
                  </Page>
                </Route>
                <Route path="/tournament-signup">
                  <Page
                    pageName="Zapisy na turniej"
                    title={<Typography variant="h5">Zapisy na turniej</Typography>}
                    avatar={
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    }>
                    <TournamentRegistrationPage />
                  </Page>
                </Route>
                <Route path="/signup">
                  <Page
                    pageName="Zapisy do ligi"
                    title={<Typography variant="h5">Zapisy do ligi</Typography>}
                    avatar={
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    }>
                    <RegistrationPage />
                  </Page>
                </Route>
                <Route path="/match-offers/:leagueYear/:leagueId">
                  <Page
                    pageName="Giełda meczów"
                    title={<Typography variant="h5">Giełda meczów<Badge color="primary" badgeContent="Beta">&nbsp;</Badge></Typography>}
                    avatar={
                      <Avatar>
                        <EventNoteIcon />
                      </Avatar>
                    }
                  >
                    <MatchOffersPage />
                  </Page>
                </Route>
                <Route path="/stats/:id">
                  <Page
                    pageName="Statystyki"
                    title={<Typography variant="h5">Statystyki<Badge color="primary" badgeContent="Beta">&nbsp;</Badge></Typography>}
                    avatar={
                      <Avatar>
                        <EqualizerIcon></EqualizerIcon>
                      </Avatar>
                    }
                  >
                    <StatsPage />
                  </Page>
                </Route>
                <Route path="/desktop">
                  <Page
                    pageName="Pulpit zawodnika"
                    title={<Typography variant="h5">Pulpit zawodnika<Badge color="primary" badgeContent="Beta">&nbsp;</Badge></Typography>}
                    avatar={
                      <Avatar>
                        <DesktopWindowsIcon></DesktopWindowsIcon>
                      </Avatar>
                    }
                  >
                    <DesktopPage></DesktopPage>
                  </Page>
                </Route>
                <Route path="/sponsors">
                  <Page
                    pageName="Sponsorzy"
                    title={<Typography variant="h5">Sponsorzy</Typography>}
                    avatar={
                      <Avatar>
                        <CardGiftcardIcon />
                      </Avatar>
                    }>
                    <SponsorsPage />
                  </Page>
                </Route>
                <Route path="/shop/orders">
                  <Page
                    pageName="SmartShop - Moje zamówienia"
                    title={<Typography variant="h5">SmartShop - Moje zamówienia</Typography>}
                    avatar={
                      <Avatar>
                        <StoreIcon></StoreIcon>
                      </Avatar>
                    }
                  >
                    <ShoppingOrdersPage></ShoppingOrdersPage>
                  </Page>
                </Route>
                <Route path="/shop">
                  <Page
                    pageName="SmartShop"
                    title={<Typography variant="h5">SmartShop</Typography>}
                    avatar={
                      <Avatar>
                        <StoreIcon></StoreIcon>
                      </Avatar>
                    }
                  >
                    <ShopPage></ShopPage>
                  </Page>
                </Route>
                <Route path="/leagues/:year/:id/mine-matches">
                  <Page
                    pageName="Moje mecze"
                    title={<Typography variant="h5">Moje mecze</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <MyMatchesPage></MyMatchesPage>
                  </Page>
                </Route>
                <Route path="/leagues/:year/:id/matches">
                  <Page
                    pageName="Mecze w lidze"
                    title={<Typography variant="h5">Mecze w lidze</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <AllMatchesPage></AllMatchesPage>
                  </Page>
                </Route>
                <Route path="/leagues/:year/:id">
                  <Page
                    pageName="Szczegóły ligi"
                    title={<Typography variant="h5">Szczegóły ligi</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <LeagueDetailsPage></LeagueDetailsPage>
                  </Page>
                </Route>
                <Route path="/leagues/:year">
                  <Page
                    pageName="Ligi"
                    title={<Typography variant="h5">Ligi</Typography>}
                    avatar={
                      <Avatar>
                        <FormatListNumberedIcon></FormatListNumberedIcon>
                      </Avatar>
                    }
                  >
                    <LeaguesPage></LeaguesPage>
                  </Page>
                </Route>
                <Route path="/clubs">
                  <Page
                    pageName="Kluby"
                    title={<Typography variant="h5">Kluby</Typography>}
                    avatar={
                      <Avatar>
                        <FormatListNumberedIcon></FormatListNumberedIcon>
                      </Avatar>
                    }
                  >
                    <ClubsPage></ClubsPage>
                  </Page>
                </Route>
                <Route path="/payments">
                  <Page
                    pageName="Moje płatności"
                    title={<Typography variant="h5">Moje płatności</Typography>}
                    avatar={
                      <Avatar>
                        <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
                      </Avatar>
                    }
                  >
                    <PaymentsPage></PaymentsPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:year/:id/mine-matches">
                  <Page
                    pageName="Szczegóły turnieju"
                    title={<Typography variant="h5">Moje mecze</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <MyTournamentMatchesPage></MyTournamentMatchesPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:year/:id/matches">
                  <Page
                    pageName="Szczegóły turnieju"
                    title={<Typography variant="h5">Mecze w turnieju</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <AllTournamentMatchesPage></AllTournamentMatchesPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:year/:id">
                  <Page
                    pageName="Szczegóły turnieju"
                    title={<Typography variant="h5">Szczegóły turnieju</Typography>}
                    avatar={
                      <Avatar>
                        <FeaturedPlayListIcon></FeaturedPlayListIcon>
                      </Avatar>
                    }
                  >
                    <TournamentDetailsPage></TournamentDetailsPage>
                  </Page>
                </Route>
                <Route path="/tournaments/:year">
                  <Page
                    pageName="Moje płatności"
                    title={<Typography variant="h5">Turnieje</Typography>}
                    avatar={
                      <Avatar>
                        <EventsIcon></EventsIcon>
                      </Avatar>
                    }
                  >
                    <TournamentsPage></TournamentsPage>
                  </Page>
                </Route>
                <Route path="/trainings/:year/:id/register">
                  <Page
                    pageName="Szczegóły treningu"
                    title={<Typography variant="h5">Zapisy na trening</Typography>}
                    avatar={
                      <Avatar>
                        <SchoolIcon></SchoolIcon>
                      </Avatar>
                    }
                  >
                    <TrainingRegistrationPage />
                  </Page>
                </Route>
                <Route path="/trainings/:year/:id">
                  <Page
                    pageName="Szczegóły treningu"
                    title={<Typography variant="h5">Szczegóły treningu</Typography>}
                    avatar={
                      <Avatar>
                        <SchoolIcon></SchoolIcon>
                      </Avatar>
                    }
                  >
                    <TrainingDetailsPage />
                  </Page>
                </Route>
                <Route path="/trainings/:year">
                  <Page
                    pageName="Treningi"
                    title={
                      <Typography variant="h5">Treningi</Typography>
                    }
                    avatar={
                      <Avatar>
                        <SchoolIcon></SchoolIcon>
                      </Avatar>
                    }>
                    <TrainingPage></TrainingPage>
                  </Page>
                </Route>
                <Route path="/news">
                  <Page
                    pageName="Aktualności"
                    title={<Typography variant="h5">Aktualności</Typography>}
                    avatar={
                      <Avatar>
                        <AnnouncementIcon></AnnouncementIcon>
                      </Avatar>
                    }
                  >
                    <NewsPage />
                  </Page>
                </Route>
                <Route path="/about">
                  <Page
                    pageName="O nas"
                    title={<Typography variant="h5">O nas</Typography>}
                    avatar={
                      <Avatar>
                        <AssignmentIndIcon></AssignmentIndIcon>
                      </Avatar>
                    }
                  >
                    <AboutPage></AboutPage>
                  </Page>
                </Route>
                <Route path="/cookies-policy">
                  <Page
                    pageName="Polityka cookies"
                    title={
                      <Typography variant="h5">Polityka cookies</Typography>
                    }
                    avatar={
                      <Avatar>
                        <InfoIcon></InfoIcon>
                      </Avatar>
                    }
                  >
                    <CookiesPolicyPage></CookiesPolicyPage>
                  </Page>
                </Route>
                <Route path="/privacy-policy">
                  <Page
                    pageName="Polityka prywatności"
                    title={
                      <Typography variant="h5">Polityka prywatności</Typography>
                    }
                    avatar={
                      <Avatar>
                        <InfoIcon></InfoIcon>
                      </Avatar>
                    }
                  >
                    <PrivacyPolicyPage></PrivacyPolicyPage>
                  </Page>
                </Route>
                <Route path="/admin/players">
                  <Page
                    pageName="Wszyscy gracze"
                    title={
                      <Typography variant="h5">Wszyscy gracze</Typography>
                    }
                    avatar={
                      <Avatar>
                        <BathtubIcon></BathtubIcon>
                      </Avatar>
                    }
                  >
                    <AdminAllPlayersPage />
                  </Page>
                </Route>
                <Route path="/">
                  <Redirect to="/desktop" />
                </Route>
              </PageWrapper>
              <AppBar
                position="fixed"
                style={{ top: "auto", bottom: 0 }}
                color="default"
              >
                <Toolbar variant="dense">
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm={6} md={4}>
                      &copy; Copyright {new Date().getFullYear()} by
                      SmartTennis.pl
                    </Grid>
                    <Grid item>
                      <Link
                        href="/#/cookies-policy"
                        target="_blank"
                        color="textPrimary"
                      >
                        Polityka cookies
                      </Link>
                      &nbsp;|&nbsp;
                      <Link
                        href="/#/privacy-policy"
                        target="_blank"
                        color="textPrimary"
                      >
                        Polityka prywatności
                      </Link>
                      &nbsp;|&nbsp;
                      <Link
                        href="Smart_Tennis_regulamin.pdf"
                        target="_blank"
                        color="textPrimary"
                      >
                        Regulamin
                      </Link>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Router>
          </>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
