import React from "react";
import { RegisterToTournament, TournamentHeader } from "../../../store/tournaments/models";
import { Data } from "../../../framework/data";
import { Button, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { AppState } from "../../../store/reducer";
import { Alert } from "@material-ui/lab";
import { TrainingHeader, RegisterToTraining } from "../../../store/trainings/models";

interface ITrainingRegistrationConfirmation {
    trainings: Data<TrainingHeader[]>;
    trainingToRegister: RegisterToTraining;
    onProceed: () => void;
    onMoveBack: () => void;
}

const TrainingRegistrationConfirmation: React.FC<ITrainingRegistrationConfirmation> = ({ trainings, trainingToRegister, onMoveBack, onProceed }) => {
    if (trainings.type === "LOADED" && trainingToRegister?.training?.id) {
        
        const selectedTraining = trainings.value.find(x => x.id === trainingToRegister.training.id && x.year === trainingToRegister.training.season)!;

        return (<>
            <Grid container justify="center" alignItems="center" direction="row">
                <Grid item md={4} sm={12}>
                    <Typography variant="h5" color="primary" align="center" style={{ marginBottom: 32 }}>{selectedTraining.name}</Typography>
                    <Typography variant="body1" style={{ marginBottom: 32 }}>{selectedTraining.description}</Typography>
                    <Alert severity="info">
                        <Typography variant="body1">{selectedTraining.paymentDescription}</Typography>
                    </Alert>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
            <Button color="primary" onClick={onMoveBack}>
                Wstecz
            </Button>
            <Button color="primary" variant="contained" onClick={onProceed}>
                Dalej
            </Button>
        </>);
    } else {
        return <CircularProgress />
    }
};

const mapStateToProps = (state: AppState) => ({
    trainings: state.trainings.items,
    trainingToRegister: state.registration.trainingSelection
});


export default connect(mapStateToProps)(TrainingRegistrationConfirmation);
