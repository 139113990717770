import { combineReducers } from "redux";
import { Data, LOADING, NOT_STARTED, dataOf, fail } from "../../framework/data";
import { Actions } from "./actions";
import { TournamentHeader, TournamentDetails } from "./models";

export interface State {
    items: Data<TournamentHeader[]>;
    details: Data<TournamentDetails>
}

function reduceTournaments(state: Data<TournamentHeader[]> = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TOURNAMENTS": return LOADING;
        case "LOAD_TOURNAMENTS_FAILED": return fail(action.error);
        case "LOAD_TOURNAMENTS_SUCCESS": return dataOf(action.payload.tournaments);
        default:
            return state;
    }
}

function reduceDetails(state: Data<TournamentDetails> = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_TOURNAMENT_DETAILS": return LOADING;
        case "LOAD_TOURNAMENT_DETAILS_SUCCESS": return dataOf(action.payload.tournament);
        case "LOAD_TOURNAMENT_DETAILS_FAILED": return fail(action.error);
        default:
            return state;
    }
}

export default combineReducers<State>({
    items: reduceTournaments,
    details: reduceDetails
});
