import { getConfig } from "../../config/configuration";
import { CreateMatchOfferPayload, MatchOfferHeader } from "./models";

const SuccessType = "SUCCESS";
const ServerErrorType = "SERVER_ERROR";
const UnAuthType = "UNAUTH";
const InvalidRequestType = "INVALID_REQUEST"

interface ApiResponse {
    type: string;
}

interface ApiSuccess<T> extends ApiResponse {
    type: typeof SuccessType;
    payload: T;
}

interface ApiServerError extends ApiResponse {
    type: typeof ServerErrorType;
}

interface ApiUnAuth extends ApiResponse {
    type: typeof UnAuthType;
}

interface ApiInvalidRequest extends ApiResponse {
    type: typeof InvalidRequestType;
    errors: any;
}

export type ApiResponses<T> = ApiSuccess<T> | ApiServerError | ApiUnAuth | ApiInvalidRequest;

const success: <T>(payload: T) => ApiSuccess<T> = <T>(payload: T) => ({ type: "SUCCESS", payload });
const serverError: ApiServerError = ({ type: "SERVER_ERROR" });
const unAuth: ApiUnAuth = ({ type: "UNAUTH" });
const invalidRequest: (errors: any) => ApiInvalidRequest = (errors: any) => ({ type: "INVALID_REQUEST", errors });

class MatchOffersApi {
    constructor() {
        throw new Error("This class is meant to be static!");
    }

    public static async acceptMatchOffer(x: { id: string, leagueId: string, leagueYear: string, token: string }): Promise<ApiResponses<any>> {
        const config = await getConfig();
        const { id, leagueId, leagueYear, token } = x;

        const response = await fetch(`${config.apiUrl}/match-offers/${leagueYear}/${leagueId}/${id}/accept`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            credentials: 'include'
        });

        if (response.status === 400) {
            var body = await response.json();
            return invalidRequest(body);
        }

        if (response.status < 400) {
            return success({});
        }

        if (response.status === 401 || response.status === 403) {
            return unAuth;
        }

        return serverError;
    }

    public static async cancelMatchOffer(x: { id: string, leagueId: string, leagueYear: string, token: string }): Promise<ApiResponses<any>> {
        const config = await getConfig();
        const { id, leagueId, leagueYear, token } = x;

        const response = await fetch(`${config.apiUrl}/match-offers/${leagueYear}/${leagueId}/${id}/cancel`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            credentials: 'include'
        });

        if (response.status === 400) {
            var body = await response.json();
            return invalidRequest(body.errors);
        }

        if (response.status < 400) {
            return success({});
        }

        if (response.status === 401 || response.status === 403) {
            return unAuth;
        }

        return serverError;
    }

    public static async getMatchOffers(x: {leagueId: string, leagueYear: string}): Promise<ApiResponses<MatchOfferHeader[]>> {
        var config = await getConfig();
        const {leagueId, leagueYear} = x;

        const response = await fetch(`${config.apiUrl}/match-offers/${leagueYear}/${leagueId}`, {
            headers: {
                'Accept': 'application/json'
            },
            method: 'GET'
        });

        if (response.status === 400) {
            const body = await response.json();
            return invalidRequest(body.errors);
        }

        if (response.status >= 200 && response.status < 300) {
            const body: MatchOfferHeader[] = await response.json();
            return success(body.map(v => ({ ...v, createdOn: new Date(v.createdOn), startDate: new Date(v.startDate), endDate: new Date(v.endDate) })));
        }

        if (response.status === 401 || response.status === 403) {
            return unAuth;
        }

        return serverError;
    }

    public static async createMatchOffer({ clubIds, description, endDate, endTime, leagueId, leagueYear, startDate, startTime, token }: CreateMatchOfferPayload): Promise<ApiResponses<{}>> {
        var config = await getConfig();

        const response = await fetch(`${config.apiUrl}/match-offers/${leagueYear}/${leagueId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                clubIds,
                startDate,
                startTime,
                endDate,
                endTime,
                description
            })
        });

        if (response.status === 400) {
            var body = await response.json();
            return invalidRequest(body.errors);
        }

        if (response.status < 400) {
            return success({});
        }

        if (response.status === 401 || response.status === 403) {
            return unAuth;
        }

        return serverError;
    }
}

export default MatchOffersApi;