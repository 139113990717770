import React from "react";
import { PlayerRank } from "../../store/elo/model";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, TablePagination, Link } from "@material-ui/core";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { take, drop } from "ramda";

interface RankingTableProps {
    ranking: PlayerRank[];
}

const RankingTable: React.FC<RankingTableProps> = ({ ranking }) => {

    const [page, setPage] = React.useState();
    const [pageSize, setPageSize] = React.useState();

    const viewItems = take(
        pageSize || 25,
        drop((pageSize || 25) * (page || 0), ranking)
      );

    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '70px' }}>Miejsce</TableCell>
                            <TableCell>Imię i nazwisko</TableCell>
                            <TableCell style={{ width: '50px' }}>Miasto</TableCell>
                            <TableCell style={{ width: '50px' }}>Poziom</TableCell>
                            <TableCell style={{ width: '50px' }}>Punkty</TableCell>
                            <TableCell style={{ width: '180px' }}>Wygrane / wszystkie mecze</TableCell>
                            <TableCell style={{ width: '100px' }}>% wygranych</TableCell>
                            <TableCell style={{ width: '70px' }}>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            viewItems.map((rank, i) => (
                                <TableRow key={i}>
                                    <TableCell align="right">{i + 1 + (parseInt(page) || 0) *(parseInt(pageSize) || 25)}.</TableCell>
                                    <TableCell>
                                    <Link href={`/#/stats/${btoa(rank.playerId)}`}>
                                        <span>{rank.name}</span>
                                        <span>&nbsp;</span>
                                        <span>{rank.surname}</span>
                                    </Link>
                                    </TableCell>
                                    <TableCell align="right">{rank.city}</TableCell>
                                    <TableCell align="right">{rank.level}</TableCell>
                                    <TableCell align="right">{rank.rating}</TableCell>
                                    <TableCell align="right">{rank.numberOfWinMatches}/{rank.numberOfMatches}</TableCell>
                                    <TableCell align="right">{rank.winPercentage}%</TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Statystyki zawodnika">
                                            <IconButton href={`/#/stats/${btoa(rank.playerId)}`} size="small">
                                                <EqualizerIcon fontSize="inherit"></EqualizerIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
                component="div"
                count={ranking.length}
                rowsPerPage={pageSize ?? 25}
                page={page ?? 0}
                onChangePage={(e, p) => {
                    setPage(p);
                }}
                onChangeRowsPerPage={(e) => {
                    setPageSize(parseInt(e.target.value));
                }}
            />
        </>
    )

}

export default RankingTable;