import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  TextField,
  Button,
  Divider,
  Link,
  Tooltip,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import ReactGA from "react-ga";
import Alert from "@material-ui/lab/Alert";

interface AdditionalSubmissionInfoProps {
  show: boolean;
  isDoubles: boolean;
  onClose: (x: {
    tocApproved: boolean;
    promoCode: string;
    referral: string;
    secondaryPlayerName: string;
    secondaryPlayerSurname: string;
  }) => void;
}

const AdditionalSubmissionInfo: React.FC<AdditionalSubmissionInfoProps> = ({
  show,
  isDoubles,
  onClose,
}) => {
  const [tocApproved, setTocApproved] = React.useState<boolean>();
  const [promo, setPromo] = React.useState<string>();
  const [secPlayerName, setSecPlayerName] = React.useState<string>();
  const [secPlayerSurname, setSecPlayerSurname] = React.useState<string>();
  const [referral, setReferral] = React.useState<string>(
    window.localStorage.getItem("referral") ?? ""
  );

  React.useEffect(() => {
    if (show) {
      ReactGA.modalview("Rules/promo/referral dialog");
    }
  }, [show]);

  return (
    <Dialog
      open={show}
      onClose={() =>
        onClose({
          tocApproved: false,
          promoCode: "",
          referral: "",
          secondaryPlayerName: "",
          secondaryPlayerSurname: "",
        })
      }
    >
      <DialogTitle>Zapisujesz się do ligii</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <Checkbox
              value={tocApproved}
              onChange={(e) => setTocApproved(e.target.checked)}
            ></Checkbox>
            Zgadzam się z{" "}
            <Link
              href="Smart_Tennis_regulamin.pdf"
              target="_blank"
              color="primary"
            >
              regulaminem ligi
            </Link>
          </Grid>
          <Grid item>
            <Divider style={{ marginBottom: "16px" }}></Divider>
            <Grid container spacing={2}>
              {isDoubles ? (
                <>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Zapisujesz się do ligii deblowej, musisz podać danę
                      partnera do debla
                    </Alert>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Imię partnera deblowego"
                      variant="outlined"
                      value={secPlayerName}
                      style={{ width: "100%" }}
                      onChange={(e) => setSecPlayerName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Nazwisko partnera deblowego"
                      variant="outlined"
                      value={secPlayerSurname}
                      style={{ width: "100%" }}
                      onChange={(e) => setSecPlayerSurname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12} md={6}>
                <TextField
                  label="Kod promocyjny"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={promo}
                  onChange={(e) => setPromo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Kod polecającego"
                  variant="outlined"
                  value={referral}
                  onChange={(e) => setReferral(e.target.value)}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <>
                              <Typography variant="body2">
                                Kod, który dostałeś/-aś od osoby, która
                                zaprosiła Cię do ligi. Składa się z 8-miu dużych
                                liter i cyfr, np. 85M3JUQ1
                              </Typography>
                              <Typography variant="body2" color="secondary">
                                SmartPunkty zostaną dodane, tylko jeśli jesteś
                                nowym zawodnikiem
                              </Typography>
                            </>
                          }
                        >
                          <HelpIcon
                            color="primary"
                            fontSize="small"
                            style={{ marginLeft: "4px" }}
                          ></HelpIcon>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose({
              tocApproved: false,
              promoCode: "",
              referral: "",
              secondaryPlayerName: "",
              secondaryPlayerSurname: "",
            });
          }}
        >
          Anuluj
        </Button>
        <Button
          onClick={() => {
            onClose({
              tocApproved: !!tocApproved,
              promoCode: promo ? promo : "",
              referral: referral ? referral : "",
              secondaryPlayerName: secPlayerName ? secPlayerName : "",
              secondaryPlayerSurname: secPlayerSurname ? secPlayerSurname : "",
            });
          }}
          disabled={
            !tocApproved || (isDoubles && (!secPlayerSurname || !secPlayerName))
          }
          color="primary"
        >
          Dalej
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalSubmissionInfo;
