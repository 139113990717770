import { CircularProgress } from "@material-ui/core";
import { Data } from "../../framework/data";
import { TrainingHeader } from "../../store/trainings/models";
import TrainingCityGroup from "./TrainingCityGroup";
import React from "react";

interface ITrainingGroupsProps {
    groups: Data<{[city:string]:TrainingHeader[]}>
}

const TrainingGroups: React.FC<ITrainingGroupsProps> = ({ groups }) => {

    switch(groups.type) {
        case "LOADED": {

            const toRender = Object.entries(groups.value).map(([city, trainings]) => (<TrainingCityGroup key={city} city={city} trainings={trainings} ></TrainingCityGroup>));

            return (<div>{toRender}</div>)
        }
        case "LOADING": {
            return (<div style={{ width: '64px', height: '64px', marginLeft: 'auto', marginRight: 'auto'}}><CircularProgress /></div>)
        }
        default: {
            return(<div></div>);
        } 
    }
}

export default TrainingGroups;