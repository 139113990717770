import React from "react";
import { match, withRouter } from "react-router-dom";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Badge, Button, Grid, MenuItem, Select, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import TrainingGroups from "./TrainingGroups";
import { useAuth0 } from "@auth0/auth0-react";
import { TrainingHeader } from "../../store/trainings/models";
import { selectTrainings } from "../../store/trainings/selectors";
import { loadTrainings } from "../../store/trainings/actions";

interface TrainingPageProps {
  match: match<{ year: string }>;
  trainings: Data<{ [key: string]: TrainingHeader[] }>;
  player?: Data<Player>;
  loadTrainings: (token: string, year: string) => void;
}

const TrainingPage: React.FC<TrainingPageProps> = ({ trainings, player, loadTrainings, match }) => {

  var theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [availableYears, setAvailableYears] = React.useState<string[]>([]);
  const [defaultSeason, setDefaultSeason] = React.useState<string>("2024");

  React.useEffect(() => {
    const year = new Date().getFullYear();

    if (year >= 2024) {
      setDefaultSeason(year.toString());
    } else {
      setDefaultSeason("2024");
    }


  }, []);

  React.useEffect(() => {
    const initial = 2024;
    const thisYear = new Date().getFullYear();
    const toYear = thisYear >= 2024 ? thisYear : 2024;
    const years = [];

    for (let i = initial; i <= toYear; i++) {
      years.push(i.toString());
    }

    setAvailableYears(years);

  }, []);

  const season = match.params.year;

  const setViewedYear = React.useCallback((year: string) => window.location.assign(`/#/trainings/${year}`), []);

  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const yearToRegister = new Date().getFullYear() >= 2024 ? new Date().getFullYear().toString() : "2024";
      loadTrainings(token, yearToRegister);
    }).catch((reason) => {
      const yearToRegister = new Date().getFullYear() >= 2024 ? new Date().getFullYear().toString() : "2024";
      loadTrainings("", yearToRegister);
    });
  }, [season]);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TrainingGroups
          groups={trainings!}
        ></TrainingGroups>

        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">Rok:</Typography>
          </Grid>
          <Grid item>
            {
              isSmall ? (
                <Select value={season} onChange={(evt: React.ChangeEvent<any>) => { setViewedYear(evt.target.value) }} variant="outlined">
                  {
                    availableYears.map((y, i) => (
                      <MenuItem key={i} value={y}>
                        {y.replace("-", "/")}
                        {
                          i === 0 && y !== defaultSeason ? (<Badge color="primary" badgeContent={"!"}><>&nbsp;</></Badge>) : (<></>)
                        }
                      </MenuItem>
                    ))
                  }
                </Select>
              ) : (
                  <ToggleButtonGroup
                    value={season}
                    exclusive
                    onChange={(_, v) => { setViewedYear(v) }}
                  >
                    {availableYears.map((y, i) => (
                      <ToggleButton key={y} value={y}>
                        {y.replace("-", "/")}
                        {
                          i === 0 && y !== defaultSeason ? (<Badge color="primary" badgeContent={"!"}><>&nbsp;</></Badge>) : (<></>)
                        }
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )
            }

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

}

const mapStateToProps = (state: AppState) => {
  return {
    trainings: selectTrainings(state),
    player: currentPlayer(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTrainings: (token: string, year: string) => dispatch(loadTrainings({ year, token }))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainingPage));