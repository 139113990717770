import React from "react";
import { Data } from "../../framework/data";
import { LeagueWithPaymentStatus } from "../../store/leagues/models";
import {
  IconButton,
  Badge,
  Popover,
  Typography,
  Paper,
  Link,
} from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";

interface NotifierProps {
  payments: Data<LeagueWithPaymentStatus[]>;
}

const Notifier: React.FC<NotifierProps> = ({ payments }) => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return payments.type === "LOADED" && payments.value.find(x => !x.isActive) ? (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent="!" color="error">
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ padding: "16px", maxWidth: "250px" }}>
          Sprawdź status swoich płatności i dokończ dołączanie do ligi:{" "}
          <Link href="/#/payments" onClick={handleClose}>Moje płatności</Link>
        </Paper>
      </Popover>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: AppState) => ({
  payments: state.leagues.leaguesWithPayments,
});

export default connect(mapStateToProps)(Notifier);
