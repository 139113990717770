import React from "react";
import { PaymentStatus } from "../../store/payments/models";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import HelpIcon from "@material-ui/icons/Help";

interface PaymentStatusProps {
  value: PaymentStatus;
}

const PaymentStatusC: React.FC<PaymentStatusProps> = ({ value }) => {
  switch (value) {
    case PaymentStatus.CANCELED:
      return (
        <Tooltip title="Płatność została anulowana. Płacący nie został obciążony.">
          <Grid container justify="flex-start" alignItems="center">
            <CancelIcon color="error" fontSize="small" style={{marginRight: '4px'}}></CancelIcon>
            <Typography color="error">Anulowana</Typography>
          </Grid>
        </Tooltip>
      );
    case PaymentStatus.COMPLETED:
      return (
        <Tooltip title="Płatność została zaakceptowana w całości.">
          <Grid container justify="flex-start" alignItems="center">
            <CheckCircleIcon color="primary" fontSize="small" style={{marginRight: '4px'}}></CheckCircleIcon>
            <Typography color="primary">Zatwierdzona</Typography>
          </Grid>
        </Tooltip>
      );
    case PaymentStatus.PENDING:
      return (
        <Tooltip title="Płatność jest w trakcie rozliczenia.">
          <Grid container justify="flex-start" alignItems="center">
            <HelpIcon color="secondary" fontSize="small" style={{marginRight: '4px'}}></HelpIcon>
            <Typography color="secondary">Oczekująca na wpłątę</Typography>
          </Grid>
        </Tooltip>
      );
    case PaymentStatus.WAITING_FOR_CONFIRMATION:
      return (
        <Tooltip title="SmartTennis.pl oczekuje na informacje o zarejestrowaniu wpłaty.">
          <Grid container justify="flex-start" alignItems="center">
            <SwapHorizontalCircleIcon
              color="secondary"
              fontSize="small"
              style={{marginRight: '4px'}}
            ></SwapHorizontalCircleIcon>
            <Typography color="secondary">
              Oczekująca na potwierdzenie
            </Typography>
          </Grid>
        </Tooltip>
      );
  }
};

export default PaymentStatusC;
