import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Alert } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import { AppState } from "../../../store/reducer";
import { connect } from "react-redux";
import TournamentRegistrationWizard from "./TournamentRegistrationWizard";

export interface TournamentRegistrationPageProps {
}

const TournamentRegistrationPage: React.FC<TournamentRegistrationPageProps> = ({  }) => {

    const { isAuthenticated, loginWithRedirect } = useAuth0();

    return (<>
        {
            !isAuthenticated ? (
                <Alert severity="info">
                    <div>
                        <Link color="textPrimary" href="" onClick={() => loginWithRedirect({
                            redirectUri: window.location.href, appState: {
                                targetUrl: window.location.hash
                            }
                        })}><b>Kliknij tutaj</b></Link><span> aby zalogować się lub założyć konto w celu rozpoczęcia rejestracji.</span>
                    </div>
                </Alert>
            ) : (
                    <TournamentRegistrationWizard></TournamentRegistrationWizard>
                )
        }
    </>);
};

const mapStateToProps = (state: AppState) => ({
});


export default connect(mapStateToProps)(TournamentRegistrationPage);