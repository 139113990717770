import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  Button
} from "@material-ui/core";
import { League, LeagueHeaderWithCurrentUserAssignment } from "../../store/leagues/models";
import { Data, NOT_STARTED, dataOf, fail } from "../../framework/data";
import { getConfig } from "../../config/configuration";
import Alert from "@material-ui/lab/Alert";
import ReactGA from 'react-ga';
import { useAuth0 } from "@auth0/auth0-react";

interface PriceSubmissionInfoProps {
  open: boolean;
  onClose: (succeeded: boolean) => void;
  promoCode: string;
  league?: League|LeagueHeaderWithCurrentUserAssignment;
}

interface PriceInfo {
  price: number;
}

const PriceSubmissionInfo: React.FC<PriceSubmissionInfoProps> = ({
  open,
  onClose,
  promoCode,
  league
}) => {
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    if (!!league && !!promoCode) {
      getConfig()
        .then(c => {
          const url = `/payment/${league.year}/${league.id}/price`;
          return `${c.apiUrl}${url}`;
        })
        .then(url => {
          return getAccessTokenSilently().then((token: string) => ({
            token,
            url
          }));
        })
        .then(({ token, url }) => {
          return fetch(url, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              PromoCode: promoCode,
              Authorization: `Bearer ${token}`
            },
            credentials: "include",
            method: "GET"
          });
        })
        .then(resp => (resp.ok ? resp.json() : Promise.reject()))
        .then(p => setPriceInfo(dataOf({ price: p })))
        .catch(e => setPriceInfo(fail("Błędny kod. Rabat nie naliczony")));
    } else if (!!league) {
      setPriceInfo(dataOf({ price: league.basePrice }));
    }
  }, [promoCode, league]);

  React.useEffect(() => {

    if(open) {
      ReactGA.modalview('Pricing acceptance dialog');
    }

  }, [open]);

  const [priceInfo, setPriceInfo] = React.useState<Data<PriceInfo>>(
    NOT_STARTED
  );

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Koszt dołączenia do ligii</DialogTitle>
      <DialogContent>
        {priceInfo.type === "FAILED" ? (
          <Alert severity="error">Błędny kod promocyjny!</Alert>
        ) : (
          <></>
        )}
        {priceInfo.type === "LOADED" ? (
          <Grid container justify="space-between" alignItems="center">
            <Typography>Cena: </Typography>
            <Grid item xs={4}>
              <Typography style={{ display: 'inline-block', marginRight: '16px' }}>{priceInfo.value.price} PLN</Typography>
              {priceInfo.value.price !== league?.basePrice ? (
                <Typography style={{ textDecoration: "line-through", display: 'inline-block', color: '#c0c0c0' }}>
                  {league?.basePrice} PLN
                </Typography>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs={12}>
              W następnym kroku zostaniesz przeniesiony do systemu płatności PayU
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(false);
          }}
        >
          Anuluj
        </Button>
        {priceInfo.type === "LOADED" ? (
          <Button
            onClick={() => {
              onClose(true);
            }}
            color="primary"
          >
            Zarejestruj
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PriceSubmissionInfo;
