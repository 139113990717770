import React from "react";
import { Player } from "../../store/players/models";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import ReactGA from "react-ga";
import { Club } from "../../store/clubs/models";

import ProfileEditor from "./ProfileEditor";
import { PlayerType } from "../../store/registration/model";

export interface ProfileDialogProps {
  open: boolean;
  player?: Player;
  clubs?: Club[];
  onClosed: (x: boolean, p: Player | undefined) => void;
}

const ProfileDialog: React.FC<ProfileDialogProps> = ({
  open,
  onClosed,
  player,
  clubs,
}) => {
  const handleClose = () => {
    onClosed(false, undefined);
  };

  React.useEffect(() => {
    if (open) {
      ReactGA.modalview("Profile dialog");
    }
  }, [open]);

  const [playerState, setPlayerState] = React.useState(player);

  const handleSubmit = () => {
    if (
      playerState &&
      playerState.email &&
      playerState.birthDate &&
      playerState.name &&
      playerState.phoneNumber &&
      playerState.surname
    ) {
      onClosed(true, playerState);
    }
  };

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Mój profil zawodnika</DialogTitle>
        <div style={{ padding: "24px" }}>
          <ProfileEditor player={playerState} clubs={clubs} onPlayerChanged={setPlayerState} playerType={playerState?.playerType || PlayerType.REGULAR} ></ProfileEditor>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default ProfileDialog;
