export const SET_LEAGUE_TO_REGISTER = "SET_LEAGUE_TO_REGISTER";
export const SET_REGISTRATION_PLAYER_TYPE = "SET_REGISTRATION_PLAYER_TYPE";
export const SET_UPDATED_PLAYER = "SET_UPDATED_PLAYER";

export const SEND_SMS_VERIFICATION_CODE = "SEND_SMS_VERIFICATION_CODE";
export const SEND_SMS_VERIFICATION_CODE_SUCCESS = "SEND_SMS_VERIFICATION_CODE_SUCCESS";
export const SEND_SMS_VERIFICATION_CODE_FAILED = "SEND_SMS_VERIFICATION_CODE_FAILED";

export const VERIFY_SMS_CODE = "VERIFY_SMS_CODE";
export const VERIFY_SMS_CODE_SUCCESS = "VERIFY_SMS_CODE_SUCCESS";
export const VERIFY_SMS_CODE_FAILED = "VERIFY_SMS_CODE_FAILED";

export const GET_PRICE_INFO_FOR_LEAGUE = "GET_PRICE_INFO_FOR_LEAGUE";
export const GET_PRICE_INFO_FOR_LEAGUE_SUCCESS = "GET_PRICE_INFO_FOR_LEAGUE_SUCCESS";
export const GET_PRICE_INFO_FOR_LEAGUE_FAILED = "GET_PRICE_INFO_FOR_LEAGUE_FAILED";

export const MAKE_REGISTRATION = "MAKE_REGISTRATION";
export const MAKE_REGISTRATION_SUCCESS = "MAKE_REGISTRATION_SUCCESS";
export const MAKE_REGISTRATION_FAILED = "MAKE_REGISTRATION_FAILED";

export const SEND_REGISTRATION_MESSAGE = "SEND_REGISTRATION_MESSAGE";

export const SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE = "SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE";


export const SET_TOURNAMENT_TO_REGISTER = "SET_TOURNAMENT_TO_REGISTER";
export const SET_TRAINING_TO_REGISTER = "SET_TRAINING_TO_REGISTER";
