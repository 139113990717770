import React from "react";
import { Basket, ProductItem } from "../../store/shop/models";
import {
  makeStyles,
  Card,
  CardContent,
  Avatar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import DeleteIcon from "@material-ui/icons/Delete";
import { basketSum } from "../../store/shop/utils";

interface BasketInfoProps {
  basket: Basket;
  productRemoved: (p: ProductItem) => void;
}

const useStyles = makeStyles(({ palette }) => ({
  avatar: {
    margin: "auto",
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: "0.875em",
    textAlign: "center",
    display: "block"
  },
  divider: {
    margin: "8px 0",
  },
}));

const BasketInfo: React.FC<BasketInfoProps> = ({ basket, productRemoved }) => {
  const styles = useStyles();

  if (!basket.products || basket.products.length === 0) return <></>;

  return (
    <Card>
      <CardContent>
        <>
          <Avatar className={styles.avatar}>
            <ShoppingBasketIcon />
          </Avatar>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Twój koszyk
          </Typography>
          <Typography variant="caption" className={styles.subheader}>
            Wartość koszyka: {basketSum(basket)}
          </Typography>
          <Divider className={styles.divider}></Divider>
          <List>
            {basket.products.map((p) => (
              <ListItem key={p.id}>
                <ListItemText
                  primary={p.name}
                  secondary={`Ilość: ${p.count}`}
                ></ListItemText>
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => productRemoved(p)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      </CardContent>
    </Card>
  );
};

export default BasketInfo;
