import { Action2, ActionCreator2, ActionWithError2, ActionWithErrorCreator2, ActionWithPayload2, ActionWithPayloadCreator2 } from "../../framework/action";
import { LOAD_RANKING, LOAD_RANKING_FAILED, LOAD_RANKING_SUCCESS } from "./actionTypes";
import { PlayerRank } from "./model";

export interface LoadRanking extends Action2<typeof LOAD_RANKING> { } 

export interface LoadRankingFailed extends ActionWithError2<typeof LOAD_RANKING_FAILED> { }

export interface LoadRankingSuccess extends ActionWithPayload2<typeof LOAD_RANKING_SUCCESS, PlayerRank[]> { }

export const loadRanking: ActionCreator2<LoadRanking> = () => ({ type: "LOAD_RANKING" });
export const loadRankingFailed: ActionWithErrorCreator2<LoadRankingFailed> = (error) => ({ type: "LOAD_RANKING_FAILED", error, description: "Nie udało nam się załadować rankingu. Przeładuj stronę i spróbuj ponownie." });
export const loadRankingSuccess: ActionWithPayloadCreator2<LoadRankingSuccess> = (payload) => ({ type: "LOAD_RANKING_SUCCESS", payload });

export type Actions = 
    | LoadRanking
    | LoadRankingFailed
    | LoadRankingSuccess;