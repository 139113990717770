import React from "react";
import { PaymentInfo } from "../../store/payments/models";
import { Data } from "../../framework/data";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { getUserPayments, redoUserPayment } from "../../store/payments/actions";
import PaymentsList from "./PaymentsList";
import { useAuth0 } from "@auth0/auth0-react";

interface PaymentsPageProps {
  payments: Data<PaymentInfo[]>;
  loadPayments: (token: string) => void;
  redoPayment: (token: string, payment: PaymentInfo) => void;
}

const PaymentsPage: React.FC<PaymentsPageProps> = ({
  payments,
  loadPayments,
  redoPayment
}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((x: string) => {
        loadPayments(x);
      });
    }
  }, [isAuthenticated]);

  const onRedoPayment = async (p: PaymentInfo) => {
    var token = await getAccessTokenSilently();
    redoPayment(token, p);
  }

  return (
    <>
      {payments.type === "LOADED" ? (
        <PaymentsList payments={payments.value} redoPayment={onRedoPayment}></PaymentsList>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  payments: state.payments.payments
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadPayments: (token: string) => dispatch(getUserPayments(token)),
  redoPayment: (token: string, payment: PaymentInfo) => dispatch(redoUserPayment({ token, payment }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);