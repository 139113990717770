import { Club } from "./models";
import { getConfig } from "../../config/configuration";

export async function loadClubsInProvinceApi(province: number): Promise<Club[]> {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/clubs/${province}`);

    if(response.ok) {
        const items = await response.json();
        return items;
    } else {
        throw new Error(response.statusText);
    }

}