export const LOAD_OPEN_LEAGUES = "LOAD_OPEN_LEAGUES";
export const LOAD_OPEN_LEAGUES_SUCCESS = "LOAD_OPEN_LEAGUES_SUCCESS";
export const LOAD_OPEN_LEAGUES_FAILED = "LOAD_OPEN_LEAGUES_FAILED";

export const LOAD_MY_LEAGUES = "LOAD_MY_LEAGUES";
export const LOAD_MY_LEAGUES_SUCCESS = "LOAD_MY_LEAGUES_SUCCESS";
export const LOAD_MY_LEAGUES_FAILED = "LOAD_MY_LEAGUES_FAILED";

export const ASSIGN_PLAYER_TO_LEAGUE = "ASSIGN_PLAYER_TO_LEAGUE";
export const ASSIGN_PLAYER_TO_LEAGUE_SUCCESS = "ASSIGN_PLAYER_TO_LEAGUE_SUCCESS";
export const ASSIGN_PLAYER_TO_LEAGUE_FAILED = "ASSIGN_PLAYER_TO_LEAGUE_FAILED";

export const LOAD_LEAGUE_DETAILS = "LOAD_LEAGUE_DETAILS";
export const LOAD_LEAGUE_DETAILS_SUCCESS = "LOAD_LEAGUE_DETAILS_SUCCESS";
export const LOAD_LEAGUE_DETAILS_FAILED = "LOAD_LEAGUE_DETAILS_FAILED";

export const LOAD_MY_PRIVATE_LEAGUES = "LOAD_MY_PRIVATE_LEAGUES";
export const LOAD_MY_PRIVATE_LEAGUES_SUCCESS = "LOAD_MY_PRIVATE_LEAGUES_SUCCESS";
export const LOAD_MY_PRIVATE_LEAGUES_FAILED = "LOAD_MY_PRIVATE_LEAGUES_FAILED";

export const SHOW_ADDITIONAL_DATA_DIALOG = "SHOW_ADDITIONAL_DATA_DIALOG";
export const ADDITIONAL_DATA_ACQUIRED = "ADDITIONAL_DATA_ACQUIRED";
export const SHOW_PRICING_DATA_DIALOG = "SHOW_PRICING_DATA_DIALOG";
export const PRICING_ACCEPTED = "PRICING_ACCEPTED";

export const LOAD_MY_LEAGUES_WITH_PAYMENT = "LOAD_MY_LEAGUES_WITH_PAYMENT";
export const LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS = "LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS";
export const LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED = "LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED";

export const LOAD_OPPONENTS_PLAYED_WITH = "LOAD_OPPONENTS_PLAYED_WITH";
export const LOAD_OPPONENTS_PLAYED_WITH_SUCCESS = "LOAD_OPPONENTS_PLAYED_WITH_SUCCESS";
export const LOAD_OPPONENTS_PLAYED_WITH_FAILED = "LOAD_OPPONENTS_PLAYED_WITH_FAILED";