import { groupBy } from "ramda";
import { dataOf } from "../../framework/data";
import { AppState } from "../reducer";

export const selectTrainings = (state: AppState) => state.trainings.items.type === "LOADED" ? dataOf(groupBy(x => x.city, state.trainings.items.value)) : state.trainings.items;
export const selectTrainingDetails = (state: AppState) => state.trainings.details;

export const selectTrainingsSubscribedTo = (state: AppState) => 
    state.trainings.items.type === "LOADED"
        ? dataOf(state.trainings.items.value.filter(x => x.isCurrentUserAssigned).map(x => x.id))
        : state.trainings.items;