import React, { CSSProperties } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";



const items = [
  {img: "main1.png", posY: "center", posX: "center"},
  {img: "main2.png", posY: 0, posX: 0 },
  {img: "main3.png", posY: "center", posX: "bottom" },
  {img: "main4.png", posY: "center", posX: "center" }
]

const getStyle = () => {

  const i = Math.floor(Math.random() * items.length);

  return {
    backgroundImage: `url(${items[i].img})`,
    backgroundColor: "white",
    height: "400px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: items[i].posY,
    backgroundPositionX: items[i].posX,
    width: "100%",
    opacity: 0.4
  } as CSSProperties;

}

const HeroImage: React.FC = () => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  let style = getStyle();

  if(matches) {
    style = { ...style, height: "200px"}
  }

  return <div style={style}></div>;
};

export default HeroImage;
