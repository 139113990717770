import { ActionWithError2, ActionWithErrorCreator2, ActionWithPayload2, ActionWithPayloadCreator2, Action2, ActionCreator2 } from "../../framework/action";
import { LOAD_TOURNAMENTS, LOAD_TOURNAMENTS_FAILED, LOAD_TOURNAMENTS_SUCCESS, REGISTER_TO_TOURNAMENT, REGISTER_TO_TOURNAMENT_SUCCESS, REGISTER_TO_TOURNAMENT_FAILED, LOAD_TOURNAMENT_DETAILS, LOAD_TOURNAMENT_DETAILS_SUCCESS, LOAD_TOURNAMENT_DETAILS_FAILED, SUBMIT_TOURNAMENT_MATCH, SUBMIT_TOURNAMENT_MATCH_SUCCESS, SUBMIT_TOURNAMENT_MATCH_FAILED } from "./actionTypes";
import { TournamentHeader, RegisterToTournament as Payload, TournamentDetails } from "./models";
import { Player } from "../players/models";
import { Match } from "../matches/models";

export interface LoadTournaments extends ActionWithPayload2<typeof LOAD_TOURNAMENTS, { year: string, token: string}> { };
export interface LoadTournamentsSuccess extends ActionWithPayload2<typeof LOAD_TOURNAMENTS_SUCCESS, { tournaments: TournamentHeader[] }> { }
export interface LoadTournamentsFailed extends ActionWithError2<typeof LOAD_TOURNAMENTS_FAILED> { }

export interface RegisterToTournament extends ActionWithPayload2<typeof REGISTER_TO_TOURNAMENT, MakeTournamentRegistration> {};
export interface RegisterToTournamentSuccess extends ActionWithPayload2<typeof REGISTER_TO_TOURNAMENT_SUCCESS, {}>{};
export interface RegisterToTournamentFailed extends ActionWithError2<typeof REGISTER_TO_TOURNAMENT_FAILED>{};

export interface LoadTournamentDetails extends ActionWithPayload2<typeof LOAD_TOURNAMENT_DETAILS, { year: string, id: string, token?: string}> {};
export interface LoadTournamentDetailsSuccess extends ActionWithPayload2<typeof LOAD_TOURNAMENT_DETAILS_SUCCESS, { tournament: TournamentDetails }> { }
export interface LoadTournamentDetailsFailed extends ActionWithError2<typeof LOAD_TOURNAMENT_DETAILS_FAILED> { }

export interface SubmitTournamentMatch extends ActionWithPayload2<typeof SUBMIT_TOURNAMENT_MATCH, { tournament: TournamentDetails, match: Match, token: string }> {};
export interface SubmitTournamentMatchSuccess extends Action2<typeof SUBMIT_TOURNAMENT_MATCH_SUCCESS> { };
export interface SubmitTournamentMatchFailed extends ActionWithError2<typeof SUBMIT_TOURNAMENT_MATCH_FAILED>{ };



export const loadTournaments: ActionWithPayloadCreator2<LoadTournaments> = (x) => ({
    type: "LOAD_TOURNAMENTS",
    payload: x
});

export const loadTournamentsSuccess : ActionWithPayloadCreator2<LoadTournamentsSuccess> = (x) => ({
    type: "LOAD_TOURNAMENTS_SUCCESS",
    payload: x
});

export const loadTournamentsFailed: ActionWithErrorCreator2<LoadTournamentsFailed> = (error) => ({
    type: "LOAD_TOURNAMENTS_FAILED",
    error,
    description: 'Nie udało się załadować turniejów'
});

export const registerToTournament: ActionWithPayloadCreator2<RegisterToTournament> = (payload) => ({
    type: "REGISTER_TO_TOURNAMENT",
    payload
});

export const registerToTournamentSuccess: ActionWithPayloadCreator2<RegisterToTournamentSuccess> = (payload) => ({
    type: "REGISTER_TO_TOURNAMENT_SUCCESS",
    payload
});

export const registerToTournamentFailed: ActionWithErrorCreator2<RegisterToTournamentFailed> = (error) => ({
    type: "REGISTER_TO_TOURNAMENT_FAILED",
    error,
    description: "Nie udało się zapisać zawodnika na turniej"
});

export type MakeTournamentRegistration = {
    playerData: Player,
    target: Payload,
    token: string
}

export const loadTournamentDetails: ActionWithPayloadCreator2<LoadTournamentDetails> = (payload) => ({
    type: "LOAD_TOURNAMENT_DETAILS",
    payload
});

export const loadTournamentDetailsSuccess: ActionWithPayloadCreator2<LoadTournamentDetailsSuccess> = (payload) => ({
    type: "LOAD_TOURNAMENT_DETAILS_SUCCESS",
    payload
});

export const loadTournamentDetailsFailed: ActionWithErrorCreator2<LoadTournamentDetailsFailed> = (error) => ({
    type: "LOAD_TOURNAMENT_DETAILS_FAILED",
    error,
    description: "Nie udało się załadować szczegółów turnieju"
});

export const submitTournamentMatch: ActionWithPayloadCreator2<SubmitTournamentMatch> = (payload) => ({
    type: "SUBMIT_TOURNAMENT_MATCH",
    payload
});

export const submitTournamentMatchSuccess: ActionCreator2<SubmitTournamentMatchSuccess> = () => ({
    type: "SUBMIT_TOURNAMENT_MATCH_SUCCESS"
});

export const submitTournamentMatchFailed: ActionWithErrorCreator2<SubmitTournamentMatchFailed> = (error) => ({
    type: "SUBMIT_TOURNAMENT_MATCH_FAILED",
    error,
    description: "Nie udało się utworzyć meczu"
});

export type Actions = 
    | LoadTournaments
    | LoadTournamentsFailed
    | LoadTournamentsSuccess
    | RegisterToTournament
    | RegisterToTournamentSuccess
    | RegisterToTournamentFailed
    | LoadTournamentDetails
    | LoadTournamentDetailsSuccess
    | LoadTournamentDetailsFailed
    | SubmitTournamentMatch
    | SubmitTournamentMatchFailed
    | SubmitTournamentMatchSuccess;