import React from "react";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { clearNotification } from "../../store/shell/actions";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Notification } from '../../framework/action'

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

interface MessageNotifierProps {
    notification: Notification;
    clearNotification: () => void;
}

const MessageNotifier : React.FC<MessageNotifierProps> = ({ notification, clearNotification }) => {
    return (<Snackbar open={!!notification} autoHideDuration={6000} onClose={() => clearNotification()}>
    <Alert onClose={() => clearNotification()} severity={notification?.severity}>
      {notification?.message}
    </Alert>
  </Snackbar>)
};

const mapStateToProps = (state: AppState) => ({
    notification: state.shell.notification
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearNotification: () => dispatch(clearNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageNotifier);