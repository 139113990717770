import { ActionWithPayload, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator } from "../../framework/action";
import { GET_USER_PAYMENTS, GET_USER_PAYMENTS_SUCCESS, GET_USER_PAYMENTS_FAILED, REDO_USER_PAYMENT, REDO_USER_PAYMENT_SUCCESS, REDO_USER_PAYMENT_FAILED } from "./actionTypes";
import { PaymentInfo } from "./models";
import { Action, ActionCreator } from "redux";

export interface GetUserPayments extends ActionWithPayload<string> {
    type: typeof GET_USER_PAYMENTS;
}

export interface GetUserPaymentsSuccess extends ActionWithPayload<PaymentInfo[]> {
    type: typeof GET_USER_PAYMENTS_SUCCESS;
}

export interface GetUserPaymentsFailed extends ActionWithError {
    type: typeof GET_USER_PAYMENTS_FAILED;
}

export interface RedoUserPayment extends ActionWithPayload<{token: string, payment: PaymentInfo}> {
    type: typeof REDO_USER_PAYMENT;
}

export interface RedoUserPaymentSuccess extends Action {
    type: typeof REDO_USER_PAYMENT_SUCCESS;
}

export interface RedoUserPaymentFailed extends ActionWithError {
    type: typeof REDO_USER_PAYMENT_FAILED;
}

export const getUserPayments: ActionWithPayloadCreator<string> = (token: string) => ({
    type: GET_USER_PAYMENTS,
    payload: token
});

export const getUserPaymentsSuccess: ActionWithPayloadCreator<PaymentInfo[]> = (payments: PaymentInfo[]) => ({
    type: GET_USER_PAYMENTS_SUCCESS,
    payload: payments
});

export const getUserPaymentsFailed: ActionWithErrorCreator = (error: string) => ({
    type: GET_USER_PAYMENTS_FAILED,
    error,
    description: 'Nie udało nam się pobrać informacji o Twoich płatnościach. Przeładuj stronę, a zapewne się załadują'
});

export const redoUserPayment: ActionWithPayloadCreator<{token: string, payment: PaymentInfo}> = (payload) => ({
    type: REDO_USER_PAYMENT,
    payload
});

export const redoUserPaymentSuccess: ActionCreator<RedoUserPaymentSuccess> = () => ({
    type: REDO_USER_PAYMENT_SUCCESS
});

export const redoUserPaymentFailed: ActionWithErrorCreator = (error: string) => ({
    type: REDO_USER_PAYMENT_FAILED,
    error,
    description: 'Nie udało się rozpocząć powtarzania płatności. Spróbuj ponownie!'
});

export type Actions =
    | GetUserPayments
    | GetUserPaymentsSuccess
    | GetUserPaymentsFailed
    | RedoUserPayment
    | RedoUserPaymentSuccess
    | RedoUserPaymentFailed;