import React from "react";
import { Dialog, DialogTitle, TextField, Grid, Typography, FormControlLabel, Checkbox, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { Club } from "../../store/clubs/models";
import { Player } from "../../store/players/models";
import { sortBy } from "ramda";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { State } from "../../store/match-offers/models";
import { startOfDay, addHours, startOfHour } from "date-fns";

interface CreateMatchOfferDialogProps {
    open: boolean;
    player: Player;
    clubs: Club[];
    leagueId: string;
    leagueYear: string;
    errors?: { [k: string]: string[] };
    state: State

    onClosed: () => void;
    onSubmit: (preferredClubs: string[], fromDate: Date, fromTime: string, toDate: Date, toTime: string, description: string) => void;
}

enum TimeSpanType {
    Single, Span
}

const CreateMatchOfferDialog: React.FC<CreateMatchOfferDialogProps> = ({ open, player, clubs, leagueId, leagueYear, errors, onClosed, onSubmit, state }) => {

    const getOptionsLabel = (option: any) => {
        if (!option) {
            return "";
        }

        if (!!option.name) {
            return option.name;
        }

        if (typeof option === "string") {
            return clubs?.find((x) => x.id === option)?.name || "";
        }

        return "";
    };

    const handlePreferredClubsChange = (max: number) => (_: any, value: any[]) => {
        if (value.length <= max) {
            setPreferredClubs(
                value.map((x) => {
                    if (typeof x === "string") {
                        return clubs?.find((y) => y.id === x)?.id;
                    }
                    return x.id;
                }));
        }
    };

    const [preferredClubs, setPreferredClubs] = React.useState<string[]>(player.preferredClubs?.length || 0 > 0 ? [player.preferredClubs![0]] : [] as string[]);
    const [preferredClubsValid, setPreferredClubsValid] = React.useState<boolean>(true);
    const [preferredClubsError, setPreferredClubsError] = React.useState<string>();

    const [fromDate, setFromDate] = React.useState<Date | null>(startOfHour(addHours(new Date(), 1)));
    const [fromDateValid, setFromDateValid] = React.useState<boolean>(true);
    const [fromDateError, setFromDateError] = React.useState<string>();

    const [toDate, setToDate] = React.useState<Date | null>(new Date());
    const [toDateValid, setToDateValid] = React.useState<boolean>(true);
    const [toDateError, setToDateError] = React.useState<string>();

    const [fromTime, setFromTime] = React.useState<Date | null>(new Date());
    const [fromTimeValid, setFromTimeValid] = React.useState<boolean>(true);
    const [fromTimeError, setFromTimeError] = React.useState<string>();

    const [toTime, setToTime] = React.useState<Date | null>(new Date());
    const [toTimeValid, setToTimeValid] = React.useState<boolean>(true);
    const [toTimeError, setToTimeError] = React.useState<string>();

    const [description, setDescription] = React.useState<string>("");
    const [descriptionValid, setDescriptionValid] = React.useState<boolean>(true);
    const [descriptionError, setDescriptionError] = React.useState<string>();

    const [clubPickerReadonly, setClubPickerReadonly] = React.useState<boolean>(false);

    const [timeSpanType, setTimeSpanType] = React.useState<TimeSpanType>(TimeSpanType.Single);

    React.useEffect(() => {

        if (errors) {
            if (errors["ClubIds"]) {
                setPreferredClubsValid(false);
                setPreferredClubsError(errors["ClubIds"][0]);
            }

            if (errors["StartDate"]) {
                setFromDateValid(false);
                setFromDateError(errors["StartDate"][0]);
            }

            if (errors["EndDate"]) {
                setToDateValid(false);
                setToDateError(errors["EndDate"][0]);
            }

            if (errors["StartTime"]) {
                setFromTimeValid(false);
                setFromTimeError(errors["StartTime"][0]);
            }

            if (errors["EndTime"]) {
                setToTimeValid(false);
                setToTimeError(errors["EndTime"][0]);
            }

            if (errors["Description"]) {
                setDescriptionValid(false);
                setDescriptionError(errors["Description"][0]);
            }
        }

    }, [errors]);

    return (<form autoComplete="off" noValidate>
        <Dialog open={open}>
            <DialogTitle>Dodaj propozycję meczu</DialogTitle>
            <div style={{ padding: "24px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-read-only-input"
                            label="Ja, zawodnik"
                            defaultValue={player.name + ' ' + player.surname}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            style={{ width: '100%' }}
                        />
                    </Grid>
                    <>
                        {
                            timeSpanType === TimeSpanType.Span ? (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            mogę grać w dniach:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            autoOk
                                            error={!fromDateValid}
                                            helperText={fromDateError}
                                            inputVariant="outlined"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            style={{ width: "100%" }}
                                            label="Od dnia"
                                            invalidDateMessage="Błędny format daty"
                                            minDate={startOfDay(new Date())}
                                            minDateMessage="Data meczu nie może być datą w przeszłości"
                                            value={fromDate}
                                            onChange={(x) => { setFromDate(x); setFromDateValid(true); setFromDateError(undefined); }}>
                                        </KeyboardDatePicker>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            autoOk
                                            error={!toDateValid}
                                            helperText={toDateError}
                                            inputVariant="outlined"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            style={{ width: "100%" }}
                                            label="Do dnia"
                                            invalidDateMessage="Błędny format daty"
                                            minDate={fromDate}
                                            minDateMessage="Data meczu nie może być wcześniejsza niż data początku 'Od dnia'"
                                            value={toDate}
                                            onChange={(x) => { setToDate(x); setToDateValid(true); setToDateError(undefined); }}>

                                        </KeyboardDatePicker>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            w godzinach:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyboardTimePicker
                                            ampm={false}
                                            autoOk
                                            error={!fromTimeValid}
                                            helperText={fromTimeError}
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Od godziny"
                                            value={fromTime}
                                            minutesStep={5}
                                            onChange={(x) => { setFromTime(x); setFromTimeValid(true); setFromTimeError(undefined); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <KeyboardTimePicker
                                            ampm={false}
                                            autoOk
                                            error={!toTimeValid}
                                            helperText={toTimeError}
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Do godziny"
                                            value={toTime}
                                            minutesStep={5}
                                            onChange={(x) => { setToTime(x); setToTimeValid(true); setToTimeError(undefined); }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1">
                                            na kortach:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="preferred-clubs"
                                            multiple
                                            value={preferredClubs || []}
                                            options={sortBy((x) => x.address?.city || "Kraków", clubs || [])}
                                            getOptionLabel={getOptionsLabel}
                                            getOptionSelected={(o: Club, v: string) => o.id === v}
                                            groupBy={(o: Club) => o.address?.city || "Kraków"}
                                            filterSelectedOptions
                                            onChange={handlePreferredClubsChange(3)}
                                            disabled={clubPickerReadonly}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!preferredClubsValid}
                                                    helperText={preferredClubsError}
                                                    variant="outlined"
                                                    label="Preferowane kluby (max 3)"
                                                    placeholder="Kluby na których chciałbym grać"
                                                />
                                            )}
                                        ></Autocomplete>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={clubPickerReadonly}
                                                    onChange={(_, checked) => {
                                                        setClubPickerReadonly(checked);
                                                        setPreferredClubs([]);
                                                        setPreferredClubsError(undefined);
                                                        setPreferredClubsValid(true);
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Obojętnie jakich kortach"
                                        />
                                    </Grid>
                                </>
                            ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                mogę grać:
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <KeyboardDateTimePicker
                                                variant="inline"
                                                ampm={false}
                                                autoOk
                                                inputVariant="outlined"
                                                format="yyyy-MM-dd HH:mm"
                                                margin="normal"
                                                style={{ width: "100%" }}
                                                label="W dniu i godzinie"
                                                invalidDateMessage="Błędny format daty"
                                                minDate={new Date()}
                                                minDateMessage="Data meczu nie może być datą w przeszłości"
                                                minutesStep={30}
                                                value={fromDate}
                                                onChange={(x) => {
                                                    setFromDate(x); setFromDateValid(true); setFromDateError(undefined);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                na korcie:
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="preferred-clubs"
                                                multiple
                                                value={preferredClubs || []}
                                                options={sortBy((x) => x.address?.city || "Kraków", clubs || [])}
                                                getOptionLabel={getOptionsLabel}
                                                getOptionSelected={(o: Club, v: string) => o.id === v}
                                                groupBy={(o: Club) => o.address?.city || "Kraków"}
                                                filterSelectedOptions
                                                onChange={handlePreferredClubsChange(1)}
                                                disabled={clubPickerReadonly}
                                                style={{ width: "100%" }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={!preferredClubsValid}
                                                        helperText={preferredClubsError}
                                                        variant="outlined"
                                                        label="Kort na którym chciałbym grać"
                                                        placeholder="Kort na którym chciałbym grać"
                                                    />
                                                )}
                                            ></Autocomplete>
                                        </Grid>
                                    </>
                                )
                        }
                    </>


                    <Grid item xs={12}>
                        <TextField
                            label="Dodatkowe informacje"
                            rowsMax={4}
                            error={!descriptionValid}
                            value={description}
                            onChange={(e) => { setDescription(e.target.value); setDescriptionError(undefined); setDescriptionValid(true); }}
                            multiline
                            variant="outlined"
                            helperText={`${description?.length || 0} / 4096`}
                            style={{ width: "100%" }}>
                        </TextField>
                    </Grid>
                </Grid>

            </div>
            <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
                {state === State.SUBMITTING ? (
                    <>
                        <div style={{
                            width: "64px",
                            height: "64px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                            <CircularProgress />
                        </div>
                    </>
                ) : (
                        <>
                            <Button onClick={() => onClosed()}>Zamknij</Button>
                            <Button
                                onClick={() => {

                                    let isOk = true;

                                    if (!clubPickerReadonly && preferredClubs.length === 0) {
                                        setPreferredClubsValid(false);
                                        setPreferredClubsError("Proszę wybrać klub");
                                        isOk = false;
                                    } else {
                                        setPreferredClubsValid(true);
                                        setPreferredClubsError(undefined);
                                    }

                                    if (!fromDate) {
                                        setFromDateValid(false);
                                        setFromDateError("Pole wymagane");
                                        isOk = false;
                                    } else if (fromDate < startOfDay(new Date())) {
                                        setFromDateValid(false);
                                        setFromDateError("Data nie może być w przeszłości");
                                        isOk = false;
                                    } else {
                                        setFromDateValid(true);
                                        setFromDateError(undefined);
                                    }

                                    if (timeSpanType === TimeSpanType.Span) {
                                        if (!toDate) {
                                            setToDateValid(false);
                                            setToDateError("Pole wymagane");
                                            isOk = false;
                                        } else if (toDate.valueOf() < (fromDate || startOfDay(new Date())).valueOf()) {
                                            setToDateValid(false);
                                            setToDateError("Data nie może być wcześniejsza niż data pola 'Od dnia'");
                                            isOk = false;
                                        } else {
                                            setToDateValid(true);
                                            setToDateError(undefined);
                                        }

                                        if (!fromTime) {
                                            setFromTimeValid(false);
                                            setFromTimeError("Pole wymagane");
                                            isOk = false;
                                        } else {
                                            setFromTimeValid(true);
                                            setFromTimeError(undefined);
                                        }

                                        if (!toTime) {
                                            setToTimeValid(false);
                                            setToTimeError("Pole wymagane");
                                            isOk = false;
                                        } else if (toTime.valueOf() < (fromTime || startOfDay(new Date())).valueOf()) {
                                            setToTimeValid(false);
                                            setToTimeError("Godzina nie może być wcześniejsza niż data pola 'Od godziny'");
                                            isOk = false;
                                        } else {
                                            setToTimeValid(true);
                                            setToTimeError(undefined);
                                        }
                                    }


                                    if (description.length > 4096) {
                                        setDescriptionValid(false);
                                        setDescriptionError("Dodatkowe informacje nie mogą być dłuższe niż 4096 znaków.")
                                        isOk = false;
                                    } else {
                                        setDescriptionValid(true);
                                        setDescriptionError(undefined);
                                    }

                                    if (isOk) {

                                        if(timeSpanType === TimeSpanType.Span) {
                                            onSubmit(
                                                preferredClubs,
                                                fromDate!,
                                                fromTime!.toLocaleTimeString('pl-PL'),
                                                toDate!,
                                                toTime!.toLocaleTimeString('pl-PL'),
                                                description);
                                        } else {
                                            onSubmit(
                                                preferredClubs,
                                                fromDate!,
                                                fromDate!.toLocaleTimeString('pl-PL'),
                                                fromDate!,
                                                fromDate!.toLocaleTimeString('pl-PL'),
                                                description);
                                        }

                                        
                                    }

                                }}
                                variant="contained"
                                color="primary">
                                Zapisz
                </Button>
                        </>
                    )}
            </DialogActions>
        </Dialog>
    </form>);

};

export default CreateMatchOfferDialog;