import React, { Fragment } from "react";
import { Club } from "../../store/clubs/models";
import { Paper, Typography, makeStyles, Divider, Link, Grid } from "@material-ui/core";
import WorkingHour from "./WorkingHour";
import { describeSportCardType, describeCourtType, describeSurfaceType, describeBoolean, canWorkingHoursBeShortened, shortenedWorkingHours } from "./utils";
import PriceList from "./PriceList";
import ReactMarkdown from "react-markdown";

interface ClubDetailsProps {
  club?: Club;
}

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF"
  },
  markdown: {
    "& table": {
      width: '100%',
      borderWidth: '1px',
      borderStyle: "solid",
      borderCollapse: "collapse",

      "& th": {
        padding: "4px 8px",
        border: "1px solid #0000001f"
      },

      "& td": {
        padding: "4px 8px",
        border: "1px solid #0000001f"
      }


    },

  }
}));

const ClubDetails: React.FC<ClubDetailsProps> = ({ club }) => {
  const styles = useStyles();

  return (
    <Paper elevation={0} style={{ minWidth: "320px" }}>
      <div style={{ padding: "32px 32px" }} className={styles.header}>
        <Typography variant="h4">{club?.name}</Typography>
      </div>
      <div style={{ padding: "32px 32px" }}>
        <Typography style={{ fontWeight: "bold" }}>Adres</Typography>
        <Typography>
          {club?.address.address1}, {club?.address.postalCode}{" "}
          {club?.address.city}
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <Typography style={{ fontWeight: "bold" }}>Strona internetowa</Typography>
        <Typography>
          <Link href={club?.websiteUrl} target="_blank" >{club?.websiteUrl}</Link>
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <Typography style={{ fontWeight: "bold" }}>Telefon</Typography>
        <Typography>
          <Link href={`tel:${club?.phoneNumber}`} >{club?.phoneNumber}</Link>
        </Typography>
        {
          (club?.workingHours && club.workingHours.length == 7) ? (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Typography style={{ fontWeight: "bold" }}>Godziny otwarcia</Typography>
              <Grid container direction="column">
                {
                  canWorkingHoursBeShortened(club?.workingHours)
                    ? shortenedWorkingHours(club?.workingHours).map(wh => (<WorkingHour key={`${wh.dayOfWeek.toString()}-${wh.openTime}-${wh.closeTime}`} value={wh}></WorkingHour>))
                    : club?.workingHours?.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map(wh => <WorkingHour key={`${wh.dayOfWeek.toString()}-${wh.openTime}-${wh.closeTime}`} value={wh}></WorkingHour>)
                }
              </Grid>
            </>
          ) : (<></>)
        }
        {
          !!club && !!club.priceList && club.priceList.length > 0 ? (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Typography style={{ fontWeight: "bold" }}>Cennik</Typography>

              <div className={styles.markdown}><ReactMarkdown source={club.priceList}></ReactMarkdown></div>
            </>
          ) : (<></>)
        }
        {
          !!club && !!club.sportCards && club.sportCards.length > 0 ? (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Typography style={{ fontWeight: "bold" }}>Karty sportowe</Typography>
              {club.sportCards.map(c => (
                <Grid key={c.type.toString()} container justify="space-between">
                  <Grid item>
                    {describeSportCardType(c.type)}
                  </Grid>
                  <Grid item>
                    -{c.priceDiscount} zł
                </Grid>
                </Grid>

              ))}
            </>
          ) : (
              <></>
            )
        }
        {
          !!club && !!club.courts && club.courts.length > 0 ? (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Typography style={{ fontWeight: "bold" }}>Korty</Typography>
              {
                club.courts.map((c, i) => (
                  <div key={c.name}>
                    <Typography color="primary" style={{ display: 'inline-block' }}>{c.name}</Typography> : {describeCourtType(c.courtType)} / {describeSurfaceType(c.surfaceType)} / Oświetlenie: {describeBoolean(c.lighting)}
                  </div>
                ))
              }
            </>
          ) : (<></>)
        }



      </div>
    </Paper>
  );
};

export default ClubDetails;
