import React from "react";
import { LeaguePlayerCanIPlay, LeagueStatus } from "../../store/leagues/models";
import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  Tooltip,
  Popover,
  Link,
} from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import { take, drop, sortBy, compose, toLower, prop } from "ramda";
import { Player } from "../../store/players/models";
import LeaguePlayerView from "./LeaguePlayerView";
import { Data } from "../../framework/data";
import { Club } from "../../store/clubs/models";
import AdvanceToPlayoffs from '@material-ui/icons/ArrowUpward';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { PlayerType } from "../../store/registration/model";

type RankingMode =
  | "full"
  | "player-list"

interface LeagueRankingProps {
  players: LeaguePlayerCanIPlay[];
  currentPlayer?: Player;
  mode: RankingMode;
  leagueId: string;
  leagueYear: string;
  leagueAdvancingPlayersCount: number;
  clubs: Data<Club[]>;
  leagueStatus: LeagueStatus;
  onRegisterMatchResultWithPlayer: (p: LeaguePlayerCanIPlay) => void;
}

const LeagueRanking: React.FC<LeagueRankingProps> = ({
  players,
  currentPlayer,
  leagueId,
  leagueYear,
  leagueStatus,
  clubs,
  mode,
  onRegisterMatchResultWithPlayer,
  leagueAdvancingPlayersCount
}) => {

  let advanceCounter = 0; 

  const rankedPlayers = players
    .filter((p) => p.rank > 0)
    .sort((x, y) => x.rank - y.rank)
    .map((p) => {
      if(advanceCounter < leagueAdvancingPlayersCount && p.score.matchesPlayed > 1) {
        advanceCounter+=1;
        return {...p, advance: true}
      }

      return {...p, advance: false}
    });
  const unrankedPlayers = sortBy(
    compose(toLower, prop("name")),
    players.filter((p) => p.rank === 0)
  );

  const orderedPlayers = [...rankedPlayers, ...unrankedPlayers];

  const [page, setPage] = React.useState();
  const [pageSize, setPageSize] = React.useState();
  const [playerInView, setPlayerInView] = React.useState<{
    el: HTMLElement;
    playerId: string;
  } | null>(null);

  const viewItems = take(
    pageSize || 25,
    drop((pageSize || 25) * (page || 0), orderedPlayers)
  );

  const registerMatchResultWithPlayer = (player: LeaguePlayerCanIPlay) => {
    onRegisterMatchResultWithPlayer(player);
  };

  const getPlayerDetails = (playerId: string, target: HTMLElement) => {
    if (
      !currentPlayer ||
      (!!currentPlayer && !players.find((p) => p.id === currentPlayer.id))
    ) {
      return;
    }

    setPlayerInView({ el: target, playerId });
  };

  return (
    <>
      <Popover
        open={Boolean(playerInView?.el)}
        anchorEl={playerInView?.el}
        onClose={() => setPlayerInView(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!playerInView ? (
          <LeaguePlayerView
            playerId={playerInView?.playerId}
            clubs={clubs}
            leagueId={leagueId}
            leagueYear={leagueYear}
          ></LeaguePlayerView>
        ) : (
            <></>
          )}
      </Popover>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Zawodnik</TableCell>
              {
                mode === "full" ? (<>
                  <TableCell align="center">Punkty</TableCell>
                  <TableCell align="center">Mecze</TableCell>
                  <TableCell align="center">M. Wygrane</TableCell>
                  <TableCell align="center">Sety</TableCell>
                  <TableCell align="center">Gemy</TableCell>
                </>) : (<></>)
              }

              <TableCell align="right">Akcje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewItems.map((player, i) => (
              <TableRow key={i} >
                {
                  player.gender === "K" ? (
                    <Tooltip title="Kat. kobiet">
                      <TableCell align="right" style={{ borderLeft: player.gender === "K" ? '10px solid #ff8298' : undefined }}>
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal"
                          }}
                        >
                          {i + 1 + ((parseInt(page) || 0) * (parseInt(pageSize) || 25))}
                        </span>
                      </TableCell>
                    </Tooltip>
                  ) : (
                      <TableCell align="right" style={{ borderLeft: player.gender === "K" ? '10px solid #ff8298' : undefined }}>
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal"
                          }}
                        >
                          {i + 1 + ((parseInt(page) || 0) * (parseInt(pageSize) || 25))}
                        </span>
                      </TableCell>
                    )
                }

                <TableCell>
                  <span
                    style={{
                      fontWeight:
                        currentPlayer && currentPlayer.id === player.id
                          ? "bolder"
                          : "normal"/*,
                        display: "inline-block",
                        lineHeight: '34px'*/
                    }}
                  >
                    <Link href={`/#/stats/${btoa(player.id)}`}>
                      {player.name} {player.surname} {!!(player as any).advance ? <AdvanceToPlayoffs color="secondary" fontSize="small" /> : <></> }
                    </Link>
                  </span>
                  {/*player.playerType === PlayerType.TRIAL ? (<Tooltip title="Konto próbne"><MoneyOffIcon color="disabled" style={{ verticalAlign: 'inherit' }}/></Tooltip>) : (<></>)*/}
                </TableCell>
                {
                  mode === "full" ? (
                    <>
                      <TableCell align="center">
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal",
                          }}
                        >
                          {player.score.points}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal",
                          }}
                        >
                          {player.score.matchesPlayed}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal",
                          }}
                        >
                          {player.score.matchesWon}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal",
                          }}
                        >
                          {player.score.setsWon} : {player.score.setsPlayed - player.score.setsWon}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            fontWeight:
                              currentPlayer && currentPlayer.id === player.id
                                ? "bolder"
                                : "normal",
                          }}
                        >
                          {player.score.gemsWon} : {player.score.gemsLost}
                        </span>
                      </TableCell>
                    </>
                  ) : (<></>)
                }

                <TableCell align="right">
                  {(!!currentPlayer && player.id === currentPlayer.id) ||
                    !currentPlayer ||
                    (!!currentPlayer &&
                      !players.find((p) => p.id === currentPlayer.id)) || (
                      leagueStatus === LeagueStatus.Finished
                    ) ? (
                      <></>
                    ) : (
                      <>
                        {
                          !!player.canIPlay ? (
                            <Tooltip title="Zaplanuj mecz z tym zawodnikiem">
                              <IconButton
                              size="small"
                                onClick={() => registerMatchResultWithPlayer(player)}
                              >
                                <NoteAddIcon fontSize="inherit"></NoteAddIcon>
                              </IconButton>
                            </Tooltip>
                          ) : (<></>)
                        }

                        <Tooltip title="Dane zawodnika">
                          <IconButton
                          size="small"
                            onClick={(evt) =>
                              getPlayerDetails(player.id, evt.currentTarget)
                            }
                          >
                            <PermContactCalendarIcon fontSize="inherit"></PermContactCalendarIcon>
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  <Tooltip title="Statystyki zawodnika">
                    <IconButton href={`/#/stats/${btoa(player.id)}`} size="small">
                      <EqualizerIcon fontSize="inherit"></EqualizerIcon>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={orderedPlayers.length}
        rowsPerPage={pageSize ?? 25}
        page={page ?? 0}
        onChangePage={(e, p) => {
          setPage(p);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(parseInt(e.target.value));
        }}
      />
    </>
  );
};

export default LeagueRanking;
