import React from "react";
import { Avatar, Badge, Grid, Typography } from "@material-ui/core";
import NewestMatches from "../matches/NewestMatches";
import { PlayersMatchesInLeague } from "../../store/stats/model";

interface RecentPlayersMatchesProps {
    matches: PlayersMatchesInLeague;
}

const RecentPlayersMatches: React.FC<RecentPlayersMatchesProps> = ({ matches: playersMatchesInLeague }) => {
    if(!!playersMatchesInLeague && playersMatchesInLeague.matches.length > 0) {
        return (<>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{ flexWrap: "nowrap" }}>
                <Grid item>
                    <Typography variant="h6">Mecze w {playersMatchesInLeague.leagueName}</Typography>
                </Grid>
                <Grid item>
                    <Avatar variant="rounded">
                        {playersMatchesInLeague.matches.length}
                    </Avatar>
                </Grid>
            </Grid>
    
    
            <NewestMatches matches={playersMatchesInLeague.matches}></NewestMatches>
        </>);
    } else {
        return (<>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{ flexWrap: "nowrap" }}>
                <Grid item>
                    <Typography variant="h6">Brak meczów</Typography>
                </Grid>
                <Grid item>
                    <Avatar variant="rounded">
                        0
                    </Avatar>
                </Grid>
            </Grid>
        </>)
    }
    
}

export default RecentPlayersMatches;