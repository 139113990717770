export interface PaymentNeeded {
    externalPaymentOrderId: string;
    finalPrice: string;
    paymentLink: string;
}

export interface PaymentInfo {
    externalPaymentOrderId: string;
    paymentDate: Date;
    paymentStatus:PaymentStatus;
    finalPrice: number;
    leagueId: string;
    leagueYear: string;
    leagueName: string;
}

export enum PaymentStatus {
    PENDING,
    WAITING_FOR_CONFIRMATION,
    COMPLETED,
    CANCELED
}