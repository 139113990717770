import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  MobileStepper,
  Button,
  makeStyles,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";

const items = [
  {
    image: "step1.png",
    content: (
      <>
        <Typography variant="h5" color="primary" style={{ marginTop: "16px" }}>
          Załóż swój profil
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          Aby zapisać się do ligii i uczestniczyć w jej rozgrywkach potrzebujesz
          stworzyć utworzyć użytkownika i stworzyć jego profil.
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          Zaloguj / zarejestruj się, a potem wybierz z menu 'Profil zawodnika'.
          Nie musisz robić tego od razu - bez pośpiechu, ale stworzenie profilu
          od razu pozwoli Ci na szybsze dołączenie do ligii.
        </Typography>
      </>
    ),
  },
  {
    image: "step2.png",
    content: (
      <>
        <Typography variant="h5" color="primary" style={{ marginTop: "16px" }}>
          Zapisz się do ligii
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          Wspieramy ligi dla zawodników na różnych poziomach zaawansowania
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          Zapisywanie się do ligi jest naprawdę proste - kliknij 'Zapisz się', a
          my poprowadzimy Cię przez (krótki) proces dołączania do ligi.
        </Typography>
      </>
    ),
  },
  {
    image: "step3.png",
    content: (
      <>
        <Typography variant="h5" color="primary" style={{ marginTop: "16px" }}>
          Umawiaj mecze i rejestruj wyniki
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          Umawianie meczy i rejestrowanie wyników jeszcze nigdy nie było takie
          łatwe...
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          ...ale jeśli potrzebujesz - zawsze możesz napisać do nas na:{" "}
          <a href="mailto:support@smarttennis.pl">support@smarttennis.pl</a> -
          na pewno pomożemy!
        </Typography>
      </>
    ),
  },
  {
    image: "step4.png",
    content: (
      <>
        <Typography variant="h5" color="primary" style={{ marginTop: "16px" }}>
          Wygrywaj...
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          ...i dobrze się baw...
        </Typography>
        <Typography style={{ marginTop: "16px" }}>
          W końcu przecież chodzi tu o przyjacielską rywalizację i doskonalenie
          swoich umiejętności :)
        </Typography>
      </>
    ),
  },
];

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const OnboardingDialog: React.FC = () => {
  const classes = useStyles();

  const [openOnboarding, setOpenOnboarding] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onboardingDone = new Boolean(
      localStorage.getItem("__onboarding_done__")
    );

    setOpenOnboarding(!onboardingDone.valueOf());
  });

  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Dialog open={openOnboarding} maxWidth="sm">
       <DialogTitle>
        <Grid
          container
          alignItems="flex-end"
          direction="column"
          style={{ width: "100%" }}
        >
          <Grid item>
            <IconButton
              onClick={() => {
                localStorage.setItem("__onboarding_done__", "true");
                setOpenOnboarding(false);
              }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <img src={items[activeStep].image} width="100%"></img>
      <DialogContent>{items[activeStep].content}</DialogContent>
      <DialogActions>
        <MobileStepper
          steps={items.length}
          position="static"
          variant="dots"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            (activeStep === items.length - 1) ? (
              <Button
              size="small"
              onClick={() => {
                localStorage.setItem("__onboarding_done__", "true");
                setOpenOnboarding(false);
              }}
            >
              Do dzieła!
            </Button>
            ) : (
              <Button
              size="small"
              onClick={() => {
                setActiveStep((x) => x + 1);
              }}
            >
              Dalej
              <KeyboardArrowRight />
            </Button>
            )
            
          }
          backButton={
            <Button
              size="small"
              onClick={() => {
                setActiveStep((x) => x - 1);
              }}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Wstecz
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingDialog;
