import { Data, NOT_STARTED, LOADING, dataOf, fail } from "../../framework/data";
import { Product, Basket, ProductItem, Spot, OrderSubmissionState, OrderSubmissionStatus, Order } from "./models";
import { combineReducers } from "redux";
import { Actions } from "./actionts";
import { findIndex, update, remove } from "ramda";

export interface State {
    products: Data<Product[]>;
    basket: Basket;
    spots: Data<Spot[]>;
    selectedSpot: Spot | null;
    submissionState: OrderSubmissionState;
    orders: Data<Order[]>;
}

function reduceProducts(state = NOT_STARTED, action: Actions) {
    switch (action.type) {
        case "LOAD_PRODUCTS": return LOADING;
        case "LOAD_PRODUCTS_SUCCESS": return dataOf(action.payload);
        case "LOAD_PRODUCTS_FAILED": return fail(action.error);
        default: return state;
    }
}

function reduceBasket(state: Basket = { products: [] }, action: Actions): Basket {
    switch (action.type) {
        case "ADD_PRODUCT_TO_BASKET": {
            const id = action.payload.id;
            if (!!state.products.find(x => x.id === id)) {
                const idx = findIndex<ProductItem>(x => x.id === id)(state.products);
                return { products: update(idx, { ...state.products[idx], count: state.products[idx].count + 1 })(state.products) };
            } else {
                return { products: [...state.products, action.payload] };
            }
        }
        case "REMOVE_PRODUCT_FROM_BASKET": {
            const id = action.payload;

            if (!!state.products.find(x => x.id === id)) {
                const idx = findIndex<ProductItem>(x => x.id === id)(state.products);
                if (state.products[idx].count > 1) {
                    return { products: update(idx, { ...state.products[idx], count: state.products[idx].count - 1 })(state.products) };
                }

                return { products: remove(idx, 1, state.products) };
            }

        }
        default: return state;
    }
}

function reduceSpots(state = NOT_STARTED, action: Actions): Data<Spot[]> {
    switch (action.type) {
        case "LOAD_SPOTS": {
            return LOADING;
        }
        case "LOAD_SPOTS_FAILED": {
            return fail(action.error);
        }
        case "LOAD_SPOTS_SUCCESS": {
            return dataOf(action.payload);
        }
        default: return state;
    }
}

function reduceSelectedSpot(state = null, action: Actions): Spot | null {
    switch (action.type) {
        case "SET_SPOT": {
            return action.payload;
        }
        default: return state;
    }
}

const initialState: OrderSubmissionState = {
    status: OrderSubmissionStatus.NOT_STARTED, 
    message: ''
}

function reduceSubmissionState(state = initialState, action: Actions): OrderSubmissionState {
    switch (action.type) {
        case "CREATE_SHOPPING_ORDER": {
            return { status: OrderSubmissionStatus.PENDING, message: "Składamy Twoje zamówienie..." }
        }
        case "CREATE_SHOPPING_ORDER_SUCCESS": {
            return { status: OrderSubmissionStatus.FINISHED, message: "Twoje zamówienie zostało złożone. Za 10 sekund przeniesiemy Cię na stronę główną." }
        }
        case "CREATE_SHOPPING_ORDER_FAILED": {
            return { status: OrderSubmissionStatus.FAILED, message: action.description }
        }
        default: return state;
    }

}

function reduceOrders(state = NOT_STARTED, action: Actions): Data<Order[]> {
    switch(action.type) {
        case "LOAD_MY_ORDERS": {
            return LOADING;
        }
        case "LOAD_MY_ORDERS_FAILED": {
            return fail(action.error);
        }
        case "LOAD_MY_ORDERS_SUCCESS": {
            return dataOf(action.payload);
        }
        default: return state;
    }
}

export default combineReducers({ 
    products: reduceProducts, 
    basket: reduceBasket, 
    spots: reduceSpots, 
    selectedSpot: reduceSelectedSpot, 
    submissionState: reduceSubmissionState,
    orders: reduceOrders
});