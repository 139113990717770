import { RegisterToTraining, TrainingHeader, TrainingDetails } from "./models";
import { getConfig } from "../../config/configuration";
import { RegistrationStatus } from "../registration/model";
import { Match } from "../matches/models";

class TrainingRegistrationApi {

    public static async register(token: string, target: RegisterToTraining) : Promise<RegistrationStatus> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/trainings/register`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify(target)
        });

        if(response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    public static async load(token: string, year: string) : Promise<TrainingHeader[]> {
        const config = await getConfig();

        const header = !!token ? {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        } : {
            'Accept': 'application/json',
        }

        const response = await fetch(`${config.apiUrl}/trainings/${year}`, {
            headers: (header as any),
            method: 'GET'
        });

        if(response.ok) {
            const data = await response.json() as TrainingDetails[];
            
            return data.map(d => ({...d, endDate: new Date(d.endDate), startDate: new Date(d.startDate) }));

        }

        throw new Error(response.statusText);
    }

    public static async loadDetails(token: string|undefined, year: string, id: string) : Promise<TrainingDetails> {
        const config = await getConfig();

        const header = !!token ? {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        } : {
            'Accept': 'application/json',
        }

        const response = await fetch(`${config.apiUrl}/trainings/${year}/${id}`, {
            headers: (header as any),
            method: 'GET'
        });

        if(response.ok) {
            const data = await response.json() as TrainingDetails;
            
            return {...data, endDate: new Date(data.endDate), startDate: new Date(data.startDate)};

        }

        throw new Error(response.statusText);
    }
}

export default TrainingRegistrationApi;