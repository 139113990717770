import { LOAD_OPEN_LEAGUES, LOAD_OPEN_LEAGUES_FAILED, LOAD_OPEN_LEAGUES_SUCCESS, LOAD_MY_LEAGUES, LOAD_MY_LEAGUES_FAILED, LOAD_MY_LEAGUES_SUCCESS, ASSIGN_PLAYER_TO_LEAGUE, ASSIGN_PLAYER_TO_LEAGUE_SUCCESS, ASSIGN_PLAYER_TO_LEAGUE_FAILED, LOAD_LEAGUE_DETAILS, LOAD_LEAGUE_DETAILS_SUCCESS, LOAD_LEAGUE_DETAILS_FAILED, LOAD_MY_PRIVATE_LEAGUES, LOAD_MY_PRIVATE_LEAGUES_SUCCESS, LOAD_MY_PRIVATE_LEAGUES_FAILED, SHOW_ADDITIONAL_DATA_DIALOG, ADDITIONAL_DATA_ACQUIRED, PRICING_ACCEPTED, SHOW_PRICING_DATA_DIALOG, LOAD_MY_LEAGUES_WITH_PAYMENT, LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS, LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED, LOAD_OPPONENTS_PLAYED_WITH, LOAD_OPPONENTS_PLAYED_WITH_SUCCESS, LOAD_OPPONENTS_PLAYED_WITH_FAILED  } from './actionTypes';
import { ActionCreator, Action } from 'redux';
import { ActionWithPayloadCreator, ActionWithErrorCreator, ActionWithError, ActionWithPayload, WithNotification, Severity } from '../../framework/action';
import { LeagueHeader, AssignPlayerToLeagueRequest, League, LeagueWithPaymentStatus } from './models';

export const loadOpenLeagues: ActionWithPayloadCreator<string|undefined> = (year?: string) => ({ type: LOAD_OPEN_LEAGUES, payload: year});
export const loadOpenLeaguesFailed: ActionWithErrorCreator<string> = (error: string) => ({ type: LOAD_OPEN_LEAGUES_FAILED, error, description: "Nie udało się załadować lig! Spróbuj przeładować stronę, a na pewno się powiedzie." });
export const loadOpenLeaguesSuccess: ActionWithPayloadCreator<LeagueHeader[]> = (leagues: LeagueHeader[]) => ({ type: LOAD_OPEN_LEAGUES_SUCCESS, payload: leagues });

export const loadMyLeagues: ActionCreator<LoadMyLeagues> = (token: string) => ({ type: LOAD_MY_LEAGUES, payload: token });
export const loadMyLeaguesFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_MY_LEAGUES_FAILED, error, description: "Nie udało się załadować Twoich lig! Najprawdopodobniej jesteś w stanie przeglądać ligii, ale spróbuj przeładować stronę i zaloguj się ponownie." });
export const loadMyLeaguesSuccess: ActionWithPayloadCreator<string[]> = (leagueIds) => ({ type: LOAD_MY_LEAGUES_SUCCESS, payload: leagueIds });

export const assignPlayerToLeague: ActionWithPayloadCreator<AssignPlayerToLeagueRequest> = ({player, league, token}) => (
    {  
        type: ASSIGN_PLAYER_TO_LEAGUE, 
        payload: {
            player,
            league,
            token
        }
    });

export const assignPlayerToLeagueFailed: ActionWithErrorCreator = (error) => ({ type: ASSIGN_PLAYER_TO_LEAGUE_FAILED, error, description: "Nie udało nam się przypisać Cię do ligi. Spróbuj ponownie." });
export const assignPlayerToLeagueSuccess: ActionCreator<AssignPlayerToLeagueSuccess> = () => ({ type: ASSIGN_PLAYER_TO_LEAGUE_SUCCESS, notification: { severity: Severity.Success, message: 'Gratulacje! Zostałeś zapisany do ligii!' } });

export const loadLeagueDetails: ActionCreator<LoadLeagueDetails> = ({ year, id }) => ({ type: LOAD_LEAGUE_DETAILS, payload: { year, id } });
export const loadLeagueDetailsFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_LEAGUE_DETAILS_FAILED, error, description: "Nie udało się załadować informacji szczegółowych o lidze! Spróbuj przeładować stronę, a pewnie się powiedzie." })
export const loadLeagueDetailsSuccess: ActionWithPayloadCreator<League> = (payload: League) => ({ type: LOAD_LEAGUE_DETAILS_SUCCESS, payload });

export const loadMyPrivateLeagues: ActionWithPayloadCreator<string> = (token: string) => ({ type: LOAD_MY_PRIVATE_LEAGUES, payload: token });
export const loadMyPrivateLeaguesFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_MY_PRIVATE_LEAGUES_FAILED, error, description: "Nie udało się załadować lig prywatnych, w których jesteś zawodnikiem. Spróbuj przeładować stronę." });
export const loadMyPrivateLeaguesSuccess: ActionWithPayloadCreator<LeagueHeader[]> = (leagues: LeagueHeader[]) => ({ type: LOAD_MY_PRIVATE_LEAGUES_SUCCESS, payload: leagues });

export const showAdditionalDataDialog: ActionCreator<ShowAdditionalDataDialog> = () => ({ type: SHOW_ADDITIONAL_DATA_DIALOG });
export const additionalDataAcquired: ActionWithPayloadCreator<{ tocApproved: boolean, promoCode: string, referral: string, secondaryPlayerName?: string, secondaryPlayerSurname?: string }> = ({ tocApproved, promoCode, referral, secondaryPlayerName, secondaryPlayerSurname }) => ({ type: ADDITIONAL_DATA_ACQUIRED, payload: { promoCode, tocApproved, referral, secondaryPlayerName, secondaryPlayerSurname } });
export const showPricingDataDialog: ActionWithPayloadCreator<string> = (promoCode: string) => ({ type: SHOW_PRICING_DATA_DIALOG, payload: promoCode });
export const pricingAccepted: ActionWithPayloadCreator<boolean> = (accepted: boolean) => ({ type: PRICING_ACCEPTED, payload: accepted });

export const loadMyLeaguesWithPayment : ActionWithPayloadCreator<{ token: string, year?: string }> = (payload) => ({ type: LOAD_MY_LEAGUES_WITH_PAYMENT, payload });
export const loadMyLeaguesWithPaymentFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED, error, description: 'Nie udało nam się załadować płatności za Twoje ligii.' });
export const loadMyLeaguesWithPaymentSuccess: ActionWithPayloadCreator<LeagueWithPaymentStatus[]> = (payload) => ({ type: LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS, payload });

export const loadOpponentsPlayedWith : ActionWithPayloadCreator<{ token: string, year: string, id: string}> = (payload) => ({ type: LOAD_OPPONENTS_PLAYED_WITH, payload });
export const loadOpponentsPlayedWithFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_OPPONENTS_PLAYED_WITH_FAILED, error, description: 'Błąd ładowania danych ligii' });
export const loadOpponentsPlayedWithSuccess: ActionWithPayloadCreator<string[]> = (payload) => ({ type: LOAD_OPPONENTS_PLAYED_WITH_SUCCESS, payload });

export interface LoadOpenLeagues extends ActionWithPayload<string> {
    type: typeof LOAD_OPEN_LEAGUES
}

export interface LoadOpenLeaguesFailed extends ActionWithError {
    type: typeof LOAD_OPEN_LEAGUES_FAILED
}

export interface LoadOpenLeaguesSuccess extends ActionWithPayload<LeagueHeader[]> {
    type: typeof LOAD_OPEN_LEAGUES_SUCCESS
}

export interface LoadMyLeagues extends ActionWithPayload<string> {
    type: typeof LOAD_MY_LEAGUES
}

export interface LoadMyLeaguesFailed extends ActionWithError {
    type: typeof LOAD_MY_LEAGUES_FAILED;
}

export interface LoadMyLeaguesSuccess extends ActionWithPayload<string[]> {
    type: typeof LOAD_MY_LEAGUES_SUCCESS;
}

export interface AssignPlayerToLeague extends ActionWithPayload<AssignPlayerToLeagueRequest> {
    type: typeof ASSIGN_PLAYER_TO_LEAGUE;
}

export interface AssignPlayerToLeagueSuccess extends Action, WithNotification {
    type: typeof ASSIGN_PLAYER_TO_LEAGUE_SUCCESS;
}

export interface AssignPlayerToLeagueFailed extends ActionWithError {
    type: typeof ASSIGN_PLAYER_TO_LEAGUE_FAILED;
}

export interface LeagueApiDescriptor {
    year: string;
    id: string;
}

export interface LoadLeagueDetails extends ActionWithPayload<LeagueApiDescriptor> {
    type: typeof LOAD_LEAGUE_DETAILS;
}

export interface LoadLeagueDetailsSuccess extends ActionWithPayload<League> {
    type: typeof LOAD_LEAGUE_DETAILS_SUCCESS;
}

export interface LoadLeagueDetailsFailed extends ActionWithError {
    type: typeof LOAD_LEAGUE_DETAILS_FAILED;
}

export interface LoadMyPrivateLeagues extends ActionWithPayload<string> {
    type: typeof LOAD_MY_PRIVATE_LEAGUES;
}

export interface LoadMyPrivateLeaguesSuccess extends ActionWithPayload<LeagueHeader[]> {
    type: typeof LOAD_MY_PRIVATE_LEAGUES_SUCCESS;
}

export interface LoadMyPrivateLeaguesFailed extends ActionWithError {
    type: typeof LOAD_MY_PRIVATE_LEAGUES_FAILED;
}

export interface ShowAdditionalDataDialog extends Action {
    type: typeof SHOW_ADDITIONAL_DATA_DIALOG;
}

export interface AdditionalDataAcquired extends ActionWithPayload<{ tocApproved: boolean, promoCode: string, referral: string }> {
    type: typeof ADDITIONAL_DATA_ACQUIRED;
}

export interface ShowPricingDataDialog extends ActionWithPayload<string> {
    type: typeof SHOW_PRICING_DATA_DIALOG;
}

export interface PricingAccepted extends ActionWithPayload<boolean> {
    type: typeof PRICING_ACCEPTED;
}

export interface LoadMyLeaguesWithPayment extends ActionWithPayload<{ token: string, year?: string }> {
    type: typeof LOAD_MY_LEAGUES_WITH_PAYMENT;
}

export interface LoadMyLeaguesWithPaymentSuccess extends ActionWithPayload<LeagueWithPaymentStatus[]> {
    type: typeof LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS;
}

export interface LoadMyLeaguesWithPaymentFailed extends ActionWithError {
    type: typeof LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED;
}

export interface LoadOpponentsPlayedWith extends ActionWithPayload<{ token: string, year: string, id: string }> {
    type: typeof LOAD_OPPONENTS_PLAYED_WITH;
}

export interface LoadOpponentsPlayedWithSuccess extends ActionWithPayload<string[]> {
    type: typeof LOAD_OPPONENTS_PLAYED_WITH_SUCCESS;
}

export interface LoadOpponentsPlayerWithFailed extends ActionWithError {
    type: typeof LOAD_OPPONENTS_PLAYED_WITH_FAILED;
}

export type Actions =
    | LoadOpenLeagues
    | LoadOpenLeaguesFailed
    | LoadOpenLeaguesSuccess
    | LoadMyLeagues
    | LoadMyLeaguesFailed
    | LoadMyLeaguesSuccess
    | AssignPlayerToLeague
    | AssignPlayerToLeagueFailed
    | AssignPlayerToLeagueSuccess
    | LoadLeagueDetails
    | LoadLeagueDetailsFailed
    | LoadLeagueDetailsSuccess
    | LoadMyPrivateLeagues
    | LoadMyPrivateLeaguesFailed
    | LoadMyPrivateLeaguesSuccess
    | ShowAdditionalDataDialog
    | AdditionalDataAcquired
    | ShowPricingDataDialog
    | PricingAccepted
    | LoadMyLeaguesWithPayment
    | LoadMyLeaguesWithPaymentSuccess
    | LoadMyLeaguesWithPaymentFailed
    | LoadOpponentsPlayedWith
    | LoadOpponentsPlayedWithSuccess
    | LoadOpponentsPlayerWithFailed;