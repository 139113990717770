import { Data, LOADING, fail, dataOf, NOT_STARTED } from '../../framework/data';
import { LeagueHeader, League, PricingDialogState, LeagueWithPaymentStatus } from './models';
import { Actions } from './actions';
import { combineReducers } from 'redux';

export interface State {
    items: Data<LeagueHeader[]>;
    subscribedTo: Data<string[]>;
    currentDetails: Data<League>;
    currentOpponents: Data<string[]>;
    privateLeagues: Data<LeagueHeader[]>;
    showAdditionalDataDialog: boolean;
    showPricingDataDialog: PricingDialogState;
    leaguesWithPayments: Data<LeagueWithPaymentStatus[]>;
};

function reduceItems(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_OPEN_LEAGUES": return LOADING;
        case "LOAD_OPEN_LEAGUES_FAILED": return fail(action.error);
        case "LOAD_OPEN_LEAGUES_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
};

function reduceSubscribedTo(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_MY_LEAGUES": return LOADING;
        case "LOAD_MY_LEAGUES_FAILED": return fail(action.error);
        case "LOAD_MY_LEAGUES_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceMyLeaguePayments(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_MY_LEAGUES_WITH_PAYMENT_FAILED": return fail(action.error);
        case "LOAD_MY_LEAGUES_WITH_PAYMENT_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceCurrentDetails(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_LEAGUE_DETAILS": return LOADING;
        case "LOAD_LEAGUE_DETAILS_FAILED": return fail(action.error);
        case "LOAD_LEAGUE_DETAILS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reducePrivateLeagues(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_MY_LEAGUES": return LOADING;
        case "LOAD_MY_PRIVATE_LEAGUES": return fail(action.error);
        case "LOAD_MY_PRIVATE_LEAGUES_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceShowAdditionalDataDialog(state = false, action: Actions) {
    switch(action.type) {
        case "SHOW_ADDITIONAL_DATA_DIALOG": return true;
        case "ADDITIONAL_DATA_ACQUIRED": return false;
        default: return state;
    }
}

const initialState:PricingDialogState = { showDialog: false, promoCode: "" };

function reduceShowPricingDataDialog(state = initialState, action: Actions):PricingDialogState {
    switch(action.type) {
        case "SHOW_PRICING_DATA_DIALOG": return { showDialog: true, promoCode: action.payload };
        case "PRICING_ACCEPTED": return initialState;
        default: return state;
    }
}

function reduceCurrentOpponents(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_OPPONENTS_PLAYED_WITH": return LOADING;
        case "LOAD_OPPONENTS_PLAYED_WITH_FAILED": return fail(action.error);
        case "LOAD_OPPONENTS_PLAYED_WITH_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({ 
    items: reduceItems, 
    subscribedTo: reduceSubscribedTo, 
    currentDetails: reduceCurrentDetails, 
    privateLeagues: reducePrivateLeagues, 
    showAdditionalDataDialog: reduceShowAdditionalDataDialog,
    showPricingDataDialog: reduceShowPricingDataDialog,
    leaguesWithPayments: reduceMyLeaguePayments,
    currentOpponents: reduceCurrentOpponents
});