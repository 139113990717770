import React from "react";
import { withRouter, match } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Data, isLoaded } from "../../../framework/data";
import { MatchHeader } from "../../../store/matches/models";
import MatchesTable from "../../matches/MatchesTable";
import { AppState } from "../../../store/reducer";
import { TournamentDetails } from "../../../store/tournaments/models";
import { selectTournamentDetails } from "../../../store/tournaments/selectors";
import { loadTournamentDetails } from "../../../store/tournaments/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { getTournamentMatches } from "../../../store/matches/actions";

interface AllTournamentMatchesPageProps {
  tournament: Data<TournamentDetails>;
  matches: Data<MatchHeader[]>;
  match: match<{ year: string; id: string }>;
  loadTournamentDetails: (year: string, id: string, token: string) => void;
  loadTournamentMatches: (
    year: string,
    id: string,
    skip: number,
    take: number
  ) => void;
}

const AllTournamentMatchesPage: React.FC<AllTournamentMatchesPageProps> = ({
  tournament,
  loadTournamentDetails,
  loadTournamentMatches,
  matches,
  match
}) => {
  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  React.useEffect(() => {
    loadTournamentMatches(match.params.year, match.params.id, 0, 25);
  }, [match]);

  return (
    <>
      {matches.type === "LOADED" ? (
        <MatchesTable
        forSinglePlayerOnly={false}
          matches={matches.value}
          onLoadData={async (page, pageSize) => {
            const skip = page * pageSize;
            const take = pageSize;
            loadTournamentMatches(
              match.params.year,
              match.params.id,
              skip,
              take
            );
            setPage(page);
            setPageSize(pageSize);
          }}
          page={page || 0}
          pageSize={pageSize || 25}
        ></MatchesTable>
      ) : (
        <></>
      )}
            <Button startIcon={<ChevronLeftIcon/>} onClick={() => window.history.back()}>Powrót</Button>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
    return {
      tournament:  selectTournamentDetails(state),
      matches: state.matches.currentTournamentAllMatchesPage
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      loadTournamentDetails: (year: string, id: string, token: string) => dispatch(loadTournamentDetails({ year, id, token })),
      loadTournamentMatches: (
        year: string,
        id: string,
        skip: number,
        take: number,
      ) => dispatch(getTournamentMatches({ year, id, skip, take })),
      
    };
  };

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AllTournamentMatchesPage)
  );