import React from "react";
import { LeagueHeaderWithCurrentUserAssignment } from "../../store/leagues/models";
import { Typography, Collapse, IconButton } from "@material-ui/core";
import ExploreIcon from '@material-ui/icons/Explore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LeaguesList from "./LeaguesList";

interface LeagueCityGroupProps {
    city: string;
    leagues: LeagueHeaderWithCurrentUserAssignment[];
}

const LeagueCityGroup : React.FC<LeagueCityGroupProps> = ({ city, leagues }) => {

    

    const [isOpen,  open] = React.useState<boolean>();
    
    const season = leagues[0].year;

    React.useEffect(() => {

        try {
            const state = window.localStorage.getItem(`${season}---${city}`);
            if(state === 'open') {
                open(true);
                return;
            }

            if(window.location.hash.includes(`hint=${encodeURI(city)}`)) {
                open(true);
            }
            

        } catch(e) {
            console.error(e);
        }
        

    }, [city, season]);

    const setOpen = React.useCallback((o: boolean) => {

        open(o);

        try {

            if(!!o) {
                window.localStorage.setItem(`${season}---${city}`, 'open');
            } else {
                window.localStorage.setItem(`${season}---${city}`, 'closed');
            }
            
        } catch(e) {
            console.error(e);
        }
        
    }, [city, season]);

    return <div key={city} style={{ marginBottom: '16px' }}><Typography color={"primary"} variant="h5" style={{ marginBottom: '8px'}} key={city}><ExploreIcon fontSize={"small"} />  {city}  <IconButton onClick={() => setOpen(!isOpen)}>{ isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/> }</IconButton> </Typography><Collapse in={isOpen}><LeaguesList leagues={leagues}></LeaguesList></Collapse></div>
};

export default LeagueCityGroup;