import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button,
  Checkbox,
  Link
} from "@material-ui/core";
import { update } from "ramda";
import ReactGA from 'react-ga';
import { PlayerDescriptor } from "../../../store/leagues/models";
import { Set } from "../../../store/matches/models";
import SetResult from "../../matches/SetResult";
import { Player } from "../../../store/players/models";

interface TournamentMatchResultDialogProps {
  open: boolean;
  onClosed: (success: boolean, sets: Set[], walkover: boolean) => void;
  firstPlayer?: PlayerDescriptor;
  secondPlayer?: PlayerDescriptor;
  currentPlayer: Player;
}

const TournamentMatchResultDialog: React.FC<TournamentMatchResultDialogProps> = ({
  open,
  onClosed,
  firstPlayer,
  secondPlayer,
  currentPlayer
}) => {

  const [sets, setSets] = React.useState<Set[]>([
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 },
    { firstPlayer: 0, secondPlayer: 0 }
  ]);
  const [walkover, setWalkover] = React.useState<boolean>(false);

  const handleClose = () => {
    onClosed(false, [], false);
  };

  const handleWalkover = (isWalkover: boolean) => {
    if (firstPlayer?.id === currentPlayer.id && isWalkover) {
      setSets([
        { firstPlayer: 6, secondPlayer: 0 },
        { firstPlayer: 6, secondPlayer: 0 },
        { firstPlayer: 0, secondPlayer: 0 },
      ])
    } else if (secondPlayer?.id === currentPlayer.id && isWalkover) {
      setSets([
        { firstPlayer: 0, secondPlayer: 6 },
        { firstPlayer: 0, secondPlayer: 6 },
        { firstPlayer: 0, secondPlayer: 0 },
      ])
    }

    setWalkover(isWalkover)
  }

  const handleSubmit = () => {
    onClosed(true, sets, walkover);
    setSets([
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 },
      { firstPlayer: 0, secondPlayer: 0 }
    ]);
  };

  return (
    <form autoComplete="off" noValidate>
      <Dialog open={open}>
        <DialogTitle>Podaj wynik meczu</DialogTitle>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">
                {firstPlayer?.name} {firstPlayer?.surname} : {secondPlayer?.name} {secondPlayer?.surname}
              </Typography>
            </Grid>
          </Grid>
          {
            walkover ? (<>Walkowera przyznajemy tylko w określonych przypadkach. Jeżeli chcesz wpisać walkowera to pierwsze skontaktuj się z nami poprzez <Link href="mailto:biuro@smarttennis.pl">biuro@smarttennis.pl</Link> i opisz całą sytuację.</>) : (<>
              <Grid container spacing={2}>
                {sets &&
                  sets.map((set: Set, i: number) => (
                    <Grid item key={i} sm={4} xs={12}>
                      <Typography variant="body2" style={{ textAlign: "center" }}>
                        {i + 1}. SET
                  </Typography>
                      <SetResult
                        style={{
                          width: '150px',
                          padding: "4px 0"
                        }}
                        set={set}
                        onSetChange={s => {
                          setSets([...update(i, { ...s }, sets)]);
                        }}
                      ></SetResult>
                    </Grid>
                  ))}
              </Grid>
            </>)
          }
          <Grid container spacing={2}>
            <Grid item>
              <Checkbox value={walkover} onChange={(e) => handleWalkover(e.target.checked)}></Checkbox>{" "}Walkover
              </Grid>
          </Grid>
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Button onClick={() => handleClose()}>Zamknij</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default TournamentMatchResultDialog;
