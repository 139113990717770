import { ActionWithPayload2, ActionWithError2, ActionWithPayloadCreator2, ActionWithErrorCreator2 } from "../../framework/action";
import { LOAD_TRAININGS, LOAD_TRAININGS_SUCCESS, LOAD_TRAININGS_FAILED, REGISTER_TO_TRAINING, REGISTER_TO_TRAINING_SUCCESS, REGISTER_TO_TRAINING_FAILED, LOAD_TRAINING_DETAILS, LOAD_TRAINING_DETAILS_SUCCESS, LOAD_TRAINING_DETAILS_FAILED } from "./actionTypes";
import { Player } from "../players/models";
import { TrainingHeader, TrainingDetails, RegisterToTraining as Payload } from "./models";

export interface LoadTrainings extends ActionWithPayload2<typeof LOAD_TRAININGS, { year: string, token: string}> { };
export interface LoadTrainingsSuccess extends ActionWithPayload2<typeof LOAD_TRAININGS_SUCCESS, { trainings: TrainingHeader[] }> { }
export interface LoadTrainingsFailed extends ActionWithError2<typeof LOAD_TRAININGS_FAILED> { }

export interface RegisterToTraining extends ActionWithPayload2<typeof REGISTER_TO_TRAINING, MakeTrainingRegistration> {};
export interface RegisterToTrainingSuccess extends ActionWithPayload2<typeof REGISTER_TO_TRAINING_SUCCESS, {}>{};
export interface RegisterToTrainingFailed extends ActionWithError2<typeof REGISTER_TO_TRAINING_FAILED>{};

export interface LoadTrainingDetails extends ActionWithPayload2<typeof LOAD_TRAINING_DETAILS, { year: string, id: string, token?: string}> {};
export interface LoadTrainingDetailsSuccess extends ActionWithPayload2<typeof LOAD_TRAINING_DETAILS_SUCCESS, { training: TrainingDetails }> { }
export interface LoadTrainingDetailsFailed extends ActionWithError2<typeof LOAD_TRAINING_DETAILS_FAILED> { }

export const loadTrainings: ActionWithPayloadCreator2<LoadTrainings> = (x) => ({
    type: "LOAD_TRAININGS",
    payload: x
});

export const loadTrainingsSuccess : ActionWithPayloadCreator2<LoadTrainingsSuccess> = (x) => ({
    type: "LOAD_TRAININGS_SUCCESS",
    payload: x
});

export const loadTrainingsFailed: ActionWithErrorCreator2<LoadTrainingsFailed> = (error) => ({
    type: "LOAD_TRAININGS_FAILED",
    error,
    description: 'Nie udało się załadować turniejów'
});

export const registerToTraining: ActionWithPayloadCreator2<RegisterToTraining> = (payload) => ({
    type: "REGISTER_TO_TRAINING",
    payload
});

export const registerToTrainingSuccess: ActionWithPayloadCreator2<RegisterToTrainingSuccess> = (payload) => ({
    type: "REGISTER_TO_TRAINING_SUCCESS",
    payload
});

export const registerToTrainingFailed: ActionWithErrorCreator2<RegisterToTrainingFailed> = (error) => ({
    type: "REGISTER_TO_TRAINING_FAILED",
    error,
    description: "Nie udało się zapisać zawodnika na turniej"
});

export type MakeTrainingRegistration = {
    playerData: Player,
    target: Payload,
    token: string
}

export const loadTrainingDetails: ActionWithPayloadCreator2<LoadTrainingDetails> = (payload) => ({
    type: "LOAD_TRAINING_DETAILS",
    payload
});

export const loadTrainingDetailsSuccess: ActionWithPayloadCreator2<LoadTrainingDetailsSuccess> = (payload) => ({
    type: "LOAD_TRAINING_DETAILS_SUCCESS",
    payload
});

export const loadTrainingDetailsFailed: ActionWithErrorCreator2<LoadTrainingDetailsFailed> = (error) => ({
    type: "LOAD_TRAINING_DETAILS_FAILED",
    error,
    description: "Nie udało się załadować szczegółów turnieju"
});

export type Actions = 
    | LoadTrainings
    | LoadTrainingsFailed
    | LoadTrainingsSuccess
    | RegisterToTraining
    | RegisterToTrainingSuccess
    | RegisterToTrainingFailed
    | LoadTrainingDetails
    | LoadTrainingDetailsSuccess
    | LoadTrainingDetailsFailed;
