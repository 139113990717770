import React from "react";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { userAssignedLeagueWithPossibleMatches } from "../../store/leagues/selectors";
import { Dispatch } from "redux";
import {
  loadLeagueDetails,
  assignPlayerToLeague,
  additionalDataAcquired,
  pricingAccepted, loadOpponentsPlayedWith
} from "../../store/leagues/actions";
import { connect } from "react-redux";
import { withRouter, match } from "react-router-dom";
import { Data, unwrap } from "../../framework/data";
import { Player } from "../../store/players/models";
import {
  LeaguePlayer,
  League, LeagueWithPlayersICanPlay
} from "../../store/leagues/models";
import { CircularProgress } from "@material-ui/core";
import LeagueDetails from "./LeagueDetails";
import ProfileDialog from "../players/ProfileDialog";
import CreateMatchDialog from "../matches/CreateMatchDialog";
import { Match, MatchHeader } from "../../store/matches/models";
import {
  submitMatch,
  getNewestMatches
} from "../../store/matches/actions";
import AdditionalSubmissionInfo from "./AdditionalSubmissionInfo";
import logger from "../../framework/logger";
import PriceSubmissionInfo from "./PriceSubmissionInfo";
import { Club } from "../../store/clubs/models";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { useAuth0 } from "@auth0/auth0-react";

interface LeagueDetailsPageProps {
  player: Data<Player>;
  details: Data<LeagueWithPlayersICanPlay>;
  newestMatches: Data<MatchHeader[]>;
  match: match;
  loadLeaguesDetails: (year: string, id: string) => void;
  submitToLeagueRequested: (
    x: LeagueWithPlayersICanPlay,
    p: Player,
    token: string
  ) => void;
  submitMatch: (league: League, match: Match, token: string) => void;
  getNewestMatches: (year: string, id: string) => void;
  showAdditionalDialog: boolean;
  additionalDataObtained: (
    tocApproved: boolean,
    promoCode: string,
    referral: string,
    secondaryPlayerName?: string, 
    secondaryPlayerSurname?: string
  ) => void;
  showPricingInfo: boolean;
  pricingAccepted: (accepted: boolean) => void;
  promoCode: string;
  clubs: Data<Club[]>;
  loadClubs: () => void;
  loadOpponentsPlayedWith: (token: string, year: string, id: string) => void;
}

const LeagueDetailsPage: React.FC<LeagueDetailsPageProps> = ({
  details,
  match,
  loadLeaguesDetails,
  player,
  submitToLeagueRequested,
  submitMatch,
  newestMatches,
  getNewestMatches,
  showAdditionalDialog,
  additionalDataObtained,
  showPricingInfo,
  pricingAccepted,
  promoCode,
  clubs,
  loadClubs,
  loadOpponentsPlayedWith
}) => {

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    var id = (match.params as any).id;
    var year = (match.params as any).year;
    loadLeaguesDetails(year, id);
    getNewestMatches(year, id);

  }, [match.params, loadLeaguesDetails]);

  React.useEffect(() => {
    var year = (match.params as any).year;
    var id = (match.params as any).id;

    if(player.type === "LOADED" && player.value) {
      getAccessTokenSilently().then((t: string) => loadOpponentsPlayedWith(t, year, id));
    }
  }, [match.params, player])

  React.useEffect(() => {
    if(clubs.type === "NOT_STARTED" && loadClubs) {
      loadClubs();
    }
  }, []);

  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [matchResultDialogOpen, setMatchResultDialogOpen] = React.useState(false);

  const [oponent, setOponent] = React.useState<LeaguePlayer>();

  const onSubmitToLeagueRequested = async (
    x: LeagueWithPlayersICanPlay
  ) => {

    logger.logEvent(`User attempted to join league: ${x.id} ${x.name}`);
    let token: string = "";
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      loginWithRedirect({
        redirectUri: window.location.href, appState: {
          targetUrl: window.location.hash
        }
      });
      return;
    }
    if (player && player.type === "LOADED" && unwrap(player)) {
      logger.logEvent(`Player ${player.value.email} attempted to join league: ${x.id} ${x.name}`);
      submitToLeagueRequested(x, unwrap(player), token);
    } else {
      logger.logEvent(`User without profile attempted to join league: ${x.id} ${x.name}.`);
      setProfileDialogOpen(true);
    }
  };

  const onProfileDialogClosed = async (
    succeeded: boolean,
    player: Player | undefined
  ) => {
    setProfileDialogOpen(false);
    if (succeeded && player && details && details.type === "LOADED") {
      var token = await getAccessTokenSilently();
      logger.logEvent(`User without profile attempted to join league: ${details.value.id} ${details.value.name}. Creating profile for him: ${player.email}`);
      submitToLeagueRequested(details.value, player, token);
    }
  };

  const registerMatchResultWithPlayerRequested = (player: LeaguePlayer) => {
    setOponent(player);
    setMatchResultDialogOpen(true);
  };

  const onRegisteringMatchFinished = async (
    success: boolean,
    match: Match | null
  ) => {
    if (success && details.type === "LOADED" && match) {
      const token = await getAccessTokenSilently();
      const league = details.value;
      logger.logEvent(`Creating match between ${match.player.id} and ${match.oponent.id} on ${match.club.name} on ${match.date}`);
      submitMatch(league, match, token);
    }

    setMatchResultDialogOpen(false);
  };

  const unsafePlayer =
    player && player.type === "LOADED" ? player.value : undefined;

  switch (details.type) {
    case "LOADED": {
      return (
        <>
          {player.type === "LOADED" && oponent ? (
            <CreateMatchDialog
              league={details.value}
              oponent={oponent!}
              player={details.value.isDoubles ? details.value.players.find(p => p.id === player.value.id)! : player.value}
              open={matchResultDialogOpen}
              onClosed={onRegisteringMatchFinished}
            ></CreateMatchDialog>
          ) : (
            <></>
          )}

          <ProfileDialog
            open={profileDialogOpen}
            onClosed={onProfileDialogClosed}
            clubs={clubs.type === "LOADED" ? clubs.value : []}
          ></ProfileDialog>
          <AdditionalSubmissionInfo
            show={showAdditionalDialog}
            isDoubles={details.value.isDoubles}
            onClose={({ promoCode, tocApproved, referral, secondaryPlayerName, secondaryPlayerSurname }) =>
              additionalDataObtained(tocApproved, promoCode, referral, secondaryPlayerName, secondaryPlayerSurname)
            }
          ></AdditionalSubmissionInfo>
          <PriceSubmissionInfo open={showPricingInfo} league={details.value} promoCode={promoCode} onClose={(x) => pricingAccepted(x) }></PriceSubmissionInfo>
          <LeagueDetails
            clubs={clubs}
            league={details.value}
            player={unsafePlayer}
            newestMatches={newestMatches}
            onSubmitToLeagueRequested={onSubmitToLeagueRequested}
            onRegisterMatchResultWithPlayerRequested={
              registerMatchResultWithPlayerRequested
            }
          ></LeagueDetails>
        </>
      );
    }
    case "LOADING": {
      return (
        <div
          style={{
            width: "64px",
            height: "64px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    default: {
      return <div></div>;
    }
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    details: userAssignedLeagueWithPossibleMatches(state),
    player: currentPlayer(state),
    newestMatches: state.matches.currentLeagueNewestMatches,
    showAdditionalDialog: state.leagues.showAdditionalDataDialog,
    showPricingInfo: state.leagues.showPricingDataDialog.showDialog,
    promoCode: state.leagues.showPricingDataDialog.promoCode,
    clubs: state.clubs.clubs
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadLeaguesDetails: (year: string, id: string) =>
      dispatch(loadLeagueDetails({ year, id })),
    submitToLeagueRequested: (
      league: LeagueWithPlayersICanPlay,
      player: Player,
      token: string
    ) => {
      league &&
        player &&
        token &&
        dispatch(assignPlayerToLeague({ league, player, token }));
    },
    submitMatch: (league: League, match: Match, token: string) => {
      league &&
        match &&
        token &&
        dispatch(submitMatch({ league, match, token }));
    },
    getNewestMatches: (year: string, leagueId: string) =>
      dispatch(getNewestMatches({ leagueId, year })),
    additionalDataObtained: (
      tocApproved: boolean,
      promoCode: string,
      referral: string,
      secondaryPlayerName?: string,
      secondaryPlayerSurname?: string
    ) => dispatch(additionalDataAcquired({ tocApproved, promoCode, referral, secondaryPlayerName, secondaryPlayerSurname })),
    pricingAccepted: (x: boolean) => dispatch(pricingAccepted(x)),
    loadClubs: () => dispatch(loadClubsInProvince()),
    loadOpponentsPlayedWith: (token: string, year: string, id: string) => dispatch(loadOpponentsPlayedWith({token, year, id}))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeagueDetailsPage)
);
