import { getConfig } from "../../config/configuration";
import { MatchHeader } from "../matches/models";

export async function loadPlayersStatsApi(player: string, year: string) {

    const config = await getConfig();

    const response = await fetch(`${config.apiUrl}/stats/${year}/${player}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    if(response.ok) {
        const data = await response.json();

        return data.map((x:any) => ({ ...x,  leagueStartDate: new Date(x.leagueStartDate)  }));
    } else {
        throw new Error(response.statusText);
    }

}

export async function loadPlayersMatchesApi(player: string, year: string) {

    const config = await getConfig();


    const response = await fetch(`${config.apiUrl}/players/${year}/${player}/matches`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    if(response.ok) {
        const data = await response.json() as MatchHeader[];

        return data.map(r => ({ ...r, date: new Date(r.date) }));
    } else {
        throw new Error(response.statusText);
    }

}