import { RegisterToTournament, TournamentHeader, TournamentDetails } from "./models";
import { getConfig } from "../../config/configuration";
import { RegistrationStatus } from "../registration/model";
import { Match } from "../matches/models";

class TournamentRegistrationApi {

    public static async register(token: string, target: RegisterToTournament) : Promise<RegistrationStatus> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/tournaments/register`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify(target)
        });

        if(response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    public static async load(token: string, year: string) : Promise<TournamentHeader[]> {
        const config = await getConfig();

        const header = !!token ? {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        } : {
            'Accept': 'application/json',
        }

        const response = await fetch(`${config.apiUrl}/tournaments/${year}`, {
            headers: (header as any),
            method: 'GET'
        });

        if(response.ok) {
            const data = await response.json() as TournamentHeader[];
            
            return data.map(d => ({...d, endDate: new Date(d.endDate), startDate: new Date(d.startDate) }));

        }

        throw new Error(response.statusText);
    }

    public static async loadDetails(token: string|undefined, year: string, id: string) : Promise<TournamentDetails> {
        const config = await getConfig();

        const header = !!token ? {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        } : {
            'Accept': 'application/json',
        }

        const response = await fetch(`${config.apiUrl}/tournaments/${year}/${id}`, {
            headers: (header as any),
            method: 'GET'
        });

        if(response.ok) {
            const data = await response.json() as TournamentDetails;
            
            return {...data, endDate: new Date(data.endDate), startDate: new Date(data.startDate)};

        }

        throw new Error(response.statusText);
    }

    public static async submitMatch(token: string, tournament: TournamentDetails, match: Match): Promise<{}> {
        const config = await getConfig();
        const header = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const response = await fetch(`${config.apiUrl}/tournaments/${tournament.year}/${tournament.id}/matches`, {
            headers: (header as any),
            method: 'POST',
            body: JSON.stringify({
                oponent: match.oponent.id,
                date: match.date,
                club: match.club.id
            })
        });

        if(response.ok) {
            return ({});
        } else {
            throw new Error(response.statusText);
        }
    }

}

export default TournamentRegistrationApi;