import { getConfig } from "../../config/configuration";
import { MatchHeader } from "../matches/models";

class DesktopApi {

    constructor() {
        throw new Error("This class is meant to be static")
    }

    public static async getUnconfirmedMatches(token: string, year: string): Promise<MatchHeader[]> {
        var config = await getConfig();

        const response = await fetch(`${config.apiUrl}/players/me/matches/${year}/awaiting-confirmation`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
            method: 'GET'
        });

        if(response.ok) {
            const items: MatchHeader[] = await response.json();

            return items.map(x => ({...x, date: new Date(x.date)}))
        } else {
            throw new Error(response.statusText);
        }
    }

    public static async getConfirmedMatches(token: string, year: string) {
        var config = await getConfig();

        const response = await fetch(`${config.apiUrl}/players/me/matches/${year}/confirmed`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
            method: 'GET'
        });

        if(response.ok) {
            const items: MatchHeader[] = await response.json();

            return items.map(x => ({...x, date: new Date(x.date)}))
        } else {
            throw new Error(response.statusText);
        }
    }

}

export default DesktopApi;