import React from "react";
import { getConfig } from "../../config/configuration";
import { Player } from "../../store/players/models";
import { Paper, Typography, CircularProgress, Link } from "@material-ui/core";
import { Club } from "../../store/clubs/models";
import { Data } from "../../framework/data";
import { useAuth0 } from "@auth0/auth0-react";

interface LeaguePlayerViewProperties {
  playerId: string;
  leagueId: string;
  leagueYear: string;
  clubs: Data<Club[]>;
}

const LeaguePlayerView: React.FC<LeaguePlayerViewProperties> = ({
  playerId,
  leagueId,
  leagueYear,
  clubs,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [player, setPlayer] = React.useState<Player | undefined>();

  React.useEffect(() => {
    getConfig()
      .then((c) => {
        const url = `/leagues/open/${leagueYear}/${leagueId}/players/${playerId}`;
        return `${c.apiUrl}${url}`;
      })
      .then((url) => {
        return getAccessTokenSilently().then((token: string) => ({
          token,
          url,
        }));
      })
      .then(({ token, url }) => {
        return fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
          method: "GET",
        });
      })
      .then((resp) => (resp.ok ? resp.json() : Promise.reject()))
      .then(setPlayer);
  }, [playerId]);

  return (
    <Paper style={{ padding: "16px", minWidth: "250px" }} elevation={0}>
      {!!player ? (
        <>
          <Typography variant="body2">
            Imię i nazwisko:{" "}
            <Link href={`/#/stats/${btoa(player?.id || "")}`}>
              <span style={{ fontWeight: "bold" }}>
                {player.name} {player.surname}
              </span>
            </Link>
          </Typography>
          <Typography variant="body2">
            Telefon:{" "}
            <span style={{ fontWeight: "bold" }}>{player.phoneNumber}</span>
          </Typography>
          <Typography variant="body2">
            Adres email:{" "}
            <span style={{ fontWeight: "bold" }}>{player.email}</span>
          </Typography>
          <Typography variant="body2">
            Poziom NTRP:{" "}
            <span style={{ fontWeight: "bold" }}>{player.level}</span>
          </Typography>
          <Typography variant="body2">Preferowane kluby: </Typography>
          {clubs.type === "LOADED" ? (
            <ul style={{margin: 0}}>
              {clubs.value
                .filter(
                  (c) =>
                    !!player.preferredClubs &&
                    player.preferredClubs.includes(c.id)
                )
                .map((c) => (
                  <li key={c.id} style={{ fontWeight: "bold" }} className="MuiTypography-body2">
                    {c.name}
                  </li>
                ))}
            </ul>
          ) : (
            <></>
          )}

          <Typography variant="body2">Preferowane godziny: </Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "bold" }}>
              {player.preferredTime || ""}
            </span>
          </Typography>
        </>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};

export default LeaguePlayerView;
