import React, { Props } from "react";

import { Card, useTheme, useMediaQuery } from "@material-ui/core";
import { Switch } from "react-router-dom";

const PageWrapper: React.FC = (props: Props<any>) => {
  
  var theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
  <Card
    square={true}
    style={{
      position: "relative",
      top: "-100px",
      width: (isSmall) ? "100%" : "90%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: (isSmall) ? "0px" : "16px"
    }}
  >
    <Switch>
        {props.children}
      
    </Switch>
  </Card>
);
}

export default PageWrapper;