import React from "react";
import PlayerStep from "../../registration/PlayerStep";
import { Step, StepButton, Stepper } from "@material-ui/core";
import TrainingRegistrationConfirmation from "./TrainingRegistrationConfirmation";
import TrainingRegistrationProgress from "./TrainingRegistrationProgress";

const getSteps = () => {
    return [
        "Profil zawodnika",
        "Informacje dla zawodników",
        "Zakończenie"
    ]
}


const RegistrationWizard: React.FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const getStepContent = (idx: number) => {
        switch(idx) {
            case 0: {
                return <PlayerStep onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)} />
            }
            case 1: {
                return <TrainingRegistrationConfirmation onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)}></TrainingRegistrationConfirmation>
            }
            case 2: {
                return <TrainingRegistrationProgress></TrainingRegistrationProgress>
            }
        }
    }

    return <>
        <Stepper nonLinear activeStep={activeStep}>
            {
                steps.map((step) => (
                    <Step key={step}>
                        <StepButton>
                            {step}
                        </StepButton>
                    </Step>
                ))
            }
        </Stepper>
        {
            getStepContent(activeStep)
        }
    </>
};

export default RegistrationWizard;