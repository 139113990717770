import React from "react";
import { PaymentInfo } from "../../store/payments/models";
import { compareDesc } from "date-fns";
import { drop, take } from "ramda";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DateTimeView from "../../framework/components/DateTimeView";
import PaymentStatus from "./PaymentStatus";
import { PaymentStatus as PS } from "../../store/payments/models";
import ReplayIcon from "@material-ui/icons/Replay";

interface PaymentsListProps {
  payments: PaymentInfo[];
  redoPayment(payment: PaymentInfo): void;
}

const PaymentsList: React.FC<PaymentsListProps> = ({ payments, redoPayment }) => {
  const orderedPlayers = payments.sort((x, y) =>
    compareDesc(x.paymentDate, y.paymentDate)
  );

  const [page, setPage] = React.useState();
  const [pageSize, setPageSize] = React.useState();

  const viewItems = drop(pageSize * page, take(pageSize, orderedPlayers));

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Liga</TableCell>
              <TableCell>Identyfikator płatności</TableCell>
              <TableCell>Kwota</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Akcje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewItems.map((payment) => (
              <TableRow key={payment.externalPaymentOrderId}>
                <TableCell>
                  <DateTimeView value={payment.paymentDate}></DateTimeView>
                </TableCell>
                <TableCell>{payment.leagueName}</TableCell>
                <TableCell>{payment.externalPaymentOrderId}</TableCell>
                <TableCell>{payment.finalPrice} PLN</TableCell>
                <TableCell>
                  <PaymentStatus value={payment.paymentStatus}></PaymentStatus>
                </TableCell>
                <TableCell>
                  {payment.paymentStatus !== PS.COMPLETED ? (
                    <Tooltip title="Ponów płatność (nowa płatność zostanie utworzona)">
                      <IconButton onClick={() => redoPayment(payment)} size="small">
                        <ReplayIcon></ReplayIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={orderedPlayers.length}
        rowsPerPage={pageSize ?? 10}
        page={page ?? 0}
        onChangePage={(e, p) => {
          setPage(p);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(e.target.value);
        }}
      />
    </>
  );
};

export default PaymentsList;
