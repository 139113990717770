import { AnyAction } from "redux";

export interface ActionWithPayload<T> extends AnyAction {
    payload: T
};

export interface ActionWithError<T = string> extends AnyAction {
    error: T
    description: string;
    context?: any;
}

export type ActionWithPayloadCreator<T> = (x: T) => ActionWithPayload<T>;
export type ActionWithErrorCreator<T = string> = (x: T, context?: any) => ActionWithError<T>

export interface WithNotification {
    notification: Notification;
}

export interface Notification {
    severity: Severity;
    message: string;
}

export enum Severity {
    Success = "success",
    Error = "error",
    Warning = "warning",
    Info = "info",
}

export interface Action2<TType = string> extends AnyAction {
    type: TType;
}

export interface ActionWithPayload2<TType = string, TPayload = any> extends Action2<TType> {
    payload: TPayload;
}

export interface ActionWithError2<TType = string> extends Action2<TType> {
    error: string;
    description: string;
    context?: any;
}

export type ActionCreator2<TAction extends Action2> = () => TAction;
export type ActionWithPayloadCreator2<TAction extends ActionWithPayload2> = (payload: TAction["payload"]) => TAction;
export type ActionWithErrorCreator2<TAction extends ActionWithError2> = (error: string, context?: any) => TAction;


