import React from "react";
import { Basket, Spot } from "../../store/shop/models";
import {
  Grid,
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

interface ShoppingSummaryProps {
  basket: Basket;
  spot: Spot;
}

const ShoppingSummary: React.FC<ShoppingSummaryProps> = ({ basket, spot }) => {
  return (
    <Grid container spacing={2} justify="center">
      <Grid item sm={12} md={6} lg={5}>
        <Typography variant="h5">Produkty</Typography>
        <List>
          {basket.products.map((x) => (
            <ListItem key={x.id}>
              <ListItemAvatar>
                <Avatar>{x.count}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={x.name}
                secondary={`Cena: ${x.count} x ${x.smartPointsPrice} = ${
                  x.count * x.smartPointsPrice
                } SmartPunktów`}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <Typography variant="h6" color="primary">
          Suma:{" "}
          {basket.products.reduce(
            (p, c) => p + c.count * c.smartPointsPrice,
            0
          )}{" "}
          SmartPunktów
        </Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={5}>
        <Typography variant="h5">Odbiór</Typography>
        <List>
          <ListItem>
            <ListItemText primary={spot.name} secondary="Nazwa" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`${spot.address} ${spot.city}`}
              secondary="Adres"
            />
          </ListItem>
        </List>
        <Alert severity="info">
          Odbiory zamówień zazwyczaj odbywają się podczas finałów lub turniejów organizowaych przez SmartTennis.
        </Alert>
      </Grid>
      
    </Grid>
  );
};

export default ShoppingSummary;
