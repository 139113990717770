import { takeEvery, put, call } from "redux-saga/effects";
import { LOAD_USER_PLAYER, CREATE_PLAYER, UPDATE_PLAYER, SAVE_PLAYER, LOAD_ALL_PLAYERS } from "./actionTypes";
import { loadUserPlayerFailed, LoadUserPlayer, loadUserPlayerSuccess, CreatePlayer, createPlayerSuccess, createPlayerFailed, UpdatePlayer, updatePlayerSuccess, updatePlayerFailed, SavePlayer, updatePlayer, createPlayer, LoadAllPlayers, loadAllPlayersSuccess } from "./actions";
import { loadUserPlayer, createUserPlayer, updateUserPlayer, getAllPlayers } from "./api";
import ReactGA from 'react-ga';

function* loadCurrentPlayerStep(action: LoadUserPlayer) {
    try {
        const playerData = yield call(loadUserPlayer, action.payload);
        if(playerData) {
            ReactGA.set({ userId: playerData.id });
        }
        
        yield put(loadUserPlayerSuccess(playerData));
    } catch(e) {
        yield put(loadUserPlayerFailed(e.message))
    }
}

function* createPlayerStep(action: CreatePlayer) {
    try {
        const playerData = yield call(createUserPlayer, action.payload.token, action.payload.player, action.payload.playerType);
        ReactGA.event({ action: 'Player created', category: 'Players' });
        yield put(createPlayerSuccess(playerData));
    } catch(e) {
        yield put(createPlayerFailed(e.message));
    }
}

function* updatePlayerStep(action: UpdatePlayer) {
    try {
        const playerData = yield call(updateUserPlayer, action.payload.token, action.payload.player);
        ReactGA.event({ action: 'Player updated', category: 'Players' });
        yield put(updatePlayerSuccess(playerData));
    } catch(e) {
        yield put(updatePlayerFailed(e.message));
    }
}

function* savePlayerStep({ payload }: SavePlayer) {
    if(!!payload.player.id) {
        yield put(updatePlayer(payload));
    } else {
        yield put(createPlayer(payload));
    }
}

function* loadAllPlayersStep({ payload: { token } }: LoadAllPlayers) {
    try {
        const players = yield call(getAllPlayers, token);
        yield put(loadAllPlayersSuccess(players));
    } catch(e) {
        yield put(loadAllPlayersSuccess([]));
    }
}

export function* loadCurrentPlayerSaga() {
    yield takeEvery(LOAD_USER_PLAYER, loadCurrentPlayerStep);
}

export function* createPlayerSaga() {
    yield takeEvery(CREATE_PLAYER, createPlayerStep);
}

export function* updatePlayerSaga() {
    yield takeEvery(UPDATE_PLAYER, updatePlayerStep);
}

export function* savePlayerSaga() {
    yield takeEvery(SAVE_PLAYER, savePlayerStep);
}

export function* loadAllPlayers() {
    yield takeEvery(LOAD_ALL_PLAYERS, loadAllPlayersStep);
}