import React from "react";
import { TrainingHeader } from "../../store/trainings/models";
import TrainingHeaderComponent from "./TrainingHeader";


interface ITrainingListProps {
    trainings: TrainingHeader[];
}

const TrainingsList: React.FC<ITrainingListProps> = ({ trainings }) => {
    return (<> {trainings.sort((l1, l2) => (l2.startDate as any) - (l1.startDate as any)).map(l => (<TrainingHeaderComponent training={l} key={l.id}></TrainingHeaderComponent>))} </>)
};

export default TrainingsList;