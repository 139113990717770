import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@material-ui/core";
import { Player } from "../../../store/players/models";
import { AppState } from "../../../store/reducer";
import { State as RegistrationState } from "../../../store/registration/reducer";
import { RegisterToTraining } from "../../../store/trainings/models";
import { registerToTraining } from "../../../store/trainings/actions";

interface TrainingRegistrationProgressProps {
    startRegistration: (target: RegisterToTraining, playerData: Player, token: string) => void;
    registration: RegistrationState;
}

const TrainingRegistrationProgress: React.FC<TrainingRegistrationProgressProps> = ({registration, startRegistration}) => {

    const { getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {
        getAccessTokenSilently().then(token => {
            startRegistration(
                registration.trainingSelection, 
                registration.updatedPlayer!,
                token);
        }).catch((reason: any) => {
            console.log(reason);
        })

    }, []);

    return <>
        <Grid container style={{minHeight: '200px'}} alignItems="center" justify="center">
            <Grid item>
                <Typography variant="h5" color="primary">
                    {registration.registrationMessage}
                </Typography>
            </Grid>
        </Grid>
    </>

};

const mapStateToProps = (state: AppState) => ({
    registration : state.registration
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    startRegistration: (target: RegisterToTraining, playerData: Player, token: string) => dispatch(registerToTraining({
        target,
        playerData,
        token
    }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingRegistrationProgress);