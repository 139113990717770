import React from "react";
import { WorkingHours, DayOfWeek } from "../../store/clubs/models";
import { Grid } from "@material-ui/core";
import { describeDayOfWeek } from "./utils";
import Hour from "./Hour";

interface WorkingHourProps {
    value: WorkingHours;
}

const WorkingHour: React.FC<WorkingHourProps> = ({ value }) => (
    <Grid item>
        <Grid container justify="space-between">
            <Grid item>
                {describeDayOfWeek(value.dayOfWeek)}
            </Grid>
            <Grid item>
                <Hour hour={value.openTime}></Hour> - <Hour hour={value.closeTime}></Hour>
            </Grid>
        </Grid>
        
    </Grid>
);

export default WorkingHour;