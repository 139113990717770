import { Player } from "../players/models";
import { Match } from "../matches/models";
import { PlayerType } from "../registration/model";

export interface LeagueHeader {
    id: string;
    city: string;
    name: string;
    startDate: Date;
    participantsCount: number;
    description: string;
    prices: PriceBudget[];
    endDate: Date;
    clubs: Clubs[];
    year: string;
    basePrice: number;
    sequenceNo: number;
    status: LeagueStatus;
    province: number;
    isDoubles: boolean;
}

export interface PriceBudget {
    sequenceNo: number;
    description: string;
}

export interface Clubs {
    id: string;
    name: string;
    logoUrl?: string;
}

export interface LeagueHeaderWithCurrentUserAssignment extends LeagueHeader {
    isCurrentUserAssigned: boolean;
}

export interface AssignPlayerToLeagueRequest {
    player: Player;
    league: LeagueHeader|League;
    token: string;
}

export interface League {
    id: string;
    city: string;
    name: string;
    type: LeagueType;
    startDate: Date;
    endDate: Date;
    description: string;
    status: LeagueStatus;
    runtimeInfo: RuntimeInfo;
    players: LeaguePlayer[];
    prices: PriceBudget[];
    year: string;
    finals: FinalsPlan;
    basePrice: number;
    currentView: LeagueView;
    admins: string[];
    province: number;
    isDoubles: boolean;
    finalsUrl: string;
    advancingPlayersCount: number;
}

export interface LeaguePlayerCanIPlay extends LeaguePlayer {
    canIPlay: boolean;
}

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type LeagueWithPlayersICanPlay = Overwrite<LeagueWithCurrentUserAssignment, { players: LeaguePlayerCanIPlay[] }>;

export interface LeagueWithCurrentUserAssignment extends League {
    isCurrentUserAssigned: boolean;
}

export enum LeagueAccess {
    Public, OwnedByClub, Tournament
}

export enum LeagueView {
    RankingList, Finals
}

export interface PublicLeagueType {
    access: typeof LeagueAccess.Public;
    supportedClubs: Clubs[];
}

export interface PrivateLeagueType {
    access: typeof LeagueAccess.OwnedByClub;
    owner: Clubs;
}

export interface TournamentLeagueType {
    access: typeof LeagueAccess.Tournament;
    supportedClubs: Clubs[];
    adminUsers: string[];
    maxParticipants: number | undefined;
}

export type LeagueType = 
    | PublicLeagueType
    | PrivateLeagueType
    | TournamentLeagueType;

export enum LeagueStatus {
    NotStarted, InProgress, Finished
}

export interface RuntimeInfo {
    participantsCount: number;
    matchesPlayed: number;
}

export interface PlayerDescriptor {
    id: string;
    name: string;
    surname: string;
    level: string
}

export interface LeaguePlayer extends PlayerDescriptor {
    rank: number;
    score: PlayerScore;
    gender: string;
    playerType: PlayerType;
}

export interface PlayerScore {
    matchesPlayed: number;
    matchesWon: number;
    setsPlayed: number;
    setsWon: number;
    gemsWon: number;
    gemsLost: number;
    points: number;
}

export interface FinalsPlan {
    final: FinalsNode;
}

export interface FinalsNode {
    match: Match;
    child1: FinalsNode;
    child2: FinalsNode;
}

export interface PricingDialogState {
    showDialog: boolean;
    promoCode: string;
}

export interface LeagueWithPaymentStatus {
    id: string;
    city: string;
    name: string;
    status: LeagueStatus;
    year: string;
    isActive: boolean;
    playerType: PlayerType;
}