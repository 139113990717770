import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Alert } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import { AppState } from "../../../store/reducer";
import { connect } from "react-redux";
import TrainingRegistrationWizard from "./TrainingRegistrationWizard";
import { withRouter, match } from "react-router-dom";
import { Dispatch } from "redux";
import { loadTrainings } from "../../../store/trainings/actions";
import { setTrainingToRegister as setTrainingToRegisterAction } from "../../../store/registration/actions"
import { RegisterToTraining } from "../../../store/trainings/models";

export interface TrainingRegistrationPageProps {
    match: match<{ id: string, year: string }>;
    loadTrainings: (token: string, season: string) => void;
    setTrainingToRegister: (x: RegisterToTraining) => void;
}

const TrainingRegistrationPage: React.FC<TrainingRegistrationPageProps> = ({ match, loadTrainings, setTrainingToRegister  }) => {

    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {

        const trainingId = match.params.id;
        const year = match.params.year;

        getAccessTokenSilently().then(x => {
            loadTrainings(x, year);
        });

        setTrainingToRegister({
            training: {
                id: trainingId,
                season: year
            }
        });

    }, []);

    return (<>
        {
            !isAuthenticated ? (
                <Alert severity="info">
                    <div>
                        <Link color="textPrimary" href="" onClick={() => loginWithRedirect({
                            redirectUri: window.location.href, appState: {
                                targetUrl: window.location.hash
                            }
                        })}><b>Kliknij tutaj</b></Link><span> aby zalogować się lub założyć konto w celu rozpoczęcia rejestracji.</span>
                    </div>
                </Alert>
            ) : (
                    <TrainingRegistrationWizard></TrainingRegistrationWizard>
                )
        }
    </>);
};

const mapStateToProps = (state: AppState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTrainings: (token: string, season: string) => dispatch(loadTrainings({ year: season, token })),
    setTrainingToRegister: (x: RegisterToTraining) => dispatch(setTrainingToRegisterAction(x))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrainingRegistrationPage));