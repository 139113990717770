import { id } from "date-fns/locale";

interface _Data {
    type: 'LOADING' | 'LOADED' | 'FAILED' | 'NOT_STARTED'
}

interface _DataNotStarted extends _Data {
    type: 'NOT_STARTED'
}

interface _DataLoading extends _Data {
    type: 'LOADING'
}

interface _DataFailed<T = string> extends _Data {
    type: 'FAILED',
    error: T
}

interface _DataLoaded<T> extends _Data {
    type: 'LOADED',
    value: T
}

export const LOADING: _DataLoading = { type: 'LOADING' };
export const NOT_STARTED: _DataNotStarted = { type: 'NOT_STARTED' };
export const fail: <T = string>(x: T) => _DataFailed<T> = <T = string>(error: T) => ({ type: "FAILED", error });
export const dataOf: <T>(x: T) => _DataLoaded<T> = <T>(value: T) => ({ type: "LOADED", value })  

export const isLoading = (d: _Data) => d.type === "LOADING";
export const isFailed = (d: _Data) => d.type === "FAILED";
export const isLoaded = (d: _Data) => d.type === "LOADED";
export const isNotStarted = (d: _Data) => d.type === "NOT_STARTED";

export const unwrap = <T>(d: _Data) => {
    switch(d.type) {
        case "LOADED": {
            return (d as _DataLoaded<T>).value;
        }
        default: {
            throw new Error('Data not loaded');
        }
    }
}

export const unsafeUnwrap = <T>(d: _Data) => {
    if(d.type === "LOADED") {
        return (d as _DataLoaded<T>).value;
    } else {
        return undefined;
    }
}



export const combineNotLoaded = <X, Y, E>(d1: Data<X, E>, d2: Data<Y, E>) => {

    if(d1.type === "FAILED" && d2.type === "FAILED") return fail(`${d1.error} ${d2.error}`);

    if(d1.type === "FAILED") return d1;
    if(d2.type === "FAILED") return d2;

    if(d1.type === "LOADING") return d1;
    if(d2.type === "LOADING") return d2;

    return NOT_STARTED;
}

export type Data<T, E = string> = 
    | _DataNotStarted
    | _DataLoading
    | _DataLoaded<T>
    | _DataFailed<E>;