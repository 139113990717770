import { Data, NOT_STARTED, LOADING, fail, dataOf } from "../../framework/data";
import { PaymentInfo } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    payments: Data<PaymentInfo[]>;
}

function reducePayments(state: Data<PaymentInfo[]> = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "GET_USER_PAYMENTS": return state.type === "LOADED" ? state : LOADING;
        case "GET_USER_PAYMENTS_FAILED": return fail(action.error);
        case "GET_USER_PAYMENTS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({
    payments: reducePayments
})