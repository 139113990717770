import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";

const NewsPage: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={3}>
        <Card>
          <CardHeader
            avatar={
              <Avatar style={{ backgroundColor: "white" }}>
                <img src="logo2.png" style={{ width: "40px" }} />
              </Avatar>
            }
            title="Zbieraj SmartPunkty"
            subheader="25.05.2020 23:09"
          ></CardHeader>
          <CardContent>
            <Typography variant="body2">
              Czy wiesz, że grając w lidze zbierasz punkty, które później możesz
              wymieniać na nagrody? Sprawdź katalog nagród pod{" "}
              <a href="katalog-nagrod.pdf" target="_blank">
                tym linkiem
              </a>
            </Typography>
          </CardContent>
          <CardActions>
            <Button href="katalog-nagrod.pdf" target="_blank">
              Zobacz katalog
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F128105705553668&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F129373248760247&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F131596891871216&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F133398971691008&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F134486858248886&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F136238988073673&width=500"
          width="100%"
          height="683"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F136928738004698&width=500"
          width="100%"
          height="646"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F139443281086577&width=500"
          width="100%"
          height="678"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F145743220456583&width=500"
          width="100%"
          height="663"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F146638320367073&show_text=true&width=552&appId=377716342830188&height=691"
          width="100%"
          height="691"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F146378513726387&show_text=true&width=552&appId=377716342830188&height=653"
          width="100%"
          height="653"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
      <Grid item xs={12} md={3}>
        <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl%2Fposts%2F153129059717999&show_text=true&width=552&appId=377716342830188&height=528"
          width="100%"
          height="528"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default NewsPage;
