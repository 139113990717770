import { ActionWithPayload, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator } from "../../framework/action";
import { LOAD_CLUBS, LOAD_CLUBS_SUCCESS, LOAD_CLUBS_FAILED } from "./actionType";
import { Club } from "./models";
import { Action, ActionCreator } from "redux";

export interface LoadClubs extends Action {
    type: typeof LOAD_CLUBS;
}

export interface LoadClubsSuccess extends ActionWithPayload<Club[]> {
    type: typeof LOAD_CLUBS_SUCCESS;
}

export interface LoadClubsFailed extends ActionWithError {
    type: typeof LOAD_CLUBS_FAILED;
}

export const loadClubsInProvince: ActionCreator<LoadClubs> = () => ({ type: LOAD_CLUBS });
export const loadClubsInProvinceSuccess: ActionWithPayloadCreator<Club[]> = (clubs) => ({ type: LOAD_CLUBS_SUCCESS, payload: clubs });
export const loadClubsInProvinceFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_CLUBS_FAILED, error, description: 'Nie udało nam się załadować klubów. Spróbuj ponownie!'});

export type Actions = 
    | LoadClubs
    | LoadClubsFailed
    | LoadClubsSuccess;