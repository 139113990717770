import { FormState, MatchOfferHeader, NOT_STARTED } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";
import { Data, dataOf, fail, LOADING, NOT_STARTED as LOADING_NOT_STARTED } from "../../framework/data";
import { map } from "ramda";

export interface State {
    formState: FormState;
    matchOffers: Data<MatchOfferHeader[]>;
}

function reduceFormState(state: FormState = NOT_STARTED, action: Actions) {
    if(action.type === "CHANGE_FORM_STATE") {
        return action.payload;
    }
    return state;
}

function reduceMatchOffers(state: Data<MatchOfferHeader[]> = LOADING_NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "GET_MATCH_OFFERS": {
            return LOADING;
        }
        case "GET_MATCH_OFFERS_FAILED": {
            return fail(action.error);
        }
        case "GET_MATCH_OFFERS_SUCCESS": {
            return dataOf(action.payload);
        }
        case "CANCEL_MATCH_OFFER": {
            if(state.type === "LOADED") {
                return dataOf(map((x) => x.id === action.payload.id ? ({ ...x, isLoading: true }) : x, state.value));
            }
            return state;
        }
        default:
            return state;
    }
}

export default combineReducers<State>({
    formState: reduceFormState,
    matchOffers: reduceMatchOffers
});