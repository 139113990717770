import React from "react";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter, match } from "react-router-dom";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { CircularProgress } from "@material-ui/core";
import { Club } from "../../store/clubs/models";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { TrainingDetails } from "../../store/trainings/models";
import TrainingDetailsComponent from "./TrainingDetails";
import { loadTrainingDetails } from "../../store/trainings/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { selectTrainingDetails } from "../../store/trainings/selectors";

interface TrainingDetailsPageProps {
  player: Data<Player>;
  details: Data<TrainingDetails>;
  match: match;
  loadTrainingDetails: (year: string, id: string, token: string) => void;
  clubs: Data<Club[]>;
  loadClubs: () => void;
}

const TrainingDetailsPage: React.FC<TrainingDetailsPageProps> = ({
  details,
  match,
  loadTrainingDetails,
  player,
  clubs,
  loadClubs
}) => {

  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    var id = (match.params as any).id;
    var year = (match.params as any).year;

    getAccessTokenSilently().then((token) => {
      loadTrainingDetails(year, id, token);
    }).catch(() => {
      loadTrainingDetails(year, id, "");
    });

  }, [match.params, loadTrainingDetails]);

  React.useEffect(() => {
    if (clubs.type === "NOT_STARTED" && loadClubs) {
      loadClubs();
    }
  }, []);

  const unsafePlayer =
    player && player.type === "LOADED" ? player.value : undefined;

  switch (details.type) {
    case "LOADED": {
      return (
          <TrainingDetailsComponent
            clubs={clubs}
            training={details.value}
            player={unsafePlayer}
          ></TrainingDetailsComponent>
      );
    }
    case "LOADING": {
      return (
        <div
          style={{
            width: "64px",
            height: "64px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    default: {
      return <div></div>;
    }
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    details: selectTrainingDetails(state),
    player: currentPlayer(state),
    newestMatches: state.matches.currentLeagueNewestMatches,
    clubs: state.clubs.clubs
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTrainingDetails: (year: string, id: string, token: string) =>
      dispatch(loadTrainingDetails({ year, id, token })),
    loadClubs: () => dispatch(loadClubsInProvince())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrainingDetailsPage)
);
