import React from "react";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { connect } from "react-redux";
import ProfileEditor from "../players/ProfileEditor";
import { Dispatch } from "redux";
import { Club } from "../../store/clubs/models";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { Divider, Button, CircularProgress } from "@material-ui/core";
import { setUpdatedPlayer } from "../../store/registration/actions";
import { PlayerType } from "../../store/registration/model";

interface PlayerStepProps {
    onProceed: () => void;
    onMoveBack: () => void;
    currentPlayer: Data<Player>;
    updatedPlayer?: Player;
    clubs: Data<Club[]>;
    loadClubs: () => void;
    setUpdatedPlayer: (player?: Player) => void;
    playerType: PlayerType;
}

const PlayerStep: React.FC<PlayerStepProps> = ({ onProceed, onMoveBack, currentPlayer, clubs, loadClubs, setUpdatedPlayer, updatedPlayer, playerType }) => {

    React.useEffect(() => {
        loadClubs();
    }, []);

    React.useEffect(() => {
        if(currentPlayer && currentPlayer.type === "LOADED" && !!currentPlayer.value && !updatedPlayer) {
            setUpdatedPlayer(currentPlayer.value);
        }

    }, [currentPlayer])

    return <>{
        currentPlayer.type === "LOADED" && clubs.type === "LOADED" ? (<>
        <ProfileEditor player={updatedPlayer} clubs={clubs.value} onPlayerChanged={setUpdatedPlayer} playerType={playerType}></ProfileEditor>
        <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
        <Button color="primary" onClick={onMoveBack}>
                Wstecz
            </Button>
        <Button color="primary" variant="contained" onClick={onProceed}>
                Dalej
            </Button>
        </>) : (<div
                style={{
                  width: "64px",
                  height: "64px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <CircularProgress />
              </div>)
    }</>;
}

const mapStateToProps = (state: AppState) => ({
    currentPlayer: currentPlayer(state),
    clubs: state.clubs.clubs,
    updatedPlayer: state.registration.updatedPlayer,
    playerType: state.registration.playerType
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadClubs: () => dispatch(loadClubsInProvince()),
    setUpdatedPlayer: (player?: Player) => dispatch(setUpdatedPlayer(player)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStep);