import { getConfig } from "../../config/configuration";
import { Basket, Spot, OrderResponse } from "./models";

export async function loadProductsApi() {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/shop/products`);

    if(response.status === 404) {
        return [];
    } else if(response.ok) {
        const items = await response.json();
        return items;
    } else {
        throw new Error(response.statusText);
    }
}

export async function loadSpotsApi() {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/shop/spots/5`);

    if(response.ok) {
        const items = await response.json();

        return items;
    } else {
        throw new Error(response.statusText);
    }
}

export async function createShoppingOrderApi(basket: Basket, spot: Spot, token: string) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/shop/order`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            spotId: spot.id,
            province: 5,
            products: basket.products.map(({ id, count }) => ({
                id,
                quantity: count
            }))
        })
    });

    if(response.ok) {
        return 200;
    } else if(response.status === 409 || response.status === 402) { 
        return response.status;
    } else {
        throw new Error(response.statusText);
    }
}

export async function loadMyOrdersApi(token: string) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/shop/orders`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    });

    if(response.ok) {

        const orders: OrderResponse[] = await response.json();
        
        return orders.map(o => ({...o, orderDate: new Date(o.orderDate)}));
    } else {
        throw new Error(response.statusText);
    }
}