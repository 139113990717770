import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Hidden, Typography, useMediaQuery, useTheme, Chip } from "@material-ui/core";
import { TrainingHeader } from "../../store/trainings/models";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from '@material-ui/icons/Info';
import { Link } from "react-router-dom";
import DateTimeView from "../../framework/components/DateTimeView";
import DescriptionIcon from '@material-ui/icons/Description';

interface TrainingHeaderProps {
  training: TrainingHeader;
}

const TrainingHeaderComponent: React.FC<TrainingHeaderProps> = ({
  training
}) => {

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" color={"secondary"}>
              <span style={{ "display": "inline-block", "marginRight": "8px" }}>{training.name}</span>
            </Typography>
          </Grid>
          <Hidden xsDown={true}>
            <Grid item sm={"auto"} md={3}>
              <Typography variant="body2">
                <><b>Uczestnicy:</b> {training.participantsCount}{" / "}{training.maxPlayers}</>

              </Typography>
            </Grid>
          </Hidden>
          <Grid item sm={6} md={3} xs={12} style={{ textAlign: (isSmall) ? "left" : "right", paddingLeft: (isSmall) ? '0px' : 'inherit' }}>
            <Button component={Link} to={`/trainings/${training.year}/${training.id}`}>
              <InfoIcon />
                Szczegóły
            </Button>
            <Button component={Link} to={`/trainings/${training.year}/${training.id}/register`} color="primary" variant="contained">
              <DescriptionIcon />
                Zapisz się
            </Button>
          </Grid>

        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2} style={{ marginRight: "28px" }}>
          <Grid item sm={12} md={6}>
            {
              training.trainers.length === 1 ? (
                <>
                <Typography variant="body2">
              <b>Trener</b>
            </Typography>
            <Typography variant="body2" component="div">
                {training.trainers[0].name}{" "}{training.trainers[0].surname}
            </Typography>
                </>
              ) : (
                <>
                <Typography variant="body2">
              <b>Trenerzy</b>
            </Typography>
            <Typography variant="body2" component="div">
              <ul>
                {
                  training.trainers.map(t => (<li>{t.name} {t.surname}</li>))
                }
              </ul>            
            </Typography>
                </>
              )
            }
            
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Czas</b>
            </Typography>
            <Typography variant="body2">
              <DateTimeView value={training.startDate}></DateTimeView> - <DateTimeView value={training.endDate}></DateTimeView>
            </Typography>
          </Grid>
          <Grid item sm={12} md={3} style={{textAlign: "right"}}>
            <Typography variant="body2">
              <b>Cena</b>
            </Typography>
            <Typography variant="body2">
              {training.basePrice} PLN
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant="body2">
              <b>Opis</b>
            </Typography>
            <Typography variant="body2">{training.description}</Typography>
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Klub</b>
            </Typography>
            <img
                    src={training.club.logoUrl}
                    style={{ height: "64px" }}
                    alt={training.club.name}
                    title={training.club.name}
                  />
          </Grid>
          
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default TrainingHeaderComponent;
