import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Dispatch } from "redux";
import { loadMyLeagues, loadMyPrivateLeagues, loadMyLeaguesWithPayment } from "../../store/leagues/actions";
import { connect } from "react-redux";
import { loadUserPlayer, savePlayer } from "../../store/players/actions";
import { Player } from "../../store/players/models";
import ProfileDialog from "../players/ProfileDialog";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Data } from "../../framework/data";
import logger from "../../framework/logger";
import { Link } from "react-router-dom";
import { Club } from "../../store/clubs/models";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { PlayerType } from "../../store/registration/model";

interface AccountMenuProps {
  loadMyLeagues: (token: string) => void;
  loadMe: (token: string) => void;
  savePlayer: (token: string, player: Player) => void;
  loadMyPrivateLeagues: (token: string) => void;
  loadMyLeaguesWithPayment: (token: string) => void;
  player: Data<Player>;
  clubs: Data<Club[]>;
  loadClubs: () => void;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ loadMyLeagues, loadMe, player, savePlayer, loadMyLeaguesWithPayment, clubs, loadClubs }) => {
  const { isAuthenticated, loginWithRedirect, logout, user, getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(!!user)
    {
      setAnchorEl(event.currentTarget); 
    } else {
      logger.logEvent("Login attempt");

      loginWithRedirect({
        redirectUri: window.location.href, appState: {
          targetUrl: window.location.hash
        }
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileOpen = () => {
    setProfileDialogOpen(true);
  }

  const onProfileDialogClosed = async (success: boolean, player?: Player) => {
    setProfileDialogOpen(false);
    if(success && player) {
      logger.logEvent(`Player: ${player.email} attempted to save his profile`);
      const token = await getAccessTokenSilently();
      savePlayer(token, player);
    }
  }

  React.useEffect(() => {
    async function getToken() {
      if(isAuthenticated) {
        const  token  = await getAccessTokenSilently();
        loadMyLeagues(token);
        loadMe(token);
        loadMyLeaguesWithPayment(token);

        if(clubs.type === "NOT_STARTED") {
          loadClubs();
        }
      }
    }

    getToken();
    
  }, [isAuthenticated]);

  return (
    <>
    {
      player.type === "LOADED" && clubs.type === "LOADED" && (<ProfileDialog open={profileDialogOpen} onClosed={onProfileDialogClosed} player={player.value} clubs={clubs.value} ></ProfileDialog>)
    }
    
    <Button onClick={handleClick}>
      <AccountCircleIcon color={"secondary"}></AccountCircleIcon>
      <span style={{display: 'inline-block', maxWidth: '110px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{ !!user ? user.nickname : "zaloguj się"}</span>
    </Button>
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      {isAuthenticated && player.type === "LOADED" && !!player.value?.id && (
        <MenuItem onClick={() => { handleClose(); handleProfileOpen() } }>Profil zawodnika</MenuItem>
      )}
      {isAuthenticated && player.type === "LOADED" && player.value && (
        <MenuItem component={Link} to="/payments">Moje płatności</MenuItem>
      )}
      {isAuthenticated && (
        <MenuItem onClick={() => { handleClose(); logout({ returnTo: window.location.href }) } }>Wyloguj</MenuItem>
      )}
    </Menu>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  player: currentPlayer(state),
  clubs: state.clubs.clubs
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadMyLeagues: (token: string) => dispatch(loadMyLeagues(token)),
  loadMe: (token: string) => dispatch(loadUserPlayer(token)),
  savePlayer: (token: string, player: Player) => dispatch(savePlayer({token, player })),
  loadMyPrivateLeagues: (token: string) => dispatch(loadMyPrivateLeagues(token)),
  loadMyLeaguesWithPayment: (token: string) => dispatch(loadMyLeaguesWithPayment({ token })),
  loadClubs: () => dispatch(loadClubsInProvince())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);