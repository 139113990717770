import React from "react";
import { PlayerDescriptor } from "../../store/leagues/models";
import { Grid, Typography, Divider } from "@material-ui/core";

interface GeneralStatsProps {
    player: PlayerDescriptor;
    matchesWon: number;
    matchesPlayed: number;
    winPercentage: string;
}

const GeneralStats: React.FC<GeneralStatsProps> = ({ player, matchesWon, matchesPlayed, winPercentage }) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="h4" style={{textAlign: "center"}}>
                {player.name} {player.surname}
            </Typography>
            <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="caption">Mecze wygrane</Typography>
            <Typography variant="h5">{matchesWon}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="caption">Mecze rozegrane</Typography>
            <Typography variant="h5">{matchesPlayed}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Typography variant="caption">% wygranych</Typography>
            <Typography variant="h5">{winPercentage}%</Typography>
        </Grid>
    </Grid>
);

export default GeneralStats;