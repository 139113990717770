import React from "react";
import { Player } from "../../store/players/models";
import { Club } from "../../store/clubs/models";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Divider, Link, Typography, IconButton, Tooltip } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isValid, format, parse } from "date-fns";
import { sortBy } from "ramda";
import { useAuth0 } from "@auth0/auth0-react";
import { PlayerType } from "../../store/registration/model";

interface ProfileEditorProps {
    player?: Player;
    clubs?: Club[];
    onPlayerChanged: (player: Player) => void;
    playerType: PlayerType;
}

const ProfileEditor: React.FC<ProfileEditorProps> = ({ player, clubs, onPlayerChanged, playerType }) => {

    const { user } = useAuth0();

    const safePlayer: Player = player || {
        level: "N",
        gender: "N",
        birthDate: format(new Date(), "yyyy-MM-dd"),
        email: user?.email,
        name: "",
        phoneNumber: "",
        surname: "",
        id: undefined,
        joined: undefined,
    };
    const [playerState, setPlayerState] = React.useState(safePlayer);

    const setStringField = (field: keyof Player) => (event: any) => {
        if (playerState) {
            const newPlayer = { ...playerState, [field]: event.target.value };
            setPlayerState(newPlayer);
            onPlayerChanged(newPlayer);
        } else {
            const newPlayer = { [field]: event.target.value };
            setPlayerState(newPlayer);
            onPlayerChanged(newPlayer);
        }

    };

    const setPhoneNumber = () => (event: any) => {
    
        const fieldValue: string = event.target.value;

        const phoneNumber =  fieldValue ?? playerState.phoneNumber;

        if (playerState) {
            const newPlayer = { ...playerState, phoneNumber };
            setPlayerState(newPlayer);
            onPlayerChanged(newPlayer);
        } else {
            const newPlayer = { phoneNumber };
            setPlayerState(newPlayer);
            onPlayerChanged(newPlayer);
        }
    }

    const setDateField = (field: keyof Player) => (
        value: MaterialUiPickersDate
    ) => {
        if (playerState) {
            if (isValid(value)) {
                const newPlayer = {
                    ...playerState,
                    [field]: !!value ? format(value, "yyyy-MM-dd") : "",
                };
                setPlayerState(newPlayer);
                onPlayerChanged(newPlayer);
            }
        } else {
            if (isValid(value)) {
                const newPlayer = { [field]: !!value ? format(value, "yyyy-MM-dd") : "" };
                setPlayerState(newPlayer);
                onPlayerChanged(newPlayer);
            }
        }
    };

    const fromStringDate = (field: keyof Player) => {
        if (!playerState || !playerState[field]) {
            return new Date();
        } else {
            return parse(
                playerState[field]?.toString() || "",
                "yyyy-MM-dd",
                new Date()
            );
        }
    };

    const setPreferredClubs = (event: any, value: any[]) => {
        if (value.length <= 3) {
            const newPlayer = {
                ...playerState,
                preferredClubs: value.map((x) => {
                    if (typeof x === "string") {
                        return clubs?.find((y) => y.id === x)?.id;
                    }
                    return x.id;
                }),
            };
            setPlayerState(newPlayer);
            onPlayerChanged(newPlayer);
        }
    };

    const getOptionsLabel = (option: any) => {
        if (!option) {
            return "";
        }

        if (!!option.name) {
            return option.name;
        }

        if (typeof option === "string") {
            return clubs?.find((x) => x.id === option)?.name || "";
        }

        return "";
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: "100%" }}
                    label="Imię"
                    error={!playerState?.name}
                    variant="outlined"
                    value={playerState?.name || ""}
                    onChange={setStringField("name")}
                ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: "100%" }}
                    label="Nazwisko"
                    error={!playerState?.surname}
                    variant="outlined"
                    value={playerState?.surname || ""}
                    onChange={setStringField("surname")}
                ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                    inputVariant="outlined"
                    variant="inline"
                    style={{ width: "100%" }}
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data urodzenia"
                    autoOk
                    value={fromStringDate("birthDate")}
                    onChange={setDateField("birthDate")}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin="normal"
                >
                    <InputLabel id="gender">Płeć</InputLabel>
                    <Select
                        labelId="gender"
                        id="gender-field"
                        onChange={setStringField("gender")}
                        value={playerState?.gender || "N"}
                        labelWidth={32}
                    >
                        <MenuItem value={"M"}>Mężczyzna</MenuItem>
                        <MenuItem value={"K"}>Kobieta</MenuItem>
                        <MenuItem value={"N"}>Nie podaję</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin="normal"
                >
                    <InputLabel id="level">Poziom NTRP</InputLabel>
                    <Select
                        labelId="level"
                        id="level-field"
                        onChange={setStringField("level")}
                        value={playerState?.level || "N"}
                        labelWidth={100}
                    >
                        <MenuItem value={"1.0"}>1.0</MenuItem>
                        <MenuItem value={"1.5"}>1.5</MenuItem>
                        <MenuItem value={"2.0"}>2.0</MenuItem>
                        <MenuItem value={"2.5"}>2.5</MenuItem>
                        <MenuItem value={"3.0"}>3.0</MenuItem>
                        <MenuItem value={"3.5"}>3.5</MenuItem>
                        <MenuItem value={"4.0"}>4.0</MenuItem>
                        <MenuItem value={"4.5"}>4.5</MenuItem>
                        <MenuItem value={"5.0"}>5.0</MenuItem>
                        <MenuItem value={"5.5"}>5.5</MenuItem>
                        <MenuItem value={"6.0"}>6.0</MenuItem>
                        <MenuItem value={"6.5"}>6.5</MenuItem>
                        <MenuItem value={"7.0"}>7.0</MenuItem>
                        <MenuItem value={"N"}>Nie podaję</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: "100%" }}
                    label="Numer telefonu"
                    variant="outlined"
                    error={!playerState?.phoneNumber}
                    value={playerState?.phoneNumber || ""}
                    onChange={setPhoneNumber()}
                    InputProps={{
                        endAdornment: <Tooltip title="Numer telefonu w formacie np. +48511222115"><HelpIcon /></Tooltip>
                    }}
                ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    style={{ width: "100%" }}
                    label="Adres email"
                    variant="outlined"
                    error={!playerState?.email}
                    value={playerState?.email || ""}
                    onChange={setStringField("email")}
                ></TextField>
            </Grid>
            <Grid item xs={12}>
                <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
                {
                    playerType === PlayerType.REGULAR ? (
                        <Autocomplete
                            id="preferred-clubs"
                            multiple
                            value={playerState?.preferredClubs || []}
                            options={sortBy((x) => x.address?.city || "Kraków", clubs || [])}
                            getOptionLabel={getOptionsLabel}
                            getOptionSelected={(o: Club, v: string) => o.id === v}
                            groupBy={(o: Club) => o.address?.city || "Kraków"}
                            filterSelectedOptions
                            onChange={setPreferredClubs}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Preferowane kluby (max 3)"
                                    placeholder="Kluby, na kortach których chciałbym grać"
                                />
                            )}
                        ></Autocomplete>
                    ) : (
                        <TextField
                    label="Kluby, na kortach których chciałbym grać"
                    placeholder="Kluby, na kortach których chciałbym grać"
                    multiline
                    fullWidth={true}
                    rows={3}
                    value={playerState?.preferredClubsDescription || ""}
                    onChange={setStringField("preferredClubsDescription")}
                    variant="outlined"
                ></TextField>
                    )
                }

            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Dostępność czasowa"
                    placeholder="Informacje dla innych zawodników, kiedy najlepiej Ci rozgrywać mecze"
                    multiline
                    fullWidth={true}
                    rows={3}
                    value={playerState?.preferredTime || ""}
                    onChange={setStringField("preferredTime")}
                    variant="outlined"
                ></TextField>
            </Grid>
            {player && player.id ? (
                <>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            value={player.referral}
                            label="Kod do poleceń"
                            InputProps={{ readOnly: true }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            value={player.rewardPoints ?? "brak"}
                            label="SmartPunkty"
                            InputProps={{ readOnly: true }}
                        ></TextField>
                    </Grid>
                </>
            ) : (
                    <></>
                )}

            <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", flexFlow: "row", alignItems: "center" }}
            >
                <Link href="./NTRP-PL.pdf" target="_blank">
                    Opis poziomów NTRP
              </Link>
            </Grid>
            {player && player.id ? (
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Link do poleceń:{" "}
                        <b style={{ whiteSpace: "pre" }}>
                            {`https://liga.smarttennis.pl?referral=${player.referral}`}
                        </b>
                        <IconButton color="secondary" onClick={async () => {
                            await window.navigator.clipboard.writeText(`https://liga.smarttennis.pl?referral=${player.referral}`);
                        }}>
                            <FileCopyIcon></FileCopyIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            ) : (
                    <></>
                )}
        </Grid>
    );
}

export default ProfileEditor;