import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import { getConfig } from "./config/configuration";
import logger from "./framework/logger";
import ReactGA from "react-ga";
import { Auth0Provider } from "@auth0/auth0-react";

getConfig().then((conf) => {
  if (conf.aiKey) logger.initialize(conf.aiKey);

  if (conf.gaKey) ReactGA.initialize(conf.gaKey);
});

const search = (window.location.search ?? "").replace("?", "");
if (search) {
  const query: any = search
    .split("&")
    .map((q) => q.split("="))
    .reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {});

  if(query["referral"]) {
    window.localStorage.setItem("referral", query["referral"]);
  }
}

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain="tennis-league.eu.auth0.com"
    clientId="y2mG24uEjR8lf0GS527gUx6UpcsySFxD"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience="https://tennis-league/api"
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

/*ReactDOM.render(
  <App />
, document.getElementById('root'));*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '55c3a912-66c4-4d6f-892f-582784c9c6d6'
    }
}).loadAppInsights();

appInsights.trackEvent({ name: 'My event', properties: { value: 1, x: 'Test' } });
appInsights.trackException({ error: new Error('Shit happened'), id: 'my-id', severityLevel: 1, properties: { value: 2, x: 'Error' } });
appInsights.trackPageView({ isLoggedIn: false, name: 'My page', pageType: 'Root', uri: window.location.href, properties:  { value: 3, x: 'Page view' }});
appInsights.trackTrace({ message: 'Trace message', properties: { value: 4, x: 'Trace' } });
*/
//fetch('https://tennis-league-test.azurewebsites.net/test').then((r) => console.log(r.ok));

//  $env:NODE_OPTIONS = "--openssl-legacy-provider"