import { put, take, takeEvery, call } from "redux-saga/effects";
import { LOAD_TRAININGS, REGISTER_TO_TRAINING, LOAD_TRAINING_DETAILS, } from "./actionTypes";
import { LoadTrainings, loadTrainingsSuccess, RegisterToTraining, LoadTrainingDetails, loadTrainingDetailsSuccess, loadTrainingDetails } from "./actions";
import { makeRegistrationFailed, makeRegistrationSuccess, sendRegistrationMessage} from "../registration/actions";
import { createPlayer, updatePlayer } from "../players/actions";
import { PlayerType, RegistrationStatus } from "../registration/model";
import { CREATE_PLAYER_SUCCESS, UPDATE_PLAYER_SUCCESS } from "../players/actionTypes";
import Api from "./api";
import { TrainingHeader, TrainingDetails } from "./models";

function* loadTrainingsStep({ payload }: LoadTrainings) {
    // API CALL

    const {token, year} = payload;

    const result: TrainingHeader[] = yield call(Api.load, token, year);

    yield put(loadTrainingsSuccess({ trainings: result }))
}

function* loadTrainingDetailsStep({ payload }: LoadTrainingDetails) {
    const { id, token, year } = payload;
    const result: TrainingDetails = yield call(Api.loadDetails, token, year, id);
    yield put(loadTrainingDetailsSuccess({ training: result }));
}

function* registerToTrainingStep({ payload }: RegisterToTraining) {
    const { playerData, target, token } = payload;

    if(playerData.id) {
        yield put(sendRegistrationMessage("Uaktualniam dane zawodnika..."));
        yield put(updatePlayer({
            player: playerData,
            playerType: playerData.playerType,
            token
        }));

        yield take(UPDATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik uatkualniony"));
    } else {
        yield put(sendRegistrationMessage("Tworzę nowege zawodnika..."));
        yield put(createPlayer({
            player: playerData,
            playerType: PlayerType.REGULAR,
            token
        }));

        yield take(CREATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik utworzony"));
    }

    yield put(sendRegistrationMessage("Zapisujemy na trening..."));
    
    try {
        const result: RegistrationStatus = yield call(Api.register, token, target);
        if(result.isSuccess) {
            yield put(makeRegistrationSuccess({}));
            yield put(sendRegistrationMessage("To już wszystko! Witamy na treningu!"))
            window.location.assign(`/#/training/${target.training.season}`);
            
            
        } else {
            yield put(makeRegistrationFailed("błąd", { description: result.errorMessage }));
        }
    } catch(e) {
        yield put(makeRegistrationFailed(e, {}));
    }

}

export function* loadTrainingsSaga() {
    yield takeEvery(LOAD_TRAININGS, loadTrainingsStep);
}

export function* registerToTrainingSaga() {
    yield takeEvery(REGISTER_TO_TRAINING, registerToTrainingStep);
}

export function* loadTrainingDetailsSaga() {
    yield takeEvery(LOAD_TRAINING_DETAILS, loadTrainingDetailsStep);
}