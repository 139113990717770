import { getConfig } from "../../config/configuration";
import { Player } from "./models";
import { PlayerType } from "../registration/model";

export async function getAllPlayers(token: string) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/admin/players`, { 
        headers: {
            'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
    });

    if(response.status !== 200) {
        return [];
    } else {
        const items = await response.json();
        return items;
    }
}

export async function loadUserPlayer(token: string) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, { 
        headers: {
            'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
    });

    if(response.status === 404) {
        return null;
    } else {
        const items = await response.json();
        return items;
    }
}

export async function createUserPlayer(token: string, player: Player, playerType?: PlayerType) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, { 
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            ...player,
            playerType
        })
    });

    if(response.ok) {
        return await response.json();
    } else {
        throw new Error('Nie udało się stworzyć zawodnika.');
    }

}

export async function updateUserPlayer(token: string, player: Player) {
    var config = await getConfig();

    const response = await fetch(`${config.apiUrl}/players/me`, { 
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(player)
    });

    if(response.ok) {
        return await response.json();
    } else {
        throw new Error('Nie udało się uaktualnić zawodnika.');
    }
}