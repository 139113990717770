import React from "react";
import { match, withRouter } from "react-router-dom";
import { AppState } from "../../store/reducer";
import { connect } from "react-redux";
import { FormState, EDITING, shouldKeepDialogOpen, NOT_STARTED, CreateMatchOfferPayload, State, MatchOfferHeader } from "../../store/match-offers/models";
import { Button, CircularProgress, Grid, TablePagination, Paper, useTheme, Typography } from "@material-ui/core";
import { Dispatch } from "redux";
import { createChangeFormState, createMatchOffer, getMatchOffers, cancelMatchOffer, acceptMatchOffer } from "../../store/match-offers/actions";
import CreateMatchOfferDialog from "./CreateMatchOfferDialog";
import { Club } from "../../store/clubs/models";
import { Data, unsafeUnwrap, isLoaded } from "../../framework/data";
import { currentPlayer } from "../../store/players/selectors";
import { allClubs } from "../../store/clubs/selectors";
import { Player } from "../../store/players/models";
import { useAuth0 } from "@auth0/auth0-react";
import MatchOffersList from "./MatchOffersList";
import AddIcon from '@material-ui/icons/Add';
import { LeagueWithPlayersICanPlay } from "../../store/leagues/models";
import { loadLeagueDetails, loadOpponentsPlayedWith } from "../../store/leagues/actions";
import { userAssignedLeagueWithPossibleMatches } from "../../store/leagues/selectors";
import { drop, take } from "ramda";
import MatchOffersFilter from "./MatchOffersFilter";
import { MatchOffersFilterModel } from "../../store/match-offers/utils";
import { add } from "date-fns";
import { Alert, AlertTitle } from "@material-ui/lab";

interface MatchOfferPageProps {
    match: match<{ leagueYear: string, leagueId: string }>;
    formState: FormState;

    player: Data<Player>;
    clubs: Data<Club[]>;

    matchOffers: Data<MatchOfferHeader[]>;

    league: Data<LeagueWithPlayersICanPlay>;
    loadLeaguesDetails: (id: string, year: string) => void;
    loadOpponentsPlayedWith: (token: string, year: string, id: string) => void;

    startEditing: () => void;
    cancelEditing: () => void;
    submitMatchOffer: (payload: CreateMatchOfferPayload) => void;

    loadMatchOffers: (leagueId: string, leagueYear: string) => void;

    cancelMatchOffer: (id: string, leagueId: string, leagueYear: string, token: string) => void;
    acceptMatchOffer: (id: string, leagueId: string, leagueYear: string, token: string) => void;
}

const doesItemMatchFilter = (m: MatchOfferHeader, f: MatchOffersFilterModel) => {
    return m.startDate >= f.dateFrom && m.startDate <= f.dateTo && (f.clubIds.length == 0 || f.clubIds.includes(m.clubs[0].id))
}

const MatchOffersPage: React.FC<MatchOfferPageProps> = ({ match, formState, player, clubs, matchOffers, startEditing, cancelEditing, submitMatchOffer, loadMatchOffers, league, loadLeaguesDetails, loadOpponentsPlayedWith, acceptMatchOffer, cancelMatchOffer }) => {

    const leagueYear = match.params.leagueYear;
    const leagueId = match.params.leagueId;

    const { getAccessTokenSilently } = useAuth0();

    console.log(matchOffers);

    React.useEffect(() => {
        loadMatchOffers(leagueId, leagueYear);
        loadLeaguesDetails(leagueId, leagueYear);
    }, [leagueId, leagueYear]);

    React.useEffect(() => {
        if (player && isLoaded(player)) {
            getAccessTokenSilently().then((t) => {
                loadOpponentsPlayedWith(t, leagueYear, leagueId)
            })
        }
    }, [player, leagueId, leagueYear]);

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(25);

    const [filter, setFilter] = React.useState<MatchOffersFilterModel>({ clubIds: [], dateFrom: new Date(), dateTo: add(new Date(), { days: 28 }) });

    const filteredItems = React.useMemo(() => matchOffers.type === "LOADED" ? matchOffers.value.filter((mo) => doesItemMatchFilter(mo, filter)) : [], [filter, matchOffers])

    const viewableItems = React.useMemo(() => take(pageSize, drop(page * pageSize, filteredItems)), [page, pageSize, filteredItems]);

    const theme = useTheme();

    return (<Grid container spacing={2} direction="column">
        <Grid item>
        <Alert severity="info" style={{marginBottom: 16, maxWidth: '84vw'}}>
          <AlertTitle>Zmiany w giełdzie meczów</AlertTitle>
          <Typography variant="body2">Zgodnie z ankietą przeprowadzoną na grupie <i>Mecze ligowe</i> na Facebooku, w sezonie 2024/1 po kliknięciu na propozycję, którą ktoś wystawił na giełdzie meczów zostanie automatycznie utworzony <b>zatwierdzony</b> mecz.</Typography>
        </Alert>
      </Grid>
        {
            (player.type === "LOADED" && clubs.type === "LOADED")
                ? <Grid item>
                    <CreateMatchOfferDialog
                        state={formState.state}
                        open={shouldKeepDialogOpen(formState)}
                        errors={formState.state === State.FAILED ? formState.errors : undefined}
                        player={player.value}
                        clubs={clubs.value}
                        leagueId={leagueId}
                        leagueYear={leagueYear}
                        onClosed={cancelEditing}
                        onSubmit={async (preferredClubs, fromDate, fromTime, toDate, toTime, description) => {
                            const token = await getAccessTokenSilently();
                            submitMatchOffer({
                                clubIds: preferredClubs,
                                description,
                                endDate: toDate,
                                endTime: toTime,
                                leagueId,
                                leagueYear,
                                startDate: fromDate,
                                startTime: fromTime,
                                token
                            });
                        }}>
                    </CreateMatchOfferDialog>
                    <Button color="primary" variant="contained" onClick={startEditing} startIcon={
                        <AddIcon />
                    }>
                        Dodaj propozycję
                    </Button>

                </Grid>
                : <></>
        }
        <Grid item>
            <Paper variant="outlined" style={{ borderLeft: 0, borderRight: 0, margin: '0 -32px', padding: '16px 32px', backgroundColor: theme.palette.grey[100]}} square>
                <MatchOffersFilter filter={filter} onFilterChanged={setFilter} clubs={clubs.type === "LOADED" ? clubs.value : []}>

                </MatchOffersFilter>
            </Paper>
        </Grid>
        <Grid item>
            {
                matchOffers.type === "LOADED" && clubs.type === "LOADED" && league.type === "LOADED" ? (
                    <>
                        <MatchOffersList
                            clubs={clubs.value}
                            offers={viewableItems}
                            player={unsafeUnwrap(player)}
                            league={unsafeUnwrap(league)}
                            acceptMatchOffer={async (id) => {
                                if (player.type === "LOADED") {
                                    const token = await getAccessTokenSilently();
                                    acceptMatchOffer(id, leagueId, leagueYear, token);
                                }
                            }}
                            cancelMatchOffer={async (id) => {
                                if (player.type === "LOADED") {
                                    const token = await getAccessTokenSilently();
                                    cancelMatchOffer(id, leagueId, leagueYear, token);
                                }
                            }}
                        ></MatchOffersList>
                        <TablePagination
                            component="div"
                            count={filteredItems.length}
                            page={page}
                            onChangePage={(_, p) => setPage(p)}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowsPerPage={pageSize}
                            onChangeRowsPerPage={(e) => {
                                setPageSize(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </>
                ) : (
                        <div style={{
                            width: "64px",
                            height: "64px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                            <CircularProgress />
                        </div>
                    )
            }
        </Grid>



    </Grid>);
}

const mapStateToProps = (state: AppState) => ({
    formState: state.matchOffers.formState,
    player: currentPlayer(state),
    clubs: allClubs(state),
    matchOffers: state.matchOffers.matchOffers,
    league: userAssignedLeagueWithPossibleMatches(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    startEditing: () => dispatch(createChangeFormState(EDITING)),
    cancelEditing: () => dispatch(createChangeFormState(NOT_STARTED)),
    submitMatchOffer: (payload: CreateMatchOfferPayload) => dispatch(createMatchOffer(payload)),
    loadMatchOffers: (leagueId: string, leagueYear: string) => dispatch(getMatchOffers({ leagueId, leagueYear })),
    loadLeaguesDetails: (leagueId: string, leagueYear: string) => dispatch(loadLeagueDetails({ id: leagueId, year: leagueYear })),
    loadOpponentsPlayedWith: (token: string, year: string, id: string) => dispatch(loadOpponentsPlayedWith({ token, year, id })),
    cancelMatchOffer: (id: string, leagueId: string, leagueYear: string, token: string) => dispatch(cancelMatchOffer({ id, leagueId, leagueYear, token })),
    acceptMatchOffer: (id: string, leagueId: string, leagueYear: string, token: string) => dispatch(acceptMatchOffer({ id, leagueId, leagueYear, token }))
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MatchOffersPage)
);