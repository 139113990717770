import { Clubs, PlayerDescriptor } from "../leagues/models";

export interface CreateMatchOfferPayload {
    token: string;
    leagueYear: string;
    leagueId: string;
    clubIds: string[];
    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;
    description: string;
}

export interface MatchOfferHeader {
    id: string;
    league: string;
    year: string;
    firstPlayer: PlayerDescriptor;
    clubs: Clubs[];
    startDate: Date;
    endDate: Date;
    startTime: string;
    endTime: string;
    description: string;
    createdOn: Date;
    isLoading?: boolean;
}

export enum State {
    NOT_STARTED = "NOT_STARTED",
    EDITING = "EDITING",
    SUBMITTING = "SUBMITTING",
    SUBMITTED = "SUBMITTED",
    FAILED = "FAILED"
}

interface _FS {
    state: State
}

export interface _FormStateNotStarted extends _FS {
    state: typeof State.NOT_STARTED;
}

export interface _FormStateEditing extends _FS {
    state: typeof State.EDITING;
}

export interface _FormStateSubmitting extends _FS {
    state: typeof State.SUBMITTING;
}

export interface _FormStateSubmitted extends _FS {
    state: typeof State.SUBMITTED;
}

export interface _FormStateFailed extends _FS {
    state: typeof State.FAILED;
    error: string;
    errors: {
        [key : string]: string[]
    }
}

export type FormState = 
    | _FormStateNotStarted
    | _FormStateEditing
    | _FormStateSubmitting
    | _FormStateSubmitted
    | _FormStateFailed;

export const NOT_STARTED: _FormStateNotStarted = {
    state: State.NOT_STARTED
};

export const EDITING: _FormStateEditing = {
    state: State.EDITING
};

export const SUBMITTING: _FormStateSubmitting = {
    state: State.SUBMITTING
};

export const SUBMITTED: _FormStateSubmitted = {
    state: State.SUBMITTED
};

export const failed = (error: string, errors: _FormStateFailed["errors"]): _FormStateFailed => ({
    state:State.FAILED,
    error, 
    errors
});

export const shouldKeepDialogOpen = (formState: FormState) => [ State.EDITING, State.FAILED, State.SUBMITTING ].includes(formState.state);