import { takeEvery, put, call, delay } from "redux-saga/effects";
import { LOAD_PRODUCTS, LOAD_SPOTS, CREATE_SHOPPING_ORDER, LOAD_MY_ORDERS } from "./actionTypes";
import { loadProductsSuccess, loadProductsFailed, loadSpotsFailed, loadSpotsSuccess, createShoppingOrderFailed, createShoppingOrderSuccess, CreateShoppingOrder, LoadMyOrders, loadMyOrdersFailed, loadMyOrdersSuccess } from "./actionts";
import { loadProductsApi, loadSpotsApi, createShoppingOrderApi, loadMyOrdersApi } from "./api";
import ReactGA from 'react-ga';

function* loadProductsStep() {
    try {
        const data = yield call(loadProductsApi);

        yield put(loadProductsSuccess(data));
    } catch(e) {
        yield put(loadProductsFailed(e.message));
    }
}

function* loadSpotsStep() {
    try {
        const data = yield call(loadSpotsApi);

        yield put(loadSpotsSuccess(data));
    } catch(e) {
        yield put(loadSpotsFailed(e.message));
    }
}

function* createShoppingOrderStep({ payload } : CreateShoppingOrder) {
    try {

        const status = yield call(createShoppingOrderApi, payload.basket, payload.selectedSpot, payload.token);
        if(status === 200) {
            yield put(createShoppingOrderSuccess());

            ReactGA.event({ action: 'Order submitted', category: 'Shop' });

            yield delay(5000);

            window.location.assign("/#/");
        } else if(status === 409) {
            ReactGA.event({ action: 'Order submission failed - INVENTORY LOW', category: 'Shop'})
            yield put(createShoppingOrderFailed("Nie udało się złożyć zamówienia.", "Nie posiadamy wystarczającej ilości danego produktu."));    
        } else if(status === 402) {
            ReactGA.event({ action: 'Order submission failed - INSUFFICIENT POINTS', category: 'Shop'})
            yield put(createShoppingOrderFailed("Nie udało się złożyć zamówienia.", "Nie masz wystarczającej ilości SmartPunktów."))
        }
        
    } catch(e) {
        yield put(createShoppingOrderFailed("Błąd serwera", "Nie udało się złożyć zamówienia. Spróbuj ponownie!"));
    }
}

function* loadMyOrdersStep({ payload} : LoadMyOrders) {
    try {

        const token = payload;

        const orders = yield call(loadMyOrdersApi, token);

        yield put(loadMyOrdersSuccess(orders));

    } catch(e) {
        yield put(loadMyOrdersFailed(e.message));
    }
}

export function* loadProducts() {
    yield takeEvery(LOAD_PRODUCTS, loadProductsStep);
}

export function* loadSpots() {
    yield takeEvery(LOAD_SPOTS, loadSpotsStep);
}

export function* createShoppingOrderSaga() {
    yield takeEvery(CREATE_SHOPPING_ORDER, createShoppingOrderStep);
}

export function* loadMyOrdersSaga() {
    yield takeEvery(LOAD_MY_ORDERS, loadMyOrdersStep)
}