export const SUBMIT_MATCH = "SUBMIT_MATCH";
export const SUBMIT_MATCH_FAILED = "SUBMIT_MATCH_FAILED";
export const SUBMIT_MATCH_SUCCESS = "SUBMIT_MATCH_SUCCESS";

export const GET_NEWEST_MATCHES = "GET_NEWEST_MATCHES";
export const GET_NEWEST_MATCHES_FAILED = "GET_NEWEST_MATCHES_FAILED";
export const GET_NEWEST_MATCHES_SUCCESS = "GET_NEWEST_MATCHES_SUCCESS";

export const GET_PLAYERS_MATCHES = "GET_PLAYERS_MATCHES";
export const GET_PLAYERS_MATCHES_FAILED = "GET_PLAYERS_MATCHES_FAILED";
export const GET_PLAYERS_MATCHES_SUCCESS = "GET_PLAYERS_MATCHES_SUCCESS";

export const CONFIRM_MATCH = "CONFIRM_MATCH";
export const CONFIRM_MATCH_FAILED = "CONFIRM_MATCH_FAILED";
export const CONFIRM_MATCH_SUCCESS = "CONFIRM_MATCH_SUCCESS";

export const CANCEL_MATCH = "CANCEL_MATCH";
export const CANCEL_MATCH_FAILED = "CANCEL_MATCH_FAILED";
export const CANCEL_MATCH_SUCCESS = "CANCEL_MATCH_SUCCESS";

export const SUBMIT_MATCH_RESULT = "SUBMIT_MATCH_RESULT";
export const SUBMIT_MATCH_RESULT_FAILED = "SUBMIT_MATCH_RESULT_FAILED";
export const SUBMIT_MATCH_RESULT_SUCCESS = "SUBMIT_MATCH_RESULT_SUCCESS";

export const GET_LEAGUES_MATCHES = "GET_LEAGUES_MATCHES";
export const GET_LEAGUES_MATCHES_FAILED = "GET_LEAGUES_MATCHES_FAILED";
export const GET_LEAGUES_MATCHES_SUCCESS = "GET_LEAGUES_MATCHES_SUCCESS";

export const GET_TOURNAMENT_MATCHES = "GET_TOURNAMENT_MATCHES";
export const GET_TOURNAMENT_MATCHES_FAILED = "GET_TOURNAMENT_MATCHES_FAILED";
export const GET_TOURNAMENT_MATCHES_SUCCESS = "GET_TOURNAMENT_MATCHES_SUCCESS";

export const GET_PLAYERS_TOURNAMENT_MATCHES = "GET_PLAYERS_TOURNAMENT_MATCHES";
export const GET_PLAYERS_TOURNAMENT_MATCHES_FAILED = "GET_PLAYERS_TOURNAMENT_MATCHES_FAILED";
export const GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS = "GET_PLAYERS_TOURNAMENT_MATCHES_SUCCESS";

export const CONFIRM_TOURNAMENT_MATCH = "CONFIRM_TOURNAMENT_MATCH";
export const CONFIRM_TOURNAMENT_MATCH_FAILED = "CONFIRM_TOURNAMENT_MATCH_FAILED";
export const CONFIRM_TOURNAMENT_MATCH_SUCCESS = "CONFIRM_TOURNAMENT_MATCH_SUCCESS";

export const CANCEL_TOURNAMENT_MATCH = "CANCEL_TOURNAMENT_MATCH";
export const CANCEL_TOURNAMENT_MATCH_FAILED = "CANCEL_TOURNAMENT_MATCH_FAILED";
export const CANCEL_TOURNAMENT_MATCH_SUCCESS = "CANCEL_TOURNAMENT_MATCH_SUCCESS";

export const SUBMIT_TOURNAMENT_MATCH_RESULT = "SUBMIT_TOURNAMENT_MATCH_RESULT";
export const SUBMIT_TOURNAMENT_MATCH_RESULT_FAILED = "SUBMIT_TOURNAMENT_MATCH_RESULT_FAILED";
export const SUBMIT_TOURNAMENT_MATCH_RESULT_SUCCESS = "SUBMIT_TOURNAMENT_MATCH_RESULT_SUCCESS";