import React from "react";
import { Stepper, Step, StepButton } from "@material-ui/core";
import LeagueSelector from "./LeagueSelector";
import PlayerType from "./PlayerType";
import PlayerStep from "./PlayerStep";
import ConfirmationsStep from "./ConfirmationsStep";
import RegistrationProgress from "./RegistrationProgress";

interface RegistrationWizardProps {
    stepsMode: StepsMode
}

const allSteps: { key: string, title: string, factory: (activeStepIdx: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => JSX.Element }[] = [
    {
        key: "LEAGUE",
        title: "Wybór ligi",
        factory: (activeStepIdx: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => <LeagueSelector onProceed={() => setActiveStep(activeStepIdx+1)} />
    },
    {
        key: "PROFILE_TYPE",
        title: "Typ profilu",
        factory: (activeStepIdx: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => <PlayerType onProceed={() => setActiveStep(activeStepIdx+1)} onMoveBack={() => setActiveStep(activeStepIdx-1)} />
    },
    {
        key: "PLAYER_PROFILE",
        title: "Profil zawodnika",
        factory: (activeStepIdx: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => <PlayerStep onProceed={() => setActiveStep(activeStepIdx+1)} onMoveBack={() => setActiveStep(activeStepIdx-1)} />
    },
    {
        key: "SIGN_UP",
        title: "Zapis do ligi",
        factory: (activeStepIdx: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>) => <ConfirmationsStep onProceed={() => setActiveStep(activeStepIdx+1)} onMoveBack={() => setActiveStep(activeStepIdx-1)}></ConfirmationsStep>
    },
    {
        key: "FINISH",
        title: "Zakończenie",
        factory: () => <RegistrationProgress></RegistrationProgress>
    }
];

export type StepsMode = "NORMAL" | "UPGRADE_TO_PLAYER" | "FREE_LEAGUE";

const getSteps = (mode: StepsMode) => {

    switch(mode) {
        case "NORMAL": {
            return [
                "Wybór ligi",
                "Typ profilu",
                "Profil zawodnika",
                "Zapis do ligi",
                "Zakończenie"
            ]
        };
        case "FREE_LEAGUE": {
            return [
                "Wybór ligi",
                "Profil zawodnika",
                "Zapis do ligi",
                "Zakończenie"
            ]
        };
        case "UPGRADE_TO_PLAYER": {
            return [
                "Zapis do ligi",
                "Zakończenie"
            ]
        }
    }
}

const getStepContent = (mode: StepsMode, activeStepIdx: number) => {
    const stepName = getSteps(mode)[activeStepIdx];
    console.log(stepName);
    console.log(mode);
    return allSteps.find(x => x.title === stepName)?.factory;
}


const RegistrationWizard: React.FC<RegistrationWizardProps> = ({ stepsMode }) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps(stepsMode);

    return <>
        <Stepper nonLinear activeStep={activeStep}>
            {
                steps.map((step) => (
                    <Step key={step}>
                        <StepButton>
                            {step}
                        </StepButton>
                    </Step>
                ))
            }
        </Stepper>
        {
            getStepContent(stepsMode, activeStep)!(activeStep, setActiveStep)
        }
    </>
};

export default RegistrationWizard;