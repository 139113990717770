import React from "react";
import { RegisterToTournament, TournamentHeader } from "../../../store/tournaments/models";
import { Data } from "../../../framework/data";
import { Button, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { AppState } from "../../../store/reducer";
import { Alert } from "@material-ui/lab";

interface ITournamentRegistrationConfirmation {
    tournaments: Data<TournamentHeader[]>;
    tournamentToRegister: RegisterToTournament;
    onProceed: () => void;
    onMoveBack: () => void;
}

const TournamentRegistrationConfirmation: React.FC<ITournamentRegistrationConfirmation> = ({ tournaments, tournamentToRegister, onMoveBack, onProceed }) => {
    if (tournaments.type === "LOADED" && tournamentToRegister?.tournament?.id) {

        const selectedTournament = tournaments.value.find(x => x.id === tournamentToRegister.tournament.id && x.year === tournamentToRegister.tournament.season)!;

        return (<>
            <Grid container justify="center" alignItems="center" direction="row">
                <Grid item md={4} sm={12}>
                    <Typography variant="h5" color="primary" align="center" style={{ marginBottom: 32 }}>{selectedTournament.name}</Typography>
                    <Typography variant="body1" style={{ marginBottom: 32 }}>{selectedTournament.description}</Typography>
                    <Alert severity="info">
                        <Typography variant="body1">{selectedTournament.paymentDescription}</Typography>
                    </Alert>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
            <Button color="primary" onClick={onMoveBack}>
                Wstecz
            </Button>
            <Button color="primary" variant="contained" onClick={onProceed}>
                Dalej
            </Button>
        </>);
    } else {
        return <CircularProgress />
    }
};

const mapStateToProps = (state: AppState) => ({
    tournaments: state.tournaments.items,
    tournamentToRegister: state.registration.tournamentSelection
});


export default connect(mapStateToProps)(TournamentRegistrationConfirmation);
