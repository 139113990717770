export interface Product {
    id: string;
    name: string;
    price: number;
    smartPointsPrice: number;
    quantity: number;
    base64Image: string;
    additionalInfo: string;
}

export interface ProductItem {
    id: string;
    name: string;
    smartPointsPrice: number;
    count: number;
}

export interface Basket {
    products: ProductItem[];
}

export interface Spot {
    id: string;
    name: string;
    address: string;
    city: string;
}

export interface OrderSubmissionState {
    status: OrderSubmissionStatus;
    message: string;
}

export enum OrderSubmissionStatus {
    NOT_STARTED, PENDING, FINISHED, FAILED
}

export interface OrderResponse {
    id: string;
    orderDetails: OrderLine[];
    spotId: string;
    spotName: string;
    orderDate: string;
    finalPrice: number;
    status: OrderStatus;
}

export interface Order {
    id: string;
    spotId: string;
    spotName: string;
    orderDate: Date;
    finalPrice: number;
    orderDetails: OrderLine[];
    status: OrderStatus;
}

export interface OrderLine {
    id: string;
    name: string;
    quantity: number;
}

export enum OrderStatus {
    PENDING, READY, FINISHED, CANCELLED
}