import { takeEvery, put, call, take, delay, all } from "redux-saga/effects";
import { LOAD_OPEN_LEAGUES, LOAD_MY_LEAGUES, ASSIGN_PLAYER_TO_LEAGUE, LOAD_LEAGUE_DETAILS, LOAD_MY_PRIVATE_LEAGUES, ADDITIONAL_DATA_ACQUIRED, PRICING_ACCEPTED, LOAD_MY_LEAGUES_WITH_PAYMENT, LOAD_OPPONENTS_PLAYED_WITH } from "./actionTypes";
import { loadLeagueDetails as loadLeagueDetailsAction, loadMyLeagues as loadMyLeaguesAction, loadOpenLeagues as loadOpenLeaguesAction, loadOpenLeaguesSuccess, loadOpenLeaguesFailed, loadMyLeaguesSuccess, loadMyLeaguesFailed, LoadMyLeagues, AssignPlayerToLeague, assignPlayerToLeagueSuccess, assignPlayerToLeagueFailed, LoadLeagueDetails, loadLeagueDetailsSuccess, loadLeagueDetailsFailed, loadMyPrivateLeaguesFailed, loadMyPrivateLeaguesSuccess, showAdditionalDataDialog, showPricingDataDialog, LoadMyLeaguesWithPayment, loadMyLeaguesWithPaymentSuccess, loadMyLeaguesWithPayment, LoadOpenLeagues, LoadOpponentsPlayedWith, loadOpponentsPlayedWithSuccess, loadOpponentsPlayedWithFailed } from "./actions";
import { loadOpenLeagues, loadMyLeagues, assignPlayerToLeagueApi, loadLeagueDetailsApi, loadMyPrivateLeagues, loadMyLeaguesWithPaymentStatusApi, loadOpponentsPlayedWithApi } from './api';
import { createPlayer, loadUserPlayer } from "../players/actions";
import { CREATE_PLAYER_SUCCESS } from "../players/actionTypes";
import { createPaymentApi } from "../payments/api";
import { PaymentNeeded } from "../payments/models";
import ReactGA from 'react-ga';
import { getConfig } from "../../config/configuration";
import { LeagueWithPaymentStatus } from "./models";

function* loadLeagues({ payload }: LoadOpenLeagues) {
    try {
        const leaguesData = yield call(loadOpenLeagues, payload);
        yield put(loadOpenLeaguesSuccess(leaguesData));
    } catch(e) {
        yield put(loadOpenLeaguesFailed(e.message))
    }
}

function* loadMyLeaguesStep(a: LoadMyLeagues) {
    try {

        const token = a.payload;
        const config = yield call(getConfig);

        const callables = (config.seasons as string[]).map(x => call(loadMyLeagues, token, x));

        const data: string[][] = yield all(callables);

        const leaguesIds = data.reduce((prev, curr) => [...prev, ...curr], []);

        yield put(loadMyLeaguesSuccess(leaguesIds));
    } catch(e) {
        yield put(loadMyLeaguesFailed(e.message));
    }
}

function* assignPlayerToLeague(a: AssignPlayerToLeague) {
    try {

        yield put(showAdditionalDataDialog());
        const { payload } = yield take(ADDITIONAL_DATA_ACQUIRED);
        const { tocApproved, promoCode, referral, secondaryPlayerName, secondaryPlayerSurname } = payload;

        if(!tocApproved) {
            return;
        }

        ReactGA.event({ action: 'TOC approved', category: 'League' });

        yield put(showPricingDataDialog(promoCode));
        const pricingAccepted = yield take(PRICING_ACCEPTED);
        if(!pricingAccepted.payload)
            return;

        ReactGA.event({ action: 'Pricing accepted', category: 'League' });
        
        if(!a.payload.player.id) { // player is about to be created
            yield put(createPlayer({ player: a.payload.player, token: a.payload.token }));
            yield take(CREATE_PLAYER_SUCCESS);

            ReactGA.event({ action: 'Player created', category: 'League' });
        }

        yield call(assignPlayerToLeagueApi, a.payload.token, a.payload.league, referral, promoCode, secondaryPlayerName, secondaryPlayerSurname);
        const response:PaymentNeeded = yield call(createPaymentApi, a.payload.league.id, a.payload.league.year, promoCode, a.payload.token);
        if(response.paymentLink) {
            ReactGA.event({ action: 'Player assigned to league - redirect to payment', category: 'League' });
            window.localStorage.removeItem("referral");
            window.location.assign(response.paymentLink);
        }
        yield put(assignPlayerToLeagueSuccess());
        yield put(loadOpenLeaguesAction(undefined));
        yield put(loadUserPlayer(a.payload.token));
        yield put(loadMyLeaguesAction(a.payload.token));
        yield put(loadLeagueDetailsAction({ id: a.payload.league.id, year: a.payload.league.year }));
        
    } catch(e) {
        yield put(assignPlayerToLeagueFailed(e.message));
    }
}

function* loadLeagueDetails({ payload }: LoadLeagueDetails) {
    try {

        const { year, id} = payload;
        const leagueData = yield call(loadLeagueDetailsApi, year, id);
        yield put(loadLeagueDetailsSuccess(leagueData));
    } catch(e) {
        yield put(loadLeagueDetailsFailed(e.message));
    }
}

function* loadMyPrivateLeaguesStep({ payload } : LoadMyLeagues) {
    try {
        const token = payload;
        const leaguesData = yield call(loadMyPrivateLeagues, token);
        yield put(loadMyPrivateLeaguesSuccess(leaguesData));
    } catch(e) {
        yield put(loadMyPrivateLeaguesFailed(e.message));
    }
}

function* loadMyLeaguesWithPaymentStep({ payload } : LoadMyLeaguesWithPayment) {
    try {
        const { token, year } = payload;

        if(!year) {
            const config = yield call(getConfig);

            const seasons = config.matchesSeasons as string[];

            let leagues: LeagueWithPaymentStatus[] = [];

            for(let i = 0; i < seasons.length; i++) {
                const season = seasons[i];

                const data: LeagueWithPaymentStatus[] = yield call(loadMyLeaguesWithPaymentStatusApi, token, season);

                leagues.push(...data);
            }

            yield put(loadMyLeaguesWithPaymentSuccess(leagues));
        } else {
            const data = yield call(loadMyLeaguesWithPaymentStatusApi, token, year);
            yield put(loadMyLeaguesWithPaymentSuccess(data));
        }

        yield delay(10000);
        yield put(loadMyLeaguesWithPayment(payload));
    } catch(e) {
        
    } 
}

function* loadOpponentsPlayedWithStep({ payload }: LoadOpponentsPlayedWith) {
    try {
        const { token, year, id } = payload;

        const opponentsPlayedWith = yield call(loadOpponentsPlayedWithApi, token, year, id);

        yield put(loadOpponentsPlayedWithSuccess(opponentsPlayedWith));
    } catch(e) {
        yield put(loadOpponentsPlayedWithFailed(e.message));
    }
}

export function* loadLeaguesSaga() {
    yield takeEvery(LOAD_OPEN_LEAGUES, loadLeagues);
}

export function* loadMyLeaguesSaga() {
    yield takeEvery(LOAD_MY_LEAGUES, loadMyLeaguesStep);
}

export function* assignPlayerToLeagueSaga() {
    yield takeEvery(ASSIGN_PLAYER_TO_LEAGUE, assignPlayerToLeague);
}

export function* loadLeagueDetailsSaga() {
    yield takeEvery(LOAD_LEAGUE_DETAILS, loadLeagueDetails);
}

export function* loadMyPrivateLeaguesSaga() {
    yield takeEvery(LOAD_MY_PRIVATE_LEAGUES, loadMyPrivateLeaguesStep);
}

export function* loadMyLeaguesWithPaymentSaga() {
    yield takeEvery(LOAD_MY_LEAGUES_WITH_PAYMENT, loadMyLeaguesWithPaymentStep);
}

export function* loadOpponentsPlayedWithSaga() {
    yield takeEvery(LOAD_OPPONENTS_PLAYED_WITH, loadOpponentsPlayedWithStep);
}