import React from "react";
import { Stepper, Step, StepLabel, Grid, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { Product, ProductItem, Basket, Spot, OrderSubmissionState } from "../../store/shop/models";
import ProductsList from "./ProductsList";
import SpotPlace from "./SpotPlace";
import ShoppingSummary from "./ShoppingSummary";
import ShoppingSubmissionState from "./ShoppingSubmissionState";

const constructStepContent = (
  activeStep: number,
  products: Product[],
  productAdded: (p: ProductItem) => void,
  basket: Basket,
  pointsBalance: number,
  spots: Spot[],
  spotSelected: (s: Spot) => void,
  selectedSpot: Spot | null,
  submissionState: OrderSubmissionState
) => {
  switch (activeStep) {
    case 0: {
      return (
        <ProductsList
          products={products}
          productAdded={(p) => {
            productAdded({ ...p, count: 1 });
          }}
          productRemoved={() => {}}
          basket={basket}
          pointsBalance={pointsBalance}
        />
      );
    }
    case 1: {
      return (
        <SpotPlace
          spots={spots}
          spotSelected={spotSelected}
          selectedSpot={selectedSpot}
        ></SpotPlace>
      );
    }
    case 2: {
      return (
        <ShoppingSummary
          basket={basket}
          spot={selectedSpot!!}
        ></ShoppingSummary>
      );
    }
    case 3: {
      return (<ShoppingSubmissionState state={submissionState}>

      </ShoppingSubmissionState>);
    }
    default: {
      return <></>;
    }
  }
};

interface ShoppingProcessProps {
  products: Product[];
  productAdded: (p: ProductItem) => void;
  basket: Basket;
  pointsBalance: number;
  spots: Spot[];
  spotSelected: (s: Spot) => void;
  selectedSpot: Spot | null;
  onCreateOrderRequested: (b: Basket, s: Spot) => void;
  submissionState: OrderSubmissionState;
}

const ShoppingProcess: React.FC<ShoppingProcessProps> = ({
  products,
  productAdded,
  basket,
  pointsBalance,
  spots,
  spotSelected,
  selectedSpot,
  onCreateOrderRequested,
  submissionState
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Stepper activeStep={activeStep} orientation={matches ? "horizontal" : "vertical"}>
        <Step>
          <StepLabel>Produkty</StepLabel>
        </Step>
        <Step>
          <StepLabel>Odbiór</StepLabel>
        </Step>
        <Step>
          <StepLabel>Podsumowanie</StepLabel>
        </Step>
        <Step>
          <StepLabel>Zakończenie</StepLabel>
        </Step>
      </Stepper>
      <div>
        {constructStepContent(
          activeStep,
          products,
          productAdded,
          basket,
          pointsBalance,
          spots,
          spotSelected,
          selectedSpot,
          submissionState
        )}
        <Grid container justify="flex-end">
          <Grid item>
            {activeStep !== 0 && activeStep !== 3 ? (
              <Button onClick={() => setActiveStep(activeStep - 1)}>
                Wstecz
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            {basket.products && basket.products.length > 0 && activeStep < 2 ? (
              <Button
                color="primary"
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Dalej
              </Button>
            ) : (
              <></>
            )}
            {activeStep == 2 && selectedSpot ? (
              <Button
                color="primary"
                onClick={() => {
                  onCreateOrderRequested(basket, selectedSpot);
                  setActiveStep(activeStep + 1);
                }}
              >
                Zakończ
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ShoppingProcess;
