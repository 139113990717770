import React from "react";
import { TournamentHeader } from "../../store/tournaments/models";
import TournamentHeaderComponent from "./TournamentHeader";


interface ITournamentListProps {
    tournaments: TournamentHeader[];
}

const TournamentsList: React.FC<ITournamentListProps> = ({ tournaments }) => {
    return (<> {tournaments.sort((l1, l2) => (l2.startDate as any) - (l1.startDate as any)).map(l => (<TournamentHeaderComponent tournament={l} key={l.id}></TournamentHeaderComponent>))} </>)
};

export default TournamentsList;