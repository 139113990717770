import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Data } from "../../framework/data";
import { loadRanking } from "../../store/elo/actions";
import { PlayerRank } from "../../store/elo/model";
import { AppState } from "../../store/reducer";
import { CircularProgress } from "@material-ui/core";
import RankingTable from "./RankingTable";

interface RankingPageProps {
    ranking: Data<PlayerRank[]>;
    loadRanking: () => void;   
}

const RankingPage: React.FC<RankingPageProps> = ({ ranking, loadRanking }) => {

    React.useEffect(() => {
        loadRanking();
    }, []);

    switch(ranking.type) {
        case "LOADING": return (<div style={{ width: '64px', height: '64px', marginLeft: 'auto', marginRight: 'auto'}}><CircularProgress /></div>);
        case "LOADED": return (<RankingTable ranking={ranking.value}></RankingTable>);
        default: return (<></>);
    }
};

const mapStateToProps = (state: AppState) => {
    return {
        ranking: state.elo.ranking
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadRanking: () => dispatch(loadRanking())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingPage);