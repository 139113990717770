import React from "react";
import { PriceBudget } from "../../store/leagues/models";
import { Typography } from "@material-ui/core";
import { sortBy, map } from "ramda";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

interface PricesListProps {
    prices: PriceBudget[]
}

const PricesList: React.FC<PricesListProps> = ({ prices }) => {

    const toRender = map(p => (<Typography key={p.sequenceNo} variant="body2"><EmojiEventsIcon color="secondary" fontSize="small" style={{ verticalAlign: 'text-bottom' }} /> { p.description } </Typography>), sortBy(x => x.sequenceNo, prices)) 
    return (
        <>{toRender}</>
    );
}

export default PricesList;