import { Data, NOT_STARTED, LOADING, fail, dataOf } from "../../framework/data";
import { PlayerMatch } from "../matches/models";
import { Stat } from "./model";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    matches: Data<PlayerMatch[]>;
    stats: Data<Stat[]>;    
}

function reduceMatches(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_PLAYER_MATCHES": return LOADING;
        case "LOAD_PLAYER_MATCHES_FAILED": return fail(action.error);
        case "LOAD_PLAYER_MATCHES_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceStats(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_PLAYER_STATS": return LOADING;
        case "LOAD_PLAYER_STATS_FAILED": return fail(action.error);
        case "LOAD_PLAYER_STATS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({ matches: reduceMatches, stats: reduceStats });