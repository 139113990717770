import { createSelector, Selector } from "reselect";
import { AppState } from "../reducer";
import { dataOf, combineNotLoaded, Data } from "../../framework/data";
import { PlayerMatch } from "../matches/models";
import { PlayerDescriptor } from "../leagues/models";
import { groupBy, indexBy } from "ramda";
import { PlayersMatchesInLeague, Stat } from "./model";

export const moduleState = (s: AppState) => s.stats;

export const playerMatches = createSelector(moduleState, (s) => s.matches);
export const playerStats = createSelector(moduleState, (s) => s.stats);

export const matchesWon = createSelector(playerStats, (s) => {
    if (s.type === "LOADED") {
        console.log(s);
        return dataOf(s.value.reduce((acc, val) => acc + val.score.matchesWon, 0));
    } else {
        return s;
    }
});

export const allMatches = createSelector(playerStats, (s) => {
    if (s.type === "LOADED") {
        return dataOf(s.value.reduce((acc, val) => acc + val.score.matchesPlayed, 0));
    } else {
        return s;
    }
});

/// To do frameworka
const createDataSelector = <X, Y, Z>(x: Selector<AppState, Data<X>>, y: Selector<AppState, Data<Y>>, c: (xval: X, yval: Y) => Z) => createSelector(x, y, (a, b) => {
    if (a.type === "LOADED" && b.type === "LOADED") {
        return dataOf(c(a.value, b.value));
    } else {
        return combineNotLoaded(a, b);
    }
});

/// To do frameworka
const createSingleDataSelector = <X, Y>(s: Selector<AppState, Data<X>>, c: ((xval: X) => Y)) => createSelector(s, x => {
    if (x.type === "LOADED") {
        return dataOf(c(x.value));
    } else {
        return x;
    }
});

export const winPercentage = createDataSelector(matchesWon, allMatches, (won, all) => (won / all * 100).toFixed(2));
export const statsPerSeasonOrdered = createSingleDataSelector(playerStats, (s) => s.sort((x, y) => y.leagueStartDate.valueOf() - x.leagueStartDate.valueOf()));
export const recentMatches = (x: number) => createSingleDataSelector(playerMatches, (ms) => ms.sort((x, y) => y.date.valueOf() - x.date.valueOf()).slice(0, 3));
export const matchesGrouped = createDataSelector(playerMatches, playerStats, (matches, stats) => {

    const matchesInKnownLeagues = matches.filter(m => stats.find(s => s.leagueId === m.league));
    const matchesGrouped = groupBy<PlayerMatch>(m => m.league, matchesInKnownLeagues);
    const leagues = indexBy<Stat>(s => s.leagueId, stats);
    const x = Object.keys(matchesGrouped).map<PlayersMatchesInLeague>(leagueId => ({ leagueId, leagueName: leagues[leagueId].leagueName, matches: matchesGrouped[leagueId] }));
    const y = indexBy<PlayersMatchesInLeague>(s => s.leagueId, x);

    return y;
});

export const mostFrequentClubs = createSingleDataSelector(playerMatches, (ms) => 
    Object.entries(
        ms
        .filter((x) => x.club.name !== "Inny klub")
        .reduce((acc: { [s: string]: number }, val) =>
            !!acc[val.club.name] ?
                ({ ...acc, [val.club.name]: <number>acc[val.club.name] + 1 }) :
                ({ ...acc, [val.club.name]: 1 })
            , {}))
        .sort(([_, c1], [__, c2]) => c2 - c1)
        .map(([n1, c1]) => ({ name: n1, count: c1}) )
        .slice(0, 3)
)

const playerFromMatch = (m: PlayerMatch): PlayerDescriptor|undefined => {
    const playerId = m.playerId;

    if(m.firstPlayer.id === playerId) {
        return m.firstPlayer;
    } else if(m.secondPlayer.id === playerId) {
        return m.secondPlayer;
    } else {
        return undefined;
    }
}

export const playerDataFromMatches = createSingleDataSelector(playerMatches, (matches) => matches.length === 0 ? undefined : playerFromMatch(matches[0]));
