import { takeEvery, call, put } from "redux-saga/effects";
import { CREATE_MATCH_OFFER, GET_MATCH_OFFERS, CANCEL_MATCH_OFFER, ACCEPT_MATCH_OFFER } from "./actionTypes";
import { CreateMatchOffer, createMatchOfferSuccess, createMatchOfferFailed, createChangeFormState, GetMatchOffers, getMatchOffersFailed, getMatchOffersSuccess, getMatchOffers, CancelMatchOffer, AcceptMatchOffer, cancelMatchOfferFailed, cancelMatchOfferSuccess, acceptMatchOfferFailed, acceptMatchOfferSuccess } from "./actions";
import MatchOffersApi, { ApiResponses } from "./api";
import { SUBMITTING, SUBMITTED, failed, MatchOfferHeader } from "./models";

function* createMatchOfferStep({ payload }: CreateMatchOffer) {

    yield put(createChangeFormState(SUBMITTING));
    const response: ApiResponses<{}> = yield call(MatchOffersApi.createMatchOffer, payload);

    switch (response.type) {
        case "INVALID_REQUEST": {
            yield put(createChangeFormState(failed("", response.errors)));
            break;
        }
        case "SERVER_ERROR":
        case "UNAUTH": {
            yield put(createMatchOfferFailed(response.type));
            yield put(createChangeFormState(failed("", {})));
            break;
        }
        case "SUCCESS": {
            yield put(createMatchOfferSuccess());
            yield put(createChangeFormState(SUBMITTED));
            yield put(getMatchOffers({ leagueId: payload.leagueId, leagueYear: payload.leagueYear }));

            break;
        }

    }
}

function* getMatchOffersStep({ payload }: GetMatchOffers) {

    const response: ApiResponses<MatchOfferHeader[]> = yield call(MatchOffersApi.getMatchOffers, payload);

    switch (response.type) {
        case "INVALID_REQUEST":
        case "SERVER_ERROR":
        case "UNAUTH": {
            yield put(getMatchOffersFailed(response.type));
            break;
        }
        case "SUCCESS": {
            yield put(getMatchOffersSuccess(response.payload));
            break;
        }
    }
}

function* cancelMatchOfferStep({ payload }: CancelMatchOffer) {
    const response: ApiResponses<{}> = yield call(MatchOffersApi.cancelMatchOffer, payload);

    switch (response.type) {
        case "INVALID_REQUEST": {
            yield put(cancelMatchOfferFailed(response.type, { warning: JSON.stringify(response.errors) }));
            break;
        }
        case "SERVER_ERROR":
        case "UNAUTH": {
            yield put(cancelMatchOfferFailed(response.type));
            break;
        }
        case "SUCCESS": {
            yield put(cancelMatchOfferSuccess());
            break;
        }
    }

    yield put(getMatchOffers({
        leagueId: payload.leagueId,
        leagueYear: payload.leagueYear,
    }));
}

function* acceptMatchOfferStep({ payload }: AcceptMatchOffer) {
    const response: ApiResponses<{}> = yield call(MatchOffersApi.acceptMatchOffer, payload);

    switch (response.type) {
        case "INVALID_REQUEST": {
            yield put(acceptMatchOfferFailed(response.errors));
            break;
        }
        case "SERVER_ERROR":
        case "UNAUTH": {
            yield put(acceptMatchOfferFailed(response.type));
            break;
        }
        case "SUCCESS": {
            yield put(acceptMatchOfferSuccess());
            window.location.assign(`/#/leagues/${payload.leagueYear}/${payload.leagueId}/mine-matches`);
            break;
        }
    }

    put(getMatchOffers({
        leagueId: payload.leagueId,
        leagueYear: payload.leagueYear,
    }));
}

export function* cancelMatchOfferSaga() {
    yield takeEvery(CANCEL_MATCH_OFFER, cancelMatchOfferStep);
}

export function* acceptMatchOfferSaga() {
    yield takeEvery(ACCEPT_MATCH_OFFER, acceptMatchOfferStep);
}

export function* createMatchOfferSaga() {
    yield takeEvery(CREATE_MATCH_OFFER, createMatchOfferStep);
}

export function* getMatchOffersSaga() {
    yield takeEvery(GET_MATCH_OFFERS, getMatchOffersStep)
}