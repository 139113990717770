import React from "react";
import { LeagueHeader } from "../../store/leagues/models";
import { Data } from "../../framework/data";
import { getConfig } from "../../config/configuration";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { loadOpenLeagues } from "../../store/leagues/actions";
import { connect } from "react-redux";
import { RegisterToLeague } from "../../store/registration/model";
import { Typography, FormControl, InputLabel, Select, MenuItem, ListSubheader, makeStyles, Theme, createStyles, Paper, Grid, Tooltip, Divider, Button, CircularProgress, TextField } from "@material-ui/core";
import { groupBy, uniq } from "ramda";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { setLeagueToRegister as setLeagueToRegisterAction } from '../../store/registration/actions';
import { Alert } from "@material-ui/lab";

interface LeagueSelectorProps {
    leagues: Data<LeagueHeader[]>
    loadLeagues: (season: string) => void;
    onProceed: () => void;
    leagueToRegister: RegisterToLeague;
    setLeagueToRegister: (x: RegisterToLeague) => void;
    subscribedTo: Data<string[]>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 280,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        selectPanel: {
            borderLeft: 0,
            borderRight: 0,
            margin: '0 -32px',
            padding: '16px 32px',
            backgroundColor: theme.palette.grey[100]
        },
        errorMessage: {
            color: theme.palette.error.main,
            marginLeft: 4,
            marginTop: theme.spacing(2)
        }
    }),
);

const isDoubles = (leagues: LeagueHeader[], id: string) => leagues.find(l => l.id === id)?.isDoubles;

const LeagueSelector: React.FC<LeagueSelectorProps> = ({ leagues, loadLeagues, onProceed, leagueToRegister, setLeagueToRegister, subscribedTo }) => {

    const classes = useStyles();

    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [touched, setTouched] = React.useState<boolean>(false);

    const [selectedCity, setSelectedCity] = React.useState<string>("");

    React.useEffect(() => {
        getConfig().then(c => loadLeagues(c.seasonToRegister));
    }, []);

    const onLeagueChange = (e: React.ChangeEvent<any>) => {

        const season = (leagues.type === "LOADED" ? leagues.value.find(x => x.id === e.target.value) : undefined)?.year;
        const price = (leagues.type === "LOADED" ? leagues.value.find(x => x.id === e.target.value) : undefined)?.basePrice || 0;

        setLeagueToRegister({
            league: {
                id: e.target.value,
                season: season ?? "",
                secondPlayerName: leagueToRegister.league.secondPlayerName,
                secondPlayerSurname: leagueToRegister.league.secondPlayerSurname,
                basePrice: price
            },
            city: ''
        });

        setTouched(true);
    }

    const resetLeague = () => {
        setLeagueToRegister({
            league: {
                id: '',
                season: '',
                secondPlayerName: leagueToRegister.league.secondPlayerName,
                secondPlayerSurname: leagueToRegister.league.secondPlayerSurname,
                basePrice: 1
            },
            city: ''
        });

        setTouched(true);
    }

    const onSecondPlayerNameChange = (x: string) => {
        setLeagueToRegister({
            league: {
                id: leagueToRegister.league.id,
                season: leagueToRegister.league.season,
                secondPlayerName: x,
                secondPlayerSurname: leagueToRegister.league.secondPlayerSurname,
                basePrice: leagueToRegister.league.basePrice
            },
            city: ''
        });
    }

    const onSecondPlayerSurnameChange = (x: string) => {
        setLeagueToRegister({
            league: {
                id: leagueToRegister.league.id,
                season: leagueToRegister.league.season,
                secondPlayerName: leagueToRegister.league.secondPlayerName,
                secondPlayerSurname: x,
                basePrice: leagueToRegister.league.basePrice
            },
            city: ''
        });
    }

    /*const onCityChange = (e: React.ChangeEvent<any>) => {
        setLeagueToRegister({
            league: {
                id: '',
                season: '',
                secondPlayerName: '',
                secondPlayerSurname: '',
                basePrice: 1,
            },
            city: e.target.value
        });

        setTouched(true);
    }*/

    const onSubmit = () => {
        if (leagueToRegister.city || leagueToRegister.league?.id) {

            onProceed();
        } else {
            setIsValid(false);
        }

        setTouched(true);
    }

    const isDisabled = () => {
        if (leagues.type !== "LOADED") return true;
        if (!leagueToRegister.city && !leagueToRegister.league.id) return true;

        if (isDoubles(leagues.value, leagueToRegister.league.id) && !(leagueToRegister.league.secondPlayerName && leagueToRegister.league.secondPlayerSurname)) return true;

        return false;
    }


    return <>
        <Paper variant="outlined" className={classes.selectPanel} square>
            <Typography variant="subtitle1" style={{ marginLeft: 8 }}>Wybierz ligę</Typography>
            {
                leagues.type === "LOADED" && subscribedTo.type === "LOADED" ? (
                    <>
                        <Grid container alignItems="center">
                            <Grid item>
                                <FormControl className={classes.formControl} margin="normal" variant="outlined">
                                    <InputLabel htmlFor="league-cities-select">Miasto</InputLabel>
                                    <Select defaultValue="" id="league-cities-select" labelWidth={48} value={selectedCity} onChange={(e) => { setSelectedCity(e.target.value as string); resetLeague()}}>
                                        {
                                            uniq(Object.keys(groupBy(x => x.city, leagues.value))).map((city) => 
                                                (<MenuItem value={city} key={city}>{city}</MenuItem>)
                                            )
                                            
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Wybierz miasto, w których znajduje się liga, w której chcesz grać.">
                                    <HelpOutlineIcon></HelpOutlineIcon>
                                </Tooltip>
                            </Grid>

                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item>
                                <FormControl className={classes.formControl} margin="normal" variant="outlined">
                                    <InputLabel htmlFor="leagues-select">Liga</InputLabel>
                                    <Select defaultValue="" id="leagues-select" labelWidth={32} value={leagueToRegister.league?.id} onChange={onLeagueChange}>
                                        {
                                            leagues.value.filter(l => !subscribedTo.value.includes(l.id) && l.city === selectedCity).map((l) => 
                                            (<MenuItem value={l.id} key={l.id}>{l.name}</MenuItem>))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Wybierz ligę, w której chcesz grać.">
                                    <HelpOutlineIcon></HelpOutlineIcon>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    </>
                ) : (
                        <div
                            style={{
                                width: "64px",
                                height: "64px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )
            }

        </Paper>
        {

            leagues.type === "LOADED" && isDoubles(leagues.value, leagueToRegister.league.id) ? (<>

                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Alert severity="info" style={{ marginLeft: 8 }}>
                        Wybrana liga jest ligą deblową. Proszę podać imię i nazwisko partnera deblowego.
                    </Alert>

                    <Grid container spacing={3} style={{ marginLeft: -4, marginTop: 8 }}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Imię partnera deblowego"
                                variant="outlined"
                                value={leagueToRegister.league.secondPlayerName}
                                style={{ width: "100%" }}
                                onChange={(e) => onSecondPlayerNameChange(e.target.value)}
                                error={(isDoubles(leagues.value, leagueToRegister.league.id) && !leagueToRegister.league.secondPlayerName)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Nazwisko partnera deblowego"
                                variant="outlined"
                                value={leagueToRegister.league.secondPlayerSurname}
                                style={{ width: "100%" }}
                                onChange={(e) => onSecondPlayerSurnameChange(e.target.value)}
                                error={(isDoubles(leagues.value, leagueToRegister.league.id) && !leagueToRegister.league.secondPlayerSurname)}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </>) : (<></>)}
        {
            !isValid ? (
                <Grid container className={classes.errorMessage} spacing={1}>
                    <Grid item>
                        <ErrorOutlineIcon></ErrorOutlineIcon>
                    </Grid>
                    <Grid item>
                        Wybierz ligę, w której chcesz grać
                    </Grid>
                </Grid>
            ) : (
                    <></>
                )
        }


        <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
        <Button color="primary" variant="contained" onClick={onSubmit} disabled={isDisabled()}>
            Dalej
        </Button>
    </>;
}

const mapStateToProps = (state: AppState) => ({
    leagues: state.leagues.items,
    leagueToRegister: state.registration.leagueSelection,
    subscribedTo: state.leagues.subscribedTo
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadLeagues: (season: string) => dispatch(loadOpenLeagues(season)),
    setLeagueToRegister: (x: RegisterToLeague) => dispatch(setLeagueToRegisterAction(x))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueSelector);