import { Data, dataOf, fail, LOADING, NOT_STARTED } from "../../framework/data";
import { Actions } from "./actions";
import { MatchHeader } from "../matches/models";
import { combineReducers } from "redux";

export interface State {
    myUnconfirmedMatches: Data<MatchHeader[]>;
    myConfirmedMatches: Data<MatchHeader[]>;
}

function reduceMyUnconfirmedMatches(state = NOT_STARTED, action: Actions): Data<MatchHeader[]> {
    switch(action.type) {
        case "LOAD_MY_UNCONFIRMED_MATCHES": return LOADING;
        case "LOAD_MY_UNCONFIRMED_MATCHES_FAILED": return fail(action.error);
        case "LOAD_MY_UNCONFIRMED_MATCHES_SUCCESS": return dataOf(action.payload);
        case "CANCEL_MATCH": return LOADING;
        case "CONFIRM_MATCH": return LOADING;
        default: return state;
    }
}

function reduceMyConfirmedMatches(state = NOT_STARTED, action: Actions): Data<MatchHeader[]> {
    switch(action.type) {
        case "LOAD_MY_CONFIRMED_MATCHES": return LOADING;
        case "LOAD_MY_CONFIRMED_MATCHES_FAILED": return fail(action.error);
        case "LOAD_MY_CONFIRMED_MATCHES_SUCCESS": return dataOf(action.payload);
        case "CANCEL_MATCH": return LOADING;
        case "CONFIRM_MATCH": return LOADING;
        case "SUBMIT_MATCH_RESULT": return LOADING;
        default: return state;
    }
}

export default combineReducers({
    myUnconfirmedMatches: reduceMyUnconfirmedMatches,
    myConfirmedMatches: reduceMyConfirmedMatches
});