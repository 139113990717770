import { ActionWithError, ActionWithErrorCreator, ActionWithPayload, ActionWithPayloadCreator } from "../../framework/action";
import { CancelMatch, ConfirmMatch, SubmitMatchResult } from "../matches/actions";
import { MatchHeader } from "../matches/models";
import { LOAD_MY_UNCONFIRMED_MATCHES, LOAD_MY_CONFIRMED_MATCHES, LOAD_MY_CONFIRMED_MATCHES_FAILED, LOAD_MY_CONFIRMED_MATCHES_SUCCESS, LOAD_MY_UNCONFIRMED_MATCHES_FAILED, LOAD_MY_UNCONFIRMED_MATCHES_SUCCESS } from "./actionTypes";

export interface LoadMyUnconfirmedMatches extends ActionWithPayload<string> {
    type: typeof LOAD_MY_UNCONFIRMED_MATCHES;
}

export interface LoadMyUnconfirmedMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof LOAD_MY_UNCONFIRMED_MATCHES_SUCCESS;
}

export interface LoadMyUnconfirmedMatchesFailed extends ActionWithError {
    type: typeof LOAD_MY_UNCONFIRMED_MATCHES_FAILED;
}

export interface LoadMyConfirmedMatches extends ActionWithPayload<string> {
    type: typeof LOAD_MY_CONFIRMED_MATCHES;
}

export interface LoadMyConfirmedMatchesSuccess extends ActionWithPayload<MatchHeader[]> {
    type: typeof LOAD_MY_CONFIRMED_MATCHES_SUCCESS;
}

export interface LoadMyConfirmedMatchesFailed extends ActionWithError {
    type: typeof LOAD_MY_CONFIRMED_MATCHES_FAILED;
}

export type Actions =
 | LoadMyConfirmedMatches
 | LoadMyConfirmedMatchesFailed
 | LoadMyConfirmedMatchesSuccess
 | LoadMyUnconfirmedMatches
 | LoadMyUnconfirmedMatchesFailed
 | LoadMyUnconfirmedMatchesSuccess
 | SubmitMatchResult
 | CancelMatch
 | ConfirmMatch

export const loadMyUnconfirmedMatches: ActionWithPayloadCreator<string> = (token: string) => ({ type: LOAD_MY_UNCONFIRMED_MATCHES, payload: token });
export const loadMyUnconfirmedMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (payload: MatchHeader[]) => ({ type: LOAD_MY_UNCONFIRMED_MATCHES_SUCCESS, payload });
export const loadMyUnconfirmedMatchesFailed: ActionWithErrorCreator = (error: string) => ({ type: LOAD_MY_UNCONFIRMED_MATCHES_FAILED, error, description: 'Nie udało nam się załadować Twoich niepotwierdzonych meczów!'  })

export const loadMyConfirmedMatches: ActionWithPayloadCreator<string> = (token: string) => ({ type: LOAD_MY_CONFIRMED_MATCHES, payload: token });
export const loadMyConfirmedMatchesSuccess: ActionWithPayloadCreator<MatchHeader[]> = (payload: MatchHeader[]) => ({ type: LOAD_MY_CONFIRMED_MATCHES_SUCCESS, payload });
export const loadMyConfirmedMatchesFailed: ActionWithErrorCreator = (error: string) => ({ type: LOAD_MY_CONFIRMED_MATCHES_FAILED, error, description: 'Nie udało nam się załadować Twoich potwierdzonych meczów!' })