import { RegisterToLeague, PlayerType, PriceInfo } from "./model";
import { Actions } from "./actions";
import { Player } from "../players/models";
import { Data, NOT_STARTED, dataOf, LOADING, fail } from "../../framework/data";
import { RegisterToTournament } from "../tournaments/models";
import { RegisterToTraining } from "../trainings/models";

export interface State {
    leagueSelection: RegisterToLeague;
    tournamentSelection: RegisterToTournament;
    trainingSelection: RegisterToTraining;
    playerType: PlayerType;
    updatedPlayer?: Player;
    phoneVerified: boolean | undefined;
    isVerifying: boolean;
    priceInfo: Data<PriceInfo>;
    registrationMessage?: string;
    sendingPhoneAuthMessage: Data<string>;
    isUpgrading: boolean;
}

const DEFAULT_STATE: State = {
    leagueSelection: {
        city: '',
        league: {
            id: '',
            season: '',
            secondPlayerName: '',
            secondPlayerSurname: '',
            basePrice: 1
        }
    },
    tournamentSelection: {
        tournament: {
            id: '',
            season: '',
        },
        secondPlayerLastName: '',
        secondPlayerName: '',
        secondPlayerNeeded: false
    },
    trainingSelection: {
        training: {
            id: '',
            season: ''
        }
    },
    playerType: PlayerType.REGULAR,
    updatedPlayer: undefined,
    phoneVerified: undefined,
    isVerifying: false,
    priceInfo: NOT_STARTED,
    registrationMessage: undefined,
    sendingPhoneAuthMessage: NOT_STARTED,
    isUpgrading: false,
};

const handlePlayerTypeBasedOnLeagueSelection = (leagueSelection: RegisterToLeague, currentPlayerType: PlayerType): PlayerType => {
    if(currentPlayerType === PlayerType.REGULAR && leagueSelection.city) {
        return PlayerType.TRIAL;
    }
    return currentPlayerType;
}

export default function reducer(state: State = DEFAULT_STATE, action: Actions): State {
    switch(action.type) {
        case "SET_LEAGUE_TO_REGISTER": {
            return { ...state, leagueSelection: action.payload, playerType: handlePlayerTypeBasedOnLeagueSelection(action.payload, state.playerType) };
        }
        case "SET_TOURNAMENT_TO_REGISTER": {
            return { ...state, tournamentSelection: action.payload};
        }
        case "SET_TRAINING_TO_REGISTER": {
            return { ...state, trainingSelection: action.payload};
        }
        case "SET_REGISTRATION_PLAYER_TYPE": {
            return { ...state, playerType: action.payload };
        }
        case "SET_UPDATED_PLAYER": {
            return { ...state, updatedPlayer: action.payload };
        }
        case "VERIFY_SMS_CODE": {
            return { ...state, isVerifying: true };
        }
        case "VERIFY_SMS_CODE_SUCCESS": {
            return { ...state, phoneVerified: true, isVerifying: false };
        }
        case "VERIFY_SMS_CODE_FAILED": {
            return { ...state, phoneVerified: false, isVerifying: false }
        }
        case "GET_PRICE_INFO_FOR_LEAGUE": {
            return { ...state, priceInfo: LOADING }
        }
        case "GET_PRICE_INFO_FOR_LEAGUE_FAILED": {
            return { ...state, priceInfo: fail(action.description) }
        }
        case "GET_PRICE_INFO_FOR_LEAGUE_SUCCESS": {
            return { ...state, priceInfo: dataOf(action.payload) }
        }
        case "SEND_REGISTRATION_MESSAGE": {
            return { ...state, registrationMessage: action.payload }
        }
        case "SEND_SMS_VERIFICATION_CODE_SUCCESS": {
            return { ...state, sendingPhoneAuthMessage: dataOf("") }
        }
        case "SEND_SMS_VERIFICATION_CODE_FAILED" : {
            return { ...state, sendingPhoneAuthMessage: dataOf(action.description) }
        }
        case "SET_REGISTRATION_FOR_PLAYER_TYPE_UPGRADE": {
            return {
                ...state, updatedPlayer: action.payload.player, leagueSelection: {
                    league: {
                        id: action.payload.leagueId,
                        season: action.payload.leagueYear,
                        secondPlayerName: '',
                        secondPlayerSurname: '',
                        basePrice: 1
                    },
                    city: ''
                },
                playerType: PlayerType.REGULAR,
                isUpgrading: true
            }
        }
        default: {
            return state;
        }
    }
};