import { Action } from "redux"

const logger = (store:any) => (next:(a: Action) => any) => (action:Action) => {
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
  }

export default logger;