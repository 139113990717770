export const LOAD_USER_PLAYER = "LOAD_USERS_PLAYER";
export const LOAD_USER_PLAYER_SUCCESS = "LOAD_USER_PLAYER_SUCCESS";
export const LOAD_USER_PLAYER_FAILED = "LOAD_USER_PLAYER_FAILED";

export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_FAILED = "CREATE_PLAYER_FAILED";

export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_FAILED = "UPDATE_PLAYER_FAILED"

export const SAVE_PLAYER = "SAVE_PLAYER";

export const LOAD_ALL_PLAYERS = "LOAD_ALL_PLAYERS";
export const LOAD_ALL_PLAYERS_SUCCESS = "LOAD_ALL_PLAYERS_SUCCESS";