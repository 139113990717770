import { Action, ActionCreator } from "redux";
import { LOAD_PRODUCTS, LOAD_PRODUCTS_FAILED, LOAD_PRODUCTS_SUCCESS, ADD_PRODUCT_TO_BASKET, REMOVE_PRODUCT_FROM_BASKET, LOAD_SPOTS, LOAD_SPOTS_FAILED, LOAD_SPOTS_SUCCESS, SET_SPOT, CREATE_SHOPPING_ORDER, CREATE_SHOPPING_ORDER_FAILED, CREATE_SHOPPING_ORDER_SUCCESS, LOAD_MY_ORDERS, LOAD_MY_ORDERS_SUCCESS, LOAD_MY_ORDERS_FAILED } from "./actionTypes";
import { ActionWithError, ActionWithPayload, ActionWithErrorCreator, ActionWithPayloadCreator } from "../../framework/action";
import { Product, ProductItem, Spot, Basket, Order } from "./models";

export interface LoadProducts extends Action {
    type: typeof LOAD_PRODUCTS;
}

export interface LoadProductsFailed extends ActionWithError {
    type: typeof LOAD_PRODUCTS_FAILED;
}

export interface LoadProductsSuccess extends ActionWithPayload<Product[]> {
    type: typeof LOAD_PRODUCTS_SUCCESS;
}

export interface AddProductToBasket extends ActionWithPayload<ProductItem> {
    type: typeof ADD_PRODUCT_TO_BASKET;
}

export interface RemoveProductFromBasket extends ActionWithPayload<string> {
    type: typeof REMOVE_PRODUCT_FROM_BASKET;
}

export interface LoadSpots extends Action {
    type: typeof LOAD_SPOTS;
}

export interface LoadSpotsFailed extends ActionWithError {
    type: typeof LOAD_SPOTS_FAILED;
}

export interface LoadSpotsSuccess extends ActionWithPayload<Spot[]> {
    type: typeof LOAD_SPOTS_SUCCESS;
}

export interface SetSpot extends ActionWithPayload<Spot> {
    type: typeof SET_SPOT;
}

export interface ShoppingOrderPayload {
    basket: Basket;
    selectedSpot: Spot;
    token: string;
}

export interface CreateShoppingOrder extends ActionWithPayload<ShoppingOrderPayload> {
    type: typeof CREATE_SHOPPING_ORDER;
}

export interface CreateShoppingOrderSuccess extends Action {
    type: typeof CREATE_SHOPPING_ORDER_SUCCESS;
}

export interface CreateShoppingOrderFailed extends ActionWithError {
    type: typeof CREATE_SHOPPING_ORDER_FAILED;
}

export interface LoadMyOrders extends ActionWithPayload<string> {
    type: typeof LOAD_MY_ORDERS;
}

export interface LoadMyOrdersSuccess extends ActionWithPayload<Order[]> {
    type: typeof LOAD_MY_ORDERS_SUCCESS;
}

export interface LoadMyOrdersFailed extends ActionWithError {
    type: typeof  LOAD_MY_ORDERS_FAILED;
}

export const loadProducts: ActionCreator<LoadProducts> = () => ({ type: LOAD_PRODUCTS });
export const loadProductsFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_PRODUCTS_FAILED, error, description: "Nie udało nam się załadować produktów w SmartShop, spróbuj ponownie!" });
export const loadProductsSuccess: ActionWithPayloadCreator<Product[]> = (payload: Product[]) => ({ type: LOAD_PRODUCTS_SUCCESS, payload });
export const addProductToBasket: ActionWithPayloadCreator<ProductItem> = (payload: ProductItem) => ({ type: ADD_PRODUCT_TO_BASKET, payload });
export const removeProductFromBasket: ActionWithPayloadCreator<string> = (payload: string) => ({ type: REMOVE_PRODUCT_FROM_BASKET, payload });
export const loadSpots: ActionCreator<LoadSpots> = () => ({ type: LOAD_SPOTS });
export const loadSpotsFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_SPOTS_FAILED, error, description: "Nie udało nam się załadować miejsc odbioru, spróbuj ponownie!" });
export const loadSpotsSuccess: ActionWithPayloadCreator<Spot[]> = (payload: Spot[]) => ({ type: LOAD_SPOTS_SUCCESS, payload });
export const setSpot: ActionWithPayloadCreator<Spot> = (payload: Spot) => ({ type: SET_SPOT, payload });
export const createShoppingOrder: ActionWithPayloadCreator<ShoppingOrderPayload> =  (payload: ShoppingOrderPayload) => ({ type: CREATE_SHOPPING_ORDER, payload });
export const createShoppingOrderSuccess: ActionCreator<CreateShoppingOrderSuccess> = () => ({ type: CREATE_SHOPPING_ORDER_SUCCESS });
export const createShoppingOrderFailed = (error: string, description: string) => ({ type: CREATE_SHOPPING_ORDER_FAILED, error, description })
export const loadMyOrders: ActionWithPayloadCreator<string> = (token: string) => ({ type: LOAD_MY_ORDERS, payload: token });
export const loadMyOrdersSuccess: ActionWithPayloadCreator<Order[]> = (payload: Order[]) => ({type: LOAD_MY_ORDERS_SUCCESS, payload});
export const loadMyOrdersFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_MY_ORDERS_FAILED, error, description: "Nie mogliśmy załadować Twoich wcześniejszych zamówień. Spróbuj ponownie za chwilę!" })

export type Actions =
    | LoadProducts
    | LoadProductsFailed
    | LoadProductsSuccess
    | AddProductToBasket
    | RemoveProductFromBasket
    | LoadSpots
    | LoadSpotsFailed
    | LoadSpotsSuccess
    | SetSpot
    | CreateShoppingOrder
    | CreateShoppingOrderFailed
    | CreateShoppingOrderSuccess
    | LoadMyOrders
    | LoadMyOrdersSuccess
    | LoadMyOrdersFailed;