import { Button, Divider, ExpansionPanel, ExpansionPanelActions, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, CircularProgress, Link } from "@material-ui/core";
import React from "react";
import DateView from "../../framework/components/DateView";
import { MatchOfferHeader } from "../../store/match-offers/models";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { canBeTurnedIntoMatch, canBeCancelled } from "../../store/match-offers/utils";
import { Player } from "../../store/players/models";
import { LeagueWithPlayersICanPlay } from "../../store/leagues/models";
import TimeView from "../../framework/components/TimeView";
import { Club } from "../../store/clubs/models";
import LeaguePlayerView from "../leagues/LeaguePlayerView";
import { dataOf } from "../../framework/data";

interface MatchOfferListItemProps {
    item: MatchOfferHeader;
    player?: Player;
    league?: LeagueWithPlayersICanPlay;
    clubs: Club[];
    cancelMatchOffer: (id: string) => void;
    acceptMatchOffer: (id: string) => void;
}

const MatchOfferListItem: React.FC<MatchOfferListItemProps> = ({ item, player, league, acceptMatchOffer, cancelMatchOffer, clubs }) => {

    return (<ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <PersonIcon color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {item.firstPlayer.name} {item.firstPlayer.surname}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} lg={"auto"}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <TodayIcon color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {
                                    item.startDate.valueOf() === item.endDate.valueOf() ? (
                                        <DateView value={item.startDate}></DateView>
                                    ) : (
                                            <>
                                                <DateView value={item.startDate}></DateView> - <DateView value={item.endDate}></DateView>
                                            </>
                                        )
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} lg={"auto"}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <ScheduleIcon color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {
                                    item.startTime === item.endTime ? (
                                        <TimeView value={item.startDate} />
                                    ) : (
                                            <>{item.startTime} - {item.endTime}</>
                                        )
                                }

                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">
                        Opis
                    </Typography>
                    <Typography variant="body2">
                        {item.description}
                    </Typography>

                </Grid>
                <Grid item>
                    <Divider orientation="vertical"></Divider>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        {item.clubs.length > 1 ? "Kluby" : "Klub"}
                    </Typography>
                    <Typography variant="body2" component="div">
                        <ul>
                            {item.clubs.map(c => <li key={c.id}>{c.name}</li>)}
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider orientation="vertical"></Divider>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        Kontakt
                    </Typography>
                    <Typography variant="body2" component="div">
                        <LeaguePlayerView
                            playerId={item.firstPlayer.id}
                            clubs={dataOf(clubs)}
                            leagueId={item.league}
                            leagueYear={league?.year || ""}
                        ></LeaguePlayerView>
                    </Typography>
                    <Link href={`/#/stats/${btoa(item.firstPlayer.id)}`}>Statystyki zawodnika</Link>
                </Grid>
            </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
            {
                canBeTurnedIntoMatch(item, player, league) ? (
                    <Button color="primary" onClick={() => acceptMatchOffer(item.id)} disabled={item.isLoading}>
                        {
                            item.isLoading ? (
                                <CircularProgress size="24px" />
                            ) : (<>Podejmij wyzwanie</>)
                        }
                    </Button>
                ) : (
                        <></>
                    )
            }
            {
                canBeCancelled(item, player) ? (
                    <Button onClick={() => cancelMatchOffer(item.id)} disabled={item.isLoading}>
                        {item.isLoading ? (
                            <CircularProgress size="24px" />
                        ) : (<>Anuluj</>)
                        }

                    </Button>
                ) : (
                        <></>
                    )
            }

        </ExpansionPanelActions>
    </ExpansionPanel>);

}

export default MatchOfferListItem;