import React from "react";
import { withRouter, match } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IconButton, Tooltip, Button, Popover } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import { useAuth0 } from "@auth0/auth0-react";
import { Data, isLoaded } from "../../../framework/data";
import { TournamentDetails } from "../../../store/tournaments/models";
import { Player } from "../../../store/players/models";
import { Club } from "../../../store/clubs/models";
import { Set, MatchHeader, MatchStatus } from "../../../store/matches/models";
import MatchResultDialog from "../../matches/MatchResultDialog";
import MatchesTable from "../../matches/MatchesTable";
import { currentPlayer } from "../../../store/players/selectors";
import { loadTournamentDetails } from "../../../store/tournaments/actions";
import { getPlayersMatches, getPlayersTournamentMatches, confirmTournamentMatch, cancelTournamentMatch, submitTournamentMatchResult } from "../../../store/matches/actions";
import { selectTournamentDetails } from "../../../store/tournaments/selectors";
import { AppState } from "../../../store/reducer";
import TournamentMatchResultDialog from "./TournamentMatchResultDialog";

interface MyTournamentMatchesPageProps {
  tournament: Data<TournamentDetails>;
  player: Data<Player>;
  loadTournamentDetails: (year: string, id: string, token: string) => void;
  loadPlayersMatches: (
    year: string,
    id: string,
    skip: number,
    take: number,
    token: string
  ) => void;
  match: match<{ year: string; id: string }>;
  matches: Data<MatchHeader[]>;
  confirmMatch: (
    match: MatchHeader,
    tournament: TournamentDetails,
    skip: number,
    take: number,
    token: string
  ) => void;
  cancelMatch: (
    match: MatchHeader,
    tournament: TournamentDetails,
    skip: number,
    take: number,
    token: string
  ) => void;
  submitResult: (
    match: MatchHeader,
    tournament: TournamentDetails,
    sets: Set[],
    walkover: boolean,
    skip: number,
    take: number,
    token: string
  ) => void;
}

const MyMatchesPage: React.FC<MyTournamentMatchesPageProps> = ({
  tournament,
  player,
  loadTournamentDetails,
  match,
  loadPlayersMatches,
  matches,
  confirmMatch,
  cancelMatch,
  submitResult}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = React.useState<number>();
  const [pageSize, setPageSize] = React.useState<number>();

  const [matchResultDialogOpen, setMatchResultDialogOpen] = React.useState<
    boolean
  >(false);
  const [currentMatch, setCurrentMatch] = React.useState<MatchHeader>();

  const handleMatchResult = async (success: boolean, sets: Set[], walkover: boolean) => {
    if (success && tournament.type === "LOADED" && currentMatch) {
      const token = await getAccessTokenSilently();
      submitResult(
        currentMatch,
        tournament.value,
        sets,
        walkover,
        (page || 0) * (pageSize || 25),
        pageSize || 25,
        token
      );
    }
    setMatchResultDialogOpen(false);
  };

  const handleHelpRequested = () => {
    window.location.assign(`mailto:support@smarttennis.pl?subject=Wynik%20meczu%20${currentMatch?.id}&body=Proszę%20podać%20wynik%20meczu`);
  }

  React.useEffect(() => {
    var id = match.params.id;
    var year = match.params.year;

    if (!isLoaded(tournament)) {
      getAccessTokenSilently().then((token: string) => {
        loadTournamentDetails(year, id, token);
      })
    }

    if (tournament.type === "LOADED" && player.type === "LOADED") {
      getAccessTokenSilently().then((token: string) => {
        loadPlayersMatches(tournament.value.year, tournament.value.id, 0, 25, token);
      });
    }
  }, [
    match.params.id,
    match.params.year,
    loadTournamentDetails,
    tournament,
    loadPlayersMatches
  ]);

  return (
    <>
      
      {matches.type === "LOADED" &&
      tournament.type === "LOADED" &&
      player.type === "LOADED" ? (
        <>
        <TournamentMatchResultDialog
        open={matchResultDialogOpen}
        firstPlayer={currentMatch?.firstPlayer}
        secondPlayer={currentMatch?.secondPlayer}
        currentPlayer={player.value}
        onClosed={(success, sets, walkover) => handleMatchResult(success, sets, walkover)}
      ></TournamentMatchResultDialog>
        <MatchesTable
        forSinglePlayerOnly={false}
          matches={matches.value}
          currentPlayer={player.value}
          onLoadData={async (page, pageSize) => {
            const token = await getAccessTokenSilently();
            const skip = page * pageSize;
            const take = pageSize;
            loadPlayersMatches(
              tournament.value.year,
              tournament.value.id,
              skip,
              take,
              token
            );
            setPage(page);
            setPageSize(pageSize);
          }}
          page={page || 0}
          pageSize={pageSize || 25}
          actions={m => {
            const firstAction =
              m.status === MatchStatus.New &&
              m.createdBy &&
              m.createdBy.id !== player.value.id &&
              (m.firstPlayer.id === player.value.id ||
                m.secondPlayer.id === player.value.id) ? (
                <Tooltip
                  title="Potwierdź, że jesteś umówiony na ten mecz. Zablokowane, jeśli próbujesz zatwierdzić mecz po dacie jest odbycia."
                  key="1"
                >
                  <IconButton
                    size="small"
                    disabled={m.date < new Date()}
                    onClick={async () => {
                      const token = await getAccessTokenSilently();
                      confirmMatch(
                        m,
                        tournament.value,
                        (page || 0) * (pageSize || 25),
                        pageSize || 25,
                        token
                      );
                    }}
                  >
                    <CheckIcon></CheckIcon>
                  </IconButton>
                </Tooltip>
              ) : undefined;

            const secondAction =
              (m.status === MatchStatus.New || m.status === MatchStatus.Confirmed) &&
              (m.firstPlayer.id === player.value.id ||
                m.secondPlayer.id === player.value.id) ? (
                <Tooltip title="Anuluj mecz" key="2">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const token = await getAccessTokenSilently();
                      cancelMatch(
                        m,
                        tournament.value,
                        (page || 0) * (pageSize || 25),
                        pageSize || 25,
                        token
                      );
                    }}
                  >
                    <ClearIcon></ClearIcon>
                  </IconButton>
                </Tooltip>
              ) : undefined;

            const thirdAction =
              m.status === MatchStatus.Confirmed && m.date <= new Date() &&
              (m.firstPlayer.id === player.value.id ||
                m.secondPlayer.id === player.value.id) ? (
                <Tooltip title="Podaj wynik meczu" key="3">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCurrentMatch(m);
                      setMatchResultDialogOpen(true);
                    }}
                  >
                    <PostAddIcon></PostAddIcon>
                  </IconButton>
                </Tooltip>
              ) : undefined;

              let actions:JSX.Element[] = [];
              if(!!firstAction) {
                actions = [...actions, firstAction];
              }
              if(!!secondAction) {
                actions = [...actions, secondAction];
              }
              if(!!thirdAction) {
                actions = [...actions, thirdAction];
              }
              
            return <>{actions}</>;
          }}
        ></MatchesTable>
        </>
      ) : (
        <></>
      )}
<Button startIcon={<ChevronLeftIcon/>} onClick={() => window.history.back()}>Powrót</Button>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    tournament: selectTournamentDetails(state),
    player: currentPlayer(state),
    matches: state.matches.currentTournamentPlayersMatchesPage, // currentTournamentPlayersMatches
    clubs: state.clubs.clubs
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTournamentDetails: (year: string, id: string, token: string) =>
      dispatch(loadTournamentDetails({ year, id, token })),
    loadPlayersMatches: (
      year: string,
      id: string,
      skip: number,
      take: number,
      token: string
    ) => dispatch(getPlayersTournamentMatches({ year, id, skip, take, token })),
    confirmMatch: (
      match: MatchHeader,
      tournament: TournamentDetails,
      skip: number,
      take: number,
      token: string
    ) => 
      dispatch(
        confirmTournamentMatch({
          skip,
          take,
          token,
          matchId: match.id,
          tournamentId: tournament.id,
          year: tournament.year
        })
      ),
    cancelMatch: (
      match: MatchHeader,
      tournament: TournamentDetails,
      skip: number,
      take: number,
      token: string
    ) =>
      dispatch(
        cancelTournamentMatch({
          skip,
          take,
          token,
          matchId: match.id,
          tournamentId: tournament.id,
          year: tournament.year
        })
      ),
    submitResult: (
      match: MatchHeader,
      tournament: TournamentDetails,
      sets: Set[],
      walkover: boolean,
      skip: number,
      take: number,
      token: string
    ) =>
      dispatch(
        submitTournamentMatchResult({
          tournamentId: tournament.id,
          matchId: match.id,
          sets,
          walkover,
          skip,
          take,
          token,
          year: tournament.year
        })
      )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyMatchesPage)
);
