import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Hidden, Typography, useMediaQuery, useTheme, Chip } from "@material-ui/core";
import { TournamentHeader } from "../../store/tournaments/models";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableChartIcon from '@material-ui/icons/TableChart';
import { Link } from "react-router-dom";
import PricesList from "../leagues/PricesList";
import DateTimeView from "../../framework/components/DateTimeView";
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

interface TournamentHeaderProps {
  tournament: TournamentHeader;
}

const TournamentHeaderComponent: React.FC<TournamentHeaderProps> = ({
  tournament
}) => {

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" color={"secondary"}>
              <span style={{ "display": "inline-block", "marginRight": "8px" }}>{tournament.name}</span>
              <Chip
                label={tournament.isDoubles ? "Debel" : "Singiel"}
                clickable={false}
                size="small"
                style={{ backgroundColor: (tournament.isDoubles ? "#6573c3" : "#b22a00"), color: "white" }}
                component="span"
                icon={tournament.isDoubles ? <PeopleIcon style={{ color: "white" }} /> : <PersonIcon style={{ color: "white" }} />}
              ></Chip>
            </Typography>
          </Grid>
          <Hidden xsDown={true}>
            <Grid item sm={"auto"} md={3}>
              <Typography variant="body2">
                <><b>Uczestnicy:</b> {tournament.participantsCount}{" / "}{tournament.maxPlayers}</>

              </Typography>
            </Grid>
          </Hidden>
          <Grid item sm={6} md={3} xs={12} style={{ textAlign: (isSmall) ? "left" : "right", paddingLeft: (isSmall) ? '0px' : 'inherit' }}>
            <Button component={Link} to={`/tournaments/${tournament.year}/${tournament.id}`}>
              <TableChartIcon />
              Rozgrywki
            </Button>
          </Grid>

        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2} style={{ marginRight: "28px" }}>
          <Grid item sm={12} md={6}>
            <Typography variant="body2">
              <b>Opis</b>
            </Typography>
            <Typography variant="body2">{tournament.description}</Typography>
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Nagrody</b>
            </Typography>
            <PricesList prices={tournament.prices}></PricesList>
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Czas</b>
            </Typography>
            <Typography variant="body2">
              <DateTimeView value={tournament.startDate}></DateTimeView> - <DateTimeView value={tournament.endDate}></DateTimeView>
            </Typography>
          </Grid>
          <Grid item xl={12}>
            <Typography variant="body2">
              <b>Klub</b>
            </Typography>
            <Grid container spacing={3}>
              {tournament.clubs.filter(x => !!x.logoUrl).map((c) => (
                <Grid item key={c.id}>
                  <img
                    src={c.logoUrl}
                    style={{ height: "64px" }}
                    alt={c.name}
                    title={c.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default TournamentHeaderComponent;
