export const CREATE_MATCH_OFFER = "CREATE_MATCH_OFFER";
export const CREATE_MATCH_OFFER_SUCCESS = "CREATE_MATCH_OFFER_SUCCESS";
export const CREATE_MATCH_OFFER_FAILED = "CREATE_MATCH_OFFER_FAILED";

export const CHANGE_FORM_STATE = "CHANGE_FORM_STATE";

export const GET_MATCH_OFFERS = "GET_MATCH_OFFERS";
export const GET_MATCH_OFFERS_SUCCESS = "GET_MATCH_OFFERS_SUCCESS";
export const GET_MATCH_OFFERS_FAILED = "GET_MATCH_OFFERS_FAILED";

export const CANCEL_MATCH_OFFER = "CANCEL_MATCH_OFFER";
export const CANCEL_MATCH_OFFER_SUCCESS = "CANCEL_MATCH_OFFER_SUCCESS";
export const CANCEL_MATCH_OFFER_FAILED = "CANCEL_MATCH_OFFER_FAILED";

export const ACCEPT_MATCH_OFFER = "ACCEPT_MATCH_OFFER";
export const ACCEPT_MATCH_OFFER_SUCCESS = "ACCEPT_MATCH_OFFER_SUCCESS";
export const ACCEPT_MATCH_OFFER_FAILED = "ACCEPT_MATCH_OFFER_FAILED";