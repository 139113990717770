import { compose } from "ramda";

export const orDefault = (x: number, defaultVal: number = 0) => Number.isNaN(x) ? defaultVal : x; 

export const parseIntOrDefault = compose(orDefault, parseInt);

export const formatInt = (x: number) => {
    if(!Number.isFinite(x)) {
        return '';
    }
    return x;
}

