import React from "react";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { loadSpots, loadMyOrders } from "../../store/shop/actionts";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { Spot, Order } from "../../store/shop/models";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import ShoppingOrdersList from "./ShoppingOrdersList";
import { useAuth0 } from "@auth0/auth0-react";

interface ShoppingOrdersPageProps {
  player: Data<Player>;
  spots: Data<Spot[]>;
  orders: Data<Order[]>;
  loadSpots: () => void;
  loadMyOrders: (token: string) => void;
}

const ShoppingOrdersPage: React.FC<ShoppingOrdersPageProps> = ({
  player,
  spots,
  orders,
  loadSpots,
  loadMyOrders
}) => {

    const { getAccessTokenSilently } = useAuth0();

    React.useEffect(() => {
        loadSpots();
    }, []);

    React.useEffect(() => {
        getAccessTokenSilently().then((t: string) => loadMyOrders(t));
    }, [player])

  if (
    player.type === "NOT_STARTED" ||
    player.type === "FAILED" ||
    (player.type === "LOADED" && !player.value)
  ) {
    return (
      <Alert severity="error" title="Niezalogowany">
        Musisz się zalogować aby oglądać swoje zamówienia!
      </Alert>
    );
  }

  return (
    <>
      {player.type === "LOADED" &&
      !!player.value &&
      spots.type === "LOADED" &&
      orders.type === "LOADED" ? (
        <ShoppingOrdersList orders={orders.value} spots={spots.value}></ShoppingOrdersList>
      ) : (
        <div
          style={{
            width: "64px",
            height: "64px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  player: state.player.me,
  spots: state.shop.spots,
  orders: state.shop.orders,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSpots: () => dispatch(loadSpots()),
  loadMyOrders: (token: string) => dispatch(loadMyOrders(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingOrdersPage);
