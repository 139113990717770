export const LOAD_TOURNAMENTS = "LOAD_TOURNAMENTS";
export const LOAD_TOURNAMENTS_SUCCESS = "LOAD_TOURNAMENTS_SUCCESS";
export const LOAD_TOURNAMENTS_FAILED = "LOAD_TOURNAMENTS_FAILED";

export const REGISTER_TO_TOURNAMENT = "REGISTER_TO_TOURNAMENT";
export const REGISTER_TO_TOURNAMENT_SUCCESS = "REGISTER_TO_TOURNAMENT_SUCCESS";
export const REGISTER_TO_TOURNAMENT_FAILED = "REGISTER_TO_TOURNAMENT_FAILED";

export const LOAD_TOURNAMENT_DETAILS = "LOAD_TOURNAMENT_DETAILS";
export const LOAD_TOURNAMENT_DETAILS_SUCCESS = "LOAD_TOURNAMENT_DETAILS_SUCCESS";
export const LOAD_TOURNAMENT_DETAILS_FAILED = "LOAD_TOURNAMENT_DETAILS_FAILED";

export const SUBMIT_TOURNAMENT_MATCH = "SUBMIT_TOURNAMENT_MATCH";
export const SUBMIT_TOURNAMENT_MATCH_SUCCESS = "SUBMIT_TOURNAMENT_MATCH_SUCCESS";
export const SUBMIT_TOURNAMENT_MATCH_FAILED = "SUBMIT_TOURNAMENT_MATCH_FAILED";