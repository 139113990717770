import React from "react";
import { Grid, Divider, Typography } from "@material-ui/core";

const AboutPage: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        sm={8}
        md={9}
        style={{ borderRight: "1px rgba(0, 0, 0, 0.12) solid" }}
      >
        <h3>SMART TENNIS</h3>
        <p>
          <b>
            Cześć, cieszymy się że dotarłeś do nas i jesteśmy gotowi do wspólnej
            pozytywnej rywalizacji na korcie!
          </b>
        </p>
        <p>
          Jesteśmy zespołem, który dzięki połączeniu swojej pasji
          programistycznej oraz tenisowej stworzył narzędzie służące do
          rozgrywania ligi oraz turniejów tenisowych.
        </p>
        <p>
          Na naszej platformie użytkownicy mają możliwość zapisania się do ligi,
          rozgrywek, poznania nowych partnerów tenisowych i korzystania ze
          zniżek na współpracujących z nami kortami tenisowymi.
        </p>
        <p>
          Aby do nas dołączyć, należy się zarejestrować, zapisać do
          interesującej Ciebie ligi, opłacić wpisowe, skontaktować się z
          rywalem, skorzystać z promocji która jest na korcie tenisowym oraz
          ostatnią prostą jest rozegranie meczu! Po rozgrywce wygrana osoba na
          platformie wpisuje wynik meczu. Tak dla Was działamy!
        </p>
        <h4>To nie wszystko….</h4>
        <p>
          Każdy uczestnik ligi będzie zbierał punkty za rozegrane mecze oraz
          aktywności związane z ligą w systemie motywacyjnym, które wymieni na
          nagrody. Jakie? Śledź naszą zakładkę AKTUALNOŚCI tam będziemy wrzucali
          nowości.
        </p>
        <p>
          Firmy które z nami współpracują m.in. HEAD, Happy Diet oferują Ci %
          zniżki na ich produkty.
        </p>
        <p>Do zobaczenia na korcie! 🎾</p>
        <p>Najważniejsze korzyści udziału w lidze:</p>
        <ul>
          <li>możliwość gry na dowolnych kortach w Krakowie i okolicach</li>
          <li>
            zniżki na mecze ligowe na współpracujących z nami kortach tenisowych
          </li>
          <li>szybkie znalezienie partnera na mecz o podobnej sile gry</li>
          <li>turnieje tenisowe zaliczane do ligi</li>
          <li>
            system motywacyjny uczestników (zdobywanie punktów i wymiana na
            nagrody) (rozegranie meczu - 5 smart punktów, polecenia zawodnika -
            25 smart punktów)
          </li>
          <li>
            nagrody rzeczowe oraz vouchery dla aktywnych oraz najlepszych
            uczestników ligi (łączna pula nagród to 10 000 zł)
          </li>
          <li>
            rabaty dla uczestników ligi od naszych partnerów - HEAD, Happy Diet
          </li>
        </ul>
        <img src="about1.png" style={{ width: "100%" }}></img>
      </Grid>

      <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
        <h4>Sponsorzy:</h4>
        <div style={{ paddingBottom: "24px" }}>
          <a href="https://www.head.com/pl-PL/home/" target="_blank">
            <img src="head.jpg" style={{ maxWidth: "200px" }} />
          </a>
        </div>
        <div style={{ paddingBottom: "24px" }}>
          <a href="https://happy-diet.pl/" target="_blank">
            <img src="happydiet.png" style={{ maxWidth: "200px" }} />
          </a>
        </div>
        <div style={{ paddingBottom: "24px" }}>
          <a href="https://www.jaworzynka-sport.pl/" target="_blank">
            <img src="jaworzynka.png" style={{ maxWidth: "200px" }} />
          </a>
        </div>
        <Divider></Divider>
        <div>
          <h4>Dane kontaktowe</h4>
          <b>Stowarzyszenie KLUB SPORTOWY PROJEKT TENIS</b> <br />
          Osiedle Górali 11/19 31-960 Kraków, <br />
          NIP: 6783168195, REGON: 367297286, KRS: 0000677574 <br />
          Email: <a href="mailto:biuro@smarttennis.pl">biuro@smarttennis.pl</a>
          <br /><br/>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmarttennispl&tabs&width=340&height=70&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=false&appId=377716342830188"
            height="70"
            style={{ border: "none", overflow: "hidden", width: '100%' }}
            scrolling="no"
            allow="encrypted-media"
          ></iframe>
        </div>
      </Grid>
    </Grid>
  );
};

export default AboutPage;
