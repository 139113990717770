import React from "react";
import PlayerStep from "../../registration/PlayerStep";
import { Step, StepButton, Stepper } from "@material-ui/core";
import TournamentSelector from "./TournamentSelector";
import TournamentRegistrationConfirmation from "./TournamentRegistrationConfirmation";
import TournamentRegistrationProgress from "./TournamentRegistrationProgress";

const getSteps = () => {
    return [
        "Wybór turnieju",
        "Profil zawodnika",
        "Informacje dla zawodników",
        "Zakończenie"
    ]
}


const RegistrationWizard: React.FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const getStepContent = (idx: number) => {
        switch(idx) {
            case 0: {
                return <TournamentSelector onProceed={() => setActiveStep(activeStep+1)} />
            }
            case 1: {
                return <PlayerStep onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)} />
            }
            case 2: {
                return <TournamentRegistrationConfirmation onProceed={() => setActiveStep(activeStep+1)} onMoveBack={() => setActiveStep(activeStep-1)}></TournamentRegistrationConfirmation>
            }
            case 3: {
                return <TournamentRegistrationProgress></TournamentRegistrationProgress>
            }
        }
    }

    return <>
        <Stepper nonLinear activeStep={activeStep}>
            {
                steps.map((step) => (
                    <Step key={step}>
                        <StepButton>
                            {step}
                        </StepButton>
                    </Step>
                ))
            }
        </Stepper>
        {
            getStepContent(activeStep)
        }
    </>
};

export default RegistrationWizard;