import { combineReducers } from 'redux';
import leagues, { State as LeaguesState } from './leagues/reducer';
import player, { State as PlayerState } from './players/reducer';
import shell, { State as ShellState } from './shell/reducer';
import clubs, { State as ClubsState } from './clubs/reducer'; 
import matches, { State as MatchesState } from './matches/reducer';
import payments, { State as PaymentsState} from './payments/reducer';
import shop, { State as ShopState} from './shop/reducer';
import desktop, { State as DesktopState} from './desktop/reducer';
import stats, { State as StatsState } from './stats/reducer';
import matchOffers, { State as MatchOffersState } from './match-offers/reducer';
import registration, { State as RegistrationState } from './registration/reducer';
import elo, { State as EloState } from './elo/reducer';
import tournaments, { State as TournamentsState} from './tournaments/reducer';
import trainings, { State as TrainingsState } from './trainings/reducer';

export interface AppState {
    leagues: LeaguesState;
    player: PlayerState;
    shell: ShellState;
    matches: MatchesState;
    clubs: ClubsState;
    payments: PaymentsState;
    shop: ShopState;
    desktop: DesktopState;
    stats: StatsState;
    matchOffers: MatchOffersState;
    registration: RegistrationState;
    elo: EloState;
    tournaments: TournamentsState;
    trainings: TrainingsState;
}

export default combineReducers({ leagues, player, shell, clubs, matches, payments, shop, desktop, stats, matchOffers, registration, elo, tournaments, trainings });

