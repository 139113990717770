import React from "react";
import { Dialog, DialogTitle, DialogActions, Button, Checkbox, FormControlLabel } from "@material-ui/core";

interface CancelConfirmationDialogProps {
    open: boolean;
    onClosed: (confirmed: boolean) => void;
  }
  
const CancelConfirmationDialog: React.FC<CancelConfirmationDialogProps> = ({
    open,
    onClosed
  }) => {

    const [confirmOpponentAware, setConfirmOpponentAware] = React.useState<boolean>(false);

    return (
    <Dialog open={open}>
        <DialogTitle>Anulowanie meczu</DialogTitle>
        <div style={{ padding: "24px" }}>
        <FormControlLabel
                                            control={
                                                <Checkbox
              value={confirmOpponentAware}
              onChange={(e) => setConfirmOpponentAware(e.target.checked)}
            />
                                            }
                                            label="Potwierdzam, że przeciwnik jest świadomy mojego odwołania meczu gdzie do spotkanie pozostało mniej niż 24h, skontaktowałem/am się z nim i otrzymałem odpowiedź."
                                        />            
        </div>
        <DialogActions style={{ padding: "0px 24px 24px 24px" }}>
          <Button onClick={() => onClosed(false)}>Zamknij</Button>
          <Button
            onClick={() => onClosed(true)}
            variant="contained"
            color="primary"
            disabled={!confirmOpponentAware}
          >
            Kontunuuj
          </Button>
        </DialogActions>
    </Dialog>
    );
  };

  export default CancelConfirmationDialog;  