import React from "react";
import { Data } from "../../framework/data";
import { PlayersMatchesInLeague, Stat } from "../../store/stats/model";
import { PlayerMatch } from "../../store/matches/models";
import { PlayerDescriptor } from "../../store/leagues/models";
import { connect } from "react-redux";
import { AppState } from "../../store/reducer";
import { playerDataFromMatches, matchesWon, allMatches, winPercentage, statsPerSeasonOrdered, recentMatches, mostFrequentClubs, matchesGrouped } from "../../store/stats/selectors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, CircularProgress } from "@material-ui/core";
import GeneralStats from "./GeneralStats";
import { Dispatch } from "redux";
import { loadPlayerStats, loadPlayerMatches } from "../../store/stats/actions";
import ClubStats from "./ClubStats";
import PerSeasonStats from "./PerSeasonStats";
import RecentPlayersMatches from "./RecentPlayersMatches";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

interface StatsContainerProps {

    playerId: string;

    player: Data<PlayerDescriptor | undefined>;
    matchesWon: Data<number>;
    matchesPlayed: Data<number>;
    winPercentage: Data<string>;

    statsPerSeason: Data<Stat[]>;

    matchesGrouped: Data<{ [key: string]: PlayersMatchesInLeague }>;

    clubsPlayed: Data<{ name: string, count: number }[]>;

    loadPlayerStats: (playerId: string) => void;
    loadPlayerMatches: (playerId: string) => void;
}

const StatsContainer: React.FC<StatsContainerProps> = ({
    playerId,
    player,
    matchesWon,
    matchesPlayed,
    winPercentage,
    statsPerSeason,
    clubsPlayed,
    matchesGrouped,
    loadPlayerStats,
    loadPlayerMatches }) => {

    React.useEffect(() => {

        loadPlayerStats(playerId);
        loadPlayerMatches(playerId);

    }, [playerId]);

    const [selectedLeague, setSelectedLeague] = React.useState<string>("")

    return (
        <div>
            {player.type === "LOADED" ? (

                !!player.value ? (

                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            {
                                matchesWon.type === "LOADED" && matchesPlayed.type === "LOADED" && winPercentage.type === "LOADED" ?
                                    (<GeneralStats
                                        player={player.value!}
                                        matchesPlayed={matchesPlayed.value}
                                        matchesWon={matchesWon.value}
                                        winPercentage={winPercentage.value} />
                                    ) : (
                                        <div
                                            style={{
                                                width: "64px",
                                                height: "64px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )


                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                clubsPlayed.type === "LOADED" && matchesPlayed.type === "LOADED" ?
                                    (
                                        <ClubStats clubsPlayed={clubsPlayed.value} matchesPlayed={matchesPlayed.value} />
                                    ) : (
                                        <div
                                            style={{
                                                width: "64px",
                                                height: "64px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )
                            }
                        </Grid>

                        <Grid item xs={12}>
                            {
                                statsPerSeason.type === "LOADED"
                                    ? (<PerSeasonStats stats={statsPerSeason.value} leagueSelected={(l) => setSelectedLeague(l)}></PerSeasonStats>)
                                    : (
                                        <div
                                            style={{
                                                width: "64px",
                                                height: "64px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )
                            }
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {
                                matchesGrouped.type === "LOADED"
                                    ? (!!selectedLeague ? (
                                        <RecentPlayersMatches matches={matchesGrouped.value[selectedLeague]} ></RecentPlayersMatches>
                                    ) : (
                                            <Alert severity="info">
                                                <AlertTitle>Zobacze mecze w lidze!</AlertTitle>
                                                <div>
                                                    <span>Aby zobaczyć wszystkie mecze zawodnika w danej lidze, kliknij na nazwę ligi lub ikonę </span><FormatListBulletedIcon style={{ display: "inlineBlock", marginBottom: '-4px' }} /><span> powyżej</span>
                                                </div>
                                            </Alert>
                                        ))
                                    : (
                                        <div
                                            style={{
                                                width: "64px",
                                                height: "64px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )
                            }
                        </Grid>
                    </Grid>
                ) : (
                        <Alert severity="warning">
                            Zawodnik nie ma żadnych obliczonych statystyk
                        </Alert>
                    )
            ) : (
                    <div
                        style={{
                            width: "64px",
                            height: "64px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    player: playerDataFromMatches(state),
    matchesWon: matchesWon(state),
    matchesPlayed: allMatches(state),
    winPercentage: winPercentage(state),

    statsPerSeason: statsPerSeasonOrdered(state),
    recentMatches: recentMatches(10)(state),

    clubsPlayed: mostFrequentClubs(state),
    matchesGrouped: matchesGrouped(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadPlayerStats: (player: string) => dispatch(loadPlayerStats(player)),
    loadPlayerMatches: (player: string) => dispatch(loadPlayerMatches(player))
})

export default connect(mapStateToProps, mapDispatchToProps)(StatsContainer);