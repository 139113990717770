import React from "react";
import { MatchOffersFilterModel } from "../../store/match-offers/utils";
import { Grid, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { sortBy } from "ramda";
import { Club } from "../../store/clubs/models";
import { startOfDay, endOfDay } from "date-fns";

interface MatchOffersFilterProps {
    filter: MatchOffersFilterModel;
    onFilterChanged: (f: MatchOffersFilterModel) => void;
    clubs: Club[];
}



const MatchOffersFilter: React.FC<MatchOffersFilterProps> = ({ filter, onFilterChanged, clubs }) => {

    const getOptionsLabel = (option: any) => {
        if (!option) {
            return "";
        }

        if (!!option.name) {
            return option.name;
        }

        if (typeof option === "string") {
            return clubs?.find((x) => x.id === option)?.name || "";
        }

        return "";
    };


    const handlePreferredClubsChange = (max: number) => (_: any, value: any[]) => {
        if (value.length <= max) {
            onFilterChanged({ ...filter, clubIds: (value.map((x) => {
                if (typeof x === "string") {
                    return x;
                }
                return x.id;
            })) })
        }
    };

    return <>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item md={4} xs={12}>
                <KeyboardDatePicker
                    variant="inline"
                    autoOk
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    margin="normal"
                    style={{ width: "100%" }}
                    label="Data od"
                    invalidDateMessage="Błędny format daty"
                    minDate={new Date()}
                    minDateMessage="Data meczu nie może być datą w przeszłości"
                    value={filter.dateFrom}
                    onChange={(x) => {
                        if (x) {
                            onFilterChanged({ ...filter, dateFrom: startOfDay(x) })
                        }
                    }}
                />
                <KeyboardDatePicker
                    variant="inline"
                    autoOk
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    margin="normal"
                    style={{ width: "100%" }}
                    label="Data do"
                    invalidDateMessage="Błędny format daty"
                    minDate={filter.dateFrom}
                    minDateMessage="'Data do' nie może być mniejsza niż 'Data od'"
                    value={filter.dateTo}
                    onChange={(x) => {
                        if (x) {
                            onFilterChanged({ ...filter, dateTo: endOfDay(x) })
                        }
                    }}
                />
            </Grid>
            <Grid item md={8} xs={12}>
                <Autocomplete
                    id="preferred-clubs"
                    multiple
                    value={filter.clubIds}
                    options={sortBy((x) => x.address?.city || "Kraków", clubs || [])}
                    getOptionLabel={getOptionsLabel}
                    getOptionSelected={(o: Club, v: string) => o.id === v}
                    groupBy={(o: Club) => o.address?.city || "Kraków"}
                    filterSelectedOptions
                    onChange={handlePreferredClubsChange(3)}
                    style={{ width: "100%", marginTop: '16px' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Korty na których chciałbym grać"
                            placeholder="Korty na których chciałbym grać"
                        />
                    )}
                ></Autocomplete>
            </Grid>
        </Grid>
    </>;
}

export default MatchOffersFilter;