import * as React from "react";
import { Grid } from "@material-ui/core";
import Sponsor from "./Sponsor";

const SponsorsPage: React.FC = () => {

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Tecnifibre" 
                    logoUrl="/logo/tecnifibre.jpg" 
                    url="https://tecnifibre.com/en/accueil"
                    description="Tecnifibre to wiodący producent sprzętu tenisowego. Rakiety Tecnifibre wybrała nasza mistrzyni Iga Świątek."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="La-Le" 
                    logoUrl="/logo/lale.png" 
                    url="https://la-le.pl/"
                    description="Producent naturalnych kosmetyków do pielęgnacji ciała i twarzy wytwarzanych z ekologicznych produktów. Kosmetyki La-Le są wykonywane w krakowskiej manufakturze w sposób ręczny na bazie roślinnych składników i tradycyjnych receptur."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="DUOLIFE" 
                    logoUrl="/logo/duolife.png" 
                    url="https://myduolife.com/"
                    description="DuoLife S.A. jest jedną z największych Polskich marek oferujących naturalne suplementy diety. Firma od 2012 roku stara się sprostać oczekiwaniom klientów nie tylko na rodzimym rynku, ale i za granicą. Produkty DuoLife dostępne są aż w 30 krajach świata."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Pro-Fiz" 
                    logoUrl="/logo/profiz.png" 
                    url="https://www.facebook.com/profizcentrumfizjoterapii/"
                    description="Centrum fizjoterapii w Krakowie - zabiegi rehabilitacyjne, doradztwo, treningi, szybki powrót po kontuzji."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="MM SPORT" 
                    logoUrl="/logo/mm_sport.png" 
                    url="https://mmsport.pl/"
                    description="Sklep z odzieżą sportową oferujące tysiące oryginalnych produktów w atrakcyjnych cenach dla mężczyzn, kobiet i dzieci."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Błonia Sport" 
                    logoUrl="/logo/blonia_sport.png" 
                    url="https://bloniasport.pl/"
                    description="Partner SmartTennis, który udostępnia korty tenisowe na rozgrywanie turniejów oraz finałów."/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="IOLA" 
                    logoUrl="/logo/iola.png" 
                    url="https://iola.pl/"
                    description="Platforma do tworzenia, zarządzania i automatyzacji marketingu internetowego oferująca wsparcie wielu systemach reklamowych."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Nova Klubova" 
                    logoUrl="/logo/nowa_klubowa.png" 
                    url="https://novaklubova.pl/"
                    description="Nova Klubova to połączenie klubu, coctail baru i restauracji w centrum krakowskiego kazimierza. Odbywają się tam imprezy na zakończenie sezonu ligowego."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="DP Optyk" 
                    logoUrl="/logo/dpoptyk.jpg" 
                    url="https://dpoptyk.pl/"
                    description="DP Optyk to to sieć salonów optycznych z 5 salonami w Krakowie."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Footsy" 
                    logoUrl="/logo/footsy.png" 
                    url="https://footsy.com.pl/"
                    description="Footsy to producent wysokiej jakości skarpet sportowych, które są idealne do gry w tenisa."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Cudalepianki" 
                    logoUrl="/logo/cudalepianki.png" 
                    url="https://www.facebook.com/cudalepianki.ceramika"
                    description="Cudalepianki, to pracownia ceramiczna i artystyczna mieszcząca się na krakowskim Dąbiu. W Cudalepiankiach nauczycie się toczenia na kole, ręcznego tworzenia różnymi metodami i zdobienia ceramiki, a także grafiki warsztatowej."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Winnica Novi" 
                    logoUrl="/logo/winnicanovi.png" 
                    url="https://winnicanovi.pl/"
                    description="Winnica Novi to winnica leżąca w otulinie Ojcowskiego Parku Narodowego pod Krakowem w Cianowicach."/>
            </Grid>

            <Grid item xs={12} md={3}>
                <Sponsor 
                    name="Miód od Kulmy" 
                    logoUrl="/logo/miododkulmy.png" 
                    url="https://miododkulmy.pl/"
                    description="Miód od Kulmy
Powstaje w Dolinie Cybiny we współpracy pszczół i ludzi."/>
            </Grid>
            
        </Grid>
    )

};

export default SponsorsPage;

