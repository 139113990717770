import React from "react";
import { makeStyles, Theme, createStyles, Paper, Typography, TextField, Grid, Button, Link } from "@material-ui/core";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Alert, AlertTitle } from "@material-ui/lab";
import { Data } from "../../framework/data";

interface PhoneConfirmationProps {
    phone: string;
    phoneVerified: boolean | undefined;
    isVerifying: boolean;
    phoneAuthMessage: string;
    onPhoneChallenged: (code: string) => void;
    onSendSmsMessage: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectPanel: {
            borderLeft: 0,
            borderRight: 0,
            margin: '0 -32px',
            padding: '16px 32px',
            backgroundColor: theme.palette.grey[100]
        }
    }),
);

const PhoneConfirmation: React.FC<PhoneConfirmationProps> = ({ phone, onPhoneChallenged, onSendSmsMessage, isVerifying, phoneVerified, phoneAuthMessage }) => {

    const classes = useStyles();

    const [phoneCode, setPhoneCode] = React.useState<string>("");

    React.useEffect(() => {

        onSendSmsMessage();

    }, [phone]);

    let x = (<></>);

    if (phoneVerified === true) {
        x = (<div style={{ margin: '16px 0' }}>
            <Alert severity="success">
                <AlertTitle>Sukces!</AlertTitle>
                Zweryfikowaliśmy Twój numer telefonu. Wciśnij 'Dalej' aby dokończyć rejestrację
            </Alert>
        </div>
        );
    } else if (phoneVerified === false) {
        x = (
            <div style={{ margin: '16px 0' }}>
                <Alert severity="error">
                    <AlertTitle>Niepowodzenie!</AlertTitle>
                    Nie udało nam się zweryfikować Twojego numeru telefonu. Upewnij się, że Twój numer jest poprawny i <Link href="javascript:void(0)" onClick={onSendSmsMessage}>spróbuj ponownie</Link>
                </Alert>
            </div>
        )
    }

    return <>
        {x}
        <Paper variant="outlined" className={classes.selectPanel} square>
            {
                phoneAuthMessage ? (
                    <Alert severity="error">
                        <AlertTitle>Błąd wysyłania SMS!</AlertTitle>
                        {phoneAuthMessage}
                    </Alert>
                ) : (
                        <Typography variant="subtitle1" style={{ marginLeft: 8 }}>Na Twój numer: <b>{phone}</b> wysłaliśmy wiadomość SMS z kodem.
                    Po otrzymaniu kodu, proszę przepisz go do poniższego pola.</Typography>
                    )
            }


            <Grid container spacing={2} alignItems="center" style={{ marginLeft: 8, marginTop: 8 }}>
                <Grid item>
                    <TextField
                        style={{ minWidth: 250 }}
                        label="Kod z SMSa"
                        variant="outlined"
                        value={phoneCode}
                        onChange={(e) => setPhoneCode(e.target.value)}
                        disabled={isVerifying || !!phoneVerified}
                    ></TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" startIcon={<VerifiedUserIcon />} disabled={isVerifying || !!phoneVerified} onClick={() => onPhoneChallenged(phoneCode)} >Zweryfikuj</Button>
                </Grid>
            </Grid>
        </Paper>
    </>
}

export default PhoneConfirmation;