import { groupBy } from "ramda";
import { dataOf } from "../../framework/data";
import { AppState } from "../reducer";

export const selectTournaments = (state: AppState) => state.tournaments.items.type === "LOADED" ? dataOf(groupBy(x => x.city, state.tournaments.items.value)) : state.tournaments.items;
export const selectTournamentDetails = (state: AppState) => state.tournaments.details;

export const selectTournamentsSubscribedTo = (state: AppState) => 
    state.tournaments.items.type === "LOADED"
        ? dataOf(state.tournaments.items.value.filter(x => x.isCurrentUserAssigned).map(x => x.id))
        : state.tournaments.items;