import React from "react";
import { LeagueHeaderWithCurrentUserAssignment, LeagueStatus } from "../../store/leagues/models";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  Hidden,
  Button,
  Chip,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import TableChartIcon from '@material-ui/icons/TableChart';
import PricesList from "./PricesList";
import DateView from "../../framework/components/DateView";
import { Link } from "react-router-dom";

interface LeagueHeaderProps {
  league: LeagueHeaderWithCurrentUserAssignment;
}

const LeagueHeader: React.FC<LeagueHeaderProps> = ({
  league
}) => {

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" color={"secondary"}>
              <span style={{ "display": "inline-block", "marginRight": "8px" }}>{league.name}</span>
              <Chip
                label={league.isDoubles ? "Debel" : "Singiel"}
                clickable={false}
                size="small"
                style={{ backgroundColor: (league.isDoubles ? "#6573c3" : "#b22a00"), color: "white" }}
                component="span"
                icon={league.isDoubles ? <PeopleIcon style={{ color: "white" }} /> : <PersonIcon style={{ color: "white" }} />}
              ></Chip>
            </Typography>
          </Grid>
          <Hidden xsDown={true}>
            <Grid item sm={"auto"} md={3}>
              <Typography variant="body2">
                {
                  league.startDate < new Date() ? (
                    <><b>Uczestnicy:</b> {league.participantsCount}{" "}</>
                  ) : (<></>)
                }
                
              </Typography>
            </Grid>
          </Hidden>
          <Grid item sm={6} md={3} xs={12} style={{ textAlign: (isSmall) ? "left" : "right", paddingLeft: (isSmall) ? '0px' : 'inherit' }}>
            {league.isCurrentUserAssigned ? (
              <Button
                component={Link}
                color="primary"
                to={`/leagues/${league.year}/${league.id}/mine-matches`}
              >
                <SportsTennisIcon />
                Moje mecze
              </Button>
            ) : (<></>)
            }
            {
              league.isCurrentUserAssigned && league.startDate < new Date() ? (
                <Button component={Link} to={`/match-offers/${league.year}/${league.id}`}>
                  <EventNoteIcon />
                  Giełda meczów
                </Button>
              ) : <></>
            }
            <Button component={Link} to={`/leagues/${league.year}/${league.id}`}>
              <TableChartIcon />
              Tabela
            </Button>
          </Grid>

        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2} style={{ marginRight: "28px" }}>
          <Grid item sm={12} md={6}>
            <Typography variant="body2">
              <b>Opis</b>
            </Typography>
            <Typography variant="body2">{league.description}</Typography>
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Nagrody</b>
            </Typography>
            <PricesList prices={league.prices}></PricesList>
          </Grid>
          <Grid item sm={12} md={3}>
            <Typography variant="body2">
              <b>Finał</b>
            </Typography>
            <Typography variant="body2">
              <DateView value={league.endDate}></DateView>
            </Typography>
          </Grid>
          <Grid item xl={12}>
            <Typography variant="body2">
              <b>Kluby</b>
            </Typography>
            <Grid container spacing={3}>
              {league.clubs.filter(x => !!x.logoUrl).map((c) => (
                <Grid item key={c.id}>
                  <img
                    src={c.logoUrl}
                    style={{ height: "64px" }}
                    alt={c.name}
                    title={c.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default LeagueHeader;
