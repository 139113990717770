import React from "react";
import { Grid, CircularProgress, CircularProgressProps, Box, Typography } from "@material-ui/core";

interface ClubStatsProps {
    clubsPlayed: { name: string, count: number }[],
    matchesPlayed: number;
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const ClubStats: React.FC<ClubStatsProps> = ({ clubsPlayed, matchesPlayed }) => (
    <>
    <Typography variant="h6">3 najczęściej odwiedzane kluby</Typography>
    <Grid container>
        {clubsPlayed.map((c, i) => (
            <Grid item key={i} xs={12}>
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <CircularProgressWithLabel value={(c.count / (matchesPlayed || 100)) * 100}></CircularProgressWithLabel>
                    </Grid>
                    <Grid item>
                        <Typography>{c.name}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        ))}
    </Grid>
    </>
);

export default ClubStats;