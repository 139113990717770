import React from "react";
import { Spot, Order, OrderStatus } from "../../store/shop/models";
import { List, Grid, Paper, Avatar, Typography, Tooltip } from "@material-ui/core";

import LoopIcon from '@material-ui/icons/Loop';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DateTimeView from "../../framework/components/DateTimeView";

interface ShoppingOrdersListProps {
  spots: Spot[];
  orders: Order[];
}

const OrderStatusIcon: React.FC<{status: OrderStatus}> = ({status}) => (
    
    <>
        {
            (status === OrderStatus.PENDING) ? (<Tooltip title="Zamówienie złożone"><LoopIcon fontSize="large" /></Tooltip>) :
            (status === OrderStatus.READY) ? (<Tooltip title="Zamówienie gotowe do odbioru"><AssignmentTurnedInIcon fontSize="large" color="secondary" /></Tooltip>) :
            (status === OrderStatus.FINISHED) ? (<Tooltip title="Zamówienie odebrane"><CheckIcon fontSize="large" color="primary" /></Tooltip>) :
            (status === OrderStatus.CANCELLED) ? (<Tooltip title="Zamówienie anulowane"><ClearIcon fontSize="large" color="error" /></Tooltip>) : (<></>)
        }
    </>
)

const ShoppingOrdersList: React.FC<ShoppingOrdersListProps> = ({
  spots,
  orders,
}) => (
  <Grid direction="column" spacing={2} container style={{flexWrap: 'initial'}}>
    {orders.sort((x: Order, y: Order) => y.orderDate.valueOf() - x.orderDate.valueOf()).map((o: Order) => (
      <Grid item key={o.id}>
        <Paper variant="outlined" square style={{ padding: '16px'}}>
          <Grid container spacing={3}>
            <Grid item xs={1} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <OrderStatusIcon status={o.status}></OrderStatusIcon>
            </Grid>
            <Grid item>
                <Typography variant="body2"><b>Produkty</b></Typography>
                <ul>
                    {
                        o.orderDetails.map(x => (
                            <li key={`${o.id}--${x.id}`}>{x.name} x{x.quantity}</li>
                        ))
                    }
                </ul>
            </Grid>
            <Grid item>
                <Typography variant="body2"><b>Odbiór</b></Typography>
                { o.spotName }:&nbsp;
                { spots.find(s => s.id === o.spotId)?.name }, {spots.find(s => s.id === o.spotId)?.address}, {spots.find(s => s.id === o.spotId)?.city   }
                <Typography variant="body2"><b>Data zamówienia</b></Typography>
                <DateTimeView value={o.orderDate}></DateTimeView>
            </Grid>
            <Grid item style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="h5">{o.finalPrice} Smart Punktów</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    ))}
  </Grid>
);

export default ShoppingOrdersList;

