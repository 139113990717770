import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Popover, Link } from "@material-ui/core";
import React from "react";
import DateTimeView from "../../framework/components/DateTimeView";
import { MatchHeader } from "../../store/matches/models";
import { Player } from "../../store/players/models";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import LeaguePlayerView from "../leagues/LeaguePlayerView";
import CommentIcon from '@material-ui/icons/Comment';
import { Club } from "../../store/clubs/models";
import { LeagueWithPaymentStatus } from "../../store/leagues/models";
import { dataOf } from "../../framework/data";

interface PlayersMatchesProps {

    matches: MatchHeader[];
    currentPlayer: Player;
    actions: (m: MatchHeader) => JSX.Element;
    clubs: Club[];
    leagues: LeagueWithPaymentStatus[]
}

const PlayersMatches: React.FC<PlayersMatchesProps> = ({ matches, actions, currentPlayer, clubs, leagues }) => {

    const [playerInView, setPlayerInView] = React.useState<{
        el: HTMLElement;
        playerId: string;
        leagueId: string;
    } | null>(null);

    const getPlayerDetails = (playerId: string, target: HTMLElement, leagueId: string) => {
        if (
            !currentPlayer
        ) {
            return;
        }

        setPlayerInView({ el: target, playerId, leagueId });
    };


    return (<>
        <Popover
            open={Boolean(playerInView?.el)}
            anchorEl={playerInView?.el}
            onClose={() => setPlayerInView(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            {!!playerInView ? (
                <LeaguePlayerView
                    playerId={playerInView?.playerId}
                    clubs={dataOf(clubs)}
                    leagueId={playerInView?.leagueId}
                    leagueYear={leagues.find(l => l.id === playerInView?.leagueId)?.year || ""}
                ></LeaguePlayerView>
            ) : (
                    <></>
                )}
        </Popover>
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Klub</TableCell>
                        <TableCell>Przeciwnik</TableCell>
                        <TableCell>Info</TableCell>
                        {!!actions ? <TableCell align="right">Akcje</TableCell> : <></>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        matches.map(match => (
                            <TableRow key={match.id}>
                                <TableCell>
                                    <DateTimeView value={match.date}></DateTimeView>
                                </TableCell>
                                <TableCell>
                                    <span>{match.club?.name}</span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        {
                                            match.firstPlayer.id === currentPlayer.id!
                                                ? <Link href={`/#/stats/${btoa(match.secondPlayer.id)}`}>{match.secondPlayer.name} {match.secondPlayer.surname}</Link>
                                                : <Link href={`/#/stats/${btoa(match.firstPlayer.id)}`}>{match.firstPlayer.name} {match.firstPlayer.surname}</Link>
                                        }
                                    </span>
                                </TableCell>
                                <TableCell>
                                    {
                                        !!match.description ? (<>
                                            <Tooltip title={match.description} enterTouchDelay={1}>
                                                <IconButton size="small">
                                                    <CommentIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </>) : (<></>)
                                    }
                                </TableCell>
                                {!!actions ? (
                                    <TableCell align="right">
                                        {actions(match)}
                                        <Tooltip title="Dane zawodnika">
                                            <IconButton
                                                size="small"
                                                onClick={(evt) =>
                                                    getPlayerDetails((match.firstPlayer.id === currentPlayer.id!) ? match.secondPlayer.id : match.firstPlayer.id, evt.currentTarget, match.league)
                                                }
                                            >
                                                <PermContactCalendarIcon fontSize="inherit"></PermContactCalendarIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Statystyki zawodnika">
                                            <IconButton href={`/#/stats/${btoa((match.firstPlayer.id === currentPlayer.id!) ? match.secondPlayer.id : match.firstPlayer.id)}`} size="small">
                                                <EqualizerIcon fontSize="inherit"></EqualizerIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                ) : (
                                        <></>
                                    )}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default PlayersMatches;