import React from "react";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter, match } from "react-router-dom";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { CircularProgress } from "@material-ui/core";
import { Club } from "../../store/clubs/models";
import { loadClubsInProvince } from "../../store/clubs/actions";
import { TournamentDetails } from "../../store/tournaments/models";
import TournamentDetailsComponent from "./TournamentDetails";
import { loadTournamentDetails, submitTournamentMatch } from "../../store/tournaments/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { selectTournamentDetails } from "../../store/tournaments/selectors";
import CreateTournamentMatchDialog from "./matches/CreateTournamentMatchDialog";
import { LeaguePlayer } from "../../store/leagues/models";
import { Match } from "../../store/matches/models";

interface TournamentDetailsPageProps {
  player: Data<Player>;
  details: Data<TournamentDetails>;
  match: match;
  loadTournamentDetails: (year: string, id: string, token: string) => void;
  clubs: Data<Club[]>;
  loadClubs: () => void;
  submitTournamentMatch: (tournament: TournamentDetails, match: Match, token: string) => void;
}

const TournamentDetailsPage: React.FC<TournamentDetailsPageProps> = ({
  details,
  match,
  loadTournamentDetails,
  player,
  clubs,
  loadClubs,
  submitTournamentMatch
}) => {

  const { getAccessTokenSilently } = useAuth0();

  const [matchResultDialogOpen, setMatchResultDialogOpen] = React.useState(false);

  const [oponent, setOponent] = React.useState<LeaguePlayer>();

  React.useEffect(() => {
    var id = (match.params as any).id;
    var year = (match.params as any).year;

    getAccessTokenSilently().then((token) => {
      loadTournamentDetails(year, id, token);
    }).catch(() => {
      loadTournamentDetails(year, id, "");
    });

  }, [match.params, loadTournamentDetails]);

  React.useEffect(() => {
    if (clubs.type === "NOT_STARTED" && loadClubs) {
      loadClubs();
    }
  }, []);

  const onRegisteringMatchFinished = async (
    success: boolean,
    match: Match | null
  ) => {
    if (success && details.type === "LOADED" && match) {
      const token = await getAccessTokenSilently();
      const tournament = details.value;
      submitTournamentMatch(tournament, match, token);
    }

    setMatchResultDialogOpen(false);
  };

  const registerMatchResultWithPlayerRequested = (player: LeaguePlayer) => {
    setOponent(player);
    setMatchResultDialogOpen(true);
  };

  const unsafePlayer =
    player && player.type === "LOADED" ? player.value : undefined;

  switch (details.type) {
    case "LOADED": {
      return (
        <> {
          player.type === "LOADED" && !!player && oponent ? (<CreateTournamentMatchDialog
            tournament={details.value}
            oponent={oponent!}
            player={player.value}
            open={matchResultDialogOpen}
            onClosed={onRegisteringMatchFinished}
          ></CreateTournamentMatchDialog>)
            :
            (<></>)
        }
          <TournamentDetailsComponent
            clubs={clubs}
            tournament={details.value}
            player={unsafePlayer}
            onRegisterMatchResultWithPlayerRequested={registerMatchResultWithPlayerRequested}
          ></TournamentDetailsComponent>
        </>
      );
    }
    case "LOADING": {
      return (
        <div
          style={{
            width: "64px",
            height: "64px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    default: {
      return <div></div>;
    }
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    details: selectTournamentDetails(state),
    player: currentPlayer(state),
    newestMatches: state.matches.currentLeagueNewestMatches,
    clubs: state.clubs.clubs
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTournamentDetails: (year: string, id: string, token: string) =>
      dispatch(loadTournamentDetails({ year, id, token })),
    loadClubs: () => dispatch(loadClubsInProvince()),
    submitTournamentMatch: (tournament: TournamentDetails, match: Match, token: string) => dispatch(submitTournamentMatch({
      token, tournament, match
    }))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TournamentDetailsPage)
);
