import { getConfig } from "../../config/configuration";
import { PriceInfo, RegistrationStatus, PlayerType, RegisterToLeague } from "./model";

class RegistrationApi {

    public static async sendSmsVerificationCode(phoneNumber: string, token: string) :Promise<boolean> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/players/me/phone/authentication`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                phoneNumber
            })
        });

        return response.ok;
    }

    public static async verifySmsCode(code: string, token: string): Promise<boolean> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/players/me/phone/verification`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                code
            })
        });

        return response.ok;
    }

    public static async getPriceInfo(leagueId: string, leagueYear: string, promoCode: string, referral: string, playerId?: string) : Promise<PriceInfo> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/payment/${leagueYear}/${leagueId}/price-info`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                promoCode,
                referral,
                playerId
            })
        });

        if(response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    public static async register(token: string, playerType: PlayerType, promoCode: string, referral: string, target: RegisterToLeague, isUpgrading: boolean) : Promise<RegistrationStatus> {
        const config = await getConfig();

        const response = await fetch(`${config.apiUrl}/registrations`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                playerType,
                promoCode,
                referral,
                target,
                isUpgrading
            })
        });

        if(response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }
}

export default RegistrationApi;