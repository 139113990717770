import { ActionCreator2, ActionWithPayload2, Action2, ActionWithPayloadCreator2, ActionWithErrorCreator2, ActionWithError2 } from "../../framework/action";
import { CreateMatchOfferPayload, FormState, MatchOfferHeader } from "./models";
import { CREATE_MATCH_OFFER, CREATE_MATCH_OFFER_SUCCESS, CREATE_MATCH_OFFER_FAILED, CHANGE_FORM_STATE, GET_MATCH_OFFERS, GET_MATCH_OFFERS_SUCCESS, GET_MATCH_OFFERS_FAILED, CANCEL_MATCH_OFFER, CANCEL_MATCH_OFFER_SUCCESS, ACCEPT_MATCH_OFFER, ACCEPT_MATCH_OFFER_SUCCESS, ACCEPT_MATCH_OFFER_FAILED, CANCEL_MATCH_OFFER_FAILED } from "./actionTypes";

export interface CreateMatchOffer extends ActionWithPayload2<typeof CREATE_MATCH_OFFER, CreateMatchOfferPayload> { }

export interface CreateMatchOfferSuccess extends Action2<typeof CREATE_MATCH_OFFER_SUCCESS> { }

export interface CreateMatchOfferFailed extends ActionWithError2<typeof CREATE_MATCH_OFFER_FAILED> { }

export interface ChangeFormState extends ActionWithPayload2<typeof CHANGE_FORM_STATE, FormState> { }

export interface GetMatchOffers extends ActionWithPayload2<typeof GET_MATCH_OFFERS, { leagueId: string, leagueYear: string }> { }

export interface GetMatchOffersSuccess extends ActionWithPayload2<typeof GET_MATCH_OFFERS_SUCCESS, MatchOfferHeader[]> { }

export interface GetMatchOffersFailed extends ActionWithError2<typeof GET_MATCH_OFFERS_FAILED> { }

export interface CancelMatchOffer extends ActionWithPayload2<typeof CANCEL_MATCH_OFFER, { id: string, leagueId: string, leagueYear: string, token: string}> { }

export interface CancelMatchOfferSuccess extends Action2<typeof CANCEL_MATCH_OFFER_SUCCESS> { }

export interface CancelMatchOfferFailed extends ActionWithError2<typeof CANCEL_MATCH_OFFER_FAILED> { }

export interface AcceptMatchOffer extends ActionWithPayload2<typeof ACCEPT_MATCH_OFFER, { id: string, leagueId: string, leagueYear: string, token: string }> { }

export interface AcceptMatchOfferSuccess extends Action2<typeof ACCEPT_MATCH_OFFER_SUCCESS> { }

export interface AcceptMatchOfferFailed extends ActionWithError2<typeof ACCEPT_MATCH_OFFER_FAILED> { }

const handleSubmitMatchFailedDescription = (error: any) => {
    if(!error || !error.id) {
        return "Akceptacja propozycji meczu nie powiodła się, spróbuj ponownie!";
    }
    switch(error.id) {
        case 1: return "Liga nie pozwala jeszcze na umawianie nowych meczów";
        case 2: return "Zawodnik nie jest zapisany do ligii";
        case 3: return "Przeciwnik nie jest zapisany do ligii";
        case 4: return "Klub nie wspiera tej ligii";
        case 5: return "Zawodnicy rozegrali już mecz między sobą, lub mają taki mecz umówiony";
        case 15: return "Propozycja już nieaktualna bądź próbujesz zaaceptować własną propoyzcję";
        default: return "Akceptacja propozycji meczu nie powiodła się, spróbuj ponownie!";
    }
}

const handleSubmitMatchFailedContext = (error: any) => {
    if(error && error.id) {
        return {
            warning: true
        };
    } 

    return undefined;
}

export const createMatchOfferSuccess: ActionCreator2<CreateMatchOfferSuccess> = () => ({ type: "CREATE_MATCH_OFFER_SUCCESS" });
export const createMatchOffer: ActionWithPayloadCreator2<CreateMatchOffer> = (payload) => ({ type: "CREATE_MATCH_OFFER", payload });
export const createMatchOfferFailed: ActionWithErrorCreator2<CreateMatchOfferFailed> = (error) => ({ type: "CREATE_MATCH_OFFER_FAILED", error, description: "Nie udało się wpisać meczu do Giełdy Meczów." })
export const createChangeFormState: ActionWithPayloadCreator2<ChangeFormState> = (formState) => ({ type: CHANGE_FORM_STATE, payload: formState });

export const getMatchOffers: ActionWithPayloadCreator2<GetMatchOffers> = (payload) => ({ type: "GET_MATCH_OFFERS", payload });
export const getMatchOffersSuccess: ActionWithPayloadCreator2<GetMatchOffersSuccess> = (payload) => ({ type: "GET_MATCH_OFFERS_SUCCESS", payload });
export const getMatchOffersFailed: ActionWithErrorCreator2<GetMatchOffersFailed> = (error) => ({ type: "GET_MATCH_OFFERS_FAILED", error, description: "Nie udało nam się załadować Giełdy Meczów." });

export const cancelMatchOffer: ActionWithPayloadCreator2<CancelMatchOffer> = (payload) => ({ type: "CANCEL_MATCH_OFFER", payload });
export const cancelMatchOfferSuccess: ActionCreator2<CancelMatchOfferSuccess> = () => ({ type: "CANCEL_MATCH_OFFER_SUCCESS" });
export const cancelMatchOfferFailed: ActionWithErrorCreator2<CancelMatchOfferFailed> = (error) => ({ type: "CANCEL_MATCH_OFFER_FAILED", error, description: "Nie udało nam się anulować Twojego meczu. Nie martw się jednak, nasze chomiki biegną na ratunek aby anulować ten mecz!" });

export const acceptMatchOffer: ActionWithPayloadCreator2<AcceptMatchOffer> = (payload) => ({ type: "ACCEPT_MATCH_OFFER", payload });
export const acceptMatchOfferSuccess: ActionCreator2<AcceptMatchOfferSuccess> = () => ({ type: "ACCEPT_MATCH_OFFER_SUCCESS" });
export const acceptMatchOfferFailed: ActionWithErrorCreator2<AcceptMatchOfferFailed> = (error) => ({ type: "ACCEPT_MATCH_OFFER_FAILED", error, description: handleSubmitMatchFailedDescription(error), context: handleSubmitMatchFailedContext(error) })

export type Actions = 
    | CreateMatchOffer
    | CreateMatchOfferSuccess
    | CreateMatchOfferFailed
    | ChangeFormState
    | GetMatchOffers
    | GetMatchOffersSuccess
    | GetMatchOffersFailed
    | CancelMatchOffer
    | CancelMatchOfferFailed
    | CancelMatchOfferSuccess
    | AcceptMatchOffer
    | AcceptMatchOfferFailed
    | AcceptMatchOfferSuccess;