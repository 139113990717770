import React from "react";
import { Product, Basket } from "../../store/shop/models";
import {
  List,
  ListItemText,
  ListItem,
  makeStyles,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { basketSum, productCount } from "../../store/shop/utils";

interface ProductsListProps {
  products: Product[];
  productAdded: (p: Product) => void;
  productRemoved: (p: Product) => void;
  basket: Basket;
  pointsBalance: number;
}

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    backgroundSize: "contain"
  }, 
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: 430
  },
  textContent: {
    flexGrow: 1
  }
}));

const ProductsList: React.FC<ProductsListProps> = ({
  products,
  productAdded,
  basket,
  pointsBalance,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        {products.map((p: Product) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={p.id}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={`data:image/png;base64,${p.base64Image}`}
                ></CardMedia>
              </CardActionArea>
              <CardContent className={classes.textContent}>
                <Typography gutterBottom variant="h6" component="h2">
                  {p.name}
                </Typography>
                <Typography gutterBottom variant="body2" component="p">
                  {p.additionalInfo}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {`Koszt w SmartPunktach: ${p.smartPointsPrice}`}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" startIcon={<AddShoppingCartIcon />} color="primary" disabled={basketSum(basket) + p.smartPointsPrice > pointsBalance || productCount(p.id, basket) === p.quantity } onClick={() => productAdded(p)}>
                  Dodaj do koszyka
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
    </>
  );
};

export default ProductsList;
