import { Player } from "./models";
import { Data, NOT_STARTED, LOADING, fail, dataOf } from "../../framework/data";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    me: Data<Player>,
    all: Data<Player[]>
}

function reduceMe(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_USERS_PLAYER": return LOADING;
        case "LOAD_USER_PLAYER_FAILED": return fail(action.error);
        case "LOAD_USER_PLAYER_SUCCESS": return dataOf(action.payload);
        case "CREATE_PLAYER": return LOADING;
        case "CREATE_PLAYER_FAILED": return fail(action.error);
        case "CREATE_PLAYER_SUCCESS": return dataOf(action.payload);
        case "UPDATE_PLAYER_FAILED": return fail(action.error);
        case "UPDATE_PLAYER_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

function reduceAll(state = NOT_STARTED, action: Actions) {
    switch(action.type){
        case "LOAD_ALL_PLAYERS": return LOADING;
        case "LOAD_ALL_PLAYERS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({ me: reduceMe, all: reduceAll });