import React from "react";
import { PlayerType, RegisterToLeague } from "../../store/registration/model";
import { AppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { setRegistrationPlayerType } from "../../store/registration/actions";
import { connect } from "react-redux";
import { Paper, makeStyles, Theme, createStyles, Typography, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Button, Divider, CircularProgress, Tooltip, IconButton, Chip } from "@material-ui/core";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { currentPlayer } from "../../store/players/selectors";
import { Player } from "../../store/players/models";
import { Data } from "../../framework/data";
import { Alert } from "@material-ui/lab";
import { LeagueHeader } from "../../store/leagues/models";
import ErrorIcon from '@material-ui/icons/Error';

interface PlayerTypeProps {
    playerType: PlayerType;
    leagues: Data<LeagueHeader[]>;
    setPlayerType: (x: PlayerType) => void;
    selectedLeague: RegisterToLeague;
    onProceed: () => void;
    onMoveBack: () => void;
    currentPlayer: Data<Player>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectPanel: {
            borderLeft: 0,
            borderRight: 0,
            margin: '0 -32px',
            padding: '16px 32px',
            backgroundColor: theme.palette.grey[100]
        },
        title: {
            fontSize: 14
        },
        listIcon: {
            minWidth: 32,
            height: 20
        },
        selectedPlayerType: {
            boxShadow: `0px 0px 5px 0px ${theme.palette.primary.main} inset`
        },
        selectablePlayerType: {
            cursor: "pointer"
        },
        disabledPlayerType: {
            opacity: 0.5
        }
    }),
);

const PlayerTypeComponent: React.FC<PlayerTypeProps> = ({ playerType, selectedLeague, setPlayerType, onProceed, onMoveBack, currentPlayer, leagues }) => {

    const classes = useStyles();
    const regularDisabled = !!selectedLeague.city;
    const trialDisabled = currentPlayer.type === "LOADED" && !!currentPlayer.value && currentPlayer.value.playerType === PlayerType.REGULAR;

    return <>
        {
            currentPlayer.type === "LOADED" && leagues.type === "LOADED" ? (
                <>
                    <Paper variant="outlined" className={classes.selectPanel} square>
                        <Typography variant="subtitle1" style={{ marginLeft: 8 }}>Rodzaj konta</Typography>

                        {
                            (regularDisabled && trialDisabled) ? (
                                <Alert severity="error">Niestety, nie jest możliwa rejestracja istniejącego (płatnego) zawodnika do ligi w mieście, które nie wspiera w pełni ligi. Prosimy o kontakt z <a href="mailto:support@smarttennis.pl">support@smarttennis.pl</a></Alert>
                            ) : (<></>)
                        }

                        <Grid container direction="row" spacing={2}
                            justify="center"
                            alignItems="stretch">
                            <Grid item xs={12} md={6} lg={5} className={regularDisabled ? classes.disabledPlayerType : ""}>
                                <Card variant="outlined" className={(playerType === PlayerType.REGULAR) ? classes.selectedPlayerType : classes.selectablePlayerType} onClick={() => !regularDisabled && setPlayerType(PlayerType.REGULAR)}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary">
                                            Konto
                                </Typography>
                                        <Typography variant="h5" component="h2">
                                            SMART {
                                                !regularDisabled ? (
                                                    
                                                    <b> - {leagues.value.find(x => x.id === selectedLeague.league.id)?.basePrice} zł</b>
                                                
                                                ) : (<></>)
                                            }
                                </Typography>
                                        <List dense={true}>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        dostęp do bazy przeciwników na podobnym poziomie
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        możliwość umawiania się na mecze przez system
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        rywalizacja o turniej finałowy i nagrody rzeczowe
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        zniżki na rezerwacje partnerskich kortów tenisowych
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        Smart Punkty wymienne na piłki, vouchery, owijki itp.
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} lg={5} className={trialDisabled ? classes.disabledPlayerType : ""}>
                                <Card variant="outlined" className={(playerType === PlayerType.TRIAL) ? classes.selectedPlayerType : classes.selectablePlayerType} onClick={() => !trialDisabled && setPlayerType(PlayerType.TRIAL)}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary">
                                            Konto
                                </Typography>
                                        <Typography variant="h5" component="h2">
                                            Próbne (max 3 mecze) <b>- 0 zł</b> 
                                </Typography>
                                <Typography className={classes.title} color="textSecondary">(dostępne tylko dla nowych graczy)</Typography>
                                        <List dense={true}>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        dostęp do bazy przeciwników na podobnym poziomie
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        możliwość umawiania się na mecze przez system
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CheckCircleIcon style={{ height: 20 }} color="primary" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        zniżki na rezerwacje partnerskich kortów tenisowych
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CancelIcon style={{ height: 20 }} color="error" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        rywalizacja o turniej finałowy i nagrody rzeczowe
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <CancelIcon style={{ height: 20 }} color="error" />
                                                </ListItemIcon>
                                                <ListItemText >
                                                    <Typography variant="body2" color="textSecondary">
                                                        Smart Punkty wymienne na piłki, vouchery, owijki itp.
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                    </Paper>
                    <Divider style={{ marginTop: 24, marginBottom: 32 }}></Divider>
                    <Button color="primary" onClick={onMoveBack}>
                        Wstecz
                    </Button>
                    <Button color="primary" variant="contained" onClick={onProceed}>
                        Dalej
                    </Button>
                </>
            ) : (
                    <div
                        style={{
                            width: "64px",
                            height: "64px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <CircularProgress />
                    </div>
                )
        }

    </>;
};

const mapStateToProps = (state: AppState) => ({
    playerType: state.registration.playerType,
    selectedLeague: state.registration.leagueSelection,
    currentPlayer: currentPlayer(state),
    leagues: state.leagues.items
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setPlayerType: (x: PlayerType) => dispatch(setRegistrationPlayerType(x))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerTypeComponent);