import React from "react";
import { MatchHeader } from "../../store/matches/models";
import { Paper, Typography, makeStyles, Grid, Link } from "@material-ui/core";
import DateTimeView from "../../framework/components/DateTimeView";
import { firstPlayerWon, secondPlayerWon } from "./utils";

interface NewestMatchProps {
  match: MatchHeader;
}

interface NewestMatchesProps {
  matches: MatchHeader[];
}

const useStyles = makeStyles(theme => ({
  res: {
    display: "inline-block",
    margin: "0 2px"
  },
  restb: {
    verticalAlign: "super",
    fontSize: "smaller"
  },
  gp: {
    padding: "0 8px"
  }
}));

const NewestMatch: React.FC<NewestMatchProps> = ({ match }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" style={{ padding: "8px", marginBottom: "8px" }}>
      <Grid container justify="space-around">
        <Grid item>
          <Typography color="secondary">
            <i style={{
              fontWeight:
                firstPlayerWon(match)
                  ? "bolder"
                  : "normal"
            }}>
              <Link color="secondary" href={`/#/stats/${btoa(match.firstPlayer.id)}`}>
                {match.firstPlayer.name} {match.firstPlayer.surname}
              </Link>
            </i>{" "}
            vs.{" "}
            <i style={{
              fontWeight:
                secondPlayerWon(match)
                  ? "bolder"
                  : "normal"
            }}>
              <Link color="secondary" href={`/#/stats/${btoa(match.secondPlayer.id)}`}>
                {match.secondPlayer.name} {match.secondPlayer.surname}
              </Link>
            </i>
          </Typography>
        </Grid>
        <Grid item>
          <DateTimeView value={match.date}></DateTimeView>
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: "8px" }} spacing={2}>
        {match.isOponentRetired ? (
          <Grid item xs={4}>
            <Typography variant="h6" style={{ textAlign: "center", backgroundColor: '#e0a4a4', borderRadius: '4px' }}>
              <b className={classes.res}>wo</b>
            </Typography>
          </Grid>
        ) : (<></>)}

        {match.score?.map((s, i) => (
          <Grid item xs={4} key={i}>
            <Typography variant="h6" style={{ textAlign: "center", backgroundColor: '#e0e0e0', borderRadius: '4px' }}>
              <span className={classes.res}>{s.firstPlayer}</span>
              {!!s.tieBreak ? (
                <span className={classes.restb}>{s.tieBreak.firstPlayer}</span>
              ) : (
                  <></>
                )}
              <span className={classes.res}>:</span>
              <span className={classes.res}>{s.secondPlayer}</span>
              {!!s.tieBreak ? (
                <span className={classes.restb}>{s.tieBreak.secondPlayer}</span>
              ) : (
                  <></>
                )}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const NewestMatches: React.FC<NewestMatchesProps> = ({ matches }) => (
  <>
    {matches.map(m => (
      <NewestMatch key={m.id} match={m}></NewestMatch>
    ))}
  </>
);

export default NewestMatches;
