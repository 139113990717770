import React from "react";
import { Clubs } from "../../store/leagues/models";
import { Grid, Typography } from "@material-ui/core";

interface PrivateLeagueClubProps {
    club: Clubs;
}

const PrivateLeagueClub: React.FC<PrivateLeagueClubProps> = ( {club} ) => {
    return (

        <Grid container spacing={3}>
            <Grid item>
                <Typography variant="body1">
                    To jest zamknięta liga, sponsorowana przez:
                </Typography>
            </Grid>
            <Grid item key={club.id}><img src={club.logoUrl} style={{height: '64px'}} alt={club.name} title={club.name} /><span style={{ display: 'inline-block', paddingTop: '24px', height: '20px', verticalAlign: 'top' }}>{club.name}</span></Grid>
        </Grid>
    );
}

export default PrivateLeagueClub;