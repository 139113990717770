import * as React from "react";
import { makeStyles, Card, CardActionArea, CardMedia, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        maxWidth: 400,
    },
});

interface SponsorProps {
    name: string;
    description: string;
    url: string;
    logoUrl: string;
}

const Sponsor: React.FC<SponsorProps> = ({ name, description, logoUrl, url }) => {

    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={0} variant="outlined">
      <CardActionArea href={url} target="_blank">
        <CardMedia
          component="img"
          alt={`${name} logo`}
          height="140"
          image={logoUrl}
          title={`${name} logo`}
          style={{objectFit: 'contain', paddingTop: 8, marginLeft: 8, marginRight: 8, width: "calc(100% - 16px)"}}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      </Card>
    )

}

export default Sponsor;