import React from "react";
import { Data } from "../../framework/data";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import RegistrationWizard, { StepsMode } from "./RegistrationWizard";
import { useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { setRegistrationForPlayerTypeUpgrade, setRegistrationPlayerType } from "../../store/registration/actions";
import { PlayerType } from "../../store/registration/model";

export interface RegistrationPageProps {
    player: Data<Player>;
    leagueBasePrice: number;
    upgradePlayer: (leagueId: string, leagueYear: string, player: Player) => void;
    setPlayerTypeRegular: () => void;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RegistrationPage: React.FC<RegistrationPageProps> = ({ player, upgradePlayer, leagueBasePrice, setPlayerTypeRegular }) => {

    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const [mode, setMode] = React.useState<StepsMode>("NORMAL");

    const query = useQuery();

    React.useEffect(() => {

        if (player.type === "LOADED" && player.value) {

            if (query.has('leagueId') && query.has('leagueYear')) {
                upgradePlayer(query.get('leagueId')!, query.get('leagueYear')!, player.value!);
                setMode("UPGRADE_TO_PLAYER")
            } else {
                if (leagueBasePrice === 0) {
                    setPlayerTypeRegular();
                    setMode("FREE_LEAGUE");
                } else {
                    setMode("NORMAL");
                }
            }
        }

    }, [player, query.get('leagueId'), query.get('leagueYear'), leagueBasePrice]);

    return (<>
        {
            !isAuthenticated ? (
                <Alert severity="info">
                    <div>
                        <Link color="textPrimary" href="" onClick={() => loginWithRedirect({
                            redirectUri: window.location.href, appState: {
                                targetUrl: window.location.hash
                            }
                        })}><b>Kliknij tutaj</b></Link><span> aby zalogować się lub założyć konto w celu rozpoczęcia rejestracji.</span>
                    </div>
                </Alert>
            ) : (
                    <RegistrationWizard stepsMode={mode}></RegistrationWizard>
                )
        }
    </>);
};

const mapStateToProps = (state: AppState) => ({
    player: currentPlayer(state),
    leagueBasePrice: state.registration.leagueSelection.league.basePrice
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    upgradePlayer: (leagueId: string, leagueYear: string, player: Player) => dispatch(setRegistrationForPlayerTypeUpgrade({
        leagueId,
        leagueYear,
        player
    })),
    setPlayerTypeRegular: () => dispatch(setRegistrationPlayerType(PlayerType.REGULAR))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);