import { call, put, takeEvery } from "redux-saga/effects";
import { getConfig } from "../../config/configuration";
import { loadMyConfirmedMatchesFailed, loadMyConfirmedMatchesSuccess, LoadMyUnconfirmedMatches, loadMyUnconfirmedMatchesFailed, loadMyUnconfirmedMatchesSuccess } from "./actions";
import { LOAD_MY_CONFIRMED_MATCHES, LOAD_MY_UNCONFIRMED_MATCHES } from "./actionTypes";
import DesktopApi from "./api";
import { MatchHeader } from "../matches/models";

function* loadMyUnconfirmedMatchesStep({ payload } : LoadMyUnconfirmedMatches) {
    try {
        const config = yield call(getConfig);

        const seasons = config.matchesSeasons as string[];

        let result: MatchHeader[] = [];

        for(let i = 0; i < seasons.length; i++) {
            const res: MatchHeader[] = yield call(DesktopApi.getUnconfirmedMatches, payload, seasons[i]);
            result = [...result, ...res];
        }
    
        yield put(loadMyUnconfirmedMatchesSuccess(result));
    } catch(e) {
        yield put(loadMyUnconfirmedMatchesFailed(e));
    }
}

function* loadMyConfirmedMatchesStep({ payload } : LoadMyUnconfirmedMatches) {
    try {
        const config = yield call(getConfig);

        const seasons = config.matchesSeasons as string[];

        let result: MatchHeader[] = [];

        for(let i = 0; i < seasons.length; i++) {
            const res: MatchHeader[] = yield call(DesktopApi.getConfirmedMatches, payload, seasons[i]);
            result = [...result, ...res];
        }

        yield put(loadMyConfirmedMatchesSuccess(result));
    } catch(e) {
        yield put(loadMyConfirmedMatchesFailed(e));
    }
}

export function* loadMyUnconfirmedMatchesSaga() {
    yield takeEvery(LOAD_MY_UNCONFIRMED_MATCHES, loadMyUnconfirmedMatchesStep);
}

export function* loadMyConfirmedMatchesSaga() {
    yield takeEvery(LOAD_MY_CONFIRMED_MATCHES, loadMyConfirmedMatchesStep);
}