import { SEND_SMS_VERIFICATION_CODE, VERIFY_SMS_CODE, GET_PRICE_INFO_FOR_LEAGUE, MAKE_REGISTRATION } from "./actionTypes";
import { takeEvery, call, put, take } from "redux-saga/effects";
import { SendSmsVerificationCode, VerifySmsCode, verifySmsCodeSuccess, verifySmsCodeFailed, GetPriceInfoForLeague, getPriceInfoForLeagueFailed, getPriceInfoForLeagueSuccess, MakeRegistration, sendRegistrationMessage, makeRegistrationFailed, makeRegistrationSuccess, sendSmsVerificationCodeFailed, sendSmsVerificationCodeSuccess } from "./actions";
import RegistrationApi from "./api";
import { updatePlayer, createPlayer } from "../players/actions";
import { CREATE_PLAYER_SUCCESS, UPDATE_PLAYER_SUCCESS } from "../players/actionTypes";
import { RegistrationStatus } from "./model";

function* sendSmsVerificationCodeStep(action: SendSmsVerificationCode) {
    const { phoneNumber, token } = action.payload;

    const isOk = yield call(RegistrationApi.sendSmsVerificationCode, phoneNumber, token);
    
    if(!isOk) {
        yield put(sendSmsVerificationCodeFailed(""));
    } else {
        yield put(sendSmsVerificationCodeSuccess(""));
    }

    
}

function* verifySmsCodeStep(action: VerifySmsCode) {
    const { code, token } = action.payload;

    const isOk = yield call(RegistrationApi.verifySmsCode, code, token);

    if(isOk) {
        yield put(verifySmsCodeSuccess());
    } else {
        yield put(verifySmsCodeFailed(""));
    }
}

function* getPriceInfoStep(action: GetPriceInfoForLeague) {
    const { leagueId, leagueYear, promoCode, referral, playerId } = action.payload;

    try
    {
        var result = yield call(RegistrationApi.getPriceInfo, leagueId, leagueYear, promoCode, referral, playerId);

        yield put(getPriceInfoForLeagueSuccess(result));
    }
    catch(e) {
        yield put(getPriceInfoForLeagueFailed(e))
    }
}

function* startRegistration({ payload }: MakeRegistration) {
    const { playerData, playerType, promoCode, referral, target, token, isUpgrading } = payload;

    if(playerData.id) {
        yield put(sendRegistrationMessage("Uaktualniam dane zawodnika..."));
        yield put(updatePlayer({
            player: playerData,
            playerType,
            token
        }));

        yield take(UPDATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik uatkualniony"));
    } else {
        yield put(sendRegistrationMessage("Tworzę nowege zawodnika..."));
        yield put(createPlayer({
            player: playerData,
            playerType,
            token
        }));

        yield take(CREATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik utworzony"));
    }

    if(target.city) {
        yield put(sendRegistrationMessage("Rejestrujemy..."));
    } else if(target.league) {
        yield put(sendRegistrationMessage("Zapisujemy do ligi..."));
    }

    try {
        const result: RegistrationStatus = yield call(RegistrationApi.register, token, playerType, promoCode, referral, target, isUpgrading);
        if(result.isSuccess) {
            yield put(makeRegistrationSuccess({}));

            if(result.paymentLink) {
                window.location.assign(result.paymentLink);
            } else {
                yield put(sendRegistrationMessage("To już wszystko! W mailu wysłaliśmy Ci informacje o dalszych krokach."))
            }
        } else {
            yield put(makeRegistrationFailed("błąd", { description: result.errorMessage }));
        }
    } catch(e) {
        yield put(makeRegistrationFailed(e, {}));
    }

}

export function* sendSmsVerificationCodeSaga() {
    yield takeEvery(SEND_SMS_VERIFICATION_CODE, sendSmsVerificationCodeStep)
}

export function* verifySmsCodeSaga() {
    yield takeEvery(VERIFY_SMS_CODE, verifySmsCodeStep);
}

export function* getPriceInfoSaga() {
    yield takeEvery(GET_PRICE_INFO_FOR_LEAGUE, getPriceInfoStep);
}

export function* makeRegistrationSaga() {
    yield takeEvery(MAKE_REGISTRATION, startRegistration);
}