import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Grid, CircularProgress, Divider } from "@material-ui/core";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { currentPlayer } from "../../store/players/selectors";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Data, NOT_STARTED } from "../../framework/data";
import ShoppingInfo from "./ShoppingInfo";
import {
  Product,
  ProductItem,
  Basket,
  Spot,
  OrderSubmissionState,
} from "../../store/shop/models";
import {
  loadProducts,
  addProductToBasket,
  removeProductFromBasket,
  loadSpots,
  setSpot,
  createShoppingOrder,
} from "../../store/shop/actionts";
import ShoppingProcess from "./ShoppingProcess";
import BasketInfo from "./BasketInfo";
import { useAuth0 } from "@auth0/auth0-react";

interface ShopPageProps {
  currentPlayer: Data<Player>;
  loadProducts: () => void;
  products: Data<Product[]>;
  productAdded: (p: ProductItem) => void;
  basket: Basket;
  productRemoved: (p: ProductItem) => void;
  spots: Data<Spot[]>;
  loadSpots: () => void;
  spotSelected: (s: Spot) => void;
  selectedSpot: Spot | null;
  onCreateOrderRequested: (b: Basket, s: Spot, token: string) => void;
  submissionState: OrderSubmissionState;
}

const ShopPage: React.FC<ShopPageProps> = ({
  currentPlayer,
  products,
  loadProducts,
  productAdded,
  basket,
  productRemoved,
  spots,
  loadSpots,
  spotSelected,
  selectedSpot,
  onCreateOrderRequested,
  submissionState,
}) => {
  React.useEffect(() => {
    loadProducts();
  }, []);

  React.useEffect(() => {
    loadSpots();
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  return (
    <>
      <Grid container spacing={2} style={{ margin: "16px 0" }}>
        <Grid item xs={12}>
          <Alert severity="info">
            Witaj w miejscu, w którym możesz wymienić swoje ciężko zarobione
            SmartPunkty na konkretne nagrody!

          </Alert>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
          <ShoppingInfo player={currentPlayer}></ShoppingInfo>
          <div style={{ height: "16px" }}></div>
          <BasketInfo
            basket={basket}
            productRemoved={(p) => productRemoved(p)}
          ></BasketInfo>
        </Grid>
        <Grid item xs={12} md={9} xl={10}>
          {products.type === "LOADED" && spots.type === "LOADED" ? (
            <ShoppingProcess
              products={products.value}
              productAdded={(p) => productAdded(p)}
              basket={basket}
              pointsBalance={
                currentPlayer.type === "LOADED"
                  ? currentPlayer.value.rewardPoints || 0
                  : 0
              }
              spots={spots.value}
              spotSelected={spotSelected}
              selectedSpot={selectedSpot}
              onCreateOrderRequested={async (b: Basket, s: Spot) => {
                const token = await getAccessTokenSilently();
                onCreateOrderRequested(b, s, token);
              }}
              submissionState={submissionState}
            />
          ) : (
            <div
              style={{
                width: "64px",
                height: "64px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentPlayer: currentPlayer(state),
  products: state.shop.products,
  basket: state.shop.basket,
  spots: state.shop.spots,
  selectedSpot: state.shop.selectedSpot,
  submissionState: state.shop.submissionState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadProducts: () => dispatch(loadProducts()),
  productAdded: (p: ProductItem) => dispatch(addProductToBasket(p)),
  productRemoved: (p: ProductItem) => dispatch(removeProductFromBasket(p.id)),
  loadSpots: () => dispatch(loadSpots()),
  spotSelected: (s: Spot) => dispatch(setSpot(s)),
  onCreateOrderRequested: (basket: Basket, selectedSpot: Spot, token: string) =>
    dispatch(createShoppingOrder({ basket, selectedSpot, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
