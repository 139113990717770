import rootReducer from "./reducer";
import logger from '../framework/store-logger';
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import { all, spawn, call } from 'redux-saga/effects'

// Module specific imports
import { loadLeaguesSaga, loadMyLeaguesSaga, assignPlayerToLeagueSaga, loadLeagueDetailsSaga, loadMyPrivateLeaguesSaga, loadMyLeaguesWithPaymentSaga, loadOpponentsPlayedWithSaga } from './leagues/sagas';
import { loadCurrentPlayerSaga, createPlayerSaga, updatePlayerSaga, savePlayerSaga, loadAllPlayers } from './players/sagas';
import { loadClubsForProvinceSaga } from "./clubs/sagas";
import { submitMatchSaga, getNewestMatchesSaga, loadPlayersMatchesSaga, cancelMatchSaga, confirmMatchSaga, submitMatchResultSaga, getLeagueMatchesSage, loadPlayersTournamentMatchesSaga, confirmTournamentMatchSaga, cancelTournamentMatchSaga, submitTournamentMatchResultSaga, getTournamentMatchesSaga } from "./matches/sagas";
import { getUserPaymentsSaga, redoUserPaymentSaga } from "./payments/sagas";
import { loadProducts, loadSpots, createShoppingOrderSaga, loadMyOrdersSaga } from "./shop/sagas";
import { loadMyConfirmedMatchesSaga, loadMyUnconfirmedMatchesSaga } from "./desktop/sagas";
import { loadPlayerMatchesSaga, loadPlayerStatsSaga } from "./stats/sagas";
import { createMatchOfferSaga, getMatchOffersSaga, cancelMatchOfferSaga, acceptMatchOfferSaga } from "./match-offers/sagas";
import { sendSmsVerificationCodeSaga, verifySmsCodeSaga, getPriceInfoSaga, makeRegistrationSaga } from "./registration/sagas";
import { loadRankingSaga } from "./elo/sagas";
import { loadTournamentsSaga, registerToTournamentSaga, loadTournamentDetailsSaga, submitTournamentMatchSaga } from "./tournaments/sagas";
import { loadTrainingsSaga, loadTrainingDetailsSaga, registerToTrainingSaga } from "./trainings/sagas";

const sagaMiddleware = createSagaMiddleware()

export default createStore(rootReducer, applyMiddleware(logger, sagaMiddleware));

function* rootSaga() {
  const sagas = [loadLeaguesSaga, loadMyLeaguesSaga, loadCurrentPlayerSaga,
    createPlayerSaga, updatePlayerSaga, savePlayerSaga, assignPlayerToLeagueSaga,
    loadLeagueDetailsSaga, loadMyPrivateLeaguesSaga, submitMatchSaga, getNewestMatchesSaga,
    loadClubsForProvinceSaga, loadPlayersMatchesSaga, cancelMatchSaga, confirmMatchSaga, submitMatchResultSaga, getLeagueMatchesSage,
    getUserPaymentsSaga, loadMyLeaguesWithPaymentSaga, redoUserPaymentSaga, loadProducts, loadSpots, createShoppingOrderSaga, loadMyOrdersSaga,
    loadMyConfirmedMatchesSaga, loadMyUnconfirmedMatchesSaga, loadOpponentsPlayedWithSaga, loadPlayerMatchesSaga, loadPlayerStatsSaga,
    createMatchOfferSaga, getMatchOffersSaga, cancelMatchOfferSaga, acceptMatchOfferSaga, sendSmsVerificationCodeSaga, verifySmsCodeSaga, 
    getPriceInfoSaga, makeRegistrationSaga, loadRankingSaga, loadAllPlayers, loadTournamentsSaga, registerToTournamentSaga, 
    loadTournamentDetailsSaga, submitTournamentMatchSaga, loadPlayersTournamentMatchesSaga, confirmTournamentMatchSaga, cancelTournamentMatchSaga, 
    submitTournamentMatchResultSaga, getTournamentMatchesSaga, loadTrainingsSaga, loadTrainingDetailsSaga, registerToTrainingSaga];

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          console.log(e)
        }
      }
    }))
  );
}

sagaMiddleware.run(rootSaga);