import { put, take, takeEvery, call } from "redux-saga/effects";
import { LOAD_TOURNAMENTS, REGISTER_TO_TOURNAMENT, LOAD_TOURNAMENT_DETAILS, SUBMIT_TOURNAMENT_MATCH } from "./actionTypes";
import { LoadTournaments, loadTournamentsSuccess, RegisterToTournament, LoadTournamentDetails, loadTournamentDetailsSuccess, SubmitTournamentMatch, submitTournamentMatchSuccess, loadTournamentDetails, submitTournamentMatchFailed } from "./actions";
import { makeRegistrationFailed, makeRegistrationSuccess, sendRegistrationMessage} from "../registration/actions";
import { createPlayer, updatePlayer } from "../players/actions";
import { PlayerType, RegistrationStatus } from "../registration/model";
import { CREATE_PLAYER_SUCCESS, UPDATE_PLAYER_SUCCESS } from "../players/actionTypes";
import TournamentRegistrationApi from "./api";
import { TournamentHeader, TournamentDetails } from "./models";

function* loadTournamentsStep({ payload }: LoadTournaments) {
    // API CALL

    const {token, year} = payload;

    const result: TournamentHeader[] = yield call(TournamentRegistrationApi.load, token, year);

    yield put(loadTournamentsSuccess({ tournaments: result }))
}

function* loadTournamentDetailsStep({ payload }: LoadTournamentDetails) {
    const { id, token, year } = payload;
    const result: TournamentDetails = yield call(TournamentRegistrationApi.loadDetails, token, year, id);
    yield put(loadTournamentDetailsSuccess({ tournament: result }));
}

function* registerToTournamentStep({ payload }: RegisterToTournament) {
    const { playerData, target, token } = payload;

    if(playerData.id) {
        yield put(sendRegistrationMessage("Uaktualniam dane zawodnika..."));
        yield put(updatePlayer({
            player: playerData,
            playerType: playerData.playerType,
            token
        }));

        yield take(UPDATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik uatkualniony"));
    } else {
        yield put(sendRegistrationMessage("Tworzę nowege zawodnika..."));
        yield put(createPlayer({
            player: playerData,
            playerType: PlayerType.REGULAR,
            token
        }));

        yield take(CREATE_PLAYER_SUCCESS);
        yield put(sendRegistrationMessage("Zawodnik utworzony"));
    }

    yield put(sendRegistrationMessage("Zapisujemy na turniej..."));
    
    try {
        const result: RegistrationStatus = yield call(TournamentRegistrationApi.register, token, target);
        if(result.isSuccess) {
            yield put(makeRegistrationSuccess({}));
            yield put(sendRegistrationMessage("To już wszystko! Witamy na turnieju!"))
            window.location.assign(`/#/tournaments/${target.tournament.season}`);
            
            
        } else {
            yield put(makeRegistrationFailed("błąd", { description: result.errorMessage }));
        }
    } catch(e) {
        yield put(makeRegistrationFailed(e, {}));
    }

}

function* submitTournamentMatchStep({ payload}: SubmitTournamentMatch) {
    const { match, token, tournament } = payload;

    try {
        const result = yield call(TournamentRegistrationApi.submitMatch, token as any, tournament as any, match as any );
        if(result === true) {
            yield put(submitTournamentMatchSuccess());
            yield put(loadTournamentDetails({ year: tournament.year, id: tournament.id, token }));
            //yield put(getNewestMatches({ leagueId: league.id, year: league.year }));
        } else {
            yield put(submitTournamentMatchFailed(""));
        }
    } catch(e) {
        yield put(submitTournamentMatchFailed(e.message));
    }
}

export function* loadTournamentsSaga() {
    yield takeEvery(LOAD_TOURNAMENTS, loadTournamentsStep);
}

export function* registerToTournamentSaga() {
    yield takeEvery(REGISTER_TO_TOURNAMENT, registerToTournamentStep);
}

export function* loadTournamentDetailsSaga() {
    yield takeEvery(LOAD_TOURNAMENT_DETAILS, loadTournamentDetailsStep);
}

export function* submitTournamentMatchSaga() {
    yield takeEvery(SUBMIT_TOURNAMENT_MATCH, submitTournamentMatchStep);
}