import { AppState } from "../reducer";
import { createSelector } from "reselect";
import { dataOf } from "../../framework/data";

export const allClubs = (state: AppState) => state.clubs.clubs;

export const clubsWithGeoCoordinates = createSelector(allClubs, (c => {
    if(c.type === "LOADED") {
        return dataOf(c.value.filter(c => !!c.geoCoordinate));
    } else {
        return c;
    }
}));