import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let ai: ApplicationInsights|undefined;

const initialize = (key: string) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: key,
            autoExceptionInstrumented: true,
            autoUnhandledPromiseInstrumented: true,

        }
    });
    appInsights.loadAppInsights();

    ai = appInsights;
    return ai;
}

type LogProperties = { [key: string]: any }

const logError = (message: string, exception?: Error, properties?: LogProperties) => {
    ai?.trackException({ exception, properties });
}

const logEvent = (name: string, properties?: LogProperties) => {
    ai?.trackEvent({ name, properties });
}

const logPageView = (name: string, properties?: LogProperties) => {
    ai?.trackPageView({ name, properties });
}

export default { initialize, logError, logEvent, logPageView };