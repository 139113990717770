export const LOAD_TRAININGS = "LOAD_TRAININGS";
export const LOAD_TRAININGS_SUCCESS = "LOAD_TRAININGS_SUCCESS";
export const LOAD_TRAININGS_FAILED = "LOAD_TRAININGS_FAILED";

export const REGISTER_TO_TRAINING = "REGISTER_TO_TRAINING";
export const REGISTER_TO_TRAINING_SUCCESS = "REGISTER_TO_TRAINING_SUCCESS";
export const REGISTER_TO_TRAINING_FAILED = "REGISTER_TO_TRAINING_FAILED";

export const LOAD_TRAINING_DETAILS = "LOAD_TRAINING_DETAILS";
export const LOAD_TRAINING_DETAILS_SUCCESS = "LOAD_TRAINING_DETAILS_SUCCESS";
export const LOAD_TRAINING_DETAILS_FAILED = "LOAD_TRAINING_DETAILS_FAILED";