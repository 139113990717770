import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  FormHelperText,
} from "@material-ui/core";
import { Spot } from "../../store/shop/models";
import { groupBy } from "ramda";

interface SpotPlaceProps {
  spots: Spot[];
  spotSelected: (spot: Spot) => void;
  selectedSpot: Spot|null;
}

const SpotPlace: React.FC<SpotPlaceProps> = ({ spots, spotSelected, selectedSpot }) => {
  const [error, setError] = React.useState(!selectedSpot);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "250px" }}
    >
      <Grid item>
        <FormControl style={{ minWidth: "350px" }} error={error}>
          <InputLabel htmlFor="spot-select">Wybierz miejsce odbioru</InputLabel>
          <Select
            defaultValue={selectedSpot ? selectedSpot.id : ""}
            id="spot-select"
            onChange={(e) => {
              const spot = spots.find((x) => x.id === e.target.value);
              if (!spot) {
                setError(true);
              } else {
                setError(false);
                spotSelected(spot);
              }
            }}
          >
            <MenuItem value="">
              <em>Wybierz...</em>
            </MenuItem>
            {Object.entries(groupBy((sg) => sg.city, spots)).map(
              ([name, spots]) => {
                return [
                  <ListSubheader>{name}</ListSubheader>,
                  ...spots.map((s) => (
                    <MenuItem value={s.id}>
                      {s.name}, {s.address}, {s.city}
                    </MenuItem>
                  )),
                ];
              }
            )}
          </Select>
          <FormHelperText color="red">Proszę wybrać miejsce, w których możesz odebrać zamówienie</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SpotPlace;
