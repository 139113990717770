import { ActionWithPayload, ActionWithError, ActionWithPayloadCreator, ActionWithErrorCreator } from "../../framework/action";
import { LOAD_PLAYER_STATS, LOAD_PLAYER_STATS_SUCCESS, LOAD_PLAYER_STATS_FAILED, LOAD_PLAYER_MATCHES, LOAD_PLAYER_MATCHES_SUCCESS, LOAD_PLAYER_MATCHES_FAILED } from "./actionTypes";
import { Stat } from "./model";
import { PlayerMatch } from "../matches/models";

export interface LoadPlayerStats extends ActionWithPayload<string> {
    type: typeof LOAD_PLAYER_STATS;
}

export interface LoadPlayerStatsSuccess extends ActionWithPayload<Stat[]> {
    type: typeof LOAD_PLAYER_STATS_SUCCESS;
}

export interface LoadPlayerStatsFailed extends ActionWithError {
    type: typeof LOAD_PLAYER_STATS_FAILED;
}

export interface LoadPlayerMatches extends ActionWithPayload<string> {
    type: typeof LOAD_PLAYER_MATCHES;
}

export interface LoadPlayerMatchesSuccess extends ActionWithPayload<PlayerMatch[]> {
    type: typeof LOAD_PLAYER_MATCHES_SUCCESS;
} 

export interface LoadPlayerMatchesFailed extends ActionWithError {
    type: typeof LOAD_PLAYER_MATCHES_FAILED;
}

export const loadPlayerStats: ActionWithPayloadCreator<string> = (player) => ({ type: LOAD_PLAYER_STATS, payload: player  });
export const loadPlayerStatsSuccess: ActionWithPayloadCreator<Stat[]> = (stats) =>  ({ type: LOAD_PLAYER_STATS_SUCCESS, payload: stats});
export const loadPlayerStatsFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_PLAYER_STATS_FAILED, error, description: 'Nie udało nam się załadować wszystkich statystyk.' });

export const loadPlayerMatches: ActionWithPayloadCreator<string> = (player) => ({ type: LOAD_PLAYER_MATCHES, payload: player });
export const loadPlayerMatchesSuccess: ActionWithPayloadCreator<PlayerMatch[]> = (matches) => ({ type: LOAD_PLAYER_MATCHES_SUCCESS, payload: matches });
export const loadPlayerMatchesFailed: ActionWithErrorCreator = (error) => ({ type: LOAD_PLAYER_MATCHES_FAILED, error, description: 'Nie udało nam się załadować wszystkich meczy zawodnika' });

export type Actions = 
    | LoadPlayerStats
    | LoadPlayerStatsFailed
    | LoadPlayerStatsSuccess
    | LoadPlayerMatches
    | LoadPlayerMatchesFailed
    | LoadPlayerMatchesSuccess;