import React from "react";

interface HourProps {
    hour: string;
}

const Hour: React.FC<HourProps> = ({ hour }) => {
    const [hours, minutes, _] = hour.split(':')
    return (<>{ `${hours}:${minutes}` }</>);
}

export default Hour;