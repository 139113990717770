import React from "react";
import { MatchOfferHeader } from "../../store/match-offers/models";
import { Player } from "../../store/players/models";
import MatchOfferListItem from "./MatchOfferListItem";
import { LeagueWithPlayersICanPlay } from "../../store/leagues/models";
import { Club } from "../../store/clubs/models";

interface MatchOffersListProps {
    offers: MatchOfferHeader[];
    player?: Player;
    league?: LeagueWithPlayersICanPlay;
    clubs: Club[];
    cancelMatchOffer: (id: string) => void;
    acceptMatchOffer: (id: string) => void;
}

const MatchOffersList: React.FC<MatchOffersListProps> = ({ offers, player, league, acceptMatchOffer, cancelMatchOffer, clubs }) => <>{
    offers.map(x => (
    <MatchOfferListItem 
        key={x.id} 
        item={x} 
        player={player} 
        league={league}
        clubs={clubs}
        acceptMatchOffer={acceptMatchOffer}
        cancelMatchOffer={cancelMatchOffer}
        >
    </MatchOfferListItem>))}</>;

export default MatchOffersList;