import { LOAD_RANKING } from "./actionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
import { LoadRanking, loadRankingSuccess, loadRankingFailed } from "./actions";
import EloApi from "./api";

function* loadRankingStep({ payload } : LoadRanking) {
    try {
        var result = yield call(EloApi.loadRanking);

        yield put(loadRankingSuccess(result));
    } catch (e) {
        yield put(loadRankingFailed(e));
    }
}

export function* loadRankingSaga() {
    yield takeEvery(LOAD_RANKING, loadRankingStep)
}