import { Data, NOT_STARTED, LOADING, dataOf, fail } from "../../framework/data";
import { Club } from "./models";
import { Actions } from "./actions";
import { combineReducers } from "redux";

export interface State {
    clubs: Data<Club[]>;
}

function reduceClubs(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_CLUBS": return LOADING;
        case "LOAD_CLUBS_FAILED": return fail(action.error);
        case "LOAD_CLUBS_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({ clubs: reduceClubs });