import { combineReducers } from "redux";
import { Data, dataOf, fail, LOADING, NOT_STARTED } from "../../framework/data";
import { Actions } from "./actions";
import { PlayerRank } from "./model";

export interface State {
    ranking: Data<PlayerRank[]>;
}

function reduceRanking(state = NOT_STARTED, action: Actions) {
    switch(action.type) {
        case "LOAD_RANKING": return LOADING;
        case "LOAD_RANKING_FAILED": return fail(action.error);
        case "LOAD_RANKING_SUCCESS": return dataOf(action.payload);
        default: return state;
    }
}

export default combineReducers({
    ranking: reduceRanking
});