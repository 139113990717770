import React from "react";
import { Paper, makeStyles, Theme, createStyles, Typography, Grid, TextField, Button, InputAdornment, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { LeagueHeader } from "../../store/leagues/models";
import { PriceInfo } from "../../store/registration/model";
import { Alert } from "@material-ui/lab";

interface PriceConfirmationProps {
  leagueId: string;

  leagues: LeagueHeader[];

  isVerifying: boolean;

  getPriceInfoForLeague: (id: string, year: string, promoCode: string, referral: string) => void;

  priceInfo?: PriceInfo;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectPanel: {
      borderLeft: 0,
      borderRight: 0,
      margin: '0 -32px',
      padding: '16px 32px',
      backgroundColor: theme.palette.grey[100]
    }
  }),
);

const PriceConfirmation: React.FC<PriceConfirmationProps> = ({ leagueId, leagues, isVerifying, getPriceInfoForLeague, priceInfo }) => {

  const classes = useStyles();

  const [promoCode, setPromoCode] = React.useState<string>("");
  const [referral, setReferral] = React.useState<string>("");
  const league = React.useMemo(() => leagues.find(x => x.id === leagueId), [leagues, leagueId]);

  return <>
    <Paper variant="outlined" className={classes.selectPanel} square>
      <Typography variant="subtitle1" style={{ marginLeft: 8 }}>Bazowy koszt dołączenia do ligi: <b>{league?.basePrice} PLN</b></Typography>

      {
        !!priceInfo ? <Typography variant="subtitle1" style={{ marginLeft: 8 }}>
          Kwota po zaaplikowaniu kodu promocyjnego: <b>{priceInfo.finalPrice} PLN</b>
        </Typography> : <></>
      }
      {
        !!priceInfo && priceInfo.promoCodeDescription ? <Typography style={{ marginLeft: 8 }} variant="subtitle1" color={priceInfo.promoCodeOk ? "primary" : "error"}>
          {priceInfo.promoCodeDescription}
        </Typography> : <></>
      }
      {
        !!priceInfo && priceInfo.referralDescription ? <Typography style={{ marginLeft: 8 }} variant="subtitle1" color={priceInfo.referralOk ? "primary" : "error"}>
        {priceInfo.referralDescription}
      </Typography> : <></>
      }

      <Grid container style={{ marginLeft: 8, marginTop: 8 }}>
        <Grid item xs={12} md={6}>
          <Alert variant="standard" severity="info">
            Aby zaaplikować kod promocyjny i/lub kod polecającego należy wcisnąć przycisk <b>'Użyj kodu'</b>
          </Alert>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginLeft: 8, marginTop: 8 }}>
        <Grid item>
          <TextField
            style={{ minWidth: 200 }}
            label="Kod promocyjny"
            variant="outlined"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            disabled={isVerifying}
          ></TextField>
        </Grid>
        <Grid>
          <TextField
            style={{ minWidth: 200 }}
            label="Kod polecającego"
            variant="outlined"
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
            disabled={isVerifying}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      <>
                        <Typography variant="body2">
                          Kod, który dostałeś/-aś od osoby, która
                          zaprosiła Cię do ligi. Składa się z 8-miu dużych
                          liter i cyfr, np. 85M3JUQ1
                                  </Typography>
                        <Typography variant="body2" color="secondary">
                          SmartPunkty zostaną dodane, tylko jeśli jesteś
                          nowym zawodnikiem
                                  </Typography>
                      </>
                    }
                  >
                    <HelpIcon
                      color="primary"
                      fontSize="small"
                      style={{ marginLeft: "4px" }}
                    ></HelpIcon>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" disabled={isVerifying} onClick={() => getPriceInfoForLeague(leagueId, league?.year || "", promoCode, referral)} >Użyj kodu</Button>
        </Grid>
      </Grid>
    </Paper>
  </>;
}

export default PriceConfirmation;