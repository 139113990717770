import * as React from "react";
import { Player } from "../../store/players/models";
import { AppState } from "../../store/reducer";
import { connect } from "react-redux";
import { Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, TablePagination } from "@material-ui/core";
import { loadAllPlayers } from "../../store/players/actions";
import { Dispatch } from "redux";
import { Data } from "../../framework/data";
import { useAuth0 } from "@auth0/auth0-react";
import { take, drop, sortBy } from "ramda";

interface AdminAllPlayersPageProps {
    players: Data<Player[]>;
    loadPlayers: (t: string) => void;
}

const AdminAllPlayersPage: React.FC<AdminAllPlayersPageProps> = ({players, loadPlayers}) => {

    const { getAccessTokenSilently } = useAuth0();

    const [page, setPage] = React.useState();
    const [pageSize, setPageSize] = React.useState();

    const orderedPlayers = players.type === "LOADED" ? sortBy<Player>(x => `${x.surname}${x.name}`, players.value) : [];

    const viewItems = take(
        pageSize || 25,
        drop((pageSize || 25) * (page || 0), orderedPlayers)
      );

    React.useEffect(() => {
        getAccessTokenSilently().then(t => loadPlayers(t))
    }, []);

    return (
    <Grid container spacing={2} direction="column">   
        <Grid item>
        
        <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nazwisko</TableCell>
              <TableCell>Imię</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Nr tel.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewItems.map((player, i) => (
              <TableRow key={i} >
                <TableCell>
                  {player.surname}
                </TableCell>
                <TableCell>
                  {player.name}
                </TableCell>
                <TableCell>
                  {player.email}
                </TableCell>
                <TableCell>
                  {player.phoneNumber}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
        component="div"
        count={orderedPlayers.length}
        rowsPerPage={pageSize ?? 25}
        page={page ?? 0}
        onChangePage={(e, p) => {
          setPage(p);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(parseInt(e.target.value));
        }}
      />
        
        </Grid>
    </Grid>
    );

}

const mapStateToProps = (state: AppState) => {
    return {
      players: state.player.all
    };
  };
  
  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadPlayers: (t: string) => dispatch(loadAllPlayers({token: t}))
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdminAllPlayersPage);
  