import React from "react";
import { Typography } from "@material-ui/core";

const CookiesPolicyPage: React.FC = () => (
  <Typography>
    <br />
    <br />
    ADMINISTRATOR DANYCH OSOBOWYCH
    <br />
    <br />
    Administratorem danych osobowych jest: <br />
    <ul>
      <li>
       4Steps Solutions PSA, NIP: 6772481296, REGON: 522591801, ul. Samuela Bogumiła Lindego 1c, 30-148 Kraków, tel. kontaktowy: +48603300721
      </li>
    </ul>
    <br />
    DANE OSOBOWE
    <br />
    <br />
    Dane osobowe zbierane przez Administratora przetwarzane są na zasadach
    określonych w przepisach o ochronie danych osobowych, w tym w rozporządzeniu
    Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
    sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych
    i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
    95/46/WE (ogólne rozporządzenie o ochronie danych) ("RODO") oraz w polskich
    przepisach wydanych w związku z RODO, w tym m.in. ustawie z dnia 10 maja
    2018 r. o ochronie danych osobowych. Administratorzy zachowują dane osobowe
    w całkowitej poufności i zabezpieczają je przed niepowołanym dostępem osób
    trzecich na zasadach określonych w wyżej wskazanych aktach prawnych.
    <br />
    <br />
    INSPEKTOR DANYCH OSOBOWYCH
    <br />
    <br />
    W razie pytań dotyczących przetwarzania danych prosimy o kontakt pod adresem
    poczty elektronicznej lub <a href="mailto:biuro@smarttennis.pl">biuro@smarttennis.pl</a>
    <br />
    <br />
    UPRAWNIENIA OSÓB, KTÓRYCH DOTYCZĄ DANE OSOBOWE
    <br />
    <br />
    Przetwarzanie Państwa danych osobowych odbywa się na zasadzie całkowitej
    dobrowolności, jednakże w zależności od okoliczności odmowa udostępnienia
    danych lub żądanie ich usunięcia może uniemożliwić nam np. kontakt z
    Państwem czy przekazywanie Państwu informacji o działalności Administratorów
    lub wykonanie usługi na Państwa rzecz.
    <br />
    <br />
    W każdym czasie przysługuje Państwu prawo dostępu do Państwa danych
    osobowych, prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania
    oraz prawo do przenoszenia danych. Niektóre z tych uprawnień mogą nie
    przysługiwać w zakresie przetwarzania danych w ramach świadczenia pomocy
    prawnej.
    <br />
    <br />
    Zgodnie z zasadami określonymi w przepisach prawnych dotyczących ochrony
    danych osobowych i w przewidzianym w nich zakresie przysługuje Państwu prawo
    dostępu do Państwa danych osobowych, prawo ich sprostowania, usunięcia lub
    ograniczenia przetwarzania oraz prawo do przenoszenia danych. Jeżeli nie
    chcą Państwo, aby Administrator przetwarzał Państwa dane osobowe w celach
    marketingowych, mogą Państwo w każdym czasie zgłosić sprzeciw wobec
    przetwarzania danych osobowych w tym celu.
    <br />
    <br />
    PRZETWARZANIE DANYCH OSOBOWYCH – ZAKRES I CELE
    <br />
    <br />W ramach swoich działalności Administrator zbiera i przetwarza dane
    osobowe:
    <ol>
      <li>
        w celach wynikających z prawnie uzasadnionych interesów realizowanych
        przez Administratora: w celu świadczenia usług na rzecz Klientów, oraz w
        celu zapewnienia bezpieczeństwa teleinformatycznego związanego z witryną
        liga.smarttennis.pl (art. 6 ust. 1 lit. f RODO);
      </li>
      <li>
        w celu wykonywania umów zawieranych przez Administratora z klientami
        oraz innymi podmiotami współpracującymi z Administratorem (art. 6 ust. 1
        lit. b RODO);
      </li>
    </ol>
    Dane osobowe są przetwarzane również w zakresie wypełnienia obowiązków
    prawnych ciążących na Administratorze (art. 6 ust. 1 lit. c RODO).
    <br />
    <br />
    Państwa dane osobowe nie są przedmiotem profilowania.
    <br />
    <br />
    OKRES PRZETWARZANIA
    <br />
    <br />
    Dane osobowe będą przetwarzane, odpowiednio, do czasu zrealizowania
    właściwych prawnie uzasadnionych interesów Klientów realizowanych przez
    Administratora związanych z przetwarzaniem określonych danych, do czasu
    upływu terminów przedawnienia związanych z umową, z którą jest związane
    przetwarzanie danych osobowych, lub związanych z przedawnieniem obowiązków
    wynikających z przepisów prawa lub do czasu wniesienia sprzeciwu w zakresie
    przetwarzania w celach marketingowych.
    <br />
    <br />
    PRZEKAZYWANIE DANYCH OSOBOWYCH INNYM PODMIOTOM
    <br />
    <br />
    Odbiorcami Państwa danych osobowych mogą być w szczególności podmioty
    powiązane bezpośrednio lub pośrednio z Administratorem i, w szczególności w
    sposób organizacyjny lub osobowy, które miałyby siedzibę w jednym z Państw
    Członkowskich Unii Europejskiej, oraz inne podmioty przetwarzające dane w
    imieniu Administratora na podstawie umowy powierzenia przetwarzania danych
    osobowych), księgowi, biegli rewidenci i audytorzy, prawnicy, organy
    administracji, służby państwowe i sądy.
    <br />
    <br />
    SIEDZIBY PRZETWARZANIA PAŃSTWA DANYCH OSOBOWYCH
    <br />
    <br />
    Państwa dane osobowe przetwarzane są w siedzibie Administratora. Dane
    osobowe nie są przekazywane do krajów znajdujących się poza Unią Europejską.
    W razie przekazania danych osobowych do państw trzecich znajdujących się
    poza UE Administrator zastosuje odpowiednie instrumenty mające na celu
    zapewnienie bezpieczeństwa Państwa danych osobowych.
    <br />
    <br />
    PRAWO DO WNIESIENIA SKARGI
    <br />
    <br />
    Organem nadzorczym w zakresie danych osobowych w Polsce jest obecnie Prezes
    Urzędu Ochrony Danych Osobowych. Jest Pan/Pani uprawniony wniesienia skargi
    do organu nadzorczego, a w przypadku przetwarzania danych na podstawie zgody
    – prawo do jej cofnięcia.
    <br />
    <br />
    ZMIANY POLITYKI PRYWATNOŚCI
    <br />
    <br />
    Wszelkie zmiany wprowadzane przez nas do Polityki Prywatności w przyszłości
    będą publikowane w witrynie liga.smarttennis.pl. Jeśli nie akceptują Państwo naszej
    Polityki Prywatności lub zmian w Polityce Prywatności, powinni Państwo
    zaprzestać korzystania z naszej strony internetowej.
    <br />
    <br />
  </Typography>
);

export default CookiesPolicyPage;
